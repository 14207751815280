import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import CustomText from "components/MyComponents/CustomText";
import CustomTextArea from "components/MyComponents/CustomTextArea";
import { Field, Form, Formik, FormikValues } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { CONTACT_FORM_ACT } from "./store/actionsContactForm";
import HorizontalDivider from "components/MyComponents/HorizontalDivider";
import { DialogWrapper } from "components/Layout20/DialogWrapper";

const CONTACT_FORM_VALIDATOR = YUP_VALIDATION.YUP_OBJ.shape({
  fromEmail: YUP_VALIDATION.YUP_STRING_REQ_EMAIL,
  fromName: YUP_VALIDATION.YUP_STRING_ANY_CHARS_REQ,
  messageTitle: YUP_VALIDATION.YUP_STRING_ANY_CHARS_REQ,
  messageContent: YUP_VALIDATION.YUP_STRING_ANY_CHARS_REQ,
});

const ContactFormPageContent = () => {

  const dispatch = useDispatch();
  const contactFormStore = useSelector((state: any) => state.ContactFormStore);
  const contractorId = useSelector((state: any) => state.LoginState.contractorId);
  const companyId = useSelector((state: any) => state.LoginState.companyId);
  const email = useSelector((state: any) => state.LoginState.email);
  const userFullName = useSelector((state: any) => state.LoginState.userFullName);
  const onSubmit = (values: FormikValues) => {
    dispatch(CONTACT_FORM_ACT.sendFormData(values));
  };


  return (
    <>
      <Formik
        initialValues={
          {
            fromEmail: email ? email : '',
            fromName: userFullName ? userFullName : '',
            messageTitle: '',
            messageContent: '',
            contractorId,
            companyId
          }
        }
        validationSchema={CONTACT_FORM_VALIDATOR}
        onSubmit={onSubmit}
      >
        <Form>
          <Row>
            <Col xs={12} lg={6}>
              <Field name="fromEmail" label="ContactForm.Data.fromEmail" component={CustomText} isDisabled={contactFormStore.areChangesInProgress} />
            </Col>
            <Col xs={12} lg={6}>
              <Field name="fromName" label="ContactForm.Data.fromName" component={CustomText} isDisabled={contactFormStore.areChangesInProgress} />
            </Col>
            <Col xs={12} >
              <Field name="messageTitle" label="ContactForm.Data.messageTitle" component={CustomText} isDisabled={contactFormStore.areChangesInProgress} />
            </Col>
            <Col xs={12}>
              <Field name="messageContent" label="ContactForm.Data.messageContent" component={CustomTextArea} isDisabled={contactFormStore.areChangesInProgress} />
            </Col>
          </Row>
          <Row className="pb-3">
            <Col><ButtonSubmit
              id="button-contact-form-send"
              isLoading={contactFormStore.areChangesInProgress}
              label="ContactForm.Data.Submit"
              iconClass="fa-light fa-envelope"
            /></Col>

          </Row>
        </Form>
      </Formik>

      {(companyId === 'ALG' || companyId === 'AC' || companyId === 'QA' || companyId === 'AT') &&
        <Row className="justify-content-center py-3">
          <Col xs="auto">
            <a href="https://chat.whatsapp.com/LLX4LpXK3Vn76mcGyaF7aA" target="_blank" rel="noreferrer">
              <img src="/img/whatsapp.svg" />
            </a>
          </Col>
          <Col xs="auto">
            <a href="https://m.me/113650986776346" target="_blank" rel="noreferrer">
              <img src="/img/messanger.svg" />
            </a>
          </Col>
          <Col xs="auto">
            <a href="https://www.facebook.com/avalon.partner" target="_blank" rel="noreferrer">
              <img src="/img/facebook.svg" />
            </a>
          </Col>
          <Col xs="auto">
            <a href="https://www.instagram.com/avalon_logistics_group/" target="_blank" rel="noreferrer">
              <img src="/img/instagram.svg" />
            </a>
          </Col>
        </Row>
      }
      
    </>

  );
};


export default ContactFormPageContent; 