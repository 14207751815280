import { getDbFileImage, getDbFilePdf } from "./api_helper";

export function downloadPdf(data, fileName) {
    
    const linkSource = data;
    const downloadLink = document.createElement("a");    
    downloadLink.href = "data:application/pdf;base64, "+linkSource;
    downloadLink.download = fileName;
    downloadLink.click()
}
 
export function downloadDbFileGet(fileId) {    
    
    getDbFilePdf(fileId)
        .then((fileData)=> {
            const downloadLink = document.createElement("a");    
            downloadLink.href = "data:"+fileData.contentType+";base64, "+fileData.base64Data;
            downloadLink.download = fileData.fileName;
            downloadLink.click()
        })
}


export function downloadImageFileGet(fileId) {    
    
    getDbFileImage(fileId)
        .then((fileData)=> {
            const downloadLink = document.createElement("a");    
            downloadLink.href = "data:"+fileData.contentType+";base64, "+fileData.base64Data;
            downloadLink.download = fileData.fileName;
            downloadLink.click()
        })
}