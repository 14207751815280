
export const getLogoImage20 = (companyId: string, isLight: boolean) => {


    if (companyId === 'QA') {
        if (isLight) {
            return "/img/logo/logo-20-ALG-GLOBAL.svg"
        } else {
            return "/img/logo/logo-20-ALG-GLOBAL-dark.svg"
        }
    }

    if (isLight) {
        return "/img/logo/logo-20-ALG.svg"
    } else {
        return "/img/logo/logo-20-ALG-dark.svg"
    }
}


export const getLogoImage20Width = (companyId: string, isLight: boolean) => {

    if (isLight) {
        return '100%'
    }
    return 200;
}
