import { DetailsItem } from "components/Common/datatable/DetailsItem"
import { getMoney } from "helpers/money"
import { toPln } from "helpers/pln_helper"
import { IPlaceholderMap, fillPlaceholdersHash } from "routes/placeholder_helper"

export const rowCsvItemNumber = (
    t: any, value: number | undefined | null, label: string, app: string, additionalClass?: string) => {

    if (!value) {
        return <></>
    }
    return (
        <div className={additionalClass ? additionalClass : ''}>
            <DetailsItem labelKey={`settlement.${app}.${label}`} value={value} />
        </div>
    )
}

export const rowCsvItemMoney = (
    t: any, value: number | undefined | null, label: string, app: string, additionalClass?: string) => {

    if (!value) {
        return <></>
    }
    return (
        <div className={additionalClass ? additionalClass : ''}>
            <DetailsItem labelKey={`settlement.${app}.${label}`} value={getMoney(value)} />
        </div>
    )
}

export const rowCsvItemMoneyWithZero = (
    t: any, value: number | undefined | null, label: string, app: string, additionalClass?: string, placeholdersMap?: IPlaceholderMap) => {

    if (!value && value !== 0) {
        return <></>
    }
    const transKey = t(`settlement.${app}.${label}`);
    let transValue;

    if (placeholdersMap) {
        transValue = fillPlaceholdersHash(t(transKey), placeholdersMap);
    } else {
        transValue = t(transKey);
    }
    return (
        <div className={additionalClass ? additionalClass : ''}>
            <DetailsItem labelKey={transValue} value={getMoney(value)} />
        </div>
    )
}

export const rowString = (
    t: any, value: string | undefined | null, transKey: string, additionalClass?: string) => {
    if (!value) {
        return <></>
    }
    return (
        <div className={additionalClass ? additionalClass : ''}>
            <DetailsItem labelKey={transKey} value={value} />
        </div>
    )
}


