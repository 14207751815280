import { DOCS_2_SIGN, ENDPOINT_CONFIG, get, post } from "helpers/api_helper";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";
import { ISagaParam, ResponseGridDataExtended } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { DOC2SIGN_LIST_ACT, DOC2SIGN_LIST_ACT_TYP } from "./docs2SignList";
import { reloadRoles } from "store/actions";
import { ROUTES } from "routes";

function* loadDataSaga(action: ISagaParam) {
    try {
        let gridResponse: ResponseGridDataExtended<any, any> = yield call(get, DOCS_2_SIGN.DOS_2_SIGN_GET, {})
        yield put(DOC2SIGN_LIST_ACT.loadDataDone(gridResponse.gridData, gridResponse.context, gridResponse.info, gridResponse.error));
    } catch (error) {
        console.log('docs2SignGet.loadData error', error);
        showException(error);
        yield put(DOC2SIGN_LIST_ACT.apiError(error));
    }
}

function* signDocumentAndClosePopupSaga(action: ISagaParam) {
    try {
        
        yield call(post, DOCS_2_SIGN.DOS_2_SIGN_SIGN_POST, {
            imageBase64: action.payload.dto.signature,
            documentId: action.payload.dto.extDocId
        })        
        yield put(DOC2SIGN_LIST_ACT.signDocumentAndClosePopupDone(action.payload.dto.extDocId, action.payload.history));        
        yield put(reloadRoles());         
        
        //zamknie okno
        action.payload.dto.setExtDocId(null);

        if(action.payload.dto.docsToSaveCount > 1){
            showPopupInfo('DOC2SIGN.signature.done-not-all');
        } else {
            showPopupInfo('DOC2SIGN.signature.done-all');
            action.payload.history.push(ROUTES.ROUTE_DASHBOARD);
        }
        
    } catch (error) {
        console.log('signDocumentAndClosePopupSaga error', error);
        showException(error);
        yield put(DOC2SIGN_LIST_ACT.apiError(error));
    }
}
 

export function* Docs2SignListSaga() {
    yield takeEvery(DOC2SIGN_LIST_ACT_TYP.LOAD_DATA, loadDataSaga);
    yield takeEvery(DOC2SIGN_LIST_ACT_TYP.SIGN_AND_CLOSE_POPUP, signDocumentAndClosePopupSaga);
}

export default Docs2SignListSaga;
