const PREFIX = "ATN_"

const OPEN_NEW = PREFIX + "OPEN_NEW";
const openNew = (docTypeId: string,  history: any) => ({ type: OPEN_NEW, payload: { docTypeId, history } });

const PREPARE_NEW = PREFIX + "PREPARE_NEW";
const prepareNewAtt = (docTypeId: string,  history: any) => ({ type: PREPARE_NEW, payload: { docTypeId, history } });

const PREPARE_NEW_DONE = PREFIX + "PREPARE_NEW_DONE";
const prepareNewAttDone = (documentTypeConfig: any, info: string, error: string) => ({
    type: PREPARE_NEW_DONE, payload: { documentTypeConfig, info, error }
});

const ADD_ATTACHMENT = PREFIX + "ADD_ATTACHMENT";
const addAttachment = (documentTypeConfig: any, values: any,  history: any) => ({
    type: ADD_ATTACHMENT, payload: { documentTypeConfig, values, history}
});

const ADD_ATTACHMENT_DONE = PREFIX + "ADD_ATTACHMENT_DONE";
const addAttachmentDone = () => ({
    type: ADD_ATTACHMENT_DONE, payload: {}
});

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}


export const ATTACHMENT_NEW_ACT = {
    openNew,
    prepareNewAtt, prepareNewAttDone,
    addAttachment, addAttachmentDone,
    apiError
}

export const ATTACHMENT_NEW_ACT_TYP = {
    OPEN_NEW,
    PREPARE_NEW, PREPARE_NEW_DONE,
    ADD_ATTACHMENT, ADD_ATTACHMENT_DONE,
    API_ERROR
}