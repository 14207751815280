import ButtonTripple, { ITrippeButton } from "components/MyComponents/ButtonTripple";
import { Form, useField, useFormikContext } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { isOlderThan26 } from "helpers/date_helper";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ROUTE_AGREEMENT_P2_FORM__URL_PARAMS } from "routes";
import FormItemSelectSingleField from "../FormItems/FormItemSelectSingleField";
import HelperHeader from "../FormItems/HelperHeader";
import { IFormPage } from "../IAgreement";
import AgreementPage2FormQuestionsSubPage from "./AgreementPage2FormQuestionsSubPage";
import { Col, Row } from "reactstrap";

export const QUESTIONS_VALIDATION_AC_ALG = YUP_VALIDATION.YUP_OBJ.shape({

  is_student: YUP_VALIDATION.YUP_STRING,

  is_employed: YUP_VALIDATION.YUP_STRING
    .when('is_student', { is: 'N', then: YUP_VALIDATION.YUP_STRING_REQ.oneOf(['N', 'UP', 'UZ']), }),

  employed_from: YUP_VALIDATION.YUP_DATE
    .when('is_employed', { is: 'UP', then: YUP_VALIDATION.YUP_DATE_BEFORE_NOW, })
    .when('is_employed', { is: 'UZ', then: YUP_VALIDATION.YUP_DATE_BEFORE_NOW, })
  ,
  employed_to: YUP_VALIDATION.YUP_DATE
    .when('is_employed', { is: 'UP', then: YUP_VALIDATION.YUP_DATE_AFTER_NOW_OR_EMPTY, })
    .when('is_employed', { is: 'UZ', then: YUP_VALIDATION.YUP_DATE_AFTER_NOW_OR_EMPTY, }),
  employed_salary_info: YUP_VALIDATION.YUP_STRING
    .when('is_employed', { is: 'UP', then: YUP_VALIDATION.YUP_STRING_REQ.oneOf(['BELOW', 'OVER']), })
    .when('is_employed', { is: 'UZ', then: YUP_VALIDATION.YUP_STRING_REQ.oneOf(['BELOW', 'OVER']), })
  ,
  retired: YUP_VALIDATION.YUP_STRING.when('is_student', { is: 'N', then: YUP_VALIDATION.YUP_STRING_REQ.oneOf(['N', 'R', 'E']), })
  ,
  disability_certificate: YUP_VALIDATION.YUP_STRING.when('is_student', { is: 'N', then: YUP_VALIDATION.YUP_STRING_REQ.oneOf(['N', 'S', 'M', 'L']), }),
  disability_certificate_from: YUP_VALIDATION.YUP_DATE
    .when('disability_certificate', { is: 'S', then: YUP_VALIDATION.YUP_DATE_BEFORE_NOW, })
    .when('disability_certificate', { is: 'M', then: YUP_VALIDATION.YUP_DATE_BEFORE_NOW, })
    .when('disability_certificate', { is: 'L', then: YUP_VALIDATION.YUP_DATE_BEFORE_NOW, })
  ,
  disability_certificate_to: YUP_VALIDATION.YUP_DATE
    .when('disability_certificate', { is: 'S', then: YUP_VALIDATION.YUP_DATE_AFTER_NOW, })
    .when('disability_certificate', { is: 'M', then: YUP_VALIDATION.YUP_DATE_AFTER_NOW, })
    .when('disability_certificate', { is: 'L', then: YUP_VALIDATION.YUP_DATE_AFTER_NOW, })
  ,
});

export const QUESTIONS_VALIDATION_QA = YUP_VALIDATION.YUP_OBJ.shape({
  is_student: YUP_VALIDATION.YUP_STRING_REQ,
  //ta_cit: YUP_VALIDATION.YUP_STRING.when('ta_ca_equal', { is: false, then: YUP_VALIDATION.YUP_STRING_REQ, }),

});

interface ICustomFormInterface extends ITrippeButton {
  isLoading: boolean,
  
  pageConfig: IFormPage,
}

const AgreementPage2FormQuestions = ({ isLoading,  pageConfig, onClickBack, onClickList }: ICustomFormInterface) => {

  const allData = useSelector((state: any) => state.AgreementSignStore.agreementData.allData);
  const companyId = useSelector((state: any) => state.LoginState.companyId);

  const birthDate = allData['bir_dat'];

  //dla QA nie sprawdzamy wieku
  const isCourierOlderThan26 = 'QA' !== companyId && isOlderThan26(birthDate.dateValue);

  const [isBasePage, setIsBasePage] = useState(!isCourierOlderThan26);
  const [is_student] = useField("is_student");
  const formik = useFormikContext();

  useEffect(() => {

    window.scrollTo(0, 70)

    if (isCourierOlderThan26) {
      formik.setFieldValue("is_student", "N");
    }
  }, [])

  const onClickBackQuestions = () => {
    if (isCourierOlderThan26 || isBasePage) {
      if (onClickBack) { onClickBack() }
    } else {
      setIsBasePage(true);
    }
  }

  const setDefaultQuestionValuesForStudent = () => {

    formik.setFieldValue("is_employed", "N") //N jeśli ma domyślnie być wyłączone
    formik.setFieldValue("employed_from", "")
    formik.setFieldValue("employed_to", "")
    formik.setFieldValue("employed_salary_info", "")

    formik.setFieldValue("retired", "N") //N jeśli ma domyślnie być wyłączone

    formik.setFieldValue("disability_certificate", "N") //N jeśli ma domyślnie być wyłączone
    formik.setFieldValue("disability_certificate_from", "")
    formik.setFieldValue("disability_certificate_to", "")
  }

  const cleanHiddenValues = () => {

    const isEmp = formik.getFieldMeta("is_employed").value;
    if (isEmp !== 'UP' && isEmp !== 'UZ') {
      formik.setFieldValue("employed_from", "")
      formik.setFieldValue("employed_to", "")
      formik.setFieldValue("employed_salary_info", "")
    }

    const disCert = formik.getFieldMeta("disability_certificate").value;
    if (disCert !== 'S' && disCert !== 'M' && disCert !== 'L') {
      formik.setFieldValue("disability_certificate_from", "")
      formik.setFieldValue("disability_certificate_to", "")
    }
  }

  const onNextQuestions = () => {
    if (isBasePage) {

      if (is_student.value) {
        if (is_student.value === 'Y') {
          setDefaultQuestionValuesForStudent()
          formik.submitForm();
        } else {
          setIsBasePage(false);
        }
      }
    } else {
      cleanHiddenValues();
      formik.submitForm();
    }
  }
  let { pageKey } = useParams<ROUTE_AGREEMENT_P2_FORM__URL_PARAMS>();

  return (
    <Form autoComplete="off" autoCorrect="off" key={pageKey + "address-data-form"} >
      {isBasePage &&
        <>
          <HelperHeader formItem={pageConfig.fieldsMap['top_header_page1']} context="top_header_page1" pageKey={pageKey} />
          <FormItemSelectSingleField
            //autoFocus={true} 
            formItem={
              pageConfig.fieldsMap['is_student']} context="is_student" pageKey={pageKey}
            options={
              [
                { "value": "Y", "label": t(pageKey + '.is_student.Y') },
                { "value": "N", "label": t(pageKey + '.is_student.N') }
              ]
            }
          />
        </>
      }
      {!isBasePage &&
        <>
          <HelperHeader formItem={pageConfig.fieldsMap['top_header_page2']} context="top_header_page2" pageKey={pageKey} />
          <AgreementPage2FormQuestionsSubPage pageConfig={pageConfig} />
        </>
      }
      <ButtonTripple
        id={`button-wizard-questions-${pageKey}`}
        isLoading={isLoading} label={pageKey + ".Submit"}
        onClickBack={onClickBackQuestions}
        onClickList={onClickList}
        onNext={onNextQuestions}
      />
    </Form >
  );
};

export default AgreementPage2FormQuestions; 