import PRE_REG_ACTIONS_TYPES from "./actionTypes";
import _ from 'lodash';

const INIT_STATE_REG_PRE = {
    page: 0, //auth data by default
    regUserData: {
        page0Data: { email: '', password: '', passwordConfirmation: '' },
        page1Data: { firstName: '', lastName: '', phone: '', suggestedCityId: '', languageId: '', nationalityId: '', marketingConsents: false },
        page2Data: { taxiOrCourierDecision: '' },
        page3Data: { suggestedCityId: '' },
        page4Data: { appIds: [] },
        page5Data: { suggestedVehicleTypeId: '', bagTypeId: '', registrationSource: '' }
    },
    isLoading: false, //already set by empty regUserData 
    error: '', //no error by default
    cityDomain: [],
    vehicleDomainTaxi: [],
    vehicleDomainCourier: [],
    taxiOrCourierDecisionDomain: [],
    languageDomain: [],
    nationalityDomain: [],
    appsDomain: [],
    addonBagsDomain: [],
    registrationSourceDomain: [],
};

const PreRegistration = (state = _.cloneDeep(INIT_STATE_REG_PRE), action: any) => {
    switch (action.type) {
        case PRE_REG_ACTIONS_TYPES.REG_PAGE_BACK:
            return { ...state, page: state.page - 1 }
        //page0
        case PRE_REG_ACTIONS_TYPES.STEP_0_PATCH_DATA_START:
            return { ...state, isLoading: true };
        case PRE_REG_ACTIONS_TYPES.STEP_0_PATCH_DATA_UPDATED:
            return {
                ...state, isLoading: false,
                regUserData: action.payload.regUserData, error: "", page: 1,
                languageDomain: action.payload.dictionaries.languageDomain,
                nationalityDomain: action.payload.dictionaries.nationalityDomain,
            }
        case PRE_REG_ACTIONS_TYPES.STEP_0_PATCH_DATA_NO_CHANGES:
            return { ...state, isLoading: false, error: "", page: 1 }
        //page1
        case PRE_REG_ACTIONS_TYPES.STEP_1_PATCH_DATA_START:
            return { ...state, isLoading: true };
        case PRE_REG_ACTIONS_TYPES.STEP_1_PATCH_DATA_UPDATED:
            return {
                ...state, isLoading: false,
                regUserData: action.payload.regUserData, error: "", page: 2,              
                taxiOrCourierDecisionDomain: action.payload.dictionaries.taxiOrCourierDecisionDomain,
            }
        case PRE_REG_ACTIONS_TYPES.STEP_1_PATCH_DATA_NO_CHANGES:
            return { ...state, isLoading: false, error: "", page: 2 }
        //page2
        case PRE_REG_ACTIONS_TYPES.STEP_2_PATCH_DATA_START:
            return { ...state, isLoading: true };
        case PRE_REG_ACTIONS_TYPES.STEP_2_PATCH_DATA_UPDATED:
            return {
                ...state, isLoading: false,
                regUserData: action.payload.regUserData, error: "", page: 3,
                cityDomain: action.payload.dictionaries.cityDomain,
            }
        case PRE_REG_ACTIONS_TYPES.STEP_2_PATCH_DATA_NO_CHANGES:
            return { ...state, isLoading: false, error: "", page: 3 }
        //page3
        case PRE_REG_ACTIONS_TYPES.STEP_3_PATCH_DATA_START:
            return { ...state, isLoading: true }
        case PRE_REG_ACTIONS_TYPES.STEP_3_PATCH_DATA_UPDATED:
            return {
                ...state, isLoading: false, regUserData: action.payload.regUserData, error: "", page: 4,
                appsDomain: action.payload.dictionaries.appsDomain,
                vehicleDomainTaxi: action.payload.dictionaries.vehicleDomainTaxi,
                vehicleDomainCourier: action.payload.dictionaries.vehicleDomainCourier,
            }
        case PRE_REG_ACTIONS_TYPES.STEP_3_PATCH_DATA_NO_CHANGES:
            return { ...state, isLoading: false, error: "", page: 4 }
        //page4
        case PRE_REG_ACTIONS_TYPES.STEP_4_PATCH_DATA_START:
            return { ...state, isLoading: true }
        case PRE_REG_ACTIONS_TYPES.STEP_4_PATCH_DATA_UPDATED:
            return {
                ...state, isLoading: false, regUserData: action.payload.regUserData, error: "", page: 5,
                addonBagsDomain: action.payload.dictionaries.addonBagsDomain,
                registrationSourceDomain: action.payload.dictionaries.registrationSourceDomain,
            }
        case PRE_REG_ACTIONS_TYPES.STEP_4_PATCH_DATA_NO_CHANGES:
            return { ...state, isLoading: false, error: "", page: 5 }
        //page4
        case PRE_REG_ACTIONS_TYPES.STEP_5_PATCH_DATA_START:
            return { ...state, isLoading: true }
        case PRE_REG_ACTIONS_TYPES.STEP_5_PATCH_DATA_UPDATED:
            return { ...state, ...INIT_STATE_REG_PRE }
        //{ ...state, isLoading: false, regUserData: action.payload.regUserData, error: "" }
        //other
        case PRE_REG_ACTIONS_TYPES.API_ERROR:
            return { ...state, isLoading: false, error: action.payload.error };
        case PRE_REG_ACTIONS_TYPES.GO_TO_SUMMARY:
            return { ...state, page: 6 }
        case PRE_REG_ACTIONS_TYPES.RESET_AND_GOTO_LOGIN:
            return { ...state, ...INIT_STATE_REG_PRE }
        default: {
            return state;
        }
    }
}

export default PreRegistration;