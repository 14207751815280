import { getAppSettlementLogo } from "helpers/image_helper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "routes";

interface IMenuItem {
    onClickCallback?: () => void;
}

const MenuItemDynamicSuggestedApps = ({ onClickCallback }: IMenuItem) => {

    const { t } = useTranslation();
    const history = useHistory();
    const suggestedApplications = useSelector((state: any) => state.LoginState.suggestedApplications);
    const [currentImageId, setCurrentImageId] = useState(0);

    useEffect(() => {
        let intervalId: any;

        if (suggestedApplications?.length > 1) {
            intervalId = setInterval(() => {
                setCurrentImageId(currentImageId => (currentImageId + 1) % suggestedApplications.length);
            }, 4000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
    }, [suggestedApplications])


    if (suggestedApplications?.length === 0) {
        return <></>
    }

    const onLinkClick = () => {
        history.push(ROUTES.ROUTE_SUGGEST_APP);
        if (onClickCallback) {
            onClickCallback();
        }
    }
    return <li className={history.location.pathname === ROUTES.ROUTE_SUGGEST_APP ? "active" : ""}
        onClick={onLinkClick}><span>
            <img src='/img/figma/HomeAdd.svg' className="icon forced-icon-color" />
            <span className="d-inline-flex justify-content-between align-items-center">
                <span>{t('SuggestApp.NAV')} &nbsp;</span>
                {suggestedApplications.length > currentImageId &&                    
                    <div className="app-icon suggested-app-nav-div">
                        <img className="ping suggested-app-nav-img" src={getAppSettlementLogo(suggestedApplications[currentImageId].value)} alt={suggestedApplications[currentImageId].label}
                        />
                    </div>
                }
            </span>
        </span>
    </li>
}

export default MenuItemDynamicSuggestedApps;