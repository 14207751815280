import './ProgressIndicator.scss';

type ProgressIndicatorProps = {
    step: number;
    stepCount: number;
}

export const ProgressIndicator = ({ step, stepCount }: ProgressIndicatorProps) => {

    return (
        <div> 
            <div className="stepper-wizard pb-3">
                {
                    [...Array(stepCount)].map((e, i) => <div className={'step w-100' + ((i) <= step ? ' active' : ' inactive')} key={'pbs-' + i} >&nbsp;</div>)
                }
            </div>
        </div>
    );
}