const PREFIX = "DASH_"
const REMOVE_FROM_DASHBOARD = PREFIX + "REMOVE_FROM_DASHBOARD";
const GET_DATA = PREFIX + "GET_DATA";
const FORCE_SETTLEMENT = PREFIX + "FORCE_SETTLEMENT";
const API_SUCCESS = PREFIX + "API_SUCCESS";
const API_FAIL = PREFIX + "API_FAIL";

export const DASHBOARD_ACTION_NAMES = {
    GET_DATA, FORCE_SETTLEMENT, 
    REMOVE_FROM_DASHBOARD,
    API_SUCCESS,
    API_FAIL
}