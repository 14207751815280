import { Redirect } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/login/Login"


import AgreementPage0Navbar from "pages/Agreement/AgreementPage0Navbar"
import AgreementPage0Type from "pages/Agreement/AgreementPage0Type"
import AgreementPage1Wizard from "pages/Agreement/AgreementPage1Wizard"
import AgreementPage2Form from "pages/Agreement/AgreementPage2Form"
import AgreementPage3SmsConfirmation from "pages/Agreement/AgreementPage3SmsConfirmation"
import AgreementPage4Sign from "pages/Agreement/AgreementPage4Sign"
import AgreementPage5SignSummary from "pages/Agreement/AgreementPage5SignSummary"
import AgreementListPage from "pages/AgreementList/AgreementListPage"
import LoginAffiliationWrapper from "pages/Authentication/login/LoginAffiliationWrapper"
import RegisterPageStepper from "pages/Authentication/register/steps/RegisterPageStepper"
import RegisterPageStepperAffiliationWrapper from "pages/Authentication/register/steps/RegisterPageStepperAffiliationWrapper"
import InvoiceListPage from "pages/InvoiceList/InvoiceListPage"
import SettlmentListPage from "pages/SettlementList/SettlmentListPage"
import ForgetPassword from "../pages/Authentication/ForgetPassword"
import PrivacyPolicy from "../pages/Authentication/PrivacyPolicy"
import DashboardPage from "../pages/Dashboard/DashboardPage"
import AttachmentListPage from "pages/AttachmentList/AttachmentListPage"
import AttachmentNewPage from "pages/AttachmentNew/AttachmentNew"
import InvouceNewPage from "pages/InvoiceNew/InvoiceNew"
import ProfilePage from "pages/Profile/ProfilePage"
import AffiliationLinkPage from "pages/AffiliationLink/AffiliationLinkPage"
import PdfViewerPage from "pages/PdfViewer/PdfViewerPage"
import ProfileRemovalPage from "pages/ProfileRemoval/ProfileRemovalPage"
import ContactFormPage from "pages/ContactForm/ContactFormPage"
import ContactFormPageUnlogged from "pages/Authentication/ContactFormPageUnlogged"
import ProfileBankAccountPage from "pages/ProfileBankAccount/ProfileBankAccountPage"
import PdfViewerPageUnsavedAgreementPreview from "pages/PdfViewer/PdfViewerPageUnsavedAgreementPreview"
import DealAddPage from "pages/DealAdd/DealAddPage"
import NotyficationsListPage from "pages/NotificationsList/NotyficationsListPage"
import Docs2SignListPage from "pages/Docs2SignList/Docs2SignListPage"

export type ROUTE_WITH_AFFILATION = {
  channel: string;
  affiliationKey: string;
};

export type ROUTE_AGREEMENT_P1_WIZARD__URL_PARAMS = {
  agreementType: string;
};
export type ROUTE_AGREEMENT_P2_FORM__URL_PARAMS = {
  agreementType: string;
  pageKey: string;
};

export type ROUTE_AGREEMENT_P5_SUMMARY = {
  agreementDocNumber: string;
};



const ROUTE_SUGGEST_APP = "/app-add"

const ROUTE_NOTIFICATIONS_LIST = "/notifications-list"
const ROUTE_NOTIFICATIONS_LIST_WITH_ID = "/notifications-list/:notificationId"

export type ROUTE_NOTIFICATION_ID = {
  notificationId?: string
};


const ROUTE_INVOICE_LIST = "/invoice-list"
const ROUTE_INVOICE_ADD = "/invoice-add"

const ROUTE_EXT_DOC_TO_SIGN = "/docs-to-sign"


const ROUTE_SETTLEMENT_LIST = "/settlement-list"
const ROUTE_SETTLEMENT_LIST_WITH_ID = "/settlement-list/:settlementId"

export type ROUTE_SETTLEMENT_WITH_ID = {
  settlementId?: string
};

const ROUTE_AGREEMENT_LIST = "/agreement-list"

export type ROUTE_ATTACHMENT_TYPE__URL_PARAMS = {
  docTypeId: string;
};

const ROUTE_PDF_PREVIEW = "/pdf-preview/:pdfFileId/:pdfFileName"
const ROUTE_PDF_PREVIEW_AGREEMENT_TEMPLATE = "/pdf-preview/:agreementType"


const ROUTE_ATTACHMENT_LIST = "/attachment-list"
const ROUTE_ATTACHMENT_NEW = "/attachment-new/:docTypeId"


const ROUTE_AGREEMENT_P0_NAVBAR = "/agreement"
const ROUTE_AGREEMENT_P0_TYPE_SELECTION = "/agreement-type"
const ROUTE_AGREEMENT_P1_WIZARD = "/agreement-wizard/:agreementType"
const ROUTE_AGREEMENT_P2_FORM = "/agreement-form/:agreementType/:pageKey"
const ROUTE_AGREEMENT_P3_SMS_CONFIRM = "/agreement-sms/:agreementType"
const ROUTE_AGREEMENT_P4_SIGN = "/agreement-sign/:agreementType"
const ROUTE_AGREEMENT_P5_SIGN_SUMMARY = "/agreement-sign/summary/:agreementDocNumber"

const ROUTE_CONTACT_FORM = "/contact-form"
const ROUTE_CONTACT_FORM_UNLOGGED = "/public-contact-form"
const ROUTE_PRIVACY_POLICY = "/privacy-policy"

const ROUTE_DASHBOARD = "/dashboard"
const ROUTE_PROFILE = "/profile"
const ROUTE_PROFILE_REMOVE = "/profile/remove"
const ROUTE_PROFILE_BANK_ACCOUNT = "/profile/bank-account"
const ROUTE_AFF_LINK_SEND = "/earn-with-us"

const ROUTE_PRE_REGISTRATION_WIZARD = "/register"
const ROUTE_PRE_REGISTRATION_WIZARD_AFFILIATION = "/register/:channel/:affiliationKey"

const isRegistrationRoute = (path: any) => {
  let pathName = path.pathname;
  return (
    pathName === ROUTE_PRE_REGISTRATION_WIZARD_AFFILIATION ||
    pathName === ROUTE_PRE_REGISTRATION_WIZARD
  );
}

export const ROUTES = {

  //weryfikacja czy wymagany route rejestracyjny
  isRegistrationRoute,

  //rejestracja pre - dla własnej rejestracji
  ROUTE_PRE_REGISTRATION_WIZARD,
  ROUTE_PRE_REGISTRATION_WIZARD_AFFILIATION,

  //lista umów
  ROUTE_AGREEMENT_LIST,

  //lista załączników
  ROUTE_ATTACHMENT_LIST,
  ROUTE_ATTACHMENT_NEW,

  //podgląd PDF
  ROUTE_PDF_PREVIEW,
  ROUTE_PDF_PREVIEW_AGREEMENT_TEMPLATE,

  //umowy
  ROUTE_AGREEMENT_P0_NAVBAR,
  ROUTE_AGREEMENT_P0_TYPE_SELECTION,
  ROUTE_AGREEMENT_P1_WIZARD,
  ROUTE_AGREEMENT_P2_FORM,
  ROUTE_AGREEMENT_P3_SMS_CONFIRM,
  ROUTE_AGREEMENT_P4_SIGN,
  ROUTE_AGREEMENT_P5_SIGN_SUMMARY,
  //logged in routes
  ROUTE_DASHBOARD,

  //profile
  ROUTE_PROFILE,
  ROUTE_PROFILE_REMOVE,
  ROUTE_PROFILE_BANK_ACCOUNT,

  ROUTE_CONTACT_FORM,
  ROUTE_CONTACT_FORM_UNLOGGED,
  ROUTE_PRIVACY_POLICY,

  //sekcja linków afiliacyjnych
  ROUTE_AFF_LINK_SEND,

  //
  ROUTE_SUGGEST_APP,

  //dokumenty do podpisu
  ROUTE_EXT_DOC_TO_SIGN,

  //lista rozliczeń
  ROUTE_SETTLEMENT_LIST,
  ROUTE_SETTLEMENT_LIST_WITH_ID,

  //faktury
  ROUTE_INVOICE_LIST,
  ROUTE_INVOICE_ADD,

  ROUTE_NOTIFICATIONS_LIST,
  ROUTE_NOTIFICATIONS_LIST_WITH_ID

}

const authProtectedRoutes = [
  { path: ROUTE_DASHBOARD, component: DashboardPage },

  //profile
  { path: ROUTE_PROFILE, component: ProfilePage },
  { path: ROUTE_PROFILE_REMOVE, component: ProfileRemovalPage },
  { path: ROUTE_PROFILE_BANK_ACCOUNT, component: ProfileBankAccountPage },

  //notifications
  { path: ROUTE_NOTIFICATIONS_LIST, component: NotyficationsListPage },
  { path: ROUTE_NOTIFICATIONS_LIST_WITH_ID, component: NotyficationsListPage },

  //invoices
  { path: ROUTE_INVOICE_LIST, component: InvoiceListPage },
  { path: ROUTE_INVOICE_ADD, component: InvouceNewPage },


  //deal add page
  { path: ROUTE_SUGGEST_APP, component: DealAddPage },

  //settlements
  { path: ROUTE_SETTLEMENT_LIST, component: SettlmentListPage },
  { path: ROUTE_SETTLEMENT_LIST_WITH_ID, component: SettlmentListPage },

  //affiliation link send
  { path: ROUTE_AFF_LINK_SEND, component: AffiliationLinkPage },

  //affiliation link send
  { path: ROUTE_CONTACT_FORM, component: ContactFormPage },


  //lista umów
  { path: ROUTE_AGREEMENT_LIST, component: AgreementListPage },

  //nowa umowa
  { path: ROUTE_AGREEMENT_P0_NAVBAR, component: AgreementPage0Navbar },
  { path: ROUTE_AGREEMENT_P0_TYPE_SELECTION, component: AgreementPage0Type },
  { path: ROUTE_AGREEMENT_P1_WIZARD, component: AgreementPage1Wizard },
  { path: ROUTE_AGREEMENT_P2_FORM, component: AgreementPage2Form },
  { path: ROUTE_AGREEMENT_P3_SMS_CONFIRM, component: AgreementPage3SmsConfirmation },
  { path: ROUTE_AGREEMENT_P4_SIGN, component: AgreementPage4Sign },
  { path: ROUTE_AGREEMENT_P5_SIGN_SUMMARY, component: AgreementPage5SignSummary },


  //lista załączników
  { path: ROUTE_ATTACHMENT_LIST, component: AttachmentListPage },
  { path: ROUTE_ATTACHMENT_NEW, component: AttachmentNewPage },

  //lista załączników
  { path: ROUTE_EXT_DOC_TO_SIGN, component: Docs2SignListPage },
  
  { path: ROUTE_PDF_PREVIEW, component: PdfViewerPage },
  { path: ROUTE_PDF_PREVIEW_AGREEMENT_TEMPLATE, component: PdfViewerPageUnsavedAgreementPreview },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]
 

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/login/:channel/:affiliationKey", component: LoginAffiliationWrapper },
  { path: "/forgot-password", component: ForgetPassword },
  { path: ROUTE_PRIVACY_POLICY, component: PrivacyPolicy },
  { path: ROUTE_CONTACT_FORM_UNLOGGED, component: ContactFormPageUnlogged },
  { path: ROUTE_PRE_REGISTRATION_WIZARD, component: RegisterPageStepper },
  { path: ROUTE_PRE_REGISTRATION_WIZARD_AFFILIATION, component: RegisterPageStepperAffiliationWrapper },
]

export { authProtectedRoutes, publicRoutes }

