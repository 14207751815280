
import { ISagaParam } from "helpers/tsx_helper";
import { call, put, takeLatest } from "redux-saga/effects";
import { fillPlaceholders } from "routes/placeholder_helper";
import { IBAN_CHANGE_ACT, IBAN_CHANGE_ACT_TYP } from "./actionsIbanChange";
import { PROFILE_ACT } from "pages/Profile/store/actionsProfile";
import { IDataResponse } from "helpers/IDataResponse";
import { IProfile } from "pages/Profile/store/reducerProfile";
import { ENDPOINTS, get, patch } from "helpers/api_helper";
import { ROUTES } from "routes";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";

function* ibanSmsCode(action: ISagaParam) {
    try {
        const smsSendUrl = fillPlaceholders(
            ENDPOINTS.RESOURCE_PROFILE_IBAN_SMS_CODE, { 'contractorId': action.payload.contractorId }
        );
        yield call(get, smsSendUrl)
    } catch (error) {
        console.log('ibanSmsCode error', error);
        showException(error);
        yield put(IBAN_CHANGE_ACT.apiError(error));
    }
}

function* ibanChangeAction(action: ISagaParam) {
    try {
        const url = fillPlaceholders(
            ENDPOINTS.RESOURCE_PROFILE_IBAN_UPDATE, {
            'contractorId': action.payload.ibanChangeAction.contractorId
        }
        );
        let profileDataResponse: IDataResponse<IProfile> = yield call(patch, url, action.payload.ibanChangeAction)
        yield put(IBAN_CHANGE_ACT.ibanChangeDone());
        yield put(PROFILE_ACT.setProfileDone(profileDataResponse.data));    
        action.payload.history.push(ROUTES.ROUTE_PROFILE);
        showPopupInfo('Profile.Data.bankAccountModified') ;
    } catch (error) {    
        console.log('ibanChangeAction error', error);
        showException(error);
        yield put(IBAN_CHANGE_ACT.apiError(error));
    }
}
 

export function* IbanChangeSaga() {
    yield takeLatest(IBAN_CHANGE_ACT_TYP.SEND_CODE, ibanSmsCode);
    yield takeLatest(IBAN_CHANGE_ACT_TYP.IBAN_CHANGE, ibanChangeAction);
}

export default IbanChangeSaga;
