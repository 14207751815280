import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SETTLEMENT_LIST_ACT } from "./store/actionsSettList";

const MarkAsReadSettlement = ({ id, markedAsPresented }: { id: number, markedAsPresented: string | undefined }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!markedAsPresented) {
      dispatch(SETTLEMENT_LIST_ACT.markAsPresented(id));
    }
  }, [markedAsPresented])

  return <></>;
}

export default MarkAsReadSettlement; 