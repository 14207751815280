
const PREFIX = "AFLS_"

//get agreement available types
const LOAD_DATA = PREFIX + "LOAD_DATA";
const loadData = () => ({ type: LOAD_DATA, payload: {} });

const LOAD_DATA_DONE = PREFIX + "LOAD_DATA_DONE";
const loadDataDone = (gridData: any[], context: any, info: string, error: string) => ({
    type: LOAD_DATA_DONE, payload: { gridData, context,  info }
});

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}


const SEND_AFFILIATION_LINK = PREFIX + "SEND_AFFILIATION_LINK";
const sendAffiliationLink = (phoneNumber: any, formikBag: any) => ({
    type: SEND_AFFILIATION_LINK, payload: {phoneNumber, formikBag}
});

const SEND_AFFILIATION_LINK_DONE = PREFIX + "SEND_AFFILIATION_LINK_DONE";
const sendAffiliationLinkDone = (info: any) => ({
    type: SEND_AFFILIATION_LINK_DONE, payload: {info}
});


const SEND_AFFILIATION_LINK_ERROR = PREFIX + "SEND_AFFILIATION_LINK_ERROR";
const sendAffiliationLinkError = (error: any) => ({
    type: SEND_AFFILIATION_LINK_ERROR, payload: {error}
});

export const AFFILIATION_LINK_ACT = {
    loadData, loadDataDone,apiError,
    sendAffiliationLink, sendAffiliationLinkDone, sendAffiliationLinkError
}

export const AFFILIATION_LINK_ACT_TYP = {
    LOAD_DATA, LOAD_DATA_DONE, API_ERROR,
    SEND_AFFILIATION_LINK, SEND_AFFILIATION_LINK_DONE, SEND_AFFILIATION_LINK_ERROR
}