import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// LoginState Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user} }) {
  try {
    const response = yield call(fireBaseBackend.forgetPassword, user.email)
    if (response) {
      yield put(userForgetPasswordSuccess("ForgetPassword.Result.OK"))
    } else {
      console.error('ForgetPassword.Result.ERR: no data');
      yield put(userForgetPasswordError('ForgetPassword.Result.ERR'))
    }
  } catch (error) {
    console.error('ForgetPassword.Result.ERR: ',error);
    yield put(userForgetPasswordError('ForgetPassword.Result.ERR'))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
