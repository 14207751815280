import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import Header20WebLanguageChangeMenu from "./Header20WebLanguageChangeMenu";
import Header20WebLogoutHeaderItem from "./Header20WebLogoutHeaderItem";
import NotificationsMenu from "./NotificationsMenu";
import AppStoreButtons from "./AppStoreButtons";

const Header20Web = () => {

	const email = useSelector((state) => state.LoginState.email);

	return (
		<section className="header">
			<div className="container">
				<div className="row">
					<div className="col d-flex justify-content-between">
						<div>
							<AppStoreButtons align="text-start" />
						</div>
						<div className="name-flag">

							<Header20WebLanguageChangeMenu />
							<div className="user-mail">
								<div className="name-div">									
									<img className="flag" src="/img/user.svg" />
									<h3>{email}</h3>
								</div>
							</div>
							<NotificationsMenu />
							<Header20WebLogoutHeaderItem />
						</div>
					</div>
				</div>
			</div>
		</section>

	);
};

Header20Web.propTypes = {
	type: PropTypes.string,
};

const mapStatetoProps = state => {
	return {
		layout: state.Layout,
	};
};
export default connect(
	mapStatetoProps,
	{}
)(withRouter(withTranslation()(Header20Web)));
