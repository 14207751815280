import MaskedText from "components/MyComponents/MaskedText";
import { Field } from "formik";
import { Mask } from "react-text-mask";
import { IGenericFormItem } from "./GenericFormItem";
import { useSelector } from "react-redux";
import { isForceEditEnabled } from "./FormItemsDisableExclusions";

export const ZIP_CODE_MASK = [/\d/, /\d/, "-", /\d/, /\d/, /\d/];

interface IMaskedFormItem extends IGenericFormItem {
    mask: Mask | ((value: string) => Mask);
}

const FormItemMaskedTextField = ({ mask, formItem, pageKey, context,autoFocus }: IMaskedFormItem) => {

    if (!formItem) {
        return <h3>conf error: {context}</h3>
    }

    const fieldKey = pageKey + '.' + formItem.name;
    const email = useSelector((state: any) => state.LoginState.email);
    let isForceEditEnabledFlag = false;
    if(formItem.disabled){
        isForceEditEnabledFlag = isForceEditEnabled(email, fieldKey)
    }
    
    return (
        <Field name={formItem.name}
            isDisabled={formItem.disabled && !isForceEditEnabledFlag}
            label={fieldKey}
            mask={mask}
            autoFocus={autoFocus}
            textBefore={formItem.textBeforeSuffix ? fieldKey + '.' + formItem.textBeforeSuffix : undefined}
            textAfter={formItem.textAfterSuffix ? fieldKey + '.' + formItem.textAfterSuffix : undefined}
            component={MaskedText} />
    )
}

export default FormItemMaskedTextField