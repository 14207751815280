import CustomDate from "components/MyComponents/CustomDate";
import CustomText from "components/MyComponents/CustomText";
import { Field } from "formik";
import { Col, Row } from "reactstrap";
import { IGenericFormItem } from "./GenericFormItem";

const FormItemDateField = ({ formItem, pageKey,autoFocus }: IGenericFormItem) => {
    const fieldKey = pageKey + '.' + formItem.name;
    return (
        <Field 
            key={fieldKey}
            name={formItem.name} 
            label={fieldKey}
            textBefore={formItem.textBeforeSuffix ? fieldKey + '.' + formItem.textBeforeSuffix : undefined}
            textAfter={formItem.textAfterSuffix ? fieldKey + '.' + formItem.textAfterSuffix : undefined}
            autoFocus={autoFocus}
            component={CustomDate} />
    )
}
export default FormItemDateField;