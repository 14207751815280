import { getAppRegistrationLogoByAppId } from "helpers/image_helper";
import './register.scss';
import { useTranslation } from "react-i18next";

interface IErrorContent {
  appId: string,
  label: string,
  isSelected: boolean,
  extraCss: string,
  toggleSelection: (appId: string) => void
}

const CorpoItemOption = ({ appId, label, extraCss, isSelected, toggleSelection }: IErrorContent) => {

  const { t } = useTranslation();

  const onItemClicked = () => {
    toggleSelection(appId);
  }

  return (
    <div className={extraCss + " reg-app-item-wrapper " + (isSelected ? "active" : "inactive")}
      onClick={onItemClicked}
    >
      <img

        className={"reg-app-item " + (isSelected ? "active" : "inactive")}
        src={getAppRegistrationLogoByAppId(appId)} alt={label} />
      <h2 className={"app-name d-none " + (isSelected ? "active" : "inactive")}>{t('App.Name.' + appId)}</h2>
    </div>
  )


}

export default CorpoItemOption;