import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import Layout20 from "./components/Layout20/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss

import "./assets/css/style.css";
import "./assets/scss/app.scss";
import "./assets/css/responsive-1200-1500.css";
import "./assets/css/responsive-1501-1799.css";
import "./assets/css/responsive-768-991.css";
import "./assets/css/responsive-992-1199.css";
import "./assets/css/responsive-max-1199.css";
import "./assets/css/responsive-max-767.css";
import "./assets/css/responsive-max-360.css";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

// init firebase backend
initFirebaseBackend(firebaseConfig);
const App = props => {
  function getLayout() {

    return Layout20;
   
  }

  const Layout = getLayout();

  return (
    <Router>
      <Switch>
        {publicRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}     
      </Switch>
    </Router>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
