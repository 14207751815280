import { all, fork } from "redux-saga/effects"

//public
import AffiliationLinkSaga from "pages/AffiliationLink/store/sagaAffiliationLink"
import DealAddSaga from "pages/DealAdd/store/sagaDealAdd"
import AgreementListSaga from "pages/AgreementList/store/sagaAgrList"
import AttachmentListSaga from "pages/AttachmentList/store/sagaAttList"
import AttachmentNewSaga from "pages/AttachmentNew/store/sagaAttNew"
import ContactFormSaga from "pages/ContactForm/store/sagaContactForm"
import InvoiceListSaga from "pages/InvoiceList/store/sagaInvList"
import InvoiceNewSaga from "pages/InvoiceNew/store/sagaInvNew"
import ProfileSaga from "pages/Profile/store/sagaProfile"
import IbanChangeSaga from "pages/ProfileBankAccount/store/sagaIbanChange"
import ProfileRemovalSaga from "pages/ProfileRemoval/store/sagaProfileRemoval"
import SettlementListSaga from "pages/SettlementList/store/sagaSettList"
import PreAgrListSaga from "../pages/Agreement/store-pre-list/sagaPreAgrList"
import AgreementTypesSaga from "../pages/Agreement/store-pre/sagaAgr"
import ForgetSaga from "./auth/forgetpwd/saga"
import AuthSaga from "./auth/login/sagaLogin"
import PreRegistrationSaga from "./auth/pre-registration/sagaRegPre"
import AccountSaga from "./auth/register/saga"
import ConfigSaga from "./configuration/sagaConfig"
import DashboardSaga from "./dashboard/saga"
import LayoutSaga from "./layout/saga"
import NotificationsListSaga from "pages/NotificationsList/store/sagaNotificationsList"
import Docs2SignListSaga from "pages/Docs2SignList/store/sagaDocs2SignList"
 
export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(DashboardSaga),
    fork(PreRegistrationSaga),
    fork(AgreementTypesSaga),
    fork(PreAgrListSaga),
    fork(AgreementListSaga),
    fork(SettlementListSaga),
    fork(InvoiceListSaga),
    fork(AttachmentListSaga),
    fork(AttachmentNewSaga),
    fork(InvoiceNewSaga),
    fork(ConfigSaga),
    fork(ProfileSaga),
    fork(AffiliationLinkSaga),
    fork(ProfileRemovalSaga),
    fork(ContactFormSaga),
    fork(IbanChangeSaga),
    fork(DealAddSaga),
    fork(NotificationsListSaga),
    fork(Docs2SignListSaga),
  ])
}
