import LoadingPage from "components/Common/LoadingPage";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import { Field, Form, Formik } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";

import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { ProgressIndicator } from "components/Layout20/ProgressIndicator";
import CustomSelect, { SELECT_SINGLE_DEFAULT_PLACEHOLDER } from "components/MyComponents/CustomSelect";
import { translateOptions } from "helpers/text_helper";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { PRE_REG_ACTIONS } from "store/auth/pre-registration/actions";
import * as Yup from 'yup';
import RegisterPageWrapper from "../RegisterPageWrapper";

interface FormValues {
  suggestedVehicleTypeId: string,
  bagTypeId: string,
  registrationSource: string,
}

const RegisterPage5_Addons = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { regUserData, isLoading, error, addonBagsDomain, vehicleDomainTaxi, vehicleDomainCourier, registrationSourceDomain } = useSelector((state: any) => (
    {
      regUserData: state.PreRegistration.regUserData,
      isLoading: state.PreRegistration.isLoading,
      error: state.PreRegistration.error,
      addonBagsDomain: state.PreRegistration.addonBagsDomain,
      vehicleDomainTaxi: state.PreRegistration.vehicleDomainTaxi,
      vehicleDomainCourier: state.PreRegistration.vehicleDomainCourier,
      registrationSourceDomain: state.PreRegistration.registrationSourceDomain,
    })
  );
  const affiliationKey = useSelector((state: any) => state.AffiliationStore.affiliationKey);
  const addonBagsDomainTr = useMemo(() => translateOptions(addonBagsDomain, t), [addonBagsDomain, i18n.language]);
  const registrationSourceDomainTr = useMemo(() => translateOptions(registrationSourceDomain, t), [registrationSourceDomain, i18n.language]);
  const vehicleDomainTaxiTr = useMemo(() => translateOptions(vehicleDomainTaxi, t), [vehicleDomainTaxi, i18n.language]);
  const vehicleDomainCourierTr = useMemo(() => translateOptions(vehicleDomainCourier, t), [vehicleDomainCourier, i18n.language]);

  if (!regUserData?.page1Data) {
    return <RegisterPageWrapper><LoadingPage /></RegisterPageWrapper>
  }

  const isCourier = regUserData.page2Data.taxiOrCourierDecision === 'COURIER';
  const vehicleDomain = isCourier ? vehicleDomainCourierTr : vehicleDomainTaxiTr;

  const formValidator = Yup.object().shape({
    suggestedVehicleTypeId: YUP_VALIDATION.YUP_STRING_REQ_SELECT,
    registrationSource: YUP_VALIDATION.YUP_STRING_REQ_SELECT,
    bagTypeId: isCourier ? YUP_VALIDATION.YUP_STRING_REQ_SELECT : YUP_VALIDATION.YUP_STRING,
  });

  const onSubmit = (values: FormValues) => {
    dispatch(PRE_REG_ACTIONS.step5StartUpdating(regUserData, regUserData.page5Data, values, affiliationKey));
  };

  const onBack = () => {
    dispatch(PRE_REG_ACTIONS.preRegistrationPageBack())
  };

  const renderForm = () => {

    return (<Form>
      <ProgressIndicator step={5} stepCount={6} />
      <Field name="registrationSource" label="Registration.Page5.RegistrationSource"
        placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
        options={registrationSourceDomainTr} className="custom-select" component={CustomSelect} />



      {isCourier &&
        <Field name="bagTypeId" label="Registration.Page5.bagTypeId"
          textAfter="Registration.Page5.bagTypeId.AdditionalComment"
          placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
          options={addonBagsDomainTr} className="custom-select" component={CustomSelect}
          isDisabled={isLoading}
        />
      }

      <Field name="suggestedVehicleTypeId" label="Registration.Page5.Vehicle"
        textAfter="Registration.Page5.Vehicle.AdditionalComment"
        placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
        options={vehicleDomain} className="custom-select" component={CustomSelect} />




      <Row className="pb-3">
        <Col xs={4} className="px-1">
          <ButtonCustom id="button-register-step-addons-back"
            isLoading={false} label="Registration.Page5.Back" onClick={onBack} /></Col>
        <Col xs={8}>
          <ButtonSubmit id="button-register-step-addons"
            isLoading={isLoading} label="Registration.Page5.Submit" iconClass="fa-light fa-angles-right fa-lg" className="w-100" />
        </Col>
      </Row>
    </Form>
    )
  }

  return (
    <RegisterPageWrapper showLoginLink={false}>
      <AlertFrameError messageKey={error} />
      <Formik
        initialValues={regUserData.page5Data}
        validationSchema={formValidator}
        render={renderForm}
        onSubmit={onSubmit}
      />
    </RegisterPageWrapper>
  );
};

export default RegisterPage5_Addons; 