
//i18n
import languages from "../../../common/languages";

export const getCurrentLanguage  = () => {
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
  if (languages[currentLanguage]) {
    return currentLanguage || 'pl';
  } 
  return 'pl';
}
 
