
export function createArrayOfGroups(listOfObjects: any[]) {
    const resultArray = [];
    const groupSet = new Set();

    for (let obj of listOfObjects) {
        if (obj.hasOwnProperty('group') && typeof obj.group === 'string' && !groupSet.has(obj.group)) {
            groupSet.add(obj.group);
            resultArray.push(obj.group);
        }
    }
    return resultArray;
}

export function createArrayOfKeys(listOfObjects: any[]) {
    const resultArray = [];
    const distinctValues = new Set();

    for (let obj of listOfObjects) {
        for (let key in obj) {
            if (
                key !== 'group' &&
                !key.endsWith('Color') &&
                !key.endsWith('Label') &&
                obj.hasOwnProperty(key) &&
                !distinctValues.has(key)
            ) {
                distinctValues.add(key);
                resultArray.push(key);
            }
        }
    }
    return resultArray;
}