import LoadingPage from "components/Common/LoadingPage";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import { Form, Formik, FormikProps } from "formik";
import { is_in_array } from "helpers/predicate_helper";
import { YUP_VALIDATION } from "helpers/YupHelper";

import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { ProgressIndicator } from "components/Layout20/ProgressIndicator";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { PRE_REG_ACTIONS } from "store/auth/pre-registration/actions";
import * as Yup from 'yup';
import CorpoItemOption from "../CorpoItemOption";
import RegisterPageWrapper from "../RegisterPageWrapper";


const formValidator = Yup.object().shape({
  appIds: YUP_VALIDATION.YUP_STRING_REQ_MULTISELECT,
});

interface FormValues {
  appIds: [];
}

const RegisterPage4_Apps = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { regUserData, isLoading, error, appsDomain } = useSelector((state: any) => (
    {
      regUserData: state.PreRegistration.regUserData,
      isLoading: state.PreRegistration.isLoading,
      error: state.PreRegistration.error,
      appsDomain: state.PreRegistration.appsDomain,
    })
  );
  const affiliationKey = useSelector((state: any) => state.AffiliationStore.affiliationKey);


  if (!regUserData?.page1Data) {
    return <RegisterPageWrapper><LoadingPage /></RegisterPageWrapper>
  }

  const onSubmit = (values: FormValues) => {
    dispatch(PRE_REG_ACTIONS.step4StartUpdating(regUserData, regUserData.page4Data, values, affiliationKey));
  };

  const onBack = () => {
    dispatch(PRE_REG_ACTIONS.preRegistrationPageBack())
  };

  const renderForm = (formikBag: FormikProps<FormValues>) => {
    const toggleSelection = (appId: string) => {
      const wasSelected = is_in_array(formikBag.values.appIds, appId);
      if (wasSelected) {
        const appsFiltered = formikBag.values.appIds.filter(app => app !== appId);
        formikBag.setFieldValue('appIds', appsFiltered);
      } else {
        const appsExtended = [...formikBag.values.appIds, appId]
        formikBag.setFieldValue('appIds', appsExtended);
      }
    }

    return (<Form>
      <ProgressIndicator step={4} stepCount={6} />
      <Row className="pb-1">
        <Col xs={12} className="text-center py-3">
          <h5 ><i className="blue fa-duotone fa-mobile-screen-button fa-xl" /> {t("Registration.Page4.Apps")}</h5>
        </Col>
        <Col className="reg-app-item-grid pb-2">
          {appsDomain.map((item: { value: string, label: string, extraCss: string }, key: number) => (
            <CorpoItemOption
              appId={item.value}
              label={item.label}
              extraCss= {item.extraCss}
              isSelected={is_in_array(formikBag.values.appIds, item.value)}
              key={'app_reg_item_' + key}
              toggleSelection={toggleSelection}
            />
          ))
          }
        </Col>
      </Row>
      <Col className="py-2 text-center" xs={12}>
        <span >{t("Registration.Page4.Apps.AdditionalComment")}</span>
      </Col>
      <Row className="py-3">
        <Col xs={4} className="px-1">
          <ButtonCustom id="button-register-step-apps-back"
            isLoading={false} label="Registration.Page4.Back" onClick={onBack} /></Col>
        <Col xs={8}><ButtonSubmit id="button-register-step-apps"
          disabled={formikBag.values.appIds.length === 0}
          isLoading={isLoading} label="Registration.Page4.Submit" iconClass="fa-light fa-angles-right fa-lg" className="w-100" /></Col>
      </Row>
    </Form>
    )
  }
  return (
    <RegisterPageWrapper showLoginLink={false}>
      <AlertFrameError messageKey={error} />
      <Formik
        initialValues={regUserData.page4Data}
        validationSchema={formValidator}
        render={renderForm}
        onSubmit={onSubmit}
      />
    </RegisterPageWrapper>
  );
};


export default RegisterPage4_Apps; 