import { convertYYYYMMDDtoDDMMYYYY } from "helpers/date_helper";
import { getAppSettlementLogo } from "helpers/image_helper";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { rowCsvItemMoney, rowCsvItemMoneyWithZero, rowCsvItemNumber } from "./SettUtil";

export interface ISettlementAggregatedCsvItemDto {
  id: number,
  corporationName: string,
  co: string,
  importAlgorithm: string,
  netAmount?: number,
  netAdjustment?: number,
  cardAdjustment?: number,
  materialFee?: number,
  platformFee?: number,
  csvRoadToll?: number,
  csvCancellations?: number,
  csvWaitingTime?: number,
  csvEntranceFee?: number,
  csvAirportFee?: number,
  csvHotelFee?: number,
  bonus?: number,
  tips?: number,
  taxCalculated?: number,
  taxVat08?: number,
  taxVat23?: number,
  otherAdjustments?: number,
  spf: string;
  spt: string;
  c: number, //calculationNoTax
  ct: number, //calculationTax
  o: number, //numberOfOrders
}

export interface ISettlementAggregatedCsvItem {
  dto: ISettlementAggregatedCsvItemDto,
  isB2B: boolean
}

export const SettlementDetails01_CsvItem = ({ isB2B, dto }: ISettlementAggregatedCsvItem) => {

  const { t } = useTranslation();
  const app = dto.co;
  return (
    <div className="mb-4">
      <Row className="csv-item-header align-items-center m-0">
        <Col xs="auto" className="csv-item-header-logo" >
          <img className="app-logo-img-details pe-1" src={getAppSettlementLogo(dto.co)} alt={dto.corporationName} />
        </Col>
        <Col xs="auto" className="csv-item-header-data">
          <span className="vstack table-h-con prevent-select">
            <h3>
              {dto.corporationName}
            </h3>
            <span>{convertYYYYMMDDtoDDMMYYYY(dto.spf)} - {convertYYYYMMDDtoDDMMYYYY(dto.spt)}</span>
          </span>
        </Col>

      </Row>
      {rowCsvItemMoney(t, dto.netAmount, 'netAmount', app)}

      {!isB2B &&
        <>
          {rowCsvItemMoney(t, dto.taxVat08, 'taxVat08', app)}
        </>
      }

      {rowCsvItemMoney(t, dto.netAdjustment, 'netAdjustment', app)}
      {rowCsvItemMoney(t, dto.platformFee, 'platformFee', app)}
      {rowCsvItemMoney(t, dto.bonus, 'bonus', app)}

      {rowCsvItemMoney(t, dto.cardAdjustment, 'cardAdjustment', app)}
      {rowCsvItemMoney(t, dto.materialFee, 'materialFee', app)}


      {rowCsvItemMoney(t, dto.csvRoadToll, 'csvRoadToll', app)}
      {rowCsvItemMoney(t, dto.csvCancellations, 'csvCancellations', app)}
      {rowCsvItemMoney(t, dto.csvWaitingTime, 'csvWaitingTime', app)}
      {rowCsvItemMoney(t, dto.csvEntranceFee, 'csvEntranceFee', app)}
      {rowCsvItemMoney(t, dto.csvAirportFee, 'csvAirportFee', app)}
      {rowCsvItemMoney(t, dto.csvHotelFee, 'csvHotelFee', app)}

      {rowCsvItemMoney(t, dto.otherAdjustments, 'otherAdjustments', app)}


      {!isB2B &&
        <>          
          {rowCsvItemMoney(t, dto.taxVat23, 'taxVat23', app)}
          {rowCsvItemMoney(t, dto.taxCalculated, 'taxCalculated', app)}          
        </>
      }
      
      {rowCsvItemMoney(t, dto.tips, 'tips', app)}

      {rowCsvItemMoneyWithZero(t, (dto.c ? dto.c : 0),
        'summary', 'app', 'summary-row-first', { 'APP_NAME': dto.corporationName }
      )}
      {rowCsvItemNumber(t, dto.o, 'orders', app, 'summary-row')}
    </div>
  )
}