import { formatNumber } from "./pln_helper";

export const getMoney = (moneyValue: number) => {

    const value = moneyValue ? moneyValue : 0;
    const className = value >= 0 ? 'money-positive' : 'money-negative'
    return <span className={className}>{formatNumber(value)} zł</span>
}

export const negative = (moneyValue : number | null | undefined ) => {

    if(moneyValue === undefined || moneyValue === null) {
        return moneyValue;
    }
    return - moneyValue;

}

export const getMoneyString = (moneyValue: number) => {
    return  moneyValue.toFixed(2)

}