import { Field, FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { FormGroup, FormText, Label } from "reactstrap";
import TranslatedErrors from "./TranslatedErrors";

interface TextProps extends FieldProps {
  placeholder?: string;
  label?: string;
  textAfter?: string;
  isDisabled?: boolean;
  forcedId?: string;
  ac: string;
}

export const CustomPassword = ({
  placeholder,
  label,
  field,
  form,
  textAfter,
  isDisabled,
  forcedId,
  ac,
}: TextProps) => {

  const { t } = useTranslation();

  if (field.value === undefined) {
    return <></>
  }

  const hasError = form.errors[field.name] && form.touched[field.name];

  return (
    <>
      <FormGroup>
        {label &&
          <Label htmlFor={field.name}>{t(label)}</Label>
        }
        <Field type="password" disabled={isDisabled}
          name={field.name}
          placeholder={placeholder}
          className={"form-control" + (hasError ? " is-invalid" : "")}
          id={forcedId ? forcedId : field.name}
          autoComplete={ac}
        />
        {hasError &&
          <TranslatedErrors errors={form.errors[field.name]} />
        }
      </FormGroup>
      {textAfter &&
        <FormGroup  >
          <FormText>{t(textAfter)}</FormText>
        </FormGroup>
      }
    </>
  );
};

export default CustomPassword;
