const PREFIX = "AGL_"

//get agreement available types
const LOAD_DATA = PREFIX + "LOAD_DATA";
const loadData = () => ({ type: LOAD_DATA, payload: {} });

const LOAD_DATA_DONE = PREFIX + "LOAD_DATA_DONE";
const loadDataDone = (gridData: any[], info: string, error: string) => ({
    type: LOAD_DATA_DONE, payload: { gridData, info }
});

const PDF_DOWNLOAD = PREFIX + "PDF_DOWNLOAD";
const pdfDownload = (agreementId: number) => ({
    type: PDF_DOWNLOAD, payload: { agreementId }
});

const REJECT_AGREEMENT = PREFIX + "REJECT_AGREEMENT";
const rejectAgreement = (agreementId: number) => ({
    type: REJECT_AGREEMENT, payload: { agreementId }
});

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const AGREEMENT_LIST_ACT = {
    //pobieranie danych
    loadData, loadDataDone,
    pdfDownload,
    rejectAgreement,
    apiError
}

export const AGREEMENT_LIST_ACT_TYP = {
    LOAD_DATA, LOAD_DATA_DONE,
    API_ERROR,
    PDF_DOWNLOAD, 
    REJECT_AGREEMENT
}