
import ButtonCustom from "components/MyComponents/ButtonCustom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PRE_REG_ACTIONS } from "store/auth/pre-registration/actions";
import RegisterPageWrapper from "../RegisterPageWrapper";
import ButtonCustomCountDown from "components/MyComponents/ButtonCustomCountDown";


const RegisterPage99_Summary = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const onGotoLogin = () => { dispatch(PRE_REG_ACTIONS.resetAndGotoLogin(history)) }

  return (<RegisterPageWrapper showLoginLink={false}>

    <div className="faq-box d-flex mb-4 mt-5">
      <div className="flex-grow-1">
        <h5 className="font-size-15 justify-content-center">
          {t('Registration.PageSummary.Main.Header')}
        </h5>
        <p className="text-muted">
          {t('Registration.PageSummary.Main.Text')}
        </p>
      </div>
    </div>
    <div className="faq-box d-flex mb-4">
      <div className="flex-shrink-0 me-3 faq-icon">
        <i className="mdi mdi-numeric-1-circle-outline font-size-20 text-success" />
      </div>
      <div className="flex-grow-1">
        <h5 className="font-size-15">
          {t('Registration.PageSummary.Step1.ApproveEmail.Header')}
        </h5>
        <p className="text-muted">
          {t('Registration.PageSummary.Step1.ApproveEmail.Text')}
        </p>
      </div>
    </div>

    <div className="faq-box d-flex mb-4">
      <div className="flex-shrink-0 me-3 faq-icon">
        <i className="mdi mdi-numeric-2-circle-outline font-size-20 text-success" />
      </div>
      <div className="flex-grow-1">
        <h5 className="font-size-15">
          {t('Registration.PageSummary.Step2.LogIn.Header')}
        </h5>
        <p className="text-muted">
          {t('Registration.PageSummary.Step2.LogIn.Text')}
        </p>
      </div>
    </div>

    <div className="pb-3">
      <ButtonCustomCountDown
        id="button-register-go-2-login-page"
        isLoading={false} label='Registration.PageSummary.Step2.LogIn.ButtonLabel' onClick={onGotoLogin} 
        iconClass="fa-regular fa-right-to-bracket"
        countDownTime={10}
        onCountDownEnd={onGotoLogin}
        />
    </div> 
  </RegisterPageWrapper>
  );
};


export default RegisterPage99_Summary; 