import { defaultIAgreementConfig, defaultIAgreementData, defaultIDictionaries, IAgreementConfig, ICompoundData, IDictionaries } from "pages/Agreement/IAgreement";
import { AGREEMENT_ACTION_TYPES } from "./actionsAgr";

export interface IPreAgreementState {
    agreementConfig: IAgreementConfig,
    agreementData: ICompoundData,
    agreementDataLoaded: boolean,
    isLoading: boolean,
    error: string,
    info: string,
    dictionaries: IDictionaries,    
    canGeneratePreview: boolean,
    canSign: boolean
}

const INIT_STATE: IPreAgreementState = {
    //wybrana konfiguracja + dane 
    agreementConfig: defaultIAgreementConfig,
    agreementData: defaultIAgreementData,
    dictionaries: defaultIDictionaries,
    agreementDataLoaded: false,
    //utils
    canGeneratePreview: false,
    canSign: false,
    isLoading: true,
    error: '',
    info: ''    
};

const AgreementSignStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        //obtaining agreement configuration and contractor filled data
        case AGREEMENT_ACTION_TYPES.GET_AGREEMENT_CONFIG_AND_DATA:
            return { ...state, isLoading: true, error: '', info: '' };
        case AGREEMENT_ACTION_TYPES.GET_AGREEMENT_CONFIG_AND_DATA_DONE:
            return {
                ...state,
                agreementConfig: action.payload.agreementConfig,
                agreementData: action.payload.agreementData,
                dictionaries: action.payload.dictionaries,
                canGeneratePreview: action.payload.canGeneratePreview,
                canSign: action.payload.canSign,
                agreementDataLoaded: true,
                isLoading: false, error: '', info: '',
            };

        case AGREEMENT_ACTION_TYPES.SAVE_DATA:
            return { ...state, isLoading: true, error: '', info: '' };
        case AGREEMENT_ACTION_TYPES.SAVE_DATA_DONE:
            return {
                ...state,
                agreementConfig: action.payload.agreementConfig,
                agreementData: action.payload.agreementData,
                canGeneratePreview: action.payload.canGeneratePreview,
                canSign: action.payload.canSign,
                //update słowników jeśli potrzeba
                dictionaries: Object.assign(state.dictionaries, action.payload.dictionaries),
                isLoading: false,
                error: '', info: '',
            };
        case AGREEMENT_ACTION_TYPES.OPEN_SIGN_DOCUMENT: return { ...state, info: '' };
        case AGREEMENT_ACTION_TYPES.RESEND_SMS: return { ...state, isLoading: true, error: '', info: '' };
        case AGREEMENT_ACTION_TYPES.RESEND_SMS_DONE: return { ...state, error: '', info: action.payload.info, isLoading: false };

        case AGREEMENT_ACTION_TYPES.PREVIEW_AGREEMENT: return { ...state, isLoading: true, error: '', info: '' };
        case AGREEMENT_ACTION_TYPES.PREVIEW_AGREEMENT_DONE: return { ...state, error: '', info: action.payload.info, isLoading: false };

        case AGREEMENT_ACTION_TYPES.SIGN_AGREEMENT: return { ...state, isLoading: true, error: '', info: '' };
        case AGREEMENT_ACTION_TYPES.SIGN_AGREEMENT_DONE: return {
            ...state,
            //wybrana konfiguracja + dane 
            agreementConfig: defaultIAgreementConfig,
            agreementData: defaultIAgreementData,
            dictionaries: defaultIDictionaries,
            //utils
            canGeneratePreview: false, //czy można generować podgląd niepodpisanej
            canSign: false, //czy można podpisać umowę            
            //standard
            isLoading: false,
            error: action.payload.error,
            info:  action.payload.info,            
        };

        case AGREEMENT_ACTION_TYPES.SMS_CONFIRM_CODE: return { ...state, isLoading: true, error: '', info: '' };
        case AGREEMENT_ACTION_TYPES.SMS_CONFIRM_CODE_DONE: return { ...state, isLoading: false, error: '', info: '' };

        case AGREEMENT_ACTION_TYPES.API_FAIL:
            return { ...state, isLoading: false, error: action.payload.error, info: '' };
        default:
            return state;
    }
}

export default AgreementSignStore;