import LoadingPage from "components/Common/LoadingPage";
import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { IOption } from "components/MyComponents/CustomSelect";
import { AGREEMENT_ACTION } from "pages/Agreement/store-pre/actionsAgr";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import './agreement.scss';
import { AGREEMENT_PRE_LIST_ACTION } from "./store-pre-list/actionsPreAgrList";
import { IPreAgreementListState } from "./store-pre-list/reducerPreAgrList";

const AgreementPage0Type = () => {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 70)
  }, [])

  const { isLoading, error, info, availableTypes, availableTypesLoaded, companyId } = useSelector((state: any) => {
    const agreementPreListStore: IPreAgreementListState = state.AgreementPreListStore as IPreAgreementListState;
    return {
      companyId: state.LoginState.companyId,
      isLoading: agreementPreListStore.isLoading,
      error: agreementPreListStore.error,
      info: agreementPreListStore.info,
      availableTypes: agreementPreListStore.availableTypes,
      availableTypesLoaded: agreementPreListStore.availableTypesLoaded,
    }
  }
  );

  useEffect(() => {
    if (!availableTypesLoaded && error === '') {
      dispatch(AGREEMENT_PRE_LIST_ACTION.getAvailableTypes(history))
    }
  }, [])

  if (isLoading) {
    return <Container fluid>
      <DialogWrapper title="Agreement.WizardPage.AgreementTypes"><LoadingPage /></DialogWrapper>
    </Container>
  }

  if (availableTypesLoaded && availableTypes.length === 0) {
    if (error === '' && info === '') {
      return (<Container fluid>
        <AlertFrameError messageKey={'AgreementTypeSelection.NoOptionsAvailable'} />
      </Container>
      )
    }
  }

  const onAgreementClick = (agreementType: string) => {
    dispatch(AGREEMENT_ACTION.getAgreementConfigAndData(agreementType, 'Agreement.' + agreementType + '.BasicData' /*domyślnie zawsze startujemy od tej strony*/, history, true));
  };

  const handleInfo = (link: string) => {
    window.open(link, "_blank");
  }


  const AGREEMENT_YT = {
    'NAR_UA': 'https://youtube.com/shorts/_Z6pWYqeQiw',
    'NAR_RU': 'https://youtube.com/shorts/_Z6pWYqeQiw',
    'NAS_UA': 'https://youtube.com/shorts/_Z6pWYqeQiw',
    'NAS_RU': 'https://youtube.com/shorts/_Z6pWYqeQiw',

    'NAR_GE': 'https://youtube.com/shorts/_Z6pWYqeQiw',
    'NAS_GE': 'https://youtube.com/shorts/_Z6pWYqeQiw',

    'NAS_EN': 'https://youtube.com/shorts/7L-xvg5vG9U',
    'NAR_EN': 'https://youtube.com/shorts/7L-xvg5vG9U',

    'NAS_PL': 'https://youtube.com/shorts/1NR5OddOpuo',
    'NAR_PL': 'https://youtube.com/shorts/1NR5OddOpuo',
  }

  type ATKey = keyof typeof AGREEMENT_YT;

  return (
    <Container fluid>
      <DialogWrapper title="Agreement.WizardPage.AgreementTypes">
        <div className="contract-type-btns">
          {
            availableTypes.map((item: IOption, key: number) => {
              const itemKey = 'AgreementTypeSelection.Option.' + item.value;
              let hasSocialMediaManual = false;
              let socialMediaManualLink = '';
              let socialKey = `${item.value}_${i18n.language.toUpperCase()}` as ATKey;
              if (AGREEMENT_YT.hasOwnProperty(socialKey)) {
                socialMediaManualLink = AGREEMENT_YT[socialKey];
                hasSocialMediaManual = true;
              }
              return (
                <div key={itemKey} >
                  <Link to='#' onClick={() => { onAgreementClick(item.value) }} className={"btn btn-default " + (hasSocialMediaManual ? 'with-details' : '')}
                    id={'open-agreement-type-'+item.value}>
                    <div className="btn-agr-type">
                      <i className="fa-solid fa-pen-to-square fa-xl"></i>
                      <span>{t(itemKey + '.title')}</span>
                    </div>
                  </Link>
                  {hasSocialMediaManual &&
                    <span className="contract-type-btns-details" onClick={() => { handleInfo(socialMediaManualLink); }}>{t('Agreement.WizardPage.AgreementTypes.AdditionalTikTokInfo')}
                      <i className="fa-brands fa-youtube fa-beat px-2" />
                    </span>
                  }
                </div>
              )
            }
            )}
        </div>
      </DialogWrapper>
    </Container>
  );
};


export default AgreementPage0Type; 