import { removeAuthContextFromStorage } from "helpers/auth_storage_helper";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { showException } from "helpers/sweetalert/ShowAlert";
import shallowequal from 'shallowequal';
import { ENDPOINTS, patch, post, postAsPostman } from "../../../helpers/api_helper";
import PRE_REG_ACTIONS_TYPES from "./actionTypes";
import { PRE_REG_ACTIONS } from "./actions";

function* step0StartUpdating({ payload: { regUserData, origValues, values, affiliationKey } }) {
    try {
        localStorage.removeItem("authUser")
        if (!shallowequal(origValues, values)) {
            regUserData.page0Data = values;
            regUserData.affiliationKey = affiliationKey;

            let regCtxNew = yield call(patch, ENDPOINTS.RESOURCE_PRE_REG_STEP_0_PATCH, regUserData)
            yield put(PRE_REG_ACTIONS.step0UpdateDone(regCtxNew.regUserData, regCtxNew.dictionaries));
        } else {
            yield put(PRE_REG_ACTIONS.step0UpdateNoChanges());
        }
    } catch (error) {
        if (error?.message === 'Registration.SkipSteps.ContractorExistsButNotRegistered') {
            console.info('step0StartUpdating skip registration: ' + values.email, error);
            yield put(PRE_REG_ACTIONS.firebaseRegister(values.email, values.password));
        } else {
            console.error('step0StartUpdating error', error);
            showException(error);
            yield put(PRE_REG_ACTIONS.apiError(error.message));
        }
    }
}

function* step1StartUpdating({ payload: { regUserData, origValues, values, affiliationKey  } }) {
    try {
        if (!shallowequal(origValues, values)) {
            regUserData.page1Data = values;
            regUserData.affiliationKey = affiliationKey;
            let regCtxNew = yield call(patch, ENDPOINTS.RESOURCE_PRE_REG_STEP_1_PATCH, regUserData)
            yield put(PRE_REG_ACTIONS.step1UpdateDone(regCtxNew.regUserData, regCtxNew.dictionaries));
        } else {
            yield put(PRE_REG_ACTIONS.step1UpdateNoChanges());
        }
    } catch (error) {
        console.error('step1StartUpdating error', error);
        showException(error);
        yield put(PRE_REG_ACTIONS.apiError(error.message));
    }
}

function* step2StartUpdating({ payload: { regUserData, origValues, values, affiliationKey  } }) {
    try {
        if (!shallowequal(origValues, values)) {
            regUserData.page2Data = values;
            regUserData.affiliationKey = affiliationKey;
            let regCtxNew = yield call(patch, ENDPOINTS.RESOURCE_PRE_REG_STEP_2_PATCH, regUserData)
            yield put(PRE_REG_ACTIONS.step2UpdateDone(regCtxNew.regUserData, regCtxNew.dictionaries));
        } else {
            yield put(PRE_REG_ACTIONS.step2UpdateNoChanges());
        }
    } catch (error) {
        console.error('step2StartUpdating error', error);
        showException(error);
        yield put(PRE_REG_ACTIONS.apiError(error.message));
    }
}

function* step3StartUpdating({ payload: { regUserData, origValues, values, affiliationKey  } }) {    
    try {
        if (!shallowequal(origValues, values)) {
            regUserData.page3Data = values;
            regUserData.affiliationKey = affiliationKey;
            let regCtxNew = yield call(patch, ENDPOINTS.RESOURCE_PRE_REG_STEP_3_PATCH, regUserData)
            yield put(PRE_REG_ACTIONS.step3UpdateDone(regCtxNew.regUserData, regCtxNew.dictionaries));
        } else {
            yield put(PRE_REG_ACTIONS.step3UpdateNoChanges());
        }
    } catch (error) {
        console.error('step3StartUpdating error', error);
        showException(error);
        yield put(PRE_REG_ACTIONS.apiError(error.message));
    }
}


function* step4StartUpdating({ payload: { regUserData, origValues, values, affiliationKey  } }) {
    try {
        if (!shallowequal(origValues, values)) {
            regUserData.page4Data = values;
            regUserData.affiliationKey = affiliationKey;
            let regCtxNew = yield call(patch, ENDPOINTS.RESOURCE_PRE_REG_STEP_4_PATCH, regUserData)
            yield put(PRE_REG_ACTIONS.step4UpdateDone(regCtxNew.regUserData, regCtxNew.dictionaries));            
        } else {
            yield put(PRE_REG_ACTIONS.step4UpdateNoChanges());
        }
    } catch (error) {
        console.error('step4StartUpdating error', error);
        showException(error);
        yield put(PRE_REG_ACTIONS.apiError(error.message));
    }
}

 

function* step5StartUpdating({ payload: { regUserData, origValues, values, affiliationKey  } }) {
    try {
        regUserData.page5Data = values;
        regUserData.affiliationKey = affiliationKey;

        //rejestracja w SPIRIT24
        let regCtxNew = yield call(patch, ENDPOINTS.RESOURCE_PRE_REG_STEP_5_PATCH, regUserData)
        yield put(PRE_REG_ACTIONS.step5UpdateDone(regCtxNew.regUserData, regCtxNew.dictionaries));
                    
        //rejestracja użytkownika w firebase
        if(regCtxNew.additionalParameters.firebaseRegister)
        {
            yield put(PRE_REG_ACTIONS.firebaseRegister(regUserData.page0Data.email, regUserData.page0Data.password));
        }

        //przejście na podsumowanie
        yield put(PRE_REG_ACTIONS.goToSummary());
        
        //wylogowanie i czyszczenie kontekstu na wszelki wypadek
        yield call(getFirebaseBackend().logout);    
        removeAuthContextFromStorage()

        
        /*
        ////rejestracja w systemach zewnetrzynch np. Glovo
        let appsRegister = regCtxNew.appsExternalRegistrations;
        for (let i = 0; i < appsRegister.length; i++) {
            try {        
                let appsRegisterResult  = yield call(post, appsRegister[i].postUrl,  appsRegister[i].body);   
            } catch (error) {        
                console.error('appsRegister['+i+']:' + error, error);
                console.error('appsRegister['+i+']:', appsRegister[i]);
            }
        }
        */
    } catch (error) {
        console.error('step5StartUpdating error', error);
        showException(error);
        yield put(PRE_REG_ACTIONS.apiError(error.message));
    }
}

function* firebaseRegister({ payload: { email, password } }) {
    try {
        yield call(getFirebaseBackend().registerUser, email, password);
        yield put(PRE_REG_ACTIONS.firebaseRegisterDone());
        yield put(PRE_REG_ACTIONS.goToSummary());
    } catch (error) {
        if (error === 'Firebase: The email address is already in use by another account. (auth/email-already-in-use).') {
            console.log('Email:' + email, error);
            yield put(PRE_REG_ACTIONS.goToSummary());
        } else {
            console.error('firebaseRegister error email:' + email, error);
            throw error;
        }
    }
}

function* resetAndGotoLogin({ payload: { history } }) {
    history.push('/login')
}

export function* PreRegistrationSaga() {
    yield takeEvery(PRE_REG_ACTIONS_TYPES.STEP_0_PATCH_DATA_START, step0StartUpdating);
    yield takeEvery(PRE_REG_ACTIONS_TYPES.STEP_1_PATCH_DATA_START, step1StartUpdating);
    yield takeEvery(PRE_REG_ACTIONS_TYPES.STEP_2_PATCH_DATA_START, step2StartUpdating);
    yield takeEvery(PRE_REG_ACTIONS_TYPES.STEP_3_PATCH_DATA_START, step3StartUpdating);
    yield takeEvery(PRE_REG_ACTIONS_TYPES.STEP_4_PATCH_DATA_START, step4StartUpdating);
    yield takeEvery(PRE_REG_ACTIONS_TYPES.STEP_5_PATCH_DATA_START, step5StartUpdating);
    yield takeEvery(PRE_REG_ACTIONS_TYPES.FIREBASE_REGISTER, firebaseRegister);
    yield takeEvery(PRE_REG_ACTIONS_TYPES.RESET_AND_GOTO_LOGIN, resetAndGotoLogin);    
}

export default PreRegistrationSaga;

