import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";


export interface ITrippeButton {
  
  onClickList: () => void,
  onClickBack?: () => void,
  onClickBackIcon?: string,
  onNext?: () => void,
}


export interface ITrippeButtonExt extends ITrippeButton {
  id: string,
}


interface ButtonProps extends ITrippeButtonExt {
  label: string;
  isLoading: boolean;
}

export const ButtonTripple = ({ id, label, isLoading, onClickList, onClickBack, onNext, onClickBackIcon }: ButtonProps) => {
  const { t } = useTranslation();
  let iconPrefix = isLoading ? "bx bx-loader bx-spin font-size-16 align-middle" : "bx bx-chevrons-right font-size-16 align-middle";

  let xs = 2, sm = 1;
  if (onClickBack) {
    xs = 4;
    sm = 2;
  }


  return (
    <Row className="mt-2 px-3 px-md-2 pb-xs-3 pb-3 pb-md-3">
      <Col className="px-0 mx-0" xs={xs} sm={sm}  >
        <button
          id={`${id}-to-list`}
          type="button" className="btn btn-color-default btn-outline-default btn-default" onClick={onClickList} disabled={isLoading}>
          <i className='fa-duotone fa-list align-middle blue fa-lg' />
        </button>
        {onClickBack &&
          <>
            {" "}
            <button
              id={`${id}-back`}
              type="button" className="btn btn-color-default  btn-outline-default btn-default" onClick={onClickBack} disabled={isLoading}>
              <i className={(onClickBackIcon ? onClickBackIcon : "fa-duotone fa-angles-left blue fa-lg") + " align-middle"} />
            </button>
          </>
        }
      </Col>
      <Col className="px-0 mx-0" xs={12 - xs} sm={12 - sm}>
        {onNext &&
          <Button
            id={`${id}-next`}
            type="button" color="default" className="btn btn-color-default w-100" onClick={onNext} >
            <i className={iconPrefix + ' fa-lg'}></i>
            <span>
              {" "}{t(label)}
            </span>
          </Button>
        }
        {!onNext &&
          <Button
            id={`${id}-submit`}
            type="submit" color="default" className="btn btn-color-default w-100" disabled={isLoading}>
            <i className={iconPrefix + '  fa-lg'}></i>
            <span>
              {" "}{t(label)}
            </span>
          </Button>
        }
      </Col>
    </Row>
  );
};

export default ButtonTripple;
