import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import "./wizardButton.scss";

interface ButtonProps {
  idx?: number;
  className?: string;
  pageKey: string;
  iconClass: string;
  isLoading: boolean;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>,
  buttonStyle?: string;
}

export const ButtonWizard = ({ iconClass, idx, isLoading, onClick, pageKey, isDisabled = false,
  className = "", buttonStyle = "btn-light" }: ButtonProps) => {
  const { t } = useTranslation();
  let iconPrefix = iconClass + " font-size-24 align-middle " + (isLoading ? " bx-flashing " : "")

  const disabledClass = isDisabled ? " disabled-wizard-button " : "";

  return (
    <Button
      style={{ height: '60px' }}
      disabled={isDisabled}
      type="button"
       
      className={"d-flex justify-content-xs-center justify-content-sm-center justify-content-md-start align-items-center " + className + disabledClass + " btn " + buttonStyle + "  btn-label w-100"}
      onClick={onClick}
    >
      {iconPrefix && <i style={{ width: '40px' }} className={iconPrefix + " label-icon"}></i>}
      <span   style={{ paddingLeft: '15px' }}>
        {t(pageKey + '.StepperButton')}
      </span>

    </Button>
  );
};

export default ButtonWizard;
