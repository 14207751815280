import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NOTIFICATIONS_LIST_ACT } from "./store/actionsNotificationsList";


const MarkAsReadNotification = ({ id, markedAsPresented }: { id: number, markedAsPresented: string | undefined }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!markedAsPresented) {
      dispatch(NOTIFICATIONS_LIST_ACT.markAsPresented(id));
    }
  }, [markedAsPresented])

  return <></>;
}

export default MarkAsReadNotification; 