import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";


import { ROUTE_WITH_AFFILATION } from "routes";
import { AFFILIATION_ACT } from "store/affiliation/actionsAffiliation";
import Login from "./Login";

const LoginAffiliationWrapper = () => {

  let { channel, affiliationKey } = useParams<ROUTE_WITH_AFFILATION>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (affiliationKey && channel) {
      dispatch(AFFILIATION_ACT.setUpAffiliation(`${channel}/${affiliationKey}`.toLowerCase()));
    }
  }, [channel, affiliationKey])

  return (
    <Login />
  );
};

export default LoginAffiliationWrapper; 