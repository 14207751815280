import LoadingPage from "components/Common/LoadingPage";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { Form, Formik, FormikValues } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import HelperHeaderContent from "pages/Agreement/FormItems/HelperHeaderContent";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { ROUTE_ATTACHMENT_TYPE__URL_PARAMS } from "routes";
import AttachmentNewForm from "./AttachmentNewForm";
import { ATTACHMENT_NEW_ACT } from "./store/actionsAttNew";
import { DEFAULT_EMPTY_ATT, IAttachmentNewReducer, IDocumentTypeConfig } from "./store/reducerAttNew";

const getValidationSchemaByDocConfig = (documentTypeConfig: IDocumentTypeConfig) => {

  let validationObject: any = {
    files: YUP_VALIDATION.YUP_FILES_REQUIRED,
    value: documentTypeConfig.valueRequired ? YUP_VALIDATION.YUP_STRING_REQ : YUP_VALIDATION.YUP_STRING,
    expirationDate: documentTypeConfig.expirationDateRequired ? YUP_VALIDATION.YUP_DATE_AFTER_NOW : YUP_VALIDATION.YUP_DATE,
    releaseDate: documentTypeConfig.releaseDateRequired ? YUP_VALIDATION.YUP_DATE_BEFORE_NOW : YUP_VALIDATION.YUP_DATE,
  };
  return YUP_VALIDATION.YUP_OBJ.shape(validationObject);
}

const hasAdditionalData = (documentTypeConfig: IDocumentTypeConfig) => {
  return documentTypeConfig.valueRequired || documentTypeConfig.expirationDateRequired || documentTypeConfig.releaseDateRequired;
}

const AttachmentNewPage = () => {
  let { docTypeId } = useParams<ROUTE_ATTACHMENT_TYPE__URL_PARAMS>();

  const dispatch = useDispatch();
  const history = useHistory();

  const { isReloadRequired, error, info, isLoading, documentTypeConfig } = useSelector((state: any) => {
    const attachmentNewStore: IAttachmentNewReducer = state.AttachmentNewStore as IAttachmentNewReducer;
    return (
      {
        isReloadRequired: attachmentNewStore.isReloadRequired,
        error: attachmentNewStore.error,
        info: attachmentNewStore.info,
        documentTypeConfig: attachmentNewStore.documentTypeConfig,
        isLoading: attachmentNewStore.isLoading,
      })
  }
  );

  useEffect(() => {
    if (docTypeId !== documentTypeConfig.id) {
      dispatch(ATTACHMENT_NEW_ACT.prepareNewAtt(docTypeId, history))
    }
  }, [documentTypeConfig, docTypeId])

  const validationSchemaByDocConfig = useMemo(() => getValidationSchemaByDocConfig(documentTypeConfig), [documentTypeConfig]);

  if (isReloadRequired || !documentTypeConfig) {
    return <LoadingPage />
  }

  const onAttachmentSubmit = (values: FormikValues) => {
    dispatch(ATTACHMENT_NEW_ACT.addAttachment(documentTypeConfig, values, history))
  }

  return (
    <Container fluid>
      <DialogWrapper title="">
        <Formik
          enableReinitialize
          initialValues={DEFAULT_EMPTY_ATT}
          validationSchema={validationSchemaByDocConfig}
          onSubmit={onAttachmentSubmit}
        >
          <Form>
              <HelperHeaderContent
                headerText={'ATT_LIST.missing.attachment.' + docTypeId}
                textBefore={hasAdditionalData(documentTypeConfig) ? 'ATT_NEW.attachment.comment.withRequirements' : 'ATT_NEW.attachment.comment.noRequirements'}
              />
            <AttachmentNewForm documentTypeConfig={documentTypeConfig} isLoading={isLoading} />
          </Form>
        </Formik>
      </DialogWrapper>
    </Container>
  )
}
export default AttachmentNewPage; 