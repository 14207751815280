import TranslatedErrors from "components/MyComponents/TranslatedErrors";
import { Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, FormText, Label, Row } from "reactstrap";
import { PHONE_TYPE_PREFIX, PHONE_TYPE_SUFFIX } from "../IAgreement";
import { IGenericFormItem } from "./GenericFormItem";


const FormItemPhoneField = ({ formItem, pageKey }: IGenericFormItem) => {
    const { t } = useTranslation();
    const { errors, touched } = useFormikContext();
    const fieldKey = pageKey + '.' + formItem.name;
    const textBefore = formItem.textBeforeSuffix ? fieldKey + '.' + formItem.textBeforeSuffix : undefined;
    const textAfter = formItem.textAfterSuffix ? fieldKey + '.' + formItem.textAfterSuffix : undefined;
    let hasErrorPRE = errors[formItem.name + PHONE_TYPE_PREFIX as keyof typeof errors] && touched[formItem.name + PHONE_TYPE_PREFIX as keyof typeof errors];
    let hasErrorSUF = errors[formItem.name + PHONE_TYPE_SUFFIX as keyof typeof errors] && touched[formItem.name + PHONE_TYPE_SUFFIX as keyof typeof errors];

    return (
        <>
            {textBefore &&
                <FormGroup  >
                    <FormText>{t(textBefore)}</FormText>
                </FormGroup>
            }
            <FormGroup>
                {fieldKey && <Row>
                    <Col>
                        <Label htmlFor={formItem.name}>{t(fieldKey)}</Label>
                    </Col>
                </Row>
                }
                <Row>
                    <Col style={{width: '80px'}} xs={1} >
                        <Field type="text" style={{width: '75px'}}
                            name={formItem.name + PHONE_TYPE_PREFIX} maxLength={3} placeholder='+48'
                            className={"form-control" + (hasErrorPRE ? " is-invalid" : "")}
                        />
                    </Col>
                    <Col style={{width: '140px'}} xs={1}  >
                        <Field type="text"
                            name={formItem.name + PHONE_TYPE_SUFFIX} maxLength={9}
                            className={"form-control" + (hasErrorSUF ? " is-invalid" : "")}
                        />
                    </Col>
                    <Col xs={10}></Col>
                </Row>
                {hasErrorPRE &&
                    <TranslatedErrors errors={errors[formItem.name + PHONE_TYPE_PREFIX as keyof typeof errors]} />
                }
                {hasErrorSUF &&
                    <TranslatedErrors errors={errors[formItem.name + PHONE_TYPE_SUFFIX as keyof typeof errors]} />
                }
            </FormGroup>

            {textAfter &&
                <FormGroup  >
                    <FormText>{t(textAfter)}</FormText>
                </FormGroup>
            }

        </>

    )
}

export default FormItemPhoneField