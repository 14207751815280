
import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Collapse, Row } from "reactstrap";

interface IMobileGridRow {
    id: any,
    getHeader: (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => ReactNode,
    getChildrenDeffered?: () => ReactNode,
    getChildrenInstant?: () => ReactNode,
    expandable?: boolean;
    defaultExpandState?: boolean;
    withBorder?: boolean;
    scrollToMe?: boolean;
}

export const getCollapseButton = (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => {
    return (
        <i className={"fa-solid fa-chevron-" + (isOpen ? "up" : "down")}></i>
    )
}

export const MobileGridRowCompact = ({ id, getHeader, getChildrenInstant, getChildrenDeffered, expandable = true, defaultExpandState = false, scrollToMe = false, withBorder = false }: IMobileGridRow) => {

    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(defaultExpandState);
    const [childrenDeff, setChildrenDeff] = useState<ReactNode | null>(null);
    const [lastLanguage, setLastLanguage] = useState<string>(i18n.language);

    const divRef = useRef(null);

    //załaduj opóźnione dane
    useEffect(() => {
        if (scrollToMe && divRef) {
            const curAny: any = divRef.current;
            if (curAny) {
                window.scrollTo({
                    top: curAny.offsetTop,
                    behavior: "smooth"
                })
            }
        }
    }, [scrollToMe])


    //załaduj opóźnione dane
    useEffect(() => {
        if (getChildrenDeffered && isOpen && (childrenDeff === null || lastLanguage !== i18n.language)) {
            setChildrenDeff(getChildrenDeffered());
            setLastLanguage(i18n.language)
        }
    }, [isOpen, childrenDeff, i18n.language])

    return (
        <>
            <div ref={divRef} className={"mgr-row shadow-lg" + (withBorder ? " mgr-row-with-border" : "") + (scrollToMe ? " mgr-row-exclaimed" : "") }>
                <Row className="mgr-row-header-top" onClick={() => { if (expandable) { setIsOpen(!isOpen) } }}>
                    <Col className="px-0" xs={12}>
                        {getHeader(isOpen, setIsOpen)}
                    </Col>
                </Row>
                <div className="accordion accordion-flush px-0">
                    <Collapse isOpen={isOpen} className="accordion-collapse mx-0 px-0">
                        <div className="accordion-panel accordion-body mt-1" onDoubleClick={() => { if (expandable) { setIsOpen(!isOpen) } }}>
                            {getChildrenInstant && getChildrenInstant()}
                            {childrenDeff && childrenDeff}


                            {(expandable && isOpen) &&
                                <Row className="border-top mx-1 mgr-row-header-bottom" onClick={() => { if (expandable) { setIsOpen(!isOpen) } }}>
                                    {id &&
                                        <Col xs="auto">
                                            <i className="fa-solid fa-tags px-1"></i>{id}
                                        </Col>
                                    }
                                    <Col>
                                        <span className="px-2">
                                            {(isOpen ? t('settlement.hide-details') : t('settlement.show-details'))}
                                        </span>
                                        {getCollapseButton(isOpen, setIsOpen)}
                                    </Col>
                                </Row>
                            }
                        </div>

                    </Collapse>
                </div>
            </div>
        </>
    );
}