import { useWindowSize } from "@react-hook/window-size";
import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { AlertFrameInfo } from "components/Common/datatable/AlertFrameInfo";
import { AGREEMENT_PRE_LIST_ACTION } from "pages/Agreement/store-pre-list/actionsPreAgrList";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "routes";
import { DASHBOARD_ACTIONS } from "store/actions";
import DashboardLayoutDesktop from "./DashboardLayoutDesktop";
import DashboardLayoutMobile from "./DashboardLayoutMobile";
import DashboardSubDialog_00_NotificationsList from "pages/Dashboard/DashboardSubDialog_00_NotificationsList";


const DashboardPageContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { dashboardDto } = useSelector((state: any) => ({ dashboardDto: state.Dashboard.dashboardDto, }));
  const extDocsToSignCount = useSelector((state: any) => state.LoginState.extDocsToSignCount)

  const createAgreement = () => {
    dispatch(AGREEMENT_PRE_LIST_ACTION.getAvailableTypes(history))
  }
  const updateProfile = () => {
    history.push(ROUTES.ROUTE_PROFILE);
  }

  const forceSettlement = () => {
    dispatch(DASHBOARD_ACTIONS.dashboardForceSettlement(dashboardDto.id));
  }

  const goToAttacfhments = () => {
    history.push(ROUTES.ROUTE_ATTACHMENT_LIST);
  }

  const [width] = useWindowSize()
  const layout = width > 1200 ? <DashboardLayoutDesktop /> : <DashboardLayoutMobile />;


  return (
    <>
      {dashboardDto.fraudLockWarning &&
        <AlertFrameError messageTitle="Dashboard.Alert.LockFraudUser.Title" messageKey="Dashboard.Alert.LockFraudUser.Content" />
      }


      {extDocsToSignCount > 0 &&
        <AlertFrameInfo messageTitle="Dashboard.Alert.doc2signdoc2sign.Title"
          messageKey="Dashboard.Alert.Doc2Sign.Content"
          buttonOnClickAction={() => history.push(ROUTES.ROUTE_EXT_DOC_TO_SIGN)}
          iconClass="fa-duotone fa-triangle-exclamation"
          buttonTitleKey="Dashboard.Alert.doc2sign.Button"
          buttonId="button-alert-doc2sign-required"
        />
      }



      {dashboardDto.extraInfoLock === 'NEW_AGREEMENT_REQUIRED' &&
        <AlertFrameInfo messageTitle="Dashboard.Alert.suggestAgreementRecreation.Title"
          messageKey="Dashboard.Alert.suggestAgreementRecreation.Content"
          buttonOnClickAction={createAgreement}
          iconClass="fa-duotone fa-triangle-exclamation"
          buttonTitleKey="Dashboard.Alert.suggestAgreementRecreation.Button"
          buttonId="button-alert-suggest-agreeement-recreation"
        />
      }

      {dashboardDto.extraInfoLock === 'FORCED_LOCK_VISIBLE' &&
        <AlertFrameInfo messageTitle="Dashboard.Alert.ForcedSettlementLock.Title"
          messageKey="Dashboard.Alert.ForcedSettlementLock.Content"
          buttonOnClickAction={() => history.push(ROUTES.ROUTE_CONTACT_FORM)}
          iconClass="fa-duotone fa-triangle-exclamation"
          buttonTitleKey="Dashboard.Alert.ForcedSettlementLock.Button"
          buttonId="button-alert-suggest-agreeement-recreation"
        />
      }

      {(dashboardDto.suggestProfileUpdate) &&
        <AlertFrameInfo messageTitle="Dashboard.Alert.SuggestProfileUpdate.Title"
          messageKey="Dashboard.Alert.SuggestProfileUpdate.Content"
          buttonOnClickAction={updateProfile}
          buttonTitleKey="Dashboard.Alert.SuggestProfileUpdate.Button"
          buttonId="button-alert-suggest-profile-update"
        />
      }
      {dashboardDto.suggestPreOrB2b &&
        <AlertFrameInfo messageTitle="Dashboard.Alert.suggestPreOrB2b.Title"
          messageKey="Dashboard.Alert.suggestPreOrB2b.Content"
          buttonOnClickAction={createAgreement}
          iconClass="fa-duotone fa-handshake"
          buttonTitleKey="Dashboard.Alert.suggestPreOrB2b.Button"
          buttonId="button-alert-suggest-agreeement-b2b-or-pre"
        />
      }
      {dashboardDto.suggestZlecenie &&
        <AlertFrameInfo messageTitle="Dashboard.Alert.suggestZlecenie.Title"
          messageKey="Dashboard.Alert.suggestZlecenie.Content"
          buttonOnClickAction={createAgreement}
          iconClass="fa-duotone fa-handshake"
          buttonTitleKey="Dashboard.Alert.suggestZlecenie.Button"
          buttonId="button-alert-suggest-agreeement-zle"
        />
      }
      {(dashboardDto.settlementNextCycleDate) &&
        <AlertFrameInfo messageTitle="Settlement.ForcePayment.Title"
          messageKey="Settlement.ForcePayment.Content"
          buttonOnClickAction={forceSettlement}
          iconClass="fa-duotone fa-comments-dollar"
          buttonTitleKey="Settlement.ForcePayment.Button"
          buttonId="button-alert-force-settlement"
          translationPlaceholdersOject={{
            "NEXT_SETTLMENT_DATE": dashboardDto.settlementNextCycleDate,
          }}
        />
      }
      {(dashboardDto.warningAttachmentRequired) &&
        <AlertFrameInfo messageTitle="Dashboard.Alert.WarningAttachmentRequired.Title"
          messageKey="Dashboard.Alert.WarningAttachmentRequired.Content"
          buttonOnClickAction={goToAttacfhments}
          iconClass="fa-duotone fa-paperclip"
          buttonTitleKey="Dashboard.Alert.WarningAttachmentRequired.Button"
          buttonId="button-alert-attachment-required"
        />
      }
      {(dashboardDto.warningAttachmentExpiring && !dashboardDto.warningAttachmentRequired) &&
        <AlertFrameInfo messageTitle="Dashboard.Alert.WarningAttachmentExpiring.Title"
          messageKey="Dashboard.Alert.WarningAttachmentExpiring.Content"
          buttonOnClickAction={goToAttacfhments}
          iconClass="fa-duotone fa-paperclip"
          buttonTitleKey="Dashboard.Alert.WarningAttachmentExpiring.Button"
          buttonId="button-alert-attachment-expiring"
        />
      }
      {layout}
    </>

  );
};


export default DashboardPageContent; 