import { del, ENDPOINT_AGREEMENT, get } from "helpers/api_helper";
import { downloadPdf } from "helpers/download_helper";
import { ICommonFileB64Result, ISagaParam, ResponseGridData } from "helpers/tsx_helper";
import { AGREEMENT_PRE_LIST_ACTION } from "pages/Agreement/store-pre-list/actionsPreAgrList";
import { call, put, takeEvery } from "redux-saga/effects";
import { fillPlaceholders } from "routes/placeholder_helper";
import { reloadRoles } from "store/actions";
import { AGREEMENT_LIST_ACT, AGREEMENT_LIST_ACT_TYP } from "./actionsAgrList";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";

function* loadData(action: ISagaParam) {
    try {
        let gridResponse: ResponseGridData<any> = yield call(get, ENDPOINT_AGREEMENT.RESOURCE_AGREEMENT_GET, {})
        yield put(AGREEMENT_LIST_ACT.loadDataDone(gridResponse.gridData, gridResponse.info, gridResponse.error));
    } catch (error) {
        console.log('agreementList.loadData error', error);
        showException(error);
        yield put(AGREEMENT_LIST_ACT.apiError(error));
    }
}

function* pdfDownload(action: ISagaParam) {
    try {
        const url = fillPlaceholders(
            ENDPOINT_AGREEMENT.RESOURCE_GET_AGREEMENT_GET_PDF,
            {
                'agreementId': action.payload.agreementId
            });
        let fileB64 : ICommonFileB64Result = yield call(get, url, {})
        downloadPdf(fileB64.base64Data, fileB64.fileName)
      
    } catch (error) {
        console.log('agreementList.pdfDownload error', error);
        yield put(AGREEMENT_LIST_ACT.apiError(error));
    }
}
function* rejectAgreement(action: ISagaParam) {
    try {

        const url = fillPlaceholders(
            ENDPOINT_AGREEMENT.RESOURCE_DEL_AGREEMENT_REJECT,
            {
                'agreementId': action.payload.agreementId
            });
        yield call(del, url, {})
        yield put(AGREEMENT_LIST_ACT.loadData());
        yield put(AGREEMENT_PRE_LIST_ACTION.forceReload());
        yield put(reloadRoles());
        showPopupInfo('AGR_LIST.button.rejectAwaiting.done');

    } catch (error) {
        console.log('agreementList.loadData error', error);
        showException(error);
        yield put(AGREEMENT_LIST_ACT.apiError(error));
    }
}



export function* AgreementListSaga() {
    yield takeEvery(AGREEMENT_LIST_ACT_TYP.LOAD_DATA, loadData);
    yield takeEvery(AGREEMENT_LIST_ACT_TYP.PDF_DOWNLOAD, pdfDownload);
    yield takeEvery(AGREEMENT_LIST_ACT_TYP.REJECT_AGREEMENT, rejectAgreement);
}

export default AgreementListSaga;
