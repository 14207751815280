import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

interface ButtonProps {
  id: string;
  label: string;
  className?: string;
  iconClass?: string;
  color?: string;
  outline?: boolean;
  isLoading?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const ButtonFloating = ({className, id, iconClass, label, outline = false, isLoading = false, color = "primary", onClick }: ButtonProps) => {
  const { t } = useTranslation();
  let iconPrefix = isLoading ? "fa-duotone fa-spinner fa-spin " : (iconClass ? iconClass + " " : "")
  let classNameIn = className + ' '+ 
    (outline ? `btn btn-outline-${color}` : "btn") + ` btn-${color} btn-color-${color}`



  return (
    <div className="d-grid  w-100-mobile">
      <Button id={id} type="button" color={color} className={ classNameIn} onClick={onClick}>
          {iconPrefix &&  <i className={iconPrefix + " me-2"}></i>}
          <span>{t(label)}</span>
      </Button>
    </div>
  );
};
export default ButtonFloating;
