import { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

interface ButtonProps {
  id: string;
  label: string;
  iconClass?: string;
  isLoading: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>
  color?: string;
  countDownTime: number;
  onCountDownEnd: ()=>void;
}

export const ButtonCustomCountDown = ({id, iconClass, label, isLoading, onClick, color="default" , countDownTime, onCountDownEnd }: ButtonProps) => {
  const { t } = useTranslation();
  let iconPrefix = isLoading ? "bx bx-loader bx-spin " : (iconClass ? iconClass + " " : "")

  const [timeLeft, setTimeLeft] = useState(countDownTime);

  useEffect(() => {
    if(timeLeft===0){
       onCountDownEnd();
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <div className="d-grid">
      <Button id={id} type="button" color={color} className={"btn btn-block btn-color-" + color} onClick={onClick}>
        {iconPrefix && <i className={iconPrefix + "font-size-16 align-middle me-2"}></i>}
        {t(label)} [ {timeLeft}s ]
      </Button>
    </div>
  );
};

export default ButtonCustomCountDown;
