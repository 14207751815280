import { ENDPOINTS, post } from "helpers/api_helper";
import { ISagaParam } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { fillPlaceholders } from "routes/placeholder_helper";
import { PROFILE_REMOVAL_ACT, PROFILE_REMOVAL_ACT_TYP } from "./actionsProfileRemoval";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { removeAuthContextFromStorage } from "helpers/auth_storage_helper";
import { logoutUser } from "store/actions";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";

function* removeProfile(action: ISagaParam) {
    try {
        const removeContractorUrl = fillPlaceholders(
            ENDPOINTS.RESOURCE_PROFILE_REMOVE, {'contractorId': action.payload.profileRemoval.contractorId}
            );
        yield call(post, removeContractorUrl, action.payload.profileRemoval)
        yield put(PROFILE_REMOVAL_ACT.removeProfileDone());
        yield call(getFirebaseBackend().deleteCurrentUser);
        yield call(getFirebaseBackend().logout);
        removeAuthContextFromStorage();
        yield put(PROFILE_REMOVAL_ACT.removeProfileDone());
        yield put(logoutUser(action.payload.history));
        showPopupInfo('removeProfile.profile-removed');
    } catch (error) {    
        console.log('removeProfile error', error);
        showException(error);
        yield put(PROFILE_REMOVAL_ACT.apiError(error));
    }
}

function* removeProfileDone(action: ISagaParam) {
    try {
        console.log('removeProfileDone', action);
    } catch (error) {
        console.log('removeProfileDone error', error);
        yield put(PROFILE_REMOVAL_ACT.apiError(error));
    }
}

export function* ProfileRemovalSaga() {
    yield takeEvery(PROFILE_REMOVAL_ACT_TYP.REMOVE_PROFILE, removeProfile);
    yield takeEvery(PROFILE_REMOVAL_ACT_TYP.REMOVE_PROFILE_DONE, removeProfileDone);
}

export default ProfileRemovalSaga;
