import CustomText from "components/MyComponents/CustomText";
import CustomTextWithButton from "components/MyComponents/CustomTextWithButton";
import HorizontalDivider from "components/MyComponents/HorizontalDivider";
import { Field, Form,  useFormikContext } from "formik";
import {  useSelector } from "react-redux";

type AffiliationActionDialogFieldsProps = {
  inputGroupPrefixContentCopy: JSX.Element | undefined,
  smsCount: number,
}

const AffiliationActionDialogFields = ({
  inputGroupPrefixContentCopy, smsCount
}: AffiliationActionDialogFieldsProps) => {

  const affiliationLinkStore = useSelector((state: any) => state.AffiliationLinkStore);
  const formikContext = useFormikContext();
  return (
    <Form>
      <Field name="yourLink" label="AffLinkSend.Data.yourLink"
        component={CustomText} isDisabled={true}
        textAfter="AffLinkSend.Data.yourLink.bottom"
        inputGroupPrefixContent={inputGroupPrefixContentCopy}
      />
      <HorizontalDivider labelKey="separator.or" className="text-uppercase pb-2" />
      <Field name="phoneNumber" label="AffLinkSend.Data.phoneNumber" component={CustomTextWithButton}
        isDisabled={affiliationLinkStore.areChangesInProgress}
        buttonColor={smsCount > 5 ? "secondary" : "default"}
        isButtonDisabled={smsCount > 5 || affiliationLinkStore.areChangesInProgress}
        buttonLabel="AffLinkSend.Data.Submit"
        buttonOnClick={formikContext.submitForm} isLoading={false}
      />
    </Form>
  );
};


export default AffiliationActionDialogFields; 