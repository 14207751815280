import { MobileGridRowCompact } from "components/Common/datatable/MobileGridRowCompact";
import TableComponent from "components/Common/datatable/TableComponent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

const AffiliationDialogHistory = () => {

  const affiliationLinkStore = useSelector((state: any) => state.AffiliationLinkStore);
  const { t } = useTranslation();

  const renderContent = (row: any) => {


    const getHeader = (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => {
      return (
        <>
          <Row className="align-items-start">
            <Col className="px-sm-1 px-lg-2  d-flex  align-items-start">
              <h3 className="mb-0 row-item-header">
                  <i className={(row.bonusDate ? "fa-solid fa-sack-dollar green-badge-color " : 
                  (row.taxiOrCourier === 'T' ? "fa-solid fa-taxi orange-badge-color" : "fa-solid fa-person-biking orange-badge-color")) + " px-2 py-2"} />
                {row.name}</h3>
            </Col>
          </Row>

          <Row>
            <Col><div className="head-with-badge prevent-select">
              <span className={"badge blue-badge"}>
                {t('AFF_HIST.badge.registeredDate')} {row.registrationDate}</span>
              {row.bonusDate && <span className={"badge green-badge"}>{t('AFF_HIST.badge.bonusDate')} {row.bonusDate}</span>}
              {!row.bonusDate && <span className={"badge orange-badge"}>  {t('AFF_HIST.badge.bonusAwaiting')} </span>}
            </div></Col>
          </Row>
        </>
      )
    }



    const getChildrenInstant = () => <></>

    const getChildrenDeffered = () => <></>

    return (
      <MobileGridRowCompact
        id={row.id}
        getHeader={getHeader}
        getChildrenInstant={getChildrenInstant} getChildrenDeffered={getChildrenDeffered}
        expandable={false}
      />
    )
  }

  return (
    <TableComponent
      tableName="AFF_HIST"
      data={affiliationLinkStore}
      hederKey="AFF_HIST.mobile.header"
      renderContent={renderContent}
      isFullWidthInFullMode={true}
    />
  )
};


export default AffiliationDialogHistory; 