import { IIbanChange } from "./reducerIbanChange";

const PREFIX = "PBA_"

const SEND_CODE = PREFIX + "SEND_CODE";
const ibanChangeSmsCode = (contractorId: string) => ({
    type: SEND_CODE, payload: {contractorId}
});

const IBAN_CHANGE = PREFIX + "IBAN_CHANGE";
const ibanChange = (ibanChangeAction: IIbanChange, history: any) => ({
    type: IBAN_CHANGE, payload: {ibanChangeAction, history}
});

const IBAN_CHANGE_DONE = PREFIX + "IBAN_CHANGE_DONE";
const ibanChangeDone = () => ({
    type: IBAN_CHANGE_DONE, payload: {}
});

 
const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}
 
export const IBAN_CHANGE_ACT = {
    ibanChangeSmsCode,
    ibanChange, ibanChangeDone, 
    apiError
}

export const IBAN_CHANGE_ACT_TYP = {
    SEND_CODE, IBAN_CHANGE, IBAN_CHANGE_DONE, 
    API_ERROR
}