import LoadingContent from "components/Common/LoadingContent";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { getMoney } from "helpers/money";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./settlement.scss";
import { Col, Row } from "reactstrap";


const SettlementListAdditionalInfo = () => {

  const settlementListStore = useSelector((state: any) => state.SettlementListStore);
  const { t } = useTranslation();
  return (
    <DialogWrapper title="SETT_LIST.mobile.additional-info.year.summary">
      <Row className="apps-chart-row">
        {settlementListStore.isLoading &&
         <Col><LoadingContent /></Col>
        }
        {!settlementListStore.isLoading &&
          <Col className="flex-div px-0">
            <div className="con-box">
              <span>{t('SETT_LIST.mobile.additional-info.year.transfers')}</span>
              <h3>{getMoney(settlementListStore.context.thisYearPayments)}</h3>
            </div>
            <div className="con-box">
              <span>{t('SETT_LIST.mobile.additional-info.year.settlement-count')}</span>
              <h3>{settlementListStore.context.thisYearSettlementsCount}</h3>
            </div>
          </Col>
        }
      </Row>
    </DialogWrapper>
  )
}
export default SettlementListAdditionalInfo; 