import LoadingPage from "components/Common/LoadingPage";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonWizard from "components/MyComponents/ButtonWizard";
import { AGREEMENT_ACTION } from "pages/Agreement/store-pre/actionsAgr";
import { IPreAgreementState } from "pages/Agreement/store-pre/reducerAgr";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ROUTES, ROUTE_AGREEMENT_P1_WIZARD__URL_PARAMS } from "routes";
import { fillPlaceholders } from "routes/placeholder_helper";
import { IFormPage } from "./IAgreement";
import { AGREEMENT_PRE_LIST_ACTION } from "./store-pre-list/actionsPreAgrList";

const AgreementPage1Wizard = () => {
  const history = useHistory();
  let { agreementType } = useParams<ROUTE_AGREEMENT_P1_WIZARD__URL_PARAMS>();

  useEffect(() => {
    window.scrollTo(0, 70)
  }, [])

  const { isLoading, error, agreementConfig, agreementData, agreementDataLoaded, canGeneratePreview, canSign } = useSelector((state: any) => {
    const agreementSignStore: IPreAgreementState = state.AgreementSignStore as IPreAgreementState;
    return (
      {
        isLoading: agreementSignStore.isLoading,
        error: agreementSignStore.error,
        agreementConfig: agreementSignStore.agreementConfig,
        agreementData: agreementSignStore.agreementData.pageData,
        agreementDataLoaded: agreementSignStore.agreementDataLoaded,
        canGeneratePreview: agreementSignStore.canGeneratePreview,
        canSign: agreementSignStore.canSign,
      })
  }
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!agreementDataLoaded && error === '') {
      dispatch(AGREEMENT_ACTION.getAgreementConfigAndData(agreementType, null, history, false))
    }
  }, [])

  if (!agreementDataLoaded && error === '') {
    return <Container fluid>
      <DialogWrapper title={t('Agreement.' + agreementType + '.header')}>
        <LoadingPage />
      </DialogWrapper>
    </Container>
  }



  const getButtonIcon = (isLocked: boolean, isFilled: boolean, isValid: boolean) => {

    if (isLocked) {
      return "fa-duotone fa-lock fa-lg";
    }
    if (!isFilled) {
      return "fa-duotone fa-pen-to-square fa-lg";
    }

    if (!isValid) {
      return "fa-duotone fa-triangle-exclamation fa-lg";
    }
    return "fa-duotone fa-square-check fa-lg text-success";
  }



  const getButtonIconPage = (pageKey: string) => {

    if (!agreementData) {
      return "";
    }

    return getButtonIcon(agreementData[pageKey].pageLocked, agreementData[pageKey].pageFilled, agreementData[pageKey].pageValid);
  }

  const getButtonStyle = (isLocked: boolean, isFilled: boolean, isValid: boolean) => {

    if (isLocked) {
      return "";
    }
    if (!isFilled || !isValid) {
      return "btn-default btn-color-default ";
    }
    return "btn-outline-success btn-success btn-color-success";
  }


  const getButtonStyleForPage = (pageKey: string) => {

    if (!agreementData) {
      return "";
    }

    return getButtonStyle(agreementData[pageKey].pageLocked, agreementData[pageKey].pageFilled, agreementData[pageKey].pageValid);
  }





  const isDiabled = (pageKey: string) => {

    if (!agreementData) {
      return false;
    }
    return agreementData[pageKey].pageLocked;
  }


  const onOpenForm = (pageKey: string, agreementType: string) => {
    dispatch(AGREEMENT_ACTION.openFormPage(pageKey, agreementType, history))
  }
  const onSignDoc = () => {
    dispatch(AGREEMENT_ACTION.openSignDocument(agreementType, history))
  }

  const onPreviewDoc = () => {
    history.push(fillPlaceholders(ROUTES.ROUTE_PDF_PREVIEW_AGREEMENT_TEMPLATE, {
      agreementType: agreementType,
    }));

  }
  const onClickBackToAgrementType = () => {
    dispatch(AGREEMENT_PRE_LIST_ACTION.getAvailableTypes(history))
  }
 
  return (
    <Container>
      <DialogWrapper title="">
        <div className="vstack gap-2 col-md-12 mx-auto margin-cutter">

        <div className="form-title">
          <h2 className="text-md-start"><span className="blue">{t('Agreement.' + agreementType + '.header')}</span>          
          </h2>
        </div>

          <ButtonWizard isLoading={false}
            pageKey="Agreement.WizardPage.ChangeAgreementType" 
            iconClass="fa-duotone fa-shuffle fa-lg blue" onClick={onClickBackToAgrementType}
            isDisabled={isLoading}
            className="agreement-type-button"
            buttonStyle="btn-outline-default btn-default btn-color-default "
          />

          {agreementConfig?.pages.map((page: IFormPage, key: number) => (
            <ButtonWizard key={page.pageKey}
              idx={key + 1}
              className="agreement-step"
              isLoading={isLoading}
              pageKey={page.pageKey}
              isDisabled={isLoading || isDiabled(page.pageKey)}
              iconClass={getButtonIconPage(page.pageKey)}
              buttonStyle={getButtonStyleForPage(page.pageKey)}
              onClick={() => { onOpenForm(page.pageKey, agreementType) }}
            />
          ))}
          <Row className="pb-2">
            <Col xs={12} sm={7} md={9} className="mt-2" >
              <ButtonWizard
                isLoading={isLoading}
                className="agreement-sign"
                buttonStyle="btn-default  btn-color-default border-0"
                pageKey="Agreement.WizardPage.SignAgreement"
                iconClass={"fa-duotone fa-signature  fa-lg" + (canSign ? " fa-beat" : "")} onClick={onSignDoc}
                isDisabled={isLoading || !canSign}
              />
            </Col>
            <Col xs={12} sm={5} md={3} className="mt-2" >
              <ButtonWizard
                isLoading={isLoading}
                buttonStyle="btn-outline-default btn-default btn-color-default"
                className="agreement-preview"
                pageKey="Agreement.WizardPage.PreviewAgreement"
                iconClass="fa-duotone fa-file-pdf  fa-lg" onClick={onPreviewDoc}
                isDisabled={isLoading || !canGeneratePreview}
              />
            </Col>
          </Row>
        </div>
      </DialogWrapper>
     
    </Container>
  );
};


export default AgreementPage1Wizard; 