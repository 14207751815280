import { IBAN_CHANGE_ACT_TYP } from "./actionsIbanChange";

export interface IIbanChange {
    contractorId: number,
    bankAccountBase: string,
    bankAccountLease: string,
    bankAccountReturns: string,
    smsConfirmation: string,
}

export interface IIbanChangeActionReducer {
    ibanChangeActionInProgress: boolean,
    ibanChangeAction: IIbanChange | null
    error: string,
    info: string,
}

const INIT_STATE: IIbanChangeActionReducer = {
    ibanChangeAction: null,
    ibanChangeActionInProgress: false,
    info: '',
    error: '',
};

const IbanChangeActionStore = (state = INIT_STATE, action: { type: any, payload: any }) => {

    switch (action.type) {
        case IBAN_CHANGE_ACT_TYP.SEND_CODE:
            return {
                ...state,
                ibanChangeActionInProgress: false,
                error: '',
                info: '',
            };
        case IBAN_CHANGE_ACT_TYP.IBAN_CHANGE:
            return {
                ...state,
                ibanChangeAction: action.payload.ibanChangeAction,
                ibanChangeActionInProgress: true,
                info: '',
                error: '',
            };
        case IBAN_CHANGE_ACT_TYP.IBAN_CHANGE_DONE:
            return {
                ...state,
                ibanChangeActionInProgress: false,
                info: '',
                error: '',
            };

        case IBAN_CHANGE_ACT_TYP.API_ERROR:
            return { ...state, ibanChangeActionInProgress: false, error: action.payload.error };
        default:
            return state;
    }
}

export default IbanChangeActionStore;