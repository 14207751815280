import { useTranslation } from 'react-i18next';
import { FormText } from 'reactstrap';


interface ITranslatedErrors {
  errors: any
}

const TranslatedErrors = ({ errors }: ITranslatedErrors) => {
  const { t } = useTranslation();
  if (!errors) {
    return <></>
  }

  if (!Array.isArray(errors)) {
    return <FormText  key={-1}><div className="error">{t(errors)}</div></FormText>
  }
  return (
    <div>
      {errors.map((e: string, idx: number) => {
        return <FormText key={idx}><div  className="error">{t(e)}</div></FormText>
      }
      )
      }
    </div>
  )
}

export default TranslatedErrors