import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import PDFViewer from "./PdfViewer";

export default function PdfViewerPageUnsavedAgreementPreview() {
    let { agreementType } = useParams<any>();
    return (
        <>
                <Container fluid className="p-0">
                    <PDFViewer pdfFileId={0}  pdfFileName={'agreement-preview'}
                    context="agreement-preview"
                    agreementType={agreementType} 
                    ></PDFViewer>
                </Container>

        </>
    );
}