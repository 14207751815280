export interface IPlaceholderMap {
    [index: string]: string
}

export const fillPlaceholders = (path: string, placeholders: IPlaceholderMap): string => {
    let retval = path;
    for (const [k, v] of Object.entries(placeholders)) {
        retval = retval.replaceAll(':' + k, v)
    }
    return retval;
}

export const fillPlaceholdersHash = (path: string, placeholders: IPlaceholderMap): string => {
    let retval = path;
    for (const [k, v] of Object.entries(placeholders)) {
        retval = retval.replaceAll(`#${k}#`, v)
    }
    return retval;
}