import { DEAL_ADD_ACT_TYP } from "./actionsDealAdd";

export interface IDealAddReducer {    
    inProgress: boolean,
    error: string,
}

const INIT_STATE: IDealAddReducer = {    
    inProgress: false,
    error: '',
};

const DalAddStore = (state = INIT_STATE, action: { type: any, payload: any }) => {

    switch (action.type) {
        case DEAL_ADD_ACT_TYP.DEAL_ADD:
            return { ...state, inProgress: true, error: ''};
        case DEAL_ADD_ACT_TYP.DEAL_ADD_DONE:
            return { ...state, inProgress: false, error: ''};
        case DEAL_ADD_ACT_TYP.API_ERROR:
            return { ...state, inProgress: false, error: action.payload.error};
        default:
            return state;
    }
}

export default DalAddStore;