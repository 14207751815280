import { getDbFileImage } from 'helpers/api_helper';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routes';
import { fillPlaceholders } from 'routes/placeholder_helper';
import './FileUpload.scss';
import { isImage } from './ImageCompressor';
import { IFileToPreview } from './UploadedFilesPreview';
import useToggle from '../PdfModal/useResourceModal';
import PdfModalViewer from '../PdfModal/ResourceModal';

interface IUploadedSingleFilePreview {
  file: IFileToPreview,
  singleFile: boolean;
}

function UploadedSingleFilePreview({ file, singleFile }: IUploadedSingleFilePreview) {

  const { toggleValue, changeToggle } = useToggle();
  const [fileContent, setFileContent] = useState<string>('');
  const [isMaximised, setIsMaximised] = useState<boolean>(singleFile);

  const isImageObject = useMemo(() => { return isImage(file.contentType) }, [file.contentType]);

 

  useEffect(() => {
    if (isImageObject && fileContent === '') {
      getDbFileImage(file.id)
        .then((img) => {
          setFileContent('data:' + img.contentType + ';base64,' + img.base64Data);
        }).catch((error) => {
          setFileContent('data: image/png ;base64 ');
          console.log('image error', error)
        })
    }
  }, [file.id])

  if (!isImageObject) {
    return (
      <>
        <PdfModalViewer showModal={toggleValue} close={changeToggle} pdfFileId={file.id} pdfFileName={file.fileName} />
        <div
          onClick={changeToggle}
          className="
        file-name img-thumbnail light-text w-100 text-wrap d-flex align-items-center justify-content-start p-0 m-0 cursor-pointer prevent-select
        p-2">
          <i className="fa-regular fa-file-pdf cursor-pointer fa-xl me-2 pdf-icon-color"></i>
          {file.fileName}
        </div>
      </>
    )
  }

  return (
    <div className={'db-image ' + (isMaximised ? 'db-image-sink-max' : 'db-image-sink-min')} >
      {fileContent &&
        <img
          onClick={() => setIsMaximised(!isMaximised)}
          className={'img-thumbnail ' + (isMaximised ? 'db-image-preview-max' : 'db-image-preview-min')}
          src={fileContent}
          alt={file.fileName} />
      }
      {!fileContent &&
        <div className={'img-thumbnail ' + (isMaximised ? 'db-image-preview-max' : 'db-image-preview-min')}>&nbsp;</div>
      }

      {(isMaximised && fileContent) &&
        <i className="relative-top-right fa-solid fa-cloud-arrow-down outilne-icon-light-text fa-xl"
          onClick={() => {
            const downloadLink = document.createElement("a");
            downloadLink.href = fileContent;
            downloadLink.download = file.fileName;
            downloadLink.click()
          }}
        />
      }
    </div>
  )
}
export default UploadedSingleFilePreview;