const PREFIX = "INV_"

//get agreement available types
const LOAD_DATA = PREFIX + "LOAD_DATA";
const loadData = () => ({ type: LOAD_DATA, payload: {} });

const LOAD_DATA_DONE = PREFIX + "LOAD_DATA_DONE";
const loadDataDone = (gridData: any[], info: string, error: string) => ({
    type: LOAD_DATA_DONE, payload: { gridData, info }
});


const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const INVOICE_LIST_ACT = {
    //pobieranie danych
    loadData, loadDataDone,
    apiError
}

export const INVOICE_LIST_ACT_TYP = {
    LOAD_DATA, LOAD_DATA_DONE,
    API_ERROR,
}