import { IDataResponse } from "helpers/IDataResponse";
import { ENDPOINTS, post } from "helpers/api_helper";
import { ISagaParam } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { CONTACT_FORM_ACT, CONTACT_FORM_ACT_TYP } from "./actionsContactForm";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";

function* sendFormData(action: ISagaParam) {
    try {        
        let response: IDataResponse<void> = yield call(post, ENDPOINTS.RESOURCE_CONTACT_FORM, action.payload.messageDto)
        yield put(CONTACT_FORM_ACT.sendFormDataDone(response.info));
        showPopupInfo(response.info);
    } catch (error) {    
        console.log('sendFormData error', error);
        showException(error);
        yield put(CONTACT_FORM_ACT.apiError(error));
    }
}

export function* ContactFormSaga() {
    yield takeEvery(CONTACT_FORM_ACT_TYP.SEND_CONTACT_FORM_MESSAGE, sendFormData);
}

export default ContactFormSaga;
