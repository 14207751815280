import { ResponsivePie } from "@nivo/pie";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { getAppSettlementLogo } from "helpers/image_helper";
import { toPln } from "helpers/pln_helper";
import { useSelector } from "react-redux";

const DashboardSubDialog02AwaitingSettlements = () => {

  const { dashboardDto } = useSelector((state: any) => ({ dashboardDto: state.Dashboard.dashboardDto, }));


  if (!dashboardDto.waitingForSettlement || dashboardDto.waitingForSettlement?.length === 0) {
    return <></>
  }

  return (
    <DialogWrapper title="Dashboard.Item.WaitingPayments.Title">
      <div className="dashboard-waiting-settlements-chart">
        <div className="dashboard-waiting-settlements-chart-left">
          {dashboardDto.waitingForSettlement.map((item: any, idx: number) => (
            <img key={idx} width={50} src={getAppSettlementLogo(item.key)} alt={item.label} />
          ))
          }
        </div>
        <div className="dashboard-waiting-settlements-chart-right">

          <ResponsivePie
            data={dashboardDto.waitingForSettlement}
            margin={{ top: 10, right: 20, bottom: 20, left: 10 }}
            valueFormat={toPln}
            innerRadius={0.35}
            //startAngle={180} endAngle={270}
            padAngle={2}
            cornerRadius={1}
            arcLabelsSkipAngle={20}
            activeInnerRadiusOffset={4}
            activeOuterRadiusOffset={8}
            borderWidth={0}
            colors={({ data }) => { let anyData: any = data; return anyData.color; }}
            fit={true}
            sortByValue={true}
            enableArcLinkLabels={false}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [
                [
                  'brighter',
                  8
                ]
              ]
            }}
            legends={[]}
          />
        </div>
      </div>
    </DialogWrapper>
  )
};


export default DashboardSubDialog02AwaitingSettlements; 