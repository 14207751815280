import LoadingContent from "components/Common/LoadingContent";
import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { ENDPOINT_SETTLEMENT, get } from "helpers/api_helper";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { fillPlaceholders } from "routes/placeholder_helper";
import { ISettlementAggregatedCsvItemDto, SettlementDetails01_CsvItem } from "./SettlementDetails01_CsvItem";
import { SettlementDetails02_ListOfAllCosts } from "./SettlementDetails02_ListOfAllCosts";
import { SettlementDetails03_Summary } from "./SettlementDetails03_Summary";
import { SettlementDetails04_BankTransfers } from "./SettlementDetails04_BankTransfers";

interface ISettlementDetails {
    settlementId: number,
}


export const SettlementDetails = ({ settlementId }: ISettlementDetails) => {

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [dto, setDto] = useState<any>({});

    useEffect(() => {
        const url = fillPlaceholders(ENDPOINT_SETTLEMENT.RESOURCE_GET_SETTLEMENT_DETAILS, { "settlementId": "" + settlementId })
        get(url, {})
            .then(setDto)
            .catch(setError)
            .finally(() => setIsLoading(false))
    }, [settlementId])

    if (isLoading) {
        return <Container className="py-3" fluid><LoadingContent /></Container>
    }

    if (error) {
        let anyError: any = error;
        return <AlertFrameError messageKey={anyError.message ? anyError.message : error} />
    }

    return (
        <Row>
            {(dto && dto.listOfCsvItems) &&
                dto.listOfCsvItems.map((dtoCsvItem: ISettlementAggregatedCsvItemDto, key: number) => (
                    <Col
                        className="pe-lg-5 sett-app"
                        md={4} key={`sett-csv-item-${settlementId}-${dtoCsvItem.id}`}>
                        <SettlementDetails01_CsvItem dto={dtoCsvItem} isB2B={dto.settlementDTO.b2B} />
                    </Col>
                ))
            }
            {(dto && dto.listOfAllCosts && dto.listOfAllCosts.length > 0) &&
                <Col md={4} className="pe-lg-5 sett-list-of-csts">
                    <SettlementDetails02_ListOfAllCosts
                        allCostsAndDepositsSum={dto.settlementDTO.allCostsAndDepositsSum}
                        listOfAllCosts={dto.listOfAllCosts} />
                </Col>
            }
            <Col md={4} className="pe-lg-5 sett-summary">
                <SettlementDetails03_Summary dto={dto.settlementDTO} />
            </Col>
            <Col md={4} className="pe-lg-5 sett-bank-transfers">
                <SettlementDetails04_BankTransfers listOfBankTransfers={dto.listOfBankTransfers} settlementStatus={dto.settlementDTO.status} />
            </Col>
        </Row>

    )
}