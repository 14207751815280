import { DetailsFiles } from "components/Common/datatable/DetailsFiles";
import { DetailsItem } from "components/Common/datatable/DetailsItem";
import { MobileGridRowCompact } from "components/Common/datatable/MobileGridRowCompact";
import TableComponent from "components/Common/datatable/TableComponent";
import ButtonFloating from "components/MyComponents/ButtonFloating";
import { USER_ROLES } from "constants/UserRoles";
import { getMoney } from "helpers/money";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import { ROUTES } from "routes";
import { INVOICE_CONF } from "./InvoiceStatus";
import "./invoice.scss";
import { INVOICE_LIST_ACT } from "./store/actionsInvList";
import { toPln } from "helpers/pln_helper";


const mapStatusToBadgeColor = (status: string) => {

  switch (status) {
    case INVOICE_CONF.STATUS_AC:
    case INVOICE_CONF.STATUS_AS:
      return 'green-badge';
    case INVOICE_CONF.STATUS_CNC:
      return 'red-badge'
    case INVOICE_CONF.STATUS_ARC:
      return 'gray-badge'
    case INVOICE_CONF.STATUS_W4C:
      return 'orange-badge'
    default:
      return 'blue-badge'
  }
}

const InvoiceListPage = () => {

  const availableRoles = useSelector((state: any) => state.LoginState.availableRoles);
  const history = useHistory();
  const invoiceListStore = useSelector((state: any) => state.InvoiceListStore);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (invoiceListStore.reloadRequired) {
      dispatch(INVOICE_LIST_ACT.loadData());
    }
  }, [invoiceListStore.reloadRequired, dispatch])


  const renderContent = (row: any) => {

    const hasVatReturn = row.vatReturnedValueSum != null && row.vatReturnedValueSum > 0;
    const toBeDescribed = row.invoiceNumber === 'faktura-do-opisania';

    const getChildrenInstant = () => {
      return (<>
        {!toBeDescribed &&
          <>
            <DetailsItem labelKey="INV_LIST.invoiceNumber" value={row.invoiceNumber} />
            <DetailsItem labelKey="INV_LIST.invoiceDate" value={row.invoiceDate} />
            {hasVatReturn &&
              <DetailsItem labelKey="INV_LIST.vatReturnedValueSumInfo" value={getMoney(row.vatReturnedValueSum)} />
            }
          </>
        }
        {row.reasonCode &&
          <DetailsItem labelKey="INV_LIST.reasonCode" value={t(row.reasonCode)} />
        }
        {row.statusComment &&
          <DetailsItem labelKey="INV_LIST.description" value={row.statusComment} inline={false} />
        }
        {!toBeDescribed &&
          <>
            <div className="table-con mb-0">
              <label>{t('INV_LIST.items')}:</label>
            </div>
            <table className="table-con-grid border-none w-100 mb-2">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">{t('INV_LIST.Item.netValue')}</th>
                  <th className="text-center">%</th>
                  <th className="text-center">{t('INV_LIST.Item.vatColumn')}</th>
                </tr>
              </thead>
              <tbody>
                {row.items.map((item: any, idx: number) => {
                  return (
                    <tr key={item.id}>
                      <td className="text-center">{(idx + 1)}.</td>
                      <td className="text-end money-r-spacer">{toPln(item.netValue)}</td>
                      <td className="text-center">{item.vatRate}</td>
                      <td className="text-end">{toPln(item.vatValue)}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>

            <DetailsItem labelKey="INV_LIST.netValue" value={toPln(row.netValue)} />
            <DetailsItem labelKey="INV_LIST.vatValue" value={toPln(row.vatValue)} />
            <DetailsItem labelKey="INV_LIST.grossValue" value={toPln(row.grossValue)} />
          </>
        }
        {row.description &&
          <div className="border-top">
            <DetailsItem labelKey="INV_LIST.description" value={row.description} inline={false} />
          </div>
        }
      </>
      )
    }

    const getChildrenDeffered = () => {
      return (
        <div className="border-top pt-2">
          <DetailsFiles labelKey="INV_LIST.files" files={row.files} />
        </div>
      )
    }

    const getHeader = () => {

      return (
        <>
          <div>
            <div className="head-with-badge prevent-select">
              <h3 className="w-100 row-item-header">
                {row.invoiceNumber === 'faktura-do-opisania' &&
                  <span className="blue">#{row.id} - {t('INV_LIST.faktura-do-opisania')}</span>
                }
                {row.invoiceNumber !== 'faktura-do-opisania' &&
                  row.invoiceNumber
                }
              </h3>
              <span className={"badge " + mapStatusToBadgeColor(row.status)}>

                {(!toBeDescribed && hasVatReturn) &&
                  <>
                    +{toPln(row.vatReturnedValueSum)}
                    <i className="fa-solid fa-sack-dollar px-2" />
                  </>
                }
                {t('inv.status.' + row.status)}</span>
              {toBeDescribed &&
                <span className={"badge orange-badge"}>{t('inv.toBeDescribed')}</span>
              }
              {!toBeDescribed &&
                <span className={"badge blue-badge"}>{row.invoiceDate}</span>
              }


            </div>
          </div>
        </>
      )
    }
    return (
      <MobileGridRowCompact
        id={row.id}
        getHeader={getHeader} getChildrenInstant={getChildrenInstant} getChildrenDeffered={getChildrenDeffered} />
    )
  }

  const renderNewInvoiceButton = () => {

    const canCreateInvoices = availableRoles.includes(USER_ROLES.INVOICE_ADD);
    if (!canCreateInvoices) {
      return <></>;
    }

    const openNewInvoice = () => {
      history.push(ROUTES.ROUTE_INVOICE_ADD);
    }

    return <ButtonFloating
      id="button-invoice-add"
      color="green"
      label={'Invoices.NAV.Add'}
      onClick={openNewInvoice}
      iconClass="fa-regular fa-circle-plus fa-xl"
    />
  }


  return (<div className="invoice-list">
    <Container fluid>
      <TableComponent
        additionalHeaderRow={() => { return renderNewInvoiceButton() }}
        tableName="INV_LIST"
        hederKey="INV_LIST.mobile.header"
        renderContent={renderContent}
        data={invoiceListStore}
      />
    </Container>
  </div>
  )

}
export default InvoiceListPage; 