
const languages = {
  pl: {
    label: "Polski",
    flag20: '/img/pl.svg'
  },
  ua: {
    label: "українська",
    flag20: '/img/uk.svg'
  },
  en: {
    label: "English",
    flag20: '/img/en.svg'
  }, 
  ru: {
    label: "Русский",
    flag20: '/img/rs.svg'
  }, 
  ge: {
    label: "ქართული",
    flag20: '/img/gg.svg'
  },  
}

export default languages;
