import { del, ENDPOINT_ATTACHMENT, get } from "helpers/api_helper";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";
import { ISagaParam, ResponseGridDataExtended } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { fillPlaceholders } from "routes/placeholder_helper";
import { ATTACHMENT_LIST_ACT, ATTACHMENT_LIST_ACT_TYP } from "./actionsAttList";

function* loadDataSaga(action: ISagaParam) {
    try {
        let gridResponse: ResponseGridDataExtended<any, any> = yield call(get, ENDPOINT_ATTACHMENT.RESOURCE_ATTACHMENT_GET, {})
        yield put(ATTACHMENT_LIST_ACT.loadDataDone(gridResponse.gridData, gridResponse.context, gridResponse.info, gridResponse.error));
    } catch (error) {
        console.log('attachmentList.loadData error', error);
        showException(error);
        yield put(ATTACHMENT_LIST_ACT.apiError(error));
    }
}

function* rejectAattachmentSaga(action: ISagaParam) {
    try {
        const url = fillPlaceholders(ENDPOINT_ATTACHMENT.RESOURCE_ATTACHMENT_REJECT__DELETE,
            {
                'attachmentId': action.payload.attachmentId,
            });
        yield call(del, url, {})
        yield put(ATTACHMENT_LIST_ACT.rejectAattachmentDone('ATT_LIST.button.rejectAwaiting.Done'));        
        yield put(ATTACHMENT_LIST_ACT.loadData());
        showPopupInfo('ATT_LIST.button.rejectAwaiting.Done');
    } catch (error) {
        console.log('attachmentList.rejectAattachmentSaga error', error);
        showException(error);
        yield put(ATTACHMENT_LIST_ACT.apiError(error));
    }
}

export function* AttachmentListSaga() {
    yield takeEvery(ATTACHMENT_LIST_ACT_TYP.LOAD_DATA, loadDataSaga);
    yield takeEvery(ATTACHMENT_LIST_ACT_TYP.REJECT_ATTACHMENT, rejectAattachmentSaga);
}

export default AttachmentListSaga;
