import './FileUpload.scss';
import UploadedSingleFilePreview from './UploadedSingleFilePreview';

export interface IFileToPreview {
  id: number;
  fileName: string;
  contentType: string;
}

interface IUploadedFilesPreview {
  files: IFileToPreview[];
}

function UploadedFilesPreview({ files }: IUploadedFilesPreview) {

  if (!files) {
    return <></>
  }


  return (
    <div className='images-preview-flex'>{
      files.map((file) => <UploadedSingleFilePreview
        key={'file-' + file.id}
        file={file}
        singleFile={files.length === 1}
      />)
    }
    </div>
  )


}

export default UploadedFilesPreview;