import ButtonCustom from "components/MyComponents/ButtonCustom";
import { IMG_UTIL } from "helpers/image_helper";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { IGridData } from "store/IGridData";
import LoadingContent from "../LoadingContent";
import './tableComponent.scss';

interface ITableComponent<T> {
    data: IGridData<T>;
    hederKey: string;
    renderContent: (row: T) => ReactNode;
    tableName: string;
    additionalHeaderRow?: () => ReactNode;
    isFullWidthInFullMode? : boolean; 
}

const PRESENTED_LIMIT_INITIAL = 12;
const PRESENTED_LIMIT_EXTEND = 12;

const TableComponent = <T extends any>({ 
    data, additionalHeaderRow, hederKey, renderContent, tableName,
    isFullWidthInFullMode = false }: ITableComponent<T>) => {
    const { t } = useTranslation();
    const [presentedLimit, setPresentedLimit] = useState(PRESENTED_LIMIT_INITIAL)
    const colCss =  isFullWidthInFullMode ? "col-xs-12" : "col-xs-12 col-sm-6 col-lg-4" ;
    return (
        <>
            <div className="row wrapper-row">
                <div className="col-md-12">
                    <div className="bg-brdr res-btm">

                        <div className="row">
                            <div className="col-xs-12 main-h2 flex-buttons mb-2">
                                <h2 className="prevent-select">{t(hederKey)} </h2>
                                {additionalHeaderRow && additionalHeaderRow()}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                {(data.isLoading) &&

                                    <LoadingContent />
                                }
                                {(!data.isLoading && data.gridData.length === 0) &&
                                    <h6 className="text-center py-3 no-data-info">
                                        <img  src={IMG_UTIL.empty_icon} height={60}/>
                                        <div>{t(tableName + '.no-data')}</div>
                                    </h6>
                                }
                            </div>
                        </div>
                        <div className="row">
                            {
                                data.gridData.map((row, idx) => {

                                    let aRow  : any = row;

                                    if (idx >= presentedLimit) {
                                        return <div key={`hidden-${tableName}_${aRow.id}`}></div>
                                    }
                                    return (
                                        <div className={colCss} key={`${tableName}_${aRow.id}`}>
                                            {renderContent(row)}
                                        </div>)
                                })
                            }
                        </div>
                        {(data.gridData.length > presentedLimit) &&
                            <div className="row">
                                <div className= {colCss +" p-xs-3 text-center button-get-more-data "}>
                                    <ButtonCustom
                                        id="button-get-more-data"
                                        fullWidth={true}
                                        iconClassPre="fa-light fa-cloud-arrow-down"
                                        color="default" isLoading={false} label={t('table.load.next')} onClick={() => { setPresentedLimit(presentedLimit + PRESENTED_LIMIT_EXTEND) }} />
                                </div>
                            </div>
                        }


                    </div>
                </div>
            </div>
        </>
    )
}

export default TableComponent;