import ButtonCustom from "components/MyComponents/ButtonCustom";
import { Field, Form, Formik } from "formik";
import { translateOptions } from "helpers/text_helper";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { PRE_REG_ACTIONS } from "store/auth/pre-registration/actions";
import * as Yup from 'yup';
import LoadingPage from "../../../../components/Common/LoadingPage";
import ButtonSubmit from "../../../../components/MyComponents/ButtonSubmit";
import CustomCheckbox from "../../../../components/MyComponents/CustomCheckbox";
import CustomSelect, { SELECT_SINGLE_DEFAULT_PLACEHOLDER } from "../../../../components/MyComponents/CustomSelect";
import CustomText from "../../../../components/MyComponents/CustomText";
import { YUP_VALIDATION } from "../../../../helpers/YupHelper";
import RegisterPageWrapper from "../RegisterPageWrapper";
import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { ProgressIndicator } from "components/Layout20/ProgressIndicator";

const formValidator = Yup.object().shape({
  firstName: YUP_VALIDATION.YUP_STRING_REQ_MAX_250,
  lastName: YUP_VALIDATION.YUP_STRING_REQ_MAX_250,
  phone: YUP_VALIDATION.YUP_STRING_REQ_PHONE,
  languageId: YUP_VALIDATION.YUP_STRING_REQ,
  nationalityId: YUP_VALIDATION.YUP_STRING_REQ,
  marketingConsents: YUP_VALIDATION.YUP_CONSENTS
});

interface FormValues {
  firstName: string;
  lastName: string;
  phone: string;
  marketingConsents: boolean;
  languageId: string;
  nationalityId: string;
}

const RegisterPage1_UserData = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { regUserData, isLoading, error, languageDomain, nationalityDomain } = useSelector((state: any) => (
    {
      regUserData: state.PreRegistration.regUserData,
      isLoading: state.PreRegistration.isLoading,
      error: state.PreRegistration.error,
      languageDomain: state.PreRegistration.languageDomain,
      nationalityDomain: state.PreRegistration.nationalityDomain,
    })
  );
  const languageDomainTr = useMemo(() => translateOptions(languageDomain, t), [languageDomain, i18n.language]);
  const nationalityDomainTr = useMemo(() => translateOptions(nationalityDomain, t), [nationalityDomain, i18n.language]);
  const affiliationKey = useSelector((state: any) => state.AffiliationStore.affiliationKey);

  if (!regUserData?.page1Data) {
    return <RegisterPageWrapper><LoadingPage /></RegisterPageWrapper>
  }

  const onSubmit = (values: FormValues) => {
    dispatch(PRE_REG_ACTIONS.step1StartUpdating(regUserData, regUserData.page1BasicData, values, affiliationKey));
  };

  const onBack = () => {
    dispatch(PRE_REG_ACTIONS.preRegistrationPageBack())
  };

  const renderForm = () => {
    return (
      <Form>
        <ProgressIndicator step={1} stepCount={6} />
        <Row>
          <Col xs={12} lg={6}>
            <Field name="firstName" label="Registration.Page1.FirstName" component={CustomText} isDisabled={isLoading} />
          </Col>
          <Col xs={12} lg={6}>
            <Field name="lastName" label="Registration.Page1.LastName" component={CustomText} isDisabled={isLoading} />
          </Col>
          <Col xs={12} lg={6}>
            <Field name="phone" label="Registration.Page1.Phone" textAfter="Registration.Page1.Phone.AdditionalComment"
              component={CustomText} inputType="tel"
              maxLength={9}
              pattern="[1-9][0-9]{8}"

              inputGroupPrefixContent="+48" isDisabled={isLoading} />
          </Col>
          <Col xs={12} lg={6}></Col>
          <Col xs={12} lg={6}>
            <Field name="nationalityId" label="Registration.Page1.Nationality"
              textAfter="Registration.Page1.Nationality.AdditionalComment"
              placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
              options={nationalityDomainTr} className="custom-select" component={CustomSelect} />
          </Col>
          <Col xs={12} lg={6}>
            <Field name="languageId" label="Registration.Page1.Language"
              textAfter="Registration.Page1.Language.AdditionalComment"
              placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
              options={languageDomainTr} className="custom-select" component={CustomSelect} />
          </Col>
          <Col xs={12}>
            <Field name="marketingConsents" label="Registration.Page1.MarketingConsents" textAfter="Registration.Page1.MarketingConsents.AdditionalComment" component={CustomCheckbox} type="checkbox" isDisabled={isLoading} />
          </Col>
        </Row>
        <Row className="pb-3">
          <Col xs={4} className="px-1">
            <ButtonCustom isLoading={false} id="button-register-user-data-back" label="Registration.Page1.Back" onClick={onBack} /></Col>
          <Col xs={8}>
            <ButtonSubmit id="button-register-user-data"
              isLoading={isLoading} 
              label="Registration.Page1.Submit" 
              iconClass="fa-light fa-angles-right fa-lg" className="w-100" /></Col>
        </Row>
      </Form>
    )
  };

  return (<RegisterPageWrapper showLoginLink={false}>
    <AlertFrameError messageKey={error} />
    <Formik
      initialValues={regUserData.page1Data}
      validationSchema={formValidator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  </RegisterPageWrapper>
  );
};


export default RegisterPage1_UserData; 