import CustomText from "components/MyComponents/CustomText";
import CustomTextWithButton from "components/MyComponents/CustomTextWithButton";
import HorizontalDivider from "components/MyComponents/HorizontalDivider";
import { Field, Form, Formik, FormikValues, useFormikContext } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { showPopupInfo } from "helpers/sweetalert/ShowAlert";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AFFILIATION_LINK_ACT } from "./store/actionsAffiliationLink";
import AffiliationActionDialogFields from "./AffiliationActionDialogFields";

const AFF_LINK_VALIDATOR = YUP_VALIDATION.YUP_OBJ.shape({
  phoneNumber: YUP_VALIDATION.YUP_STRING_REQ_PHONE,
});
const AffiliationActionDialog = () => {

  const { t } = useTranslation();
  const affLinkIdentifier = useSelector((state: any) => { return state.LoginState.affLinkIdentifier });

  const dispatch = useDispatch();

  const onSubmit = (values: FormikValues, formikBag: any) => {
    setSmsCount(c => c + 1);
    dispatch(AFFILIATION_LINK_ACT.sendAffiliationLink(values.phoneNumber, formikBag));
  };


  const companyId = useSelector((state: any) => { return state.LoginState.companyId });
  const [smsCount, setSmsCount] = useState(0);
  const [_, copy, isClipboadrSupported] = useCopyToClipboard()
  const getAflLink = () => {
    if (companyId === 'ALG' || companyId === 'AC' || companyId === 'AT') {
      return `https://avalon.spirit24.pl/register/app/${affLinkIdentifier}`
    }
    return `https://spirit24.pl/register/app/${affLinkIdentifier}`
  }

  const inputGroupPrefixContentCopy = (isClipboadrSupported ?
    <div onClick={() => {
      copy(getAflLink());
      showPopupInfo('AffLinkSend.Copied');
    }}>
      <i className="fa-regular fa-copy fa-xl pe-2 blue"></i>
      <span className="pt-0 blue">{t("AffLinkSend.Data.yourLink.copy")}</span>
    </div>
    :
    undefined);

  return (
    <Formik
      initialValues={{ phoneNumber: '', yourLink: getAflLink() }}
      enableReinitialize={true} validationSchema={AFF_LINK_VALIDATOR}
      onSubmit={onSubmit}>
      <AffiliationActionDialogFields inputGroupPrefixContentCopy={inputGroupPrefixContentCopy} smsCount={smsCount} />
    </Formik>
  );
};


export default AffiliationActionDialog; 