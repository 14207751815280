import { getTextByLang } from "components/Common/datatable/ItemByLang";
import { MobileGridRowCompact } from "components/Common/datatable/MobileGridRowCompact";
import TableComponent from "components/Common/datatable/TableComponent";
import ButtonFloating from "components/MyComponents/ButtonFloating";
import i18n from "i18n";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { ROUTE_NOTIFICATION_ID } from "routes";
import MarkAsReadNotification from "./MarkAsReadNotification";
import { NOTIFICATIONS_LIST_ACT } from "./store/actionsNotificationsList";
import { SETTLEMENT_LIST_ACT } from "pages/SettlementList/store/actionsSettList";

const normalizeDate = (date: string) => {
  //2023-12-29 17:10:45.234 --> 2023-12-29 17:10
  let dateTrimmed = date.replace('T00:00:00.000', '').replace('T00:00:00', '').replace('T', ' ')
  if (dateTrimmed.length > 16) {
    return dateTrimmed.substring(0, 16)
  }
  return dateTrimmed;
}

const NotyficationsListPage = () => {
  const history = useHistory();
  const notificationsListStore = useSelector((state: any) => state.NotificationsListStore);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let params = useParams<ROUTE_NOTIFICATION_ID>();
  useEffect(() => {
    if (notificationsListStore.reloadRequired) {
      dispatch(NOTIFICATIONS_LIST_ACT.loadData(params.notificationId));
    }
  }, [notificationsListStore.reloadRequired, params.notificationId, dispatch])

  const handleButtonClick = (notificationDto: any) => {
      dispatch(NOTIFICATIONS_LIST_ACT.buttonClicked(notificationDto, history));
  }

  const renderContent = (row: any) => {

    const getChildrenInstant = () => {
      return (<>
        <div className="notification-body  formatted-text" dangerouslySetInnerHTML={{
          __html: getTextByLang({
            plText: row.contentNotificationBodyPl,
            enText: row.contentNotificationBodyEn,
            uaText: row.contentNotificationBodyUa,
            geText: row.contentNotificationBodyGe,
            ruText: row.contentNotificationBodyRu,
          }, i18n.language)
        }}></div>
        {row.contentNotificationShowButton &&
          <div>
            <ButtonFloating
              id={"button-notification-row-" + row.id}
              color="default"
              label={
                getTextByLang({
                  plText: row.contentNotificationButtonPl,
                  enText: row.contentNotificationButtonEn,
                  uaText: row.contentNotificationButtonUa,
                  geText: row.contentNotificationButtonGe,
                  ruText: row.contentNotificationButtonRu,
                }, i18n.language)
              }
              iconClass={row.contentNotificationRoute === "EXT" ? "fa-solid fa-arrow-up-right-from-square pe-2" : ""}
              onClick={() => handleButtonClick(row)}
            />
          </div>
        }
      </>
      )
    }

    const getChildrenDeffered = () => {

      if (!row.markableAfterPresentation) {
        return <></>
      }

      return (
        <MarkAsReadNotification id={row.id} markedAsPresented={row.markedAsPresented} />
      );
    }

    const getHeader = () => {

      return (
        <>
          <div className="head-with-badge prevent-select">
            {row.contentNotificationUrl &&
              <span className="w-100 notification-image-container">
                <img src={row.contentNotificationUrl} className="notification-image" />
              </span>
            }
            <h3 className="w-100 row-item-header">
              {getTextByLang({
                plText: row.contentNotificationTitlePl,
                enText: row.contentNotificationTitleEn,
                uaText: row.contentNotificationTitleUa,
                geText: row.contentNotificationTitleGe,
                ruText: row.contentNotificationTitleRu,
              }, i18n.language)
              }
            </h3>

            {row.markedAsPresented &&
              <span className={"badge green-badge"}>
                <i className="fa-regular fa-envelope-open pe-1"></i>{normalizeDate(row.markedAsPresented)}</span>
            }
            {!row.markedAsPresented &&
              <span className={"badge blue-badge "}>
                <i className="fa-regular fa-envelope pe-1"></i> {normalizeDate(row.showAfterDate)}</span>
            }
            {row.highlightedArea !== 'NONE' &&
              <span
                className={"badge " + (row.markedAsPresented ? "green-badge" : "blue-badge")}>
                {t('Notifications.Kind.' + row.highlightedArea.replaceAll('/', ''))}
              </span>
            }
          </div>
        </>
      )
    }
    return (
      <div className="notification-card">
        <MobileGridRowCompact
          id={row.id}
          getHeader={getHeader}
          getChildrenInstant={getChildrenInstant}
          getChildrenDeffered={getChildrenDeffered}
          defaultExpandState={row.id == params.notificationId || !row.markableAfterPresentation}

        />
      </div>
    )
  }



  return (
    <Container fluid>
      <TableComponent
        additionalHeaderRow={() => <></>}
        tableName="NOTIFICATION_LIST"
        hederKey="Notifications.Header"
        renderContent={renderContent}
        data={notificationsListStore}
      />
    </Container>
  )

}
export default NotyficationsListPage; 