import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import HorizontalDivider from "components/MyComponents/HorizontalDivider";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ROUTES } from "routes";


const ProfilePage_08_Admin = () => {

  const history = useHistory();

  return (
    <DialogWrapper title="profile.divider.aministration" dialogVisibilityKey="profileAdmin">
      <Row>
        <Col className="py-3 input-box">
          <ButtonCustom 
            id="button-profile-open-remove-page" 
            color="danger" 
            iconFileSrc="/img/trash-bin.svg"
            isLoading={false} label={'removeProfile.button'}
            onClick={() => { history.push(ROUTES.ROUTE_PROFILE_REMOVE); }} />
        </Col>
      </Row>
    </DialogWrapper>
  );
};

export default ProfilePage_08_Admin; 