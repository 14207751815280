import { ENDPOINTS, ENDPOINT_SETTLEMENT, get, post } from "helpers/api_helper";
import { IDataResponse } from "helpers/IDataResponse";
import { ISagaParam, ResponseGridDataExtended } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { fillPlaceholders } from "routes/placeholder_helper";
import { AFFILIATION_LINK_ACT, AFFILIATION_LINK_ACT_TYP } from "./actionsAffiliationLink";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";

function* loadData(action: ISagaParam) {
    try {
        let gridResponse: ResponseGridDataExtended<any, any> = yield call(get, ENDPOINTS.RESOURCE_GET_AFFILIATION_LINK, {})
        yield put(AFFILIATION_LINK_ACT.loadDataDone(gridResponse.gridData, gridResponse.context, gridResponse.info, gridResponse.error));
    } catch (error) {        
        console.error('settlementList.loadData error', error);
        showException(error);
        yield put(AFFILIATION_LINK_ACT.apiError(error));
    }
}


function* sendAffiliationLink(action: ISagaParam) {
    try {
        const postUrl = fillPlaceholders(
            ENDPOINTS.RESOURCE_POST_AFFILIATION_LINK,
            {
                'phoneNumber': action.payload.phoneNumber,
            });

        let profileDataResponse: IDataResponse<void> = yield call(post, postUrl, {})
        yield put(AFFILIATION_LINK_ACT.sendAffiliationLinkDone(
            profileDataResponse.info,
        ));
        action.payload.formikBag.resetForm();
        showPopupInfo(profileDataResponse.info);
    } catch (error) {
        console.log('sendAffiliationLink error', error);
        showException(error);
        yield put(AFFILIATION_LINK_ACT.sendAffiliationLinkError(error));
    }
}

export function* ProfileSaga() {
    yield takeEvery(AFFILIATION_LINK_ACT_TYP.SEND_AFFILIATION_LINK, sendAffiliationLink);
    yield takeEvery(AFFILIATION_LINK_ACT_TYP.LOAD_DATA, loadData);
}

export default ProfileSaga;
