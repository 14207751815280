import { IGridData } from "store/IGridData";
import { NOTIFICATIONS_LIST_ACT_TYP } from "./actionsNotificationsList";


interface NotificationsListStoreContext {
    activeCount: number,
    menuItems: { [key: string]: number; }
}

const INIT_STATE: IGridData<any> | { notificationContext: NotificationsListStoreContext } = {
    gridData: [],
    isLoading: false,
    reloadRequired: true,
    error: '',
    info: '',
    notificationContext: {
        activeCount: 0,
        menuItems: {}
    }
};

const NotificationsListStore = (state: any = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case NOTIFICATIONS_LIST_ACT_TYP.CONTEXT_SET:
            return { ...state, notificationContext: action.payload.notificationContext };
        case NOTIFICATIONS_LIST_ACT_TYP.LOAD_DATA:
            return { ...state, isLoading: true, error: '' };
        case NOTIFICATIONS_LIST_ACT_TYP.LOAD_DATA_DONE:
            return { ...state, isLoading: false, error: action.payload.error, gridData: action.payload.gridData, info: action.payload.info };
        case NOTIFICATIONS_LIST_ACT_TYP.MARK_PRESENTED_DONE:
            return {
                ...state,
                gridData: state.gridData.map((item: any) =>
                    item.id === action.payload.notificationId ?
                        { ...item, markedAsPresented: action.payload.markedAsPresented } : item
                ),
            };
        case NOTIFICATIONS_LIST_ACT_TYP.BUTTON_CLICKED_MARKED:
            return {
                ...state,
                gridData: state.gridData.map((item: any) =>
                    item.id === action.payload.notificationId ?
                        { ...item, markedAsButtonClicked: action.payload.markedAsButtonClicked } : item
                ),
            };

        case NOTIFICATIONS_LIST_ACT_TYP.API_ERROR:
            return { ...state, isLoading: false, error: action.payload.error, };
        default:
            return state;
    }
}

export default NotificationsListStore;