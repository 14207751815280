import { Col, Row } from "reactstrap";
import DashboardSubDialog01Summary from "./DashboardSubDialog_01_Summary";
import DashboardSubDialog02AwaitingSettlements from "./DashboardSubDialog_02_AwaitingSettlements";
import DashboardSubDialog_03_PaymentsHistory from "./DashboardSubDialog_03_PaymentsHistory";
import DashboardSubDialog_00_NotificationsList from "./DashboardSubDialog_00_NotificationsList";

const DashboardLayoutDesktop = () => {

  return (
    <div className="dashboard-layout-desktop">
      <DashboardSubDialog_00_NotificationsList />
      <Row>
        <Col>
          <DashboardSubDialog01Summary />
        </Col>

        <Col>
          <Row><Col><DashboardSubDialog02AwaitingSettlements /></Col></Row>
          <Row><Col><DashboardSubDialog_03_PaymentsHistory /></Col></Row>
        </Col>
      </Row>
    </div>
  )
};


export default DashboardLayoutDesktop; 