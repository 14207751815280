export const getAuthContextFromStorage = () => {
    let localStorageAuthContext = localStorage.getItem("authUser") || null;
    if (localStorageAuthContext) {
        return JSON.parse(localStorageAuthContext);
    }
    return null;
}

export const removeAuthContextFromStorage = () => {
    localStorage.removeItem("x-spirit-id");
    localStorage.removeItem("authUser");
}