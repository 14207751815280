import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LAYOUT20_ACT } from "store/layout/actionsLayout20";
import { DialogEnum } from "store/layout/reducerLayout20";


interface IDialogWrapper {
    title: string;
    dialogVisibilityKey?: DialogEnum,
    children: ReactElement

}

export const DialogWrapper = ({ title, dialogVisibilityKey, children }: IDialogWrapper) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const visible = useSelector((state: any) => {
        if (!dialogVisibilityKey) { return true; }
        return state.Layout20Store.dialogVisibilityMap[dialogVisibilityKey];
    });

    const toogleVisibility = () => {
        if (dialogVisibilityKey) {
            dispatch(LAYOUT20_ACT.toggleDialog(dialogVisibilityKey))
        }
    }

    return (
        <div className="row wrapper-row">
            <div className="col-md-12">
                <div className={"bg-brdr res-btm " + (!visible? "pb-2":"")}>
                    {title &&
                        <div className="row">
                            <div className="col-xs-12 main-h2">
                                <h2 className="prevent-select" onClick={toogleVisibility}>{t(title)}
                                    {dialogVisibilityKey &&
                                        <img src={'img/AltArrow' + (visible ? "Down" : "Up") + '.svg'} />
                                    }
                                </h2>
                            </div>
                        </div>
                    }
                    {visible &&
                        <div className="row">
                            <div className="col-xs-12">
                                {children}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}