import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
//i18n
import { USER_ROLES } from "constants/UserRoles";
import { useOutsideClick } from "hooks/useOutsideClick";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes";
import { logoutUser } from "store/actions";
import { LAYOUT20_ACT } from "store/layout/actionsLayout20";
import { getCurrentLanguageFromLocalStorage } from "./Header20WebLanguageChangeMenu";
import MenuItem from "./MenuItem";
import MenuItemDynamicSuggestedApps from "./MenuItemDynamicSuggestedApps";


const MobileNav = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();

	const { availableRoles,  suggestedApplications, extDocsToSignCount } = useSelector((state) => {
		return (
		  {
			availableRoles: state.LoginState.availableRoles,
			extDocsToSignCount: state.LoginState.extDocsToSignCount,
			suggestedApplications: state.LoginState.suggestedApplications
		  }
		)
	  });


	const [_, setSelectedLang] = useState("pl");

	useEffect(() => {
		setSelectedLang(getCurrentLanguageFromLocalStorage());
	}, [])

	const changeLanguageAction = (lang) => {
		//set language as i18n
		i18n.changeLanguage(lang);
		localStorage.setItem("I18N_LANGUAGE", lang);
		setSelectedLang(lang);
	}

	const closeSidebarMenu = () => {
		dispatch(LAYOUT20_ACT.closeSidebar());
	}

	const ref = useOutsideClick(() => {
		closeSidebarMenu();
	});


	const openAffLink = () => {
		history.push(ROUTES.ROUTE_AFF_LINK_SEND)
		closeSidebarMenu();
	}


	const { sidebarVisible } = useSelector(state => ({ sidebarVisible: state.Layout20Store.sidebarVisible }));

	const logoutMe = () => {
		dispatch(logoutUser(history))
		closeSidebarMenu();
	}

	return (
		<React.Fragment>
			<div className={sidebarVisible ? "mobile-nav active" : "mobile-nav"} ref={ref}>
				<div className="nav-styles">
					<ul className="sidemenu-styles">
						<MenuItem labelKey="Dashboard.NAV" imageSrc="/img/menu-home.svg" routeTo={ROUTES.ROUTE_DASHBOARD} onClickCallback={closeSidebarMenu} />

						{suggestedApplications?.length >= 0 &&
							<MenuItemDynamicSuggestedApps  onClickCallback={closeSidebarMenu}   />
						}
						{extDocsToSignCount > 0 &&
							<MenuItem labelKey="ExtDocs.NAV.List" imageSrc="/img/menu-exd-doc-sign.svg" routeTo={ROUTES.ROUTE_EXT_DOC_TO_SIGN} onClickCallback={closeSidebarMenu} />
						}
						<MenuItem labelKey="Profile.NAV" imageSrc="/img/menu-user.svg" routeTo={ROUTES.ROUTE_PROFILE} roleRequired={USER_ROLES.PROFILE} onClickCallback={closeSidebarMenu} />
						<MenuItem labelKey="Agreement.List.NAV" imageSrc="/img/menu-file.svg" routeTo={ROUTES.ROUTE_AGREEMENT_LIST} roleRequired={USER_ROLES.AGREEMENT_LIST} onClickCallback={closeSidebarMenu} />
						<MenuItem labelKey="Attachment.List.NAV" imageSrc="/img/menu-document.svg" routeTo={ROUTES.ROUTE_ATTACHMENT_LIST} roleRequired={USER_ROLES.ATTACHMENT_LIST} onClickCallback={closeSidebarMenu} />
						<MenuItem labelKey="Settlements.NAV.List" imageSrc="/img/menu-hand-money.svg" routeTo={ROUTES.ROUTE_SETTLEMENT_LIST} roleRequired={USER_ROLES.SETTLEMENT_LIST} onClickCallback={closeSidebarMenu} />
						<MenuItem labelKey="Invoices.NAV.List" imageSrc="/img/menu-file-send.svg" routeTo={ROUTES.ROUTE_INVOICE_LIST} roleRequired={USER_ROLES.INVOICE_ADD} onClickCallback={closeSidebarMenu} />
						<MenuItem labelKey="ContactForm.NAV" imageSrc="/img/menu-question.svg" routeTo={ROUTES.ROUTE_CONTACT_FORM} onClickCallback={closeSidebarMenu} />
					</ul>
				</div>
				<div className="mobile-flag-row">
					<img src="/img/pl.svg" className={i18n.language === "pl" ? "active" : ""} onClick={() => changeLanguageAction("pl")} />
					<img src="/img/en.svg" className={i18n.language === "en" ? "active" : ""} onClick={() => changeLanguageAction("en")} />
					<img src="/img/rs.svg" className={i18n.language === "ru" ? "active" : ""} onClick={() => changeLanguageAction("ru")} />
					<img src="/img/uk.svg" className={i18n.language === "ua" ? "active" : ""} onClick={() => changeLanguageAction("ua")} />
					<img src="/img/gg.svg" className={i18n.language === "ge" ? "active" : ""} onClick={() => changeLanguageAction("ge")} />
				</div>

				<div className="mobile-logout">
					<a href="#" onClick={logoutMe}>{t("ProfileManu.NAV.Logout")}<img src="/img/logout-mobile.svg" /></a>
				</div>
				{availableRoles.includes(USER_ROLES.AFF_LINK_SEND) &&
					<div className="side-btm">
						<div className="btm-title">
							<h3>{t("AffLinkSend.NAV")}</h3>
							<div className="btm-btn" onClick={openAffLink}>
								<a href="#">									
									<i className="fa-regular fa-circle-dollar fa-lg  fa-beat-fade pe-1"></i> 
									{t("AffLinkSend.NAV.SecondLine")} 
								</a>
							</div>
						</div>
					</div>
				}
			</div>

		</React.Fragment>
	);
};

MobileNav.propTypes = {
	type: PropTypes.string,
};

export default withRouter(MobileNav);
