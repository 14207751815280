import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser } from "store/actions";



const Header20WebLogoutHeaderItem = () => {

	const dispatch = useDispatch();
	const history = useHistory();
	const {t} = useTranslation();

	const logoutMe = () => {
		dispatch(logoutUser(history))
	}

	return (
		<div className="logout-div" onClick={logoutMe}>
			<img className="flag" src="/img/logout.svg" />
			<span>{t("ProfileManu.NAV.Logout")}</span>
		</div>
	)
}

export default Header20WebLogoutHeaderItem;