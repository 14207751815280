import { INVOICE_NEW_ACT_TYP } from "./actionsInvNew";

export interface IInvoiceNewReducer {
    isLoading: boolean,
    error: string,
    info: string,
}

const INIT_STATE: IInvoiceNewReducer = {
    isLoading: false,
    error: '',
    info: '',
};

const InvoiceNewStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case INVOICE_NEW_ACT_TYP.ADD_INVOICE:
            return { ...state, isLoading: true, error: '', info: '' };
        case INVOICE_NEW_ACT_TYP.ADD_INVOICE_DONE:
            return {
                ...state, isLoading: false,
            };
        case INVOICE_NEW_ACT_TYP.API_ERROR:
            return { ...state, isLoading: false, isReloadRequired: false, error: action.payload.error };
        default:
            return state;
    }
}

export default InvoiceNewStore;