import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface IMenuItem {
    routeTo: string,
    imageSrc: string,
    labelKey: string,
    onClickCallback?: () => void;
    roleRequired?: string
}

const MenuItem = ({ routeTo, imageSrc, labelKey, roleRequired, onClickCallback }: IMenuItem) => {

    const { t } = useTranslation();
    const history = useHistory();
    const availableRoles = useSelector((state: any) => state.LoginState.availableRoles);
    const notificationContext = useSelector((state: any) => state.NotificationsListStore.notificationContext);
    
    const notificationsCount = notificationContext?.menuItems[routeTo];

    const onLinkClick = () => {
        history.push(routeTo);
        if (onClickCallback) {
            onClickCallback();
        }
    }

    if (roleRequired && !availableRoles.includes(roleRequired)) {
        return <></>
    }

    return <li className={history.location.pathname === routeTo ? "active" : ""}
        onClick={onLinkClick}><span>
            <img src={imageSrc} className="icon" />

            <span className="d-inline-flex justify-content-between align-items-center">
                 <span>{t(labelKey)} </span>
                {notificationsCount &&
                    <span className="notification-badge">{notificationsCount > 9 ? '9+' : notificationsCount}</span>
                }
            </span>
        </span>
    </li>
}

export default MenuItem;