import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { toPlnWithoutSuffix } from "helpers/pln_helper";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import AffiliationActionDialog from "./AffiliationActionDialog";
import AffiliationDialogHistory from "./AffiliationDialogHistory";
import AffiliationDialogSummary from "./AffiliationDialogSummary";
import './affiliationLink.scss';
import { AFFILIATION_LINK_ACT } from "./store/actionsAffiliationLink";

const AffiliationLinkPage = () => {

  const { t } = useTranslation();

  const affiliationLinkStore = useSelector((state: any) => state.AffiliationLinkStore);
  const dispatch = useDispatch();
  useEffect(() => {
    if (affiliationLinkStore.reloadRequired) {
      dispatch(AFFILIATION_LINK_ACT.loadData());
    }
  }, [affiliationLinkStore.reloadRequired, dispatch])
  const parameters = useSelector((state: any) => state.ConfigStore.config.parameters);
  const translationPlaceholdersOject = {
    "AFF_SETT_COUNT": parameters["AFF_SETT_COUNT"],
    "AFF_SETT_BONUS_SENDER": toPlnWithoutSuffix(parameters["AFF_SETT_BONUS_SENDER"]),
    "AFF_SETT_BONUS_RECEIVER": toPlnWithoutSuffix(parameters["AFF_SETT_BONUS_RECEIVER"]),
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={6}>
          <DialogWrapper title="AffLinkSend.mainDialog.title">
            <div className="working-list">
              <img src="/img/saving-img.svg" className="saving-img" />
              <div className="aff-link-first-panel">
                <ul className="pb-3">
                  <li>
                    <span>1</span>
                    <p><Trans i18nKey={t('AffLinkSend.mainDialog.point1')} values={translationPlaceholdersOject} components={{ italic: <i />, bold: <strong />, br: <br /> }} />
                    </p>
                  </li>
                  <li>
                    <span>2</span>
                    <p><Trans i18nKey={t('AffLinkSend.mainDialog.point2')} values={translationPlaceholdersOject} components={{ italic: <i />, bold: <strong />, br: <br /> }} /></p>
                  </li>
                  <li>
                    <span>3</span>
                    <p><Trans i18nKey={t('AffLinkSend.mainDialog.point3')} values={translationPlaceholdersOject} components={{ italic: <i />, bold: <strong />, br: <br /> }} /></p>
                  </li>
                  <li>
                    <span>4</span>
                    <p><Trans i18nKey={t('AffLinkSend.mainDialog.point4')} values={translationPlaceholdersOject} components={{ italic: <i />, bold: <strong />, br: <br /> }} /></p>
                  </li>
                </ul>
              </div>
            </div>
          </DialogWrapper>
        </Col>
        <Col xs={12} md={6}>
          <DialogWrapper title="AffLinkSend.linkDialog.title">
            <div className="aff-link-first-panel">
              <div className="aff-link-first-panel-shitf">
                <AffiliationActionDialog />
              </div>
            </div>
          </DialogWrapper>
        </Col>
        <Col xs={12} md={6}>
          <AffiliationDialogSummary />
        </Col>
        <Col xs={12} md={6}>
          <AffiliationDialogHistory />
        </Col>
      </Row>
    </Container>
  );
};


export default AffiliationLinkPage; 