const PREFIX = "SETT_"

//get agreement available types
const LOAD_DATA = PREFIX + "LOAD_DATA";
const loadData = () => ({ type: LOAD_DATA, payload: {} });

const LOAD_DATA_DONE = PREFIX + "LOAD_DATA_DONE";
const loadDataDone = (gridData: any[], context: any, info: string, error: string) => ({
    type: LOAD_DATA_DONE, payload: { gridData, context,  info }
});

const MARK_PRESENTED = PREFIX + "MARK_PRESENTED";
const markAsPresented = (settlementId: number) => ({ type: MARK_PRESENTED, payload: {settlementId} });

const MARK_PRESENTED_DONE = PREFIX + "MARK_PRESENTED_DONE";
const markAsPresentedDone = (settlementId: number, markedAsPresented: string ) => ({
    type: MARK_PRESENTED_DONE, payload: { settlementId, markedAsPresented }
});

//get agreement available types
const LUMP_TAX_PAID = PREFIX + "LUMP_TAX_PAID";
const lumpTaxPaid = (lumpTaxId: number) => ({ type: LUMP_TAX_PAID, payload: {lumpTaxId} });

const LUMP_TAX_PAID_DONE = PREFIX + "LUMP_TAX_PAID_DONE";
const lumpTaxPaidDone = () => ({ type: LUMP_TAX_PAID_DONE, payload: {} });

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const SETTLEMENT_LIST_ACT = {
    //pobieranie danych
    loadData, loadDataDone,
    markAsPresented, markAsPresentedDone,
    lumpTaxPaid, lumpTaxPaidDone,
    apiError
}

export const SETTLEMENT_LIST_ACT_TYP = {
    LOAD_DATA, LOAD_DATA_DONE,
    MARK_PRESENTED, MARK_PRESENTED_DONE,
    LUMP_TAX_PAID, LUMP_TAX_PAID_DONE,
    API_ERROR,
}