const STATUS_W4C = 'W4C';
const STATUS_SIG = 'SIG';
const STATUS_REJ = 'REJ';
const STATUS_FIN = 'FIN';



const getStatusIcon = (status: string) => {

    switch (status) {
        case STATUS_SIG:
            return <i className="agr-SIG bx bx-check-circle"></i>
        case STATUS_W4C:
            return <i className="agr-W4C  bx bx-help-circle"></i>
        case STATUS_REJ:
            return <i className="agr-REJ  bx bx-error-circle"></i>
        case STATUS_FIN:
            return <i className="agr-FIN bx bx-x-circle"></i>
        default:
            return <i className="unknown-icon bx bx-dizzy"></i>
    }
}


export const AGREEMENT_CONF = {
    getStatusIcon,
    STATUS_W4C,
    STATUS_SIG,
    STATUS_REJ,
    STATUS_FIN,
}