import { IAgreementConfig, IAgreementData, IDictionaries } from "pages/Agreement/IAgreement";
const PREFIX = "AGR_"

 
/////////////////////////////////////////////////
//get agreement type configuration and data
const GET_AGREEMENT_CONFIG_AND_DATA = PREFIX + "GET_AGREEMENT_CONFIG_AND_DATA";
const getAgreementConfigAndData = (agreementType: string, pageKey: string | null, history: any, isAgreementTypeList : boolean) => 
({ type: GET_AGREEMENT_CONFIG_AND_DATA, payload: { agreementType, pageKey, history, isAgreementTypeList } });

const GET_AGREEMENT_CONFIG_AND_DATA_DONE = PREFIX + "GET_AGREEMENT_CONFIG_AND_DATA_DONE";
const getAgreementConfigAndDataDone = (agreementConfig: IAgreementConfig, agreementData: IAgreementData, dictionaries: IDictionaries,
    canGeneratePreview: boolean, canSign: boolean
) =>
    ({ type: GET_AGREEMENT_CONFIG_AND_DATA_DONE, payload: { agreementConfig, agreementData, dictionaries, canGeneratePreview, canSign} });

/////////////////////////////////////////////////
//get agreement type configuration and data
const OPEN_FORM_PAGE = PREFIX + "OPEN_FORM_PAGE";
const openFormPage = (pageKey: string, agreementType: string, history: any) =>
    ({ type: OPEN_FORM_PAGE, payload: { pageKey, agreementType, history } });

//get agreement type configuration and data
const SAVE_DATA = PREFIX + "SAVE_DATA_START";
const saveData = (pageKey: string, pageNextKey: string, agreementType: string, values: any, history: any) =>
    ({ type: SAVE_DATA, payload: { pageKey, pageNextKey, agreementType, values, history } });

const SAVE_DATA_DONE = PREFIX + "SAVE_DATA_DONE";
const saveDataDone = (agreementConfig: IAgreementConfig, agreementData: IAgreementData, dictionaries: IDictionaries,  canGeneratePreview: boolean, canSign: boolean) =>
    ({ type: SAVE_DATA_DONE, payload: { agreementConfig, agreementData, dictionaries, canGeneratePreview, canSign } });

//SMS confirmation //check
const OPEN_SIGN_DOCUMENT = PREFIX + "OPEN_SIGN_DOCUMENT";
const openSignDocument = (agreementType: string, history: any) =>
    ({ type: OPEN_SIGN_DOCUMENT, payload: { agreementType, history } });

const RESEND_SMS = PREFIX + "RESEND_SMS";
const resendSMS = (agreementType: string) =>
    ({ type: RESEND_SMS, payload: { agreementType } });

const RESEND_SMS_DONE = PREFIX + "RESEND_SMS_DONE";
const resendSMSDone = (info: string) =>
    ({ type: RESEND_SMS_DONE, payload: { info } });

const SMS_CONFIRM_CODE = PREFIX + "SMS_CONFIRM_CODE";
const smsConfirmCode = (agreementType: string, smsCode: string, history: any) =>
    ({ type: SMS_CONFIRM_CODE, payload: { agreementType, smsCode, history } });

const SMS_CONFIRM_CODE_DONE = PREFIX + "SMS_CONFIRM_CODE_DONE";
const smsConfirmCodeDone = () =>
    ({ type: SMS_CONFIRM_CODE_DONE, payload: {} });

const PREVIEW_AGREEMENT = PREFIX + "PREVIEW_AGREEMENT";
const previewAgreement = (agreementType: string, history: any, t: any) =>
    ({ type: PREVIEW_AGREEMENT, payload: { agreementType, history, t } });

const PREVIEW_AGREEMENT_DONE = PREFIX + "PREVIEW_AGREEMENT_DONE";
const previewAgreementDone = () =>
    ({ type: PREVIEW_AGREEMENT_DONE, payload: {} });

const SIGN_AGREEMENT = PREFIX + "SIGN_AGREEMENT";
const signAgreement = (agreementType: string, signature: string, history: any) =>
    ({ type: SIGN_AGREEMENT, payload: { agreementType, signature, history } });

const SIGN_AGREEMENT_DONE = PREFIX + "SIGN_AGREEMENT_DONE";
const signAgreementDone = (error: string, info: string) =>
    ({ type: SIGN_AGREEMENT_DONE, payload: {error, info} });

const API_FAIL = PREFIX + "API_FAIL";
const apiFail = (error: any) => ({
    type: API_FAIL, payload: { error },
});


export const AGREEMENT_ACTION = {

    //pobranie konfiguracji umowy i wypełnionych danych
    getAgreementConfigAndData, getAgreementConfigAndDataDone,

    //zapis
    saveData, saveDataDone,
    //zarządzanie formularzami

    //podpisywanie dokumentu
    openSignDocument,

    resendSMS, resendSMSDone,
    smsConfirmCode, smsConfirmCodeDone,

    previewAgreement, previewAgreementDone,
    signAgreement, signAgreementDone,

    openFormPage,
    apiFail,
}

export const AGREEMENT_ACTION_TYPES = {
    GET_AGREEMENT_CONFIG_AND_DATA, GET_AGREEMENT_CONFIG_AND_DATA_DONE,
    OPEN_FORM_PAGE,
    SAVE_DATA, SAVE_DATA_DONE,
    OPEN_SIGN_DOCUMENT,
    RESEND_SMS, RESEND_SMS_DONE,
    SMS_CONFIRM_CODE, SMS_CONFIRM_CODE_DONE,
    PREVIEW_AGREEMENT, PREVIEW_AGREEMENT_DONE,
    SIGN_AGREEMENT, SIGN_AGREEMENT_DONE,
    API_FAIL
}