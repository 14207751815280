import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { ProgressIndicator } from "components/Layout20/ProgressIndicator";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { PRE_REG_ACTIONS } from "store/auth/pre-registration/actions";
import * as Yup from 'yup';
import LoadingPage from "../../../../components/Common/LoadingPage";
import ButtonSubmit from "../../../../components/MyComponents/ButtonSubmit";
import CustomSelect, { SELECT_SINGLE_DEFAULT_PLACEHOLDER } from "../../../../components/MyComponents/CustomSelect";
import { YUP_VALIDATION } from "../../../../helpers/YupHelper";
import RegisterPageWrapper from "../RegisterPageWrapper";

const formValidator = Yup.object().shape({
  suggestedCityId: YUP_VALIDATION.YUP_STRING_REQ,
});

interface FormValues {
  suggestedCityId: string;
}

const RegisterPage3_CityData = () => {
  const dispatch = useDispatch();
  const { regUserData, isLoading, error, cityDomain  } = useSelector((state: any) => (
    {
      regUserData: state.PreRegistration.regUserData,
      isLoading: state.PreRegistration.isLoading,
      error: state.PreRegistration.error,
      cityDomain: state.PreRegistration.cityDomain,
    })
  );

  const affiliationKey = useSelector((state: any) => state.AffiliationStore.affiliationKey);
  if (!regUserData?.page3Data) {
    return <RegisterPageWrapper><LoadingPage /></RegisterPageWrapper>
  }

  const onSubmit = (values: FormValues) => {
    dispatch(PRE_REG_ACTIONS.step3StartUpdating(regUserData, regUserData.page3Data, values, affiliationKey));
  };

  const onBack = () => {
    dispatch(PRE_REG_ACTIONS.preRegistrationPageBack())
  };

  const renderForm = () => {
    return (
      <Form>
        <ProgressIndicator step={3} stepCount={6} />
        <Row>
          <Col xs={12}>
            <Field name="suggestedCityId" label="Registration.Page3.City"
              textAfter="Registration.Page3.City.AdditionalComment"
              placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
              options={cityDomain} className="custom-select" component={CustomSelect}
              isDisabled={isLoading}
            />
          </Col>
        </Row>
        <Row className="pb-3">
          <Col xs={4} className="px-1">
            <ButtonCustom isLoading={false} id="button-register-user-city-back" label="Registration.Page3.Back" onClick={onBack} /></Col>
          <Col xs={8}>
            <ButtonSubmit id="button-register-user-city"
              isLoading={isLoading} 
              label="Registration.Page3.Submit" 
              iconClass="fa-light fa-angles-right fa-lg" className="w-100" /></Col>
        </Row>
      </Form>
    )
  };

  return (<RegisterPageWrapper showLoginLink={false}>
    <AlertFrameError messageKey={error} />
    <Formik
      initialValues={regUserData.page3Data}
      validationSchema={formValidator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  </RegisterPageWrapper>
  );
};


export default RegisterPage3_CityData; 