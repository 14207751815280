import ButtonTripple, { ITrippeButton } from "components/MyComponents/ButtonTripple";
import { Form } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ROUTE_AGREEMENT_P2_FORM__URL_PARAMS } from "routes";
import FormItemMaskedTextField, { ZIP_CODE_MASK } from "../FormItems/FormItemMaskedTextField";
import FormItemTextField from "../FormItems/FormItemTextField";
import HelperHeader from "../FormItems/HelperHeader";
import { IDictionaries, IFormPage } from "../IAgreement";

export const ADDRESS_DATA_FORM_VALIDATION_RULES_BASIC_NOT_REQUIRED = YUP_VALIDATION.YUP_OBJ.shape({
  ca_cit2: YUP_VALIDATION.YUP_STRING,
  ca_zc2: YUP_VALIDATION.YUP_STRING_ZIP_CODE_NOT_REQUIRED,
  ca_str2: YUP_VALIDATION.YUP_STRING,
  ca_str_no2: YUP_VALIDATION.YUP_STRING,
  ca_ap_no2: YUP_VALIDATION.YUP_STRING,
});


interface ICustomFormInterface extends ITrippeButton {
  isLoading: boolean,
  error: string,
  pageConfig: IFormPage,
  dictionaries: IDictionaries
}

const AgreementPage2FormAdressDataSecond = ({ isLoading, error, pageConfig, dictionaries, onClickBack, onClickList }: ICustomFormInterface) => {

  let { pageKey } = useParams<ROUTE_AGREEMENT_P2_FORM__URL_PARAMS>();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Form autoComplete="off" autoCorrect="off" key={pageKey + "address-data-form"} >
      <Row>
        <Col xs={12}>
          <HelperHeader formItem={pageConfig.fieldsMap['top_header']} context="top_header" pageKey={pageKey} />
        </Col>
        <Col xs={12} md={2}>
          <FormItemMaskedTextField mask={ZIP_CODE_MASK}
            formItem={pageConfig.fieldsMap['ca_zc2']} context="ca_zc2" pageKey={pageKey} />
        </Col>
        <Col xs={12} md={6}>
          <FormItemTextField formItem={pageConfig.fieldsMap['ca_cit2']} context="ca_cit2" pageKey={pageKey} />
        </Col>

        <Col xs={12} md={8}>
          <FormItemTextField formItem={pageConfig.fieldsMap['ca_str2']} context="ca_str2" pageKey={pageKey} />
        </Col>
        <Col xs={12} md={2}>
          <FormItemTextField formItem={pageConfig.fieldsMap['ca_str_no2']} context="ca_str_no2" pageKey={pageKey} />
        </Col>
        <Col xs={12} md={2}>
          <FormItemTextField formItem={pageConfig.fieldsMap['ca_ap_no2']} context="ca_ap_no2" pageKey={pageKey} />
        </Col>
      </Row>
      <ButtonTripple
      id={`button-wizard-adres-data-2-${pageKey}`}
        isLoading={isLoading} label={pageKey + ".Submit"}
        onClickBack={onClickBack}
        onClickList={onClickList}
      />
    </Form >
  );
};

export default AgreementPage2FormAdressDataSecond; 