import LoadingPage from "components/Common/LoadingPage";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import SignatureCustom from "components/MyComponents/SignatureCustom";
import { AGREEMENT_ACTION } from "pages/Agreement/store-pre/actionsAgr";
import { IPreAgreementState } from "pages/Agreement/store-pre/reducerAgr";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { ROUTES, ROUTE_AGREEMENT_P2_FORM__URL_PARAMS } from "routes";
import { fillPlaceholders } from "routes/placeholder_helper";

const AgreementPage4Sign = () => {

  const history = useHistory();
  let { agreementType, pageKey } = useParams<ROUTE_AGREEMENT_P2_FORM__URL_PARAMS>();
  const { isLoading, error, agreementDataLoaded } = useSelector((state: any) => {
    const agreementSignStore: IPreAgreementState = state.AgreementSignStore as IPreAgreementState;
    const agreementDataLoaded = agreementSignStore.agreementDataLoaded;
    return (
      {
        isLoading: agreementSignStore.isLoading,
        error: agreementSignStore.error,
        agreementDataLoaded: agreementDataLoaded,
      })
  }
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    //data fetch for reload 
    if (!agreementDataLoaded && error === '') {
      dispatch(AGREEMENT_ACTION.getAgreementConfigAndData(agreementType, pageKey, history, false))
    }
  }, [])

  if (!agreementDataLoaded && error === '') {
    return <Container fluid><LoadingPage /></Container>
  }

  const moveBack = () => {
    history.push(fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P1_WIZARD, { 'agreementType': agreementType }))
  }

  const saveSignature = (signature: string) => {
    dispatch(AGREEMENT_ACTION.signAgreement(agreementType, signature, history))
  }

  if (isLoading) {
    return (<Container fluid>      
      <DialogWrapper title="">
        <div className="px-0 px-md-2 ">
          <div className="helper-text-extender text-center blue">
            <h1>{t('Agreement.WizardPage.SignAgreement.AgreementCreationInProgress')}</h1>
          </div>
          <div className="text-center py-3">
            <i className="fa-duotone fa-gear fa-spin fa-6x text-warning"/>
          </div>
          <div className="py-2">
            {t('Agreement.WizardPage.SignAgreement.AgreementCreationInProgress.BottomText')}
          </div>
        </div>
      </DialogWrapper>
    </ Container>
    );
  }
  return (<Container fluid>
    <DialogWrapper title="">
      <SignatureCustom isLoading={false} moveBack={moveBack} saveSignature={saveSignature}
       headerText="Agreement.WizardPage.SignAgreement.Signature.top_header"
       headerTextBefore="Agreement.WizardPage.SignAgreement.Signature.top_header.line1"
       headerTextAfter="Agreement.WizardPage.SignAgreement.Signature.top_header.line2"
      />
    </DialogWrapper>
  </Container>
  );
};

export default AgreementPage4Sign;
