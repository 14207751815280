import { IGridDataExtended } from "store/IGridData";
import { DOC2SIGN_LIST_ACT_TYP } from "./docs2SignList";

export interface IDoc2SignDto {
    id: number,
    documentKind: string,
    documentNumber: string,
    documentDate: string,
    signDate: string,
    reffId: string,
    pdfFileId: number,
    pdfFileName: string,
}

const INIT_STATE: IGridDataExtended<IDoc2SignDto, any> = {
    gridData: [],
    context: { missingAttachments: [], expiringAttachments: [] },
    isLoading: false,
    reloadRequired: true,
    error: '',
    info: '',
};

const Docs2SignListStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case DOC2SIGN_LIST_ACT_TYP.LOAD_DATA:
            return { ...state, isLoading: true, error: '' };
        case DOC2SIGN_LIST_ACT_TYP.LOAD_DATA_DONE:
            return {
                ...state, isLoading: false,
                gridData: action.payload.gridData,
                context: action.payload.context,
                info: action.payload.info,
                error: action.payload.error
            };
        case DOC2SIGN_LIST_ACT_TYP.SIGN_AND_CLOSE_POPUP_DONE:
            return {
                ...state,                                
                gridData: state.gridData.filter((i: any) => i.id != action.payload.docIdToRemove)
            };
        default:
            return state;
    }
}

export default Docs2SignListStore;