import { IOption } from "components/MyComponents/CustomSelect";

export interface IAgreementFormItem {
    name: string,
    isFormik: boolean,
    disabled: boolean,
    mdSize?: number,

    domain?: string;
    domainDirect?: IOption[];
    regexpFormula?: string,
    textBeforeSuffix?: string;
    url?: string,
    urlLabel?: string,

    className?: string,

    dependentOn?: string,
    dependentOnEqual?: string,
    dependentOnNotEqual?: string,

    textAfterSuffix?: string;

    kind: "TEXT_FIELD" | "DATE_FIELD" | "CHECK_BOX" | "SELECT_SINGLE" | "SELECT_MULTI" | "NUMBER_FIELD" | "PHONE_FIELD"
    | "HELPER_HEADER" | "HELPER_TEXT",

    validation?:
    "STRING" | "STRING_REGEXP" | "STRING_REGEXP_REQUIRED" | "STRING_REQ" | "STRING_REQ_EMAIL" | "STRING_PHONE" | "STRING_REQ_PHONE" | "STRING_REQ_SELECT" | "STRING_REQ_MULTISELECT" |
    "DATE_REQ" | "DATE_AT_LEAST_16Y_OLD" | "DATE_AT_LEAST_18Y_OLD" | "DATE_BEFORE_NOW" | "DATE_AFTER_NOW" | "DATE_AFTER_NOW_OR_EMPTY"
}

export const PHONE_TYPE_PREFIX = '_PREFIX';
export const PHONE_TYPE_SUFFIX = '_SUFFIX';

export const getMdSize = (field: IAgreementFormItem) => {

    if (field.mdSize) {
        return field.mdSize;
    }
    if (field.kind === "HELPER_TEXT" || field.kind === "HELPER_HEADER" || field.kind === "CHECK_BOX") {
        return 12;
    }
    return 6;
}


export interface IFieldsMap {
    [index: string]: IAgreementFormItem
}


export interface IFormPage {
    pageKey: string;
    pageNextKey: string;
    pagePrevKey: string;
    customForm: string | null,
    fieldsMap: IFieldsMap,
    formikFields: IAgreementFormItem[],
    fields: IAgreementFormItem[],
}

export interface IAgreementConfig {
    agreementType: string,
    pages: IFormPage[]
}

export const getPageConfig = (config: IAgreementConfig, pageKey: string): IFormPage => {
    const foundFirstPageByKey = config.pages.find(page => page.pageKey === pageKey);
    if (foundFirstPageByKey) {
        return foundFirstPageByKey;
    }
    return defaultIFormPage;
}

export const getPageData = (agreementData: IAgreementData, pageKey: string): IPageData => {
    if (agreementData.hasOwnProperty(pageKey)) {
        return agreementData[pageKey as keyof typeof agreementData];
    }
    return defaultIPageData;
}




export interface IAgreementData {
    [index: string]: IPageData
}

export interface IDictionaries {
    [index: string]: IOption[]
}

export interface IPageDataItemValue {
    stringValue: string | null,
    dateValue: string | null,
    numberValue: number | null,
    booleanValue: boolean | null;
    phoneSuffixValue: string | null,
    phonePrefixValue: string | null,
}

export const get_single_value = (item: IPageDataItemValue, ctx: string): any => {

    if (!item) {
        console.error('data_to_string: undefined. ctx: ' + ctx)
        return '';
    }

    if (item.booleanValue !== null) {
        return item.booleanValue;
    }
    if (item.dateValue !== null) {
        return item.dateValue;
    }
    if (item.numberValue !== null) {
        return item.numberValue;
    }
    if (item.phoneSuffixValue !== null) {
        return item.phonePrefixValue + item.phoneSuffixValue;
    }
    return item.stringValue || '';
}


export interface IDataMap {
    [index: string]: IPageDataItemValue
}

export interface ICompoundData {
    pageData: IAgreementData,
    allData: IDataMap
}

export interface IPageData {
    pageValid: boolean,
    pageFilled: boolean,
    pageLocked: boolean,
    values: IDataMap
}
export interface IPreAgreementData {
    config: IAgreementConfig,
    data: IAgreementData,
    dictionaries: IDictionaries,
    canGeneratePreview: boolean,
    canSign: boolean,
}

export const NOT_FOUND_TYPE = 'not-found'

export const defaultIAgreementConfig: IAgreementConfig =
{
    agreementType: NOT_FOUND_TYPE,
    pages: []
}
export const defaultIAgreementData: ICompoundData = {
    pageData: {},
    allData: {}
}

export const defaultIDictionaries: IDictionaries = {}


export const defaultIFormPage: IFormPage = {
    pageKey: NOT_FOUND_TYPE,
    customForm: null,
    pageNextKey: '',
    pagePrevKey: '',
    fields: [],
    formikFields: [],
    fieldsMap: {}

}

export const defaultIPageData: IPageData = {
    pageValid: false,
    pageFilled: false,
    pageLocked: true,
    values: {}
}