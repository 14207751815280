import { getTextByLang } from "components/Common/datatable/ItemByLang";
import { useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";
import { ROUTES } from "routes";
import { fillPlaceholders } from "routes/placeholder_helper";

const DashboardSubDialog_00_NotificationsList = () => {

  const { i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const { notifications } = useSelector((state: any) => ({ notifications: state.Dashboard.dashboardDto.notifications, }));

  if(!notifications || notifications.length === 0){
    return <></>
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === notifications.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? notifications.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = notifications.map((row: any) => {
    return (
      <CarouselItem className="carousel-item"        
        tag="div"
        key={row.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <Link to={fillPlaceholders(ROUTES.ROUTE_NOTIFICATIONS_LIST_WITH_ID, { 'notificationId': row.id })}>
          <img src={row.contentNotificationUrl} className="carousel-image" />
          <img src={row.contentNotificationUrl} className="carousel-image-blured" />
          <CarouselCaption

            className="carousel-caption" captionText=''
            captionHeader={
              getTextByLang({
                plText: row.contentNotificationTitlePl,
                enText: row.contentNotificationTitleEn,
                uaText: row.contentNotificationTitleUa,
                geText: row.contentNotificationTitleGe,
                ruText: row.contentNotificationTitleRu,
              }, i18n.language)
            }
          />
        </Link>

      </CarouselItem>
    );
  });

const hideIfSingleItem = notifications.length === 1;

  return (
    <div className="wrapper-row py-2">
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        className="carousel-container"
        ride="carousel"
        slide={true}
       >
        
        <CarouselIndicators
         hidden={hideIfSingleItem}
        items={notifications} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl hidden={hideIfSingleItem} direction="prev" directionText="<" onClickHandler={previous} />
        <CarouselControl  hidden={hideIfSingleItem} direction="next" directionText=">" onClickHandler={next} />
      </Carousel>
    </div>
  );

}
export default DashboardSubDialog_00_NotificationsList; 