const PREFIX = "D2S_"

const LOAD_DATA = PREFIX + "LOAD_DATA";
const loadData = () => ({ type: LOAD_DATA, payload: {} });

const LOAD_DATA_DONE = PREFIX + "LOAD_DATA_DONE";
const loadDataDone = (gridData: any[], context: any, info: string, error: string) => ({
    type: LOAD_DATA_DONE, payload: { gridData, context, info, error }
});


const SIGN_AND_CLOSE_POPUP = PREFIX + "SIGN_AND_CLOSE_POPUP";
const signDocumentAndClosePopup = (dto: any, history: any) => ({
    type: SIGN_AND_CLOSE_POPUP, payload: { dto, history }
});

const SIGN_AND_CLOSE_POPUP_DONE = PREFIX + "SIGN_AND_CLOSE_POPUP_DONE";
const signDocumentAndClosePopupDone = (docIdToRemove: number, history: any) => ({
    type: SIGN_AND_CLOSE_POPUP_DONE, payload: { docIdToRemove, history }
});



const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const DOC2SIGN_LIST_ACT = {
    loadData, loadDataDone,
    signDocumentAndClosePopup, signDocumentAndClosePopupDone,
    apiError
}

export const DOC2SIGN_LIST_ACT_TYP = {
    LOAD_DATA, LOAD_DATA_DONE,
    SIGN_AND_CLOSE_POPUP, SIGN_AND_CLOSE_POPUP_DONE,
    API_ERROR,
}