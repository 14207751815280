import LoadingContent from "components/Common/LoadingContent";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import CustomTextWithButton from "components/MyComponents/CustomTextWithButton";
import { Field, Form, Formik, FormikValues } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ROUTES } from "routes";
import { IBAN_CHANGE_ACT } from "./store/actionsIbanChange";
import { IIbanChangeActionReducer } from "./store/reducerIbanChange";
import CustomText from "components/MyComponents/CustomText";

const VALIDATOR = YUP_VALIDATION.YUP_OBJ.shape({
  bankAccountBase: YUP_VALIDATION.YUP_BANK_ACCOUNT,
  bankAccountLease: YUP_VALIDATION.YUP_BANK_ACCOUNT,
  bankAccountReturns: YUP_VALIDATION.YUP_BANK_ACCOUNT,
  smsConfirmation: YUP_VALIDATION.YUP_STRING_REQ,
});

const ProfileBankAccountPage = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const profileStore = useSelector((state: any) => state.ProfileStore);
  const contractorId = useSelector((state: any) => state.LoginState.contractorId);
  const store: IIbanChangeActionReducer = useSelector((state: any) => state.IbanChangeActionStore);

  const [smsCount, setSmsCount] = useState(0)

  const handleSendSMS = () => {
    if (smsCount < 2) {
      dispatch(IBAN_CHANGE_ACT.ibanChangeSmsCode(contractorId));
      setSmsCount(smsCount + 1)
    }
  }

  const onSubmit = (values: FormikValues) => {

    dispatch(IBAN_CHANGE_ACT.ibanChange({
      contractorId: contractorId,
      bankAccountBase: values.bankAccountBase,
      bankAccountLease: values.bankAccountLease,
      bankAccountReturns: values.bankAccountReturns,
      smsConfirmation: values.smsConfirmation,
    },
      history
    ));
  };
  const isB2B = profileStore.profile && profileStore.profile.b2b;

  const isBA1 = profileStore.profile && profileStore.profile.bankAccountBase !== '';
  const isBA2 = profileStore.profile && profileStore.profile.bankAccountLease !== '';
  const isBA3 = profileStore.profile && profileStore.profile.bankAccountReturns !== '';

  if (!isBA1 && !isBA2 && !isBA3) {
    //można przekierować na panel profilu
    const storeInitialFetch = profileStore.initlaFetch
    console.log('storeInitialFetch', storeInitialFetch)
    if (storeInitialFetch != 'LOADED') {
      history.push(ROUTES.ROUTE_PROFILE);
      return <></>;
    }
    return <Container><LoadingContent></LoadingContent></Container>
  }

  return (
    <Container>
      <DialogWrapper title="Profile.bankAccountChange.header">
        <Formik
          initialValues={{
            bankAccountBase: profileStore.profile.bankAccountBase,
            bankAccountLease: profileStore.profile.bankAccountLease,
            bankAccountReturns: profileStore.profile.bankAccountReturns,
            smsConfirmation: ''
          }}
          validationSchema={VALIDATOR}
          onSubmit={onSubmit}
        >
          <Form>
            <Row>
              {isBA1 &&
                <Col xs={12}>
                  <Field name="bankAccountBase" label={isB2B ? "Profile.Data.bankAccountB2B":"Profile.Data.bankAccountBase"} component={CustomText} isDisabled={store.ibanChangeActionInProgress} />
                </Col>
              }
              {(!isB2B && isBA2) &&
                <Col xs={12}>
                  <Field name="bankAccountLease" label="Profile.Data.bankAccountLease" component={CustomText} isDisabled={store.ibanChangeActionInProgress} />
                </Col>
              }
              {(!isB2B && isBA3) &&
                <Col xs={12}>
                  <Field name="bankAccountReturns" label="Profile.Data.bankAccountReturns" component={CustomText} isDisabled={store.ibanChangeActionInProgress} />
                </Col>
              }
            </Row>
            <Row className="py-2 form-inline">
              <Col xs={12} >
                <Field name="smsConfirmation" label="Profile.bankAccountChange.smsCode"
                  component={CustomTextWithButton}
                  textAfter={smsCount ? "Profile.bankAccountChange.smsCode.TextAfter" : undefined}
                  buttonColor={smsCount ? "secondary" : "default"}
                  isDisabled={smsCount === 0 || store.ibanChangeActionInProgress}
                  isButtonDisabled={smsCount > 2 || store.ibanChangeActionInProgress}
                  buttonLabel="Profile.bankAccountChange.smsCode.ButtonLabel"
                  buttonOnClick={handleSendSMS}
                />
              </Col>
            </Row>
            <Row className="my-2 py-2 pb-3">
              <Col>
                <ButtonSubmit
                  id="button-profile-ibans-change"
                  disabled={smsCount === 0}
                  color={smsCount === 0 ? "secondary" : "default"}
                  isLoading={store.ibanChangeActionInProgress}
                  label="Profile.bankAccountChange.Submit"
                  iconFileSrc="/img/verified-check.svg"
                  />
              </Col>
            </Row>
          </Form>
        </Formik>
      </DialogWrapper>
    </Container>
  );
};

export default ProfileBankAccountPage; 