import axios from "axios";
import { getFirebaseBackend } from "./firebase_helper";

const getBaseUrl = () => {
  let ret = window.location.origin + '/api'
  if (window.location.origin === 'http://localhost:3000') {
    //na potrzeby testów
    ret = 'http://localhost:8092/api';
  }
  if (window.location.origin.startsWith('http://192.168') || window.location.origin.startsWith('http://10.0.0')) {
    //na potrzeby testów
    ret = window.location.origin.replace(':3000', ':8092/api');
  }
  return ret;
}

const axiosApi = axios.create({
  baseURL: getBaseUrl(),
});

const RESOURCE_POST_AFFILIATION_LINK = "/affiliation-link/:phoneNumber"
const RESOURCE_GET_AFFILIATION_LINK = "/affiliation-link"

//rejestracja włansa
const RESOURCE_PRE_REG_STEP_0_PATCH = "/pre-registration/step-0"
const RESOURCE_PRE_REG_STEP_1_PATCH = "/pre-registration/step-1"
const RESOURCE_PRE_REG_STEP_2_PATCH = "/pre-registration/step-2"
const RESOURCE_PRE_REG_STEP_3_PATCH = "/pre-registration/step-3"
const RESOURCE_PRE_REG_STEP_4_PATCH = "/pre-registration/step-4"
const RESOURCE_PRE_REG_STEP_5_PATCH = "/pre-registration/step-5"

//konteks autoryzacyjny
const RESOURCE_AUTH_CONTEXT = "/authorize"

const RESOURCE_HB_LOGIN = "/heartbeat/login"
const RESOURCE_HB_LOGOUT = "/heartbeat/logout"
const RESOURCE_HB_REFRESH = "/heartbeat/refresh"

//konteks autoryzacyjny
const DEAL_ADD = "/app-add"


//dashboard
const RESOURCE_DASHBOARD_GET = "/dashboard"
const RESOURCE_DASHBOARD_FORCED_SETTLEMENT = "/dashboard/forced-settlement/:contractorId"

//profile
const RESOURCE_PROFILE = "/profile"
const RESOURCE_PROFILE_CYCLE = "/profile/cycle"
const RESOURCE_PROFILE_REMOVE = "/profile-remove/:contractorId"

const RESOURCE_PROFILE_IBAN_SMS_CODE = "/profile-iban/:contractorId/sms"
const RESOURCE_PROFILE_IBAN_UPDATE = "/profile-iban/:contractorId"


const RESOURCE_CONTACT_FORM = "/contact-form"
const RESOURCE_NOTIFICATIONS = "/notifications"
const RESOURCE_NOTIFICATIONS_ID = "/notifications/:notificationId"
const RESOURCE_NOTIFICATIONS_ID_READ = "/notifications/:notificationId/read"
const RESOURCE_NOTIFICATIONS_ID_CLICK = "/notifications/:notificationId/click"
const RESOURCE_NOTIFICATIONS_ID_HIDE = "/notifications/:notificationId/hide"

export const ENDPOINTS = {
  RESOURCE_CONTACT_FORM,
  RESOURCE_PROFILE,
  RESOURCE_PROFILE_CYCLE,
  RESOURCE_PROFILE_REMOVE,

  DEAL_ADD,

  RESOURCE_PROFILE_IBAN_SMS_CODE,
  RESOURCE_PROFILE_IBAN_UPDATE,

  RESOURCE_POST_AFFILIATION_LINK,
  RESOURCE_GET_AFFILIATION_LINK,
  RESOURCE_AUTH_CONTEXT,

  RESOURCE_HB_LOGIN,
  RESOURCE_HB_LOGOUT,
  RESOURCE_HB_REFRESH,


  RESOURCE_PRE_REG_STEP_0_PATCH,
  RESOURCE_PRE_REG_STEP_1_PATCH,
  RESOURCE_PRE_REG_STEP_2_PATCH,
  RESOURCE_PRE_REG_STEP_3_PATCH,
  RESOURCE_PRE_REG_STEP_4_PATCH,
  RESOURCE_PRE_REG_STEP_5_PATCH,

  RESOURCE_DASHBOARD_GET,
  RESOURCE_DASHBOARD_FORCED_SETTLEMENT,

  RESOURCE_NOTIFICATIONS,
  RESOURCE_NOTIFICATIONS_ID,
  RESOURCE_NOTIFICATIONS_ID_READ,
  RESOURCE_NOTIFICATIONS_ID_CLICK,
  RESOURCE_NOTIFICATIONS_ID_HIDE
}
export const getPdfFileUrl = (pdfFileId, pdfFileName, language, agreementType, context) => {

  if (context == 'agreement-preview') {
    //p1 - language
    //p2 - agreement type
    return `${getBaseUrl()}/agreement-new/${language}/${agreementType}/preview-bytes`
  }

  //p1 - fileId
  //p2 - fileName
  return `${getBaseUrl()}/file/pdf-raw/${pdfFileId}/${pdfFileName}`
}
//agreement creation/sign
const RESOURCE_AGREEMENT_GET_AVAILABLE_TYPES = "/agreement/available"
const RESOURCE_AGREEMENT_GET = "/agreement"


const RESOURCE_GET_AGREEMENT_GET_PDF = "/agreement/:agreementId/pdf"
const RESOURCE_DEL_AGREEMENT_REJECT = "/agreement/:agreementId/reject"


const RESOURCE_PRE_AGREEMENT_LOAD = "/agreement-new/:language/:agreementType"
const RESOURCE_PRE_AGREEMENT_SAVE = "/agreement-new/:language/:agreementType/:pageKey"
const RESOURCE_PRE_AGREEMENT_VALIDATION = "/agreement-new/:language/:agreementType/sms-validation"
const RESOURCE_PRE_AGREEMENT_SMS_RESEND = "/agreement-new/:language/:agreementType/sms-resend"
const RESOURCE_PRE_AGREEMENT_SMS_CONFIRM = "/agreement-new/:language/:agreementType/sms-confirm/:sms-code"
const RESOURCE_PRE_AGREEMENT_PREVIEW = "/agreement-new/:language/:agreementType/preview"
const RESOURCE_PRE_AGREEMENT_SIGN = "/agreement-new/:language/:agreementType/sign"


//settlement
const RESOURCE_GET_SETTLEMENT = "/settlement"
const RESOURCE_GET_SETTLEMENT_DETAILS = "/settlement/:settlementId"
const RESOURCE_GET_SETTLEMENT_DETAILS_PRESENTED = "/settlement/:settlementId/read"
const LUMP_TAX_PAID = "/settlement/lease-lump-tax/:lumpTaxId";


export const ENDPOINT_SETTLEMENT = {
  RESOURCE_GET_SETTLEMENT,
  RESOURCE_GET_SETTLEMENT_DETAILS,
  RESOURCE_GET_SETTLEMENT_DETAILS_PRESENTED,
  LUMP_TAX_PAID
}

//konfuguracja
const RESOURCE_GET_CONFIG = "/config"

export const ENDPOINT_CONFIG = {
  RESOURCE_GET_CONFIG
}
//settlement
const RESOURCE_GET_INVOICE = "/invoice"
const RESOURCE_POST_INVOICE_SELF = "/invoice/self"
const RESOURCE_POST_INVOICE_OFFICE = "/invoice/office"

export const ENDPOINT_INVOICE = {
  RESOURCE_GET_INVOICE,
  RESOURCE_POST_INVOICE_SELF,
  RESOURCE_POST_INVOICE_OFFICE
}


export const ENDPOINT_AGREEMENT = {

  RESOURCE_AGREEMENT_GET,
  RESOURCE_GET_AGREEMENT_GET_PDF,
  RESOURCE_DEL_AGREEMENT_REJECT,
  RESOURCE_AGREEMENT_GET_AVAILABLE_TYPES,
  RESOURCE_PRE_AGREEMENT_LOAD,
  RESOURCE_PRE_AGREEMENT_SAVE,
  RESOURCE_PRE_AGREEMENT_VALIDATION,
  RESOURCE_PRE_AGREEMENT_SMS_RESEND,
  RESOURCE_PRE_AGREEMENT_SMS_CONFIRM,
  RESOURCE_PRE_AGREEMENT_PREVIEW,
  RESOURCE_PRE_AGREEMENT_SIGN
}


const RESOURCE_ATTACHMENT_GET = "/attachment"
const RESOURCE_ATTACHMENT_NEW_BY_TYPE = "/attachment/:docTypeId"
const RESOURCE_ATTACHMENT_ADD__POST_MULTIPART = "/attachment/:docTypeId"
const RESOURCE_ATTACHMENT_REJECT__DELETE = "/attachment/:attachmentId"

export const ENDPOINT_ATTACHMENT = {
  RESOURCE_ATTACHMENT_GET,
  RESOURCE_ATTACHMENT_NEW_BY_TYPE,
  RESOURCE_ATTACHMENT_ADD__POST_MULTIPART,
  RESOURCE_ATTACHMENT_REJECT__DELETE,
}

const DOS_2_SIGN_GET = "/docs-to-sign"
const DOS_2_SIGN_SIGN_POST = "/docs-to-sign/sign"

export const DOCS_2_SIGN = {
  DOS_2_SIGN_GET,
  DOS_2_SIGN_SIGN_POST
}

axiosApi.interceptors.request.use(async function (config) {
  const user = await JSON.parse(localStorage.getItem("authUser"));
  if (user) {
    let bearerToken = user.stsTokenManager.accessToken;
    const diff = user.stsTokenManager.expirationTime - new Date().getTime();
    if (diff < 5 * 60 * 1000 /* 5 minut do końca*/) {
      const refreshedUser = await getFirebaseBackend().reload();
      if (refreshedUser) {
        bearerToken = refreshedUser.stsTokenManager.accessToken;
      } else {
        console.log('interceptor: getFirebaseBackend().reload() - no user found')
      }
    }
    config.headers.Authorization = bearerToken ? `Bearer ${bearerToken}` : '';
    config.headers["x-spirit-id"] = localStorage.getItem("x-spirit-id") || '?';
  }
  return config;
});

export const getAuthHeaders = () => {
  const user = JSON.parse(localStorage.getItem("authUser"));
  if (user) {
    let bearerToken = user.stsTokenManager.accessToken;
    return {
      "Authorization": bearerToken ? `Bearer ${bearerToken}` : '',
      "x-spirit-id": localStorage.getItem("x-spirit-id") || '?'
    }
  }
  return { "x-spirit-id": localStorage.getItem("x-spirit-id") || '?' };
}

axiosApi.interceptors.response.use(
  response => response,
  error => {
    const response = error.response;
    if (response && response.status === 417 && response.data?.message && response.data?.uuid) {
      return Promise.reject(response.data)
    }
    return Promise.reject(error)
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}


export async function postMultipart(url, formData, config = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}) {
  return axiosApi
    .post(url, formData, { ...config })
    .then(response => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}



//db files images
export async function getDbFileImage(fileId, config = {}) {
  const url = '/file/image/' + fileId;
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

//db files pdf
export async function getDbFilePdf(fileId, config = {}) {
  const url = '/file/pdf/' + fileId;
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}


