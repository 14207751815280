import { useTranslation } from "react-i18next"

interface DetailsItemProps {
    labelKey: string,
    value: any;
    inline?: boolean
}

const removeColon = (text: string) => {
    return text.replaceAll(':', '');
}

export const DetailsItem = ({ labelKey, value, inline = true }: DetailsItemProps) => {
    const { t } = useTranslation();

    let className = inline ? 'table-con table-con-inline' : 'table-con'

    return <div className={className}>
        <label className="prevent-select">{removeColon(t(labelKey))}:</label>
        <p className={"prevent-select text-wrap text-break" + (inline?"":" text-center text-justify font-italic py-2")}>{value}</p>
    </div>
}







interface DetailsItemWithHeaderProps {
    labelKey: string,
    valueKey: string,
    value: any;
    
}

export const DetailsItemWithHeader = ({ labelKey, valueKey, value  }: DetailsItemWithHeaderProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="table-con mb-0">
                <label className="w-100 prevent-select">{removeColon(t(labelKey))}</label>
            </div>
            <div className="table-con table-con-inline">
                <label className="prevent-select">{removeColon(t(valueKey))}:</label>
                <p className="prevent-select">{value}</p>
            </div>
        </>
    )
}

