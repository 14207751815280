import LoadingPage from "components/Common/LoadingPage";
import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { ProgressIndicator } from "components/Layout20/ProgressIndicator";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import CustomSelect, { IOption, SELECT_SINGLE_DEFAULT_PLACEHOLDER } from "components/MyComponents/CustomSelect";
import { Field, Form, Formik, FormikProps, useField } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { translateOptions } from "helpers/text_helper";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { PRE_REG_ACTIONS } from "store/auth/pre-registration/actions";
import * as Yup from 'yup';
import RegisterPageWrapper from "../RegisterPageWrapper";
import CorpoItemOption from "../CorpoItemOption";
import { is_in_array } from "helpers/predicate_helper";
import TaxiOrCourierToggle from "../TaxiOrCourierToggle";

const formValidator = Yup.object().shape({
  taxiOrCourierDecision: YUP_VALIDATION.YUP_STRING_REQ_SELECT,
});

interface FormValues {
  taxiOrCourierDecision: string;
}

const RegisterPage2_TaxiOrCourier = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { regUserData, isLoading, error, taxiOrCourierDecisionDomain } = useSelector((state: any) => (
    {
      regUserData: state.PreRegistration.regUserData,
      isLoading: state.PreRegistration.isLoading,
      error: state.PreRegistration.error,
      taxiOrCourierDecisionDomain: state.PreRegistration.taxiOrCourierDecisionDomain,
    })
  );

  const taxiOrCourierDecisionDomainTr = useMemo(() => translateOptions(taxiOrCourierDecisionDomain, t), [taxiOrCourierDecisionDomain, i18n.language]);
  const affiliationKey = useSelector((state: any) => state.AffiliationStore.affiliationKey);

  if (!regUserData?.page2Data) {
    return <RegisterPageWrapper><LoadingPage /></RegisterPageWrapper>
  }

  const onSubmit = (values: FormValues) => {
    dispatch(PRE_REG_ACTIONS.step2StartUpdating(regUserData, regUserData.page2Data, values, affiliationKey));
  };

  const onBack = () => {
    dispatch(PRE_REG_ACTIONS.preRegistrationPageBack())
  };

  const renderForm = (formikBag: FormikProps<FormValues>) => {

    const currentValue = formikBag.values['taxiOrCourierDecision']

    const isSelected = !!currentValue;
    //console.log('isSelected',isSelected)

    return (
      <Form>
        <ProgressIndicator step={2} stepCount={6} />
        <Row className="pb-1">
        <Col xs={12} className="text-center py-3">
          <h5 >  {t("Registration.Page2.TaxiOrCourierDecision")}</h5>
        </Col>
        </Row>
        <div className="register-courier-or-taxi-options">
          {taxiOrCourierDecisionDomainTr.map((item: IOption) => (
            <TaxiOrCourierToggle
              key={item.value}
              option={item}
              isSelected={currentValue === item.value}
              toggleSelection={() => { formikBag.setFieldValue('taxiOrCourierDecision', item.value) }}
            />
          ))
          }
        </div>
        <Col className="py-2 text-center" xs={12}>
        <span >{t("Registration.Page2.TaxiOrCourierDecision.AdditionalComment")}</span>
      </Col>
        <Row className="py-3">
          <Col xs={4} className="px-1"><ButtonCustom
            id="button-register-step-taxi-or-courier-back"
            isLoading={false} label="Registration.Page2.Back" onClick={onBack} /></Col>
          <Col xs={8}>
            <ButtonSubmit
              id="button-register-step-taxi-or-courier"
              disabled={!isSelected}
              isLoading={isLoading} label="Registration.Page2.Submit"
               iconClass="fa-light fa-angles-right fa-lg" className="w-100" /></Col>
        </Row>
      </Form>

    );
  }

  return (<RegisterPageWrapper showLoginLink={false}>
    <AlertFrameError messageKey={error} />
    <Formik enableReinitialize
      initialValues={regUserData.page2Data}
      validationSchema={formValidator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  </RegisterPageWrapper>
  );
};


export default RegisterPage2_TaxiOrCourier; 