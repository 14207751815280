import * as Yup from 'yup';

const STRING_REQUIRED = 'Validation.String.FieldRequired'
const STRING_UP_TO_150 = 'Validation.String.StringToLong150'
const STRING_UP_TO_250 = 'Validation.String.StringToLong250'
const STRING_UP_TO_4000 = 'Validation.String.StringToLong4000'
const STRING_UP_TO_320 = 'Validation.String.StringToLong320'
const REGEXP_ERR_MESSAGE = 'Validation.Regexp'
const STRING_EMAIL = 'Validation.Email'
const STRING_PHONE = 'Validation.Phone'
const STRING_BANK_ACCOUNT = 'Validation.BankAccount'
const STRING_ZIP_CODE = 'Validation.ZipCode'
const STRING_PASSWORD = 'Validation.Password'
const BOOL_CONSENTS_CHECKED_REQUIRED = 'Validation.Bool.Consents.Required'
const BOOL_CHECKED_REQUIRED = 'Validation.Bool.Required'
const STRING_SELECT_REQUIRED = 'Validation.Select.ValueRequired'
const STRING_MULTISELECT_REQUIRED = 'Validation.Select.ValueRequired'
const STRING_ALLOWED_CHARACTERS = 'Validation.LatinOnly'
const REG_EXP_ALLOWED_CHARACTERS = /^[a-zA-Z0-9_.@ąęśćńżźńółĄĘŚĆŃŻŹÓŁ,;\`\~\)\(\[\]\+\=\\\{\}\!\&\$\#\%\^\^\*\'\|\:\?\/\>\<\'\|\\"  -]*$/

const FILES_REQUIRED = 'Validation.FilesList.Required'
const FILES_AT_LEAST_ONE = 'Validation.FilesList.AtLeastOne'

const YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365;

const YUP_DATE = Yup.date().nullable(true);
const YUP_DATE_REQ = Yup.date().nullable(false).required(STRING_REQUIRED);
const YUP_DATE_AT_LEAST_16Y_OLD = Yup.date().nullable(false).required(STRING_REQUIRED).max(new Date(Date.now() - 16 * YEAR_IN_MS), 'Validation.Date.AtLeast16YO');

const YUP_BIRTH_DATE = Yup.date().nullable().notRequired().default(undefined).max(new Date(Date.now() - 16 * YEAR_IN_MS), 'Validation.Date.AtLeast16YO');

const YUP_DATE_AT_LEAST_18Y_OLD = Yup.date().nullable(false).required(STRING_REQUIRED).max(new Date(Date.now() - 18 * YEAR_IN_MS), 'Validation.Date.AtLeast18YO');
const YUP_DATE_BEFORE_NOW = Yup.date().nullable(false).required(STRING_REQUIRED).max(new Date(Date.now()), 'Validation.Date.BeforeNow');
const YUP_DATE_AFTER_NOW = Yup.date().nullable(false).required(STRING_REQUIRED).min(new Date(Date.now()), 'Validation.Date.AfterNow');
const YUP_DATE_AFTER_NOW_OR_EMPTY = Yup.date().nullable(true).min(new Date(Date.now()), 'Validation.Date.AfterNow');


const YUP_STRING = Yup.string().matches(REG_EXP_ALLOWED_CHARACTERS, STRING_ALLOWED_CHARACTERS);
const YUP_STRING_ALL = Yup.string().matches(REG_EXP_ALLOWED_CHARACTERS, STRING_ALLOWED_CHARACTERS);
const YUP_STRING_PASS = YUP_STRING.required(STRING_REQUIRED).min(6, STRING_PASSWORD);
const YUP_STRING_REQ = YUP_STRING.required(STRING_REQUIRED);

const YUP_STRING_ANY_CHARS_REQ = Yup.string().required(STRING_REQUIRED);


const YUP_STRING_REQ_MAX_150 = YUP_STRING.required(STRING_REQUIRED).max(150, STRING_UP_TO_150);
const YUP_STRING_REQ_MAX_250 = YUP_STRING.required(STRING_REQUIRED).max(250, STRING_UP_TO_250);
const YUP_STRING_MAX_4000 = YUP_STRING.max(4000, STRING_UP_TO_4000);
const YUP_STRING_REQ_EMAIL = YUP_STRING.email(STRING_EMAIL).required(STRING_REQUIRED).max(320, STRING_UP_TO_320)

const REG_EXP_PHONE = /^\d{9}$/
const YUP_STRING_REQ_PHONE = Yup.string().matches(REG_EXP_PHONE, STRING_PHONE).required(STRING_REQUIRED)

const REG_EXP_PHONE_PREFIX = /^[+]\d{2}$/
const YUP_STRING_REQ_PHONE_PREFIX = Yup.string().matches(REG_EXP_PHONE_PREFIX, STRING_PHONE).required(STRING_REQUIRED)
const YUP_STRING_PHONE_PREFIX = Yup.string().matches(REG_EXP_PHONE_PREFIX, STRING_PHONE)

const REG_EXP_PHONE_SUFFIX = /^\d{9,10}$/
const YUP_STRING_REQ_PHONE_SUFFIX = Yup.string().matches(REG_EXP_PHONE_SUFFIX, STRING_PHONE).required(STRING_REQUIRED)
const YUP_STRING_PHONE_SUFFIX = Yup.string().matches(REG_EXP_PHONE_SUFFIX, STRING_PHONE)


const REG_EXP_BANK_ACCOUNT = /^\s*(\d\s*){26}$|^\s*PL\s*(\d\s*){26}$|^\s*LT\s*(\d\s*){18}$/
const YUP_BANK_ACCOUNT_REQUIRED = Yup.string().matches(REG_EXP_BANK_ACCOUNT, STRING_BANK_ACCOUNT).required(STRING_REQUIRED)
const YUP_BANK_ACCOUNT = Yup.string().matches(REG_EXP_BANK_ACCOUNT, STRING_BANK_ACCOUNT);


const REG_EXP_ZIP_CODE = /^\d{2}[-]\d{3}$/
const YUP_STRING_ZIP_CODE = Yup.string().matches(REG_EXP_ZIP_CODE, STRING_ZIP_CODE).required(STRING_REQUIRED)
const YUP_STRING_ZIP_CODE_NOT_REQUIRED = Yup.string().matches(REG_EXP_ZIP_CODE, STRING_ZIP_CODE)
const YUP_BOOL = Yup.bool();
const YUP_Y_N = YUP_STRING_REQ.oneOf(['Y', 'N'])

const YUP_BOOL_TRUE_REQUIRED = Yup.bool().oneOf([true], BOOL_CHECKED_REQUIRED)
const YUP_CONSENTS = Yup.bool().oneOf([true], BOOL_CONSENTS_CHECKED_REQUIRED)
const YUP_STRING_REQ_SELECT = Yup.string().nullable(false).required(STRING_SELECT_REQUIRED)
const YUP_STRING_REQ_MULTISELECT = Yup.array().required(STRING_MULTISELECT_REQUIRED)
const YUP_REGEXP = (regexpFormula) => Yup.string().matches(regexpFormula, REGEXP_ERR_MESSAGE);
const YUP_REGEXP_REQUIRED = (regexpFormula) => Yup.string().matches(regexpFormula, REGEXP_ERR_MESSAGE).required(STRING_REQUIRED);
const YUP_OBJ = Yup.object();

const YUP_FILES_REQUIRED = Yup.array().min(1, FILES_AT_LEAST_ONE).required(FILES_REQUIRED)
const YUP_ARRAY = Yup.array();



const MSG_MONEY_OVER_ZERO_INVALID = 'Validation.MoneyInvalidOverZeroRequired'
const MSG_MONEY_INVALID = 'Validation.MoneyInvalid'
const MSG_NOT_ZERO = 'Validation.NotZero'

const REG_MONEY = /^\d+(\.\d{0,2})?$/
const REG_MONEY_ALOW_MINUS = /^[-]?\d+(\.\d{0,2})?$/


  const YUP_MONEY = Yup.string().nullable(true).matches(REG_MONEY, MSG_MONEY_OVER_ZERO_INVALID);
  const YUP_MONEY_REQUIRED = Yup.string().required(STRING_REQUIRED).matches(REG_MONEY, MSG_MONEY_OVER_ZERO_INVALID);
  const YUP_MONEY_REQUIRED_ALOW_MINUS = Yup.string().required(STRING_REQUIRED).matches(REG_MONEY_ALOW_MINUS, MSG_MONEY_INVALID);
  const YUP_MONEY_REQUIRED_NON_ZERO = Yup.string().required(STRING_REQUIRED)
.matches(REG_MONEY, MSG_MONEY_OVER_ZERO_INVALID)
.notOneOf(['0','0.00','0.0','.0'],MSG_NOT_ZERO);

export const YUP_VALIDATION = {
    YUP_OBJ,
    YUP_BOOL,
    YUP_MONEY,
    YUP_MONEY_REQUIRED,
    YUP_MONEY_REQUIRED_ALOW_MINUS,
    YUP_MONEY_REQUIRED_NON_ZERO,
    YUP_STRING,
    YUP_Y_N,
    YUP_ARRAY,
    YUP_REGEXP,
    YUP_REGEXP_REQUIRED,
    YUP_STRING_REQ,
    YUP_STRING_ZIP_CODE,
    YUP_STRING_ZIP_CODE_NOT_REQUIRED,
    YUP_STRING_REQ_MAX_150,
    YUP_STRING_REQ_MAX_250,
    YUP_STRING_MAX_4000,
    YUP_STRING_REQ_EMAIL,
    YUP_STRING_REQ_PHONE,
    YUP_STRING_REQ_PHONE_PREFIX,
    YUP_STRING_REQ_PHONE_SUFFIX,
    YUP_STRING_PHONE_PREFIX,
    YUP_STRING_PHONE_SUFFIX,
    YUP_STRING_REQ_SELECT,
    YUP_STRING_REQ_MULTISELECT,
    YUP_STRING_ANY_CHARS_REQ,
    YUP_CONSENTS,
    YUP_BOOL_TRUE_REQUIRED,
    YUP_STRING_PASS,
    YUP_FILES_REQUIRED,
    YUP_DATE_AT_LEAST_16Y_OLD,
    YUP_DATE_AT_LEAST_18Y_OLD,
    YUP_DATE_BEFORE_NOW,
    YUP_DATE_AFTER_NOW,
    YUP_DATE_AFTER_NOW_OR_EMPTY,
    YUP_DATE_REQ,
    YUP_DATE,
    YUP_BIRTH_DATE,
    YUP_BANK_ACCOUNT_REQUIRED,
    YUP_BANK_ACCOUNT

}