const PREFIX = "AFI_"

const SET_UP_AFFILIATION = PREFIX + "SET_UP_AFFILIATION";
const setUpAffiliation = (affiliationKey: string) => {
    //console.log('setUpAffiliation',affiliationKey)
    return ({ type: SET_UP_AFFILIATION, payload: { affiliationKey: affiliationKey } });
}

export const AFFILIATION_ACT = {
    setUpAffiliation
}

export const AFFILIATION_ACT_TYP = {
    SET_UP_AFFILIATION
}