import { AFFILIATION_LINK_ACT_TYP } from "./actionsAffiliationLink";

export interface IAffiliationLinkReducer {
    gridData: any[],
    context: {
        bonusAmount: number,
        bonusCount: number,
        waiting: number,
    },
    isLoading: false,
    reloadRequired: true,

    areChangesInProgress: boolean,
    phoneNumber: string,
    error: string,
    info: string,
}

const INIT_STATE: IAffiliationLinkReducer = {
    gridData: [],
    context: {
        bonusAmount: 0,
        bonusCount: 0,
        waiting: 0,
        
    },
    isLoading: false,
    reloadRequired: true,

    areChangesInProgress: false,
    phoneNumber: '',
    error: '',
    info: '',
};

const AffiliationLinkStore = (state = INIT_STATE, action: { type: any, payload: any }) => {

    switch (action.type) {
        case AFFILIATION_LINK_ACT_TYP.LOAD_DATA:
            return { ...state, isLoading: true, error: '' };
        case AFFILIATION_LINK_ACT_TYP.LOAD_DATA_DONE:
            return { ...state, isLoading: false, reloadRequired: false, error: action.payload.error, gridData: action.payload.gridData, context: action.payload.context, info: action.payload.info };
        case AFFILIATION_LINK_ACT_TYP.API_ERROR:
            return { ...state, isLoading: false, error: action.payload.error, };

        case AFFILIATION_LINK_ACT_TYP.SEND_AFFILIATION_LINK:
            return { ...state, error: '', info: '', areChangesInProgress: true };
        case AFFILIATION_LINK_ACT_TYP.SEND_AFFILIATION_LINK_DONE:
            return { ...state, info: action.payload.info, areChangesInProgress: false, phoneNumber: '' };
        case AFFILIATION_LINK_ACT_TYP.SEND_AFFILIATION_LINK_ERROR:
            return { ...state, error: action.payload.error, areChangesInProgress: false };
        default:
            return state;
    }
}


export default AffiliationLinkStore;