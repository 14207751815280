import PropTypes from "prop-types";
import { Col } from "reactstrap";

import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import HorizontalDivider from "components/MyComponents/HorizontalDivider";
import ContactFormPageContent from "pages/ContactForm/ContactFormPageContent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Unauth20Wrapper } from "./Unauth20Wrapper";

const ContactFormPageUnlogged = props => {

  const contactFormStore = useSelector((state) => state.ContactFormStore);
  const { t } = useTranslation();

  return (
    <Unauth20Wrapper>
      <div className="form-title">
        <h2 className="text-md-start"><span className="blue">{t("ContactForm.Page.Header")} </span></h2>
      </div>
      <AlertFrameError messageKey={contactFormStore.error} />
      <ContactFormPageContent />
      
      <HorizontalDivider labelKey="separator.or" className="text-uppercase" />
      <Col xs={12} className="pt-2">
        <div className="signin-btn">
          <Link to="/login" >{t("Login.LogInNow")}</Link>
        </div>
      </Col>

    </Unauth20Wrapper >
  );
};

ContactFormPageUnlogged.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ContactFormPageUnlogged);
