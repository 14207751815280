import { IProfileRemoval } from "./reducerProfileRemoval";

const PREFIX = "DEL_"

const REMOVE_PROFILE = PREFIX + "REMOVE_PROFILE";
const removeProfile = (profileRemoval: IProfileRemoval, history: any) => ({
    type: REMOVE_PROFILE, payload: {profileRemoval, history}
});

const REMOVE_PROFILE_DONE = PREFIX + "REMOVE_PROFILE_DONE";
const removeProfileDone = () => ({
    type: REMOVE_PROFILE_DONE, payload: {}
});

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}
 
export const PROFILE_REMOVAL_ACT = {
    removeProfile, removeProfileDone,
    apiError
}

export const PROFILE_REMOVAL_ACT_TYP = {
    REMOVE_PROFILE, REMOVE_PROFILE_DONE,
    API_ERROR
}