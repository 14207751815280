import PropTypes from "prop-types";
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link, withRouter } from "react-router-dom";

// Formik Validation
import { useFormik } from "formik";
import * as Yup from "yup";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { AlertFrameInfo } from "components/Common/datatable/AlertFrameInfo";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { useTranslation } from "react-i18next";
import { Unauth20Wrapper } from "./Unauth20Wrapper";

const ForgetPasswordPage = props => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: YUP_VALIDATION.YUP_STRING_REQ_EMAIL,
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history));
    }
  });

  const { forgetError, forgetSuccessMsg, isLoading } = useSelector(state => ({
    isLoading: state.ForgetPassword.isLoading,
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  return (<Unauth20Wrapper>
    <div className="form-title">
      <h2 className="text-md-start"><span className="blue">{t("ForgetPassword.Top")} </span></h2>
    </div>

    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >

      <AlertFrameError messageKey={forgetError} />
      <AlertFrameInfo messageKey={forgetSuccessMsg} />

      <div className="mb-3">
        <Label className="form-label">Email</Label>
        <Input name="email" className="form-control" placeholder="" type="email"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.email || ""}
          invalid={
            validation.touched.email && validation.errors.email ? true : false
          }
        />
        {validation.touched.email && validation.errors.email ? (
          <FormFeedback type="invalid">{t(validation.errors.email)}</FormFeedback>
        ) : null}
      </div>
      <Row>
        <Col>
          <ButtonSubmit className="fw-bold" isLoading={isLoading} label="ForgetPassword.Submit.Button" id="forget-pass-submit-button" fullWidth={true} />
        </Col>
      </Row>
    </Form>
    <Col xs={12} className="pt-5">
      <div className="signin-btn">
        <Link to="/login" >{t("Login.LogInNow")}</Link>
      </div>
    </Col>
  </Unauth20Wrapper>

  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
