import DashboardSubDialog_00_NotificationsList from "./DashboardSubDialog_00_NotificationsList";
import DashboardSubDialog01Summary from "./DashboardSubDialog_01_Summary";
import DashboardSubDialog02AwaitingSettlements from "./DashboardSubDialog_02_AwaitingSettlements";
import DashboardSubDialog_03_PaymentsHistory from "./DashboardSubDialog_03_PaymentsHistory";

const DashboardLayoutMobile = () => {
  return (
    <div className="dashboard-layout-mobile">
      <DashboardSubDialog_00_NotificationsList/>
      <DashboardSubDialog01Summary />
      <DashboardSubDialog02AwaitingSettlements />
      <DashboardSubDialog_03_PaymentsHistory />
    </div>
  )
};


export default DashboardLayoutMobile; 