import { useWindowSize } from "@react-hook/window-size";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import { getMoney } from "helpers/money";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import "./settlement.scss";
import { SETTLEMENT_LIST_ACT } from "./store/actionsSettList";

const SettlmentLumpTaxPercent = () => {

  const { t } = useTranslation();
  const settlementListStore = useSelector((state: any) => state.SettlementListStore);
  const dispatch = useDispatch();
  const [width] = useWindowSize()

  if (
    settlementListStore.isLoading ||
    !settlementListStore.context.leaseLumpSumTaxInfo ||
    settlementListStore.context.leaseLumpSumTaxInfo.paidDate ||
    settlementListStore.context?.leaseLumpSumTaxInfo?.taxToPay === 0) {
    return <></>
  }

  const showInline = width >= 768;
  const handlePaidLumpTax = () => {
    dispatch(SETTLEMENT_LIST_ACT.lumpTaxPaid(settlementListStore.context.leaseLumpSumTaxInfo.id))
  }

  return (
    <DialogWrapper title="SETT_LIST.mobile.lump-tax.summary">
      <Row className="apps-chart-row">
        <>
          <Col className="flex-div px-0">
            <div className="con-box">
              <span>{t('SETT_LIST.mobile.lump-tax.to-pay')}</span>
              <h3>{getMoney(settlementListStore.context.leaseLumpSumTaxInfo.taxToPay)}</h3>
            </div>
            <div className="con-box">
              <span>{t('SETT_LIST.mobile.lump-tax.period')}</span>
              <h3>{settlementListStore.context.leaseLumpSumTaxInfo.period}</h3>
            </div>

            {showInline &&
              <ButtonCustom id="lump-tax-already-paid-submit"
                className="px-3"
                label="SETT_LIST.mobile.lump-tax.button-already-paid"
                onClick={handlePaidLumpTax}
                iconClassPre="fa-solid fa-check fa-xl pe-1"
              />
            }
          </Col>
          {(!showInline) &&
            <Col xs={12} className="py-3 px-0">
              <ButtonCustom id="lump-tax-already-paid-submit"
                className="w-100"
                label="SETT_LIST.mobile.lump-tax.button-already-paid"
                iconClassPre="fa-solid fa-check fa-xl pe-1"
                onClick={handlePaidLumpTax}
              />
            </Col>
          }
        </>
      </Row>
    </DialogWrapper>
  )
}

export default SettlmentLumpTaxPercent; 