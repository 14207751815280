import {
  LOGIN_USER_START, LOGIN_AUTHENTICATED, LOGIN_AUTHORIZED, LOGIN_USER_FINISHED,
  LOGOUT_USER, LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_REAUTHORIZE,
} from "./actionTypes"

const initialState = {

  //utility
  loading: false,
  reauthenticating: false,
  isAccessGranted: false,
  error: "",
  //authentication
  email: null,
  pictureUrl: null,

  //authorization
  availableRoles: [],
  companyId: '',
  companyName: '',
  affLinkIdentifier: '',
  extDocsToSignCount: 0,
  suggestedApplications:[],
  userFullName: '',
  contractorId: '',
  refreshSessionIntervalId: null
}

const LoginState = (state = initialState, action) => {

  switch (action.type) {

    case LOGIN_USER_START:
      state = { ...state, loading: true }
      break
    case LOGIN_AUTHENTICATED:
      state = { ...state, email: action.payload.email, pictureUrl: action.payload.pictureUrl, refreshSessionIntervalId: action.payload.refreshSessionIntervalId }
      break
    case LOGIN_REAUTHORIZE:
      state = {
        ...state,
        reauthenticating: true,
        email: action.payload.storedAuthData.email,
        pictureUrl: action.payload.storedAuthData.pictureUrl
      }
      break
    case LOGIN_AUTHORIZED:
      localStorage.setItem("x-spirit-id", `${action.payload.companyId}#${action.payload.contractorId}`);     
      state = {
        ...state,
        availableRoles: action.payload.availableRoles,
        companyId: action.payload.companyId,
        companyName: action.payload.companyName,
        affLinkIdentifier: action.payload.affLinkIdentifier,
        extDocsToSignCount: action.payload.extDocsToSignCount,
        suggestedApplications: action.payload.suggestedApplications,
        userFullName: action.payload.userFullName,        
        contractorId: action.payload.contractorId,
        isAccessGranted: true,
        loading: false, reauthenticating: false, error: '',
      }
      break
    case LOGOUT_USER:
      state = { ...state}
      break
    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        error: '',
        userData: null,
        isAccessGranted: false,
        reauthenticating: false,

        email: null,
        pictureUrl: null,
        availableRoles: [],
        companyId: '',
        companyName: '',
        affLinkIdentifier: '',
        extDocsToSignCount: 0,
        suggestedApplications:[],
        userFullName: '',
        contractorId: '',
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload,  userData: null, isAccessGranted: false, reauthenticating: false, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default LoginState
