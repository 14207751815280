import ButtonCustom from "../ButtonCustom";
import PdfModalViewer from "./ResourceModal";
import useToggle from "./useResourceModal";



interface ButonCustomPdfModalProps {
    id: string;
    label: string;
    pdfFileId: number;
    pdfFileName: string;
}

export const ButonCustomPdfModal = ({ id, label, pdfFileId, pdfFileName }: ButonCustomPdfModalProps) => {

    const { toggleValue, changeToggle } = useToggle();


    return (
        <>
            <PdfModalViewer showModal={toggleValue} close={changeToggle} pdfFileId={pdfFileId} pdfFileName={pdfFileName} />
            <ButtonCustom
                id={id}
                label={label}
                iconClassPre="fa-duotone fa-file-pdf"
                onClick={ changeToggle }
            />
        </>
    )
}