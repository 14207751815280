const STATUS_W4C = 'N';
const STATUS_VER = 'V';
const STATUS_REJ = 'R';
const STATUS_CNC = 'C';
const STATUS_WRN = 'W';


const isDeletable = (status: string) => {
    return status === STATUS_W4C || status === STATUS_CNC
}

const getStatusIcon = (status: string, expiring: boolean, expired: boolean) => {


    if (  expired) {
        return <i className="att-EXP bx bx-minus-circle"></i>
    }

    let expStatus =  expiring ? "att-WAR bx-tada" : "att-VER";

    switch (status) {
        case STATUS_VER:
            return <i className={expStatus + " bx bx-check-circle"}></i>
        case STATUS_W4C:
            return <i className="att-W4C bx bx-help-circle"></i>
        case STATUS_REJ:
            return <i className="att-REJ bx bx-error-circle"></i>
        case STATUS_CNC:
            return <i className="att-CNC bx bx-x-circle"></i>
        case STATUS_WRN:
            return <i className="att-WRN bx bx-x-circle"></i>
        default:
            return <i className="unknown-icon bx bx-dizzy"></i>
    }
}

export const ATTACHMENT_CONF = {
    getStatusIcon,
    isDeletable,
    STATUS_W4C,
    STATUS_VER,
    STATUS_REJ,
    STATUS_WRN,
    STATUS_CNC
}