import LoadingPage from "components/Common/LoadingPage";
import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonTripple from "components/MyComponents/ButtonTripple";
import { IOption } from "components/MyComponents/CustomSelect";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { AGREEMENT_ACTION } from "pages/Agreement/store-pre/actionsAgr";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ROUTE_AGREEMENT_P2_FORM__URL_PARAMS } from "routes";
import { FORM_ADDRESS_DATA_BASIC, FORM_ADDRESS_DATA_BASIC_SECOND_NOT_REQUIRED, FORM_ADDRESS_DATA_EXTENDED, FORM_QUESTIONS_ALG_AC, FORM_QUESTIONS_QA } from "./CustomForms";
import { getDynamicFormData, getDynamicFormValidation } from "./FormItems/DynamicFormsHelper";
import GenericFormItem, { isFocusableField } from "./FormItems/GenericFormItem";
import { IAgreementFormItem, getMdSize, getPageConfig, getPageData, get_single_value } from "./IAgreement";
import AgreementPage2FormAdressData from "./customFormAddressData/AgreementPage2FormAdressData";
import AgreementPage2FormAdressDataSecond from "./customFormAddressData/AgreementPage2FormAdressDataSecond";
import AgreementPage2FormQuestions from "./customFormQuestions/AgreementPage2FormQuestions";

const AgreementPage2Form = () => {

  const history = useHistory();
  const { agreementType, pageKey } = useParams<ROUTE_AGREEMENT_P2_FORM__URL_PARAMS>();

  const isLoading = useSelector((state: any) => state.AgreementSignStore.isLoading);
  const error = useSelector((state: any) => state.AgreementSignStore.error);
  const agreementDataLoaded = useSelector((state: any) => state.AgreementSignStore.agreementDataLoaded);
  const pageConfig = useSelector((state: any) => getPageConfig(state.AgreementSignStore.agreementConfig, pageKey));
  const pageData = useSelector((state: any) => getPageData(state.AgreementSignStore.agreementData.pageData, pageKey));
  const allData = useSelector((state: any) => state.AgreementSignStore.agreementData.allData);
  const dictionaries = useSelector((state: any) => state.AgreementSignStore.dictionaries);

  const initialValues = useMemo(() => getDynamicFormData(pageConfig.fields, pageData.values), [pageConfig]);
  const validationSchema = useMemo(() => getDynamicFormValidation(pageConfig, allData), [pageConfig]);


  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    //data fetch for reload 
    if (!agreementDataLoaded && error === '') {
      dispatch(AGREEMENT_ACTION.getAgreementConfigAndData(agreementType, pageKey, history, false))
    }
  }, [agreementDataLoaded, error])

  useEffect(() => {
    window.scrollTo(0, 70)
  }, [])


  if (!agreementDataLoaded && error === '') {
    return <Container fluid>
      <DialogWrapper title="">
        <LoadingPage />
      </DialogWrapper>
    </Container>
  }

  const onSubmit = (values: any) => {
    dispatch(AGREEMENT_ACTION.saveData(pageKey, pageConfig.pageNextKey, agreementType, values, history));
  };

  const onClickList = () => {
    dispatch(AGREEMENT_ACTION.getAgreementConfigAndData(agreementType, null /*domyślnie wizard*/, history, false));
  };

  const onClickBack = () => {
    if (pageConfig.pagePrevKey) {
      dispatch(AGREEMENT_ACTION.openFormPage(pageConfig.pagePrevKey, agreementType, history))
    } else {
      onClickList();
    }
  };

  const isDependentRelationFullfilled = (formikBag: FormikProps<FormikValues>, formItem: IAgreementFormItem) => {
    if (!formItem.dependentOn) {
      return true;
    }

    let fieldMetaValue = '';
    if (formikBag.values.hasOwnProperty(formItem.dependentOn)) {
      fieldMetaValue = '' + formikBag.getFieldMeta(formItem.dependentOn).value as string;
      //console.log('1a fieldMetaValue:'+formItem.dependentOn,fieldMetaValue)
    } else {
      fieldMetaValue = '' + get_single_value(allData[formItem.dependentOn], formItem.name)
      //console.log('1b fieldMetaValue:'+formItem.dependentOn,fieldMetaValue)
    }

    if (formItem.dependentOnEqual) {
      //console.log('2a fieldMetaValue:'+formItem.dependentOn + 'formItem.dependentOnEqual: '+formItem.dependentOnEqual,fieldMetaValue);
      //console.log('3a fieldMetaValue:'+formItem.dependentOn + 'formItem.dependentOnEqual: '+formItem.dependentOnEqual,fieldMetaValue == formItem.dependentOnEqual);
      return '' + fieldMetaValue == '' + formItem.dependentOnEqual;
    }
    if (formItem.dependentOnNotEqual) {
      //console.log('2b fieldMetaValue:'+formItem.dependentOn + 'formItem.dependentOnNotEqual: '+formItem.dependentOnNotEqual,fieldMetaValue);
      //console.log('3b fieldMetaValue:'+formItem.dependentOn + 'formItem.dependentOnNotEqual: '+formItem.dependentOnNotEqual,fieldMetaValue != formItem.dependentOnNotEqual);
      return '' + fieldMetaValue != '' + formItem.dependentOnNotEqual;
    }
    return false;
  }

  const renderForm = (formikBag: FormikProps<FormikValues>) => {

    if (!pageConfig.customForm) {
      return renderStandardForm(formikBag);
    }

    if (
      pageConfig.customForm === FORM_ADDRESS_DATA_BASIC ||
      pageConfig.customForm === FORM_ADDRESS_DATA_EXTENDED) {
      return <AgreementPage2FormAdressData key='address-data-form'
        isLoading={isLoading}
        pageConfig={pageConfig}
        dictionaries={dictionaries}
        onClickBack={onClickBack} onClickList={onClickList} />
    }
    if (
      pageConfig.customForm === FORM_ADDRESS_DATA_BASIC_SECOND_NOT_REQUIRED) {
      return <AgreementPage2FormAdressDataSecond key='address-data-form'
        isLoading={isLoading}
        error={error}
        pageConfig={pageConfig}
        dictionaries={dictionaries}
        onClickBack={onClickBack} onClickList={onClickList} />
    }




    if (pageConfig.customForm === FORM_QUESTIONS_QA || pageConfig.customForm === FORM_QUESTIONS_ALG_AC) {
      return <AgreementPage2FormQuestions 
        key='questions-form'
        isLoading={isLoading}
        pageConfig={pageConfig}
        onClickBack={onClickBack} onClickList={onClickList} />
    }


    return <>{pageConfig.customForm}</>
  };

  const translateLabels = (options: IOption[]) => {
    options.forEach(option => {
      if (option.labelKey) {
        option.label = t(`${pageKey}.${option.labelKey}`);
      }
    })
    return options;
  }

  const getOptions = (formItem: IAgreementFormItem) => {

    if (formItem.domain) {
      return dictionaries[formItem.domain]
    }

    if (formItem.domainDirect) {
      return translateLabels(formItem.domainDirect)
    }

    return undefined;

  }

  let foundFirstFocusable = false;

  const renderStandardForm = (formikBag: FormikProps<FormikValues>) => {
    return (
      <Form autoComplete="off" autoCorrect="off" id={"custom-form-" + pageKey} key={pageKey + "custom-form"} >
        <Row>
          {pageConfig.fields.map((formItem: IAgreementFormItem, key: number) => {
            if (!isDependentRelationFullfilled(formikBag, formItem)) {
              return <div key={pageKey + '#' + formItem.name}></div>
            }


            if (key === 0) {
              foundFirstFocusable = false;
            }

            let autoFocus = false;
            if (!foundFirstFocusable) {
              if (isFocusableField(formItem.name, formItem.kind)) {
                autoFocus = true;
                foundFirstFocusable = true;
              }
            }


            return (
              <Col xs={12} md={getMdSize(formItem)} key={pageKey + '#' + formItem.name}>
                <GenericFormItem
                  //autoFocus={autoFocus}
                  options={getOptions(formItem)}
                  formItem={formItem}
                  pageKey={pageKey}
                />
              </Col>
            )
          }
          )
          }
        </Row>
        <ButtonTripple
          id={`button-wizard-${pageKey}`}
          isLoading={isLoading}
          label={pageKey + ".Submit"}
          onClickBack={onClickBack}
          onClickList={onClickList}
        />
      </Form>
    )
  }
  return (
    <Container>
      <DialogWrapper title="">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={renderForm}
          onSubmit={onSubmit}
        />
      </DialogWrapper>
    </Container>
  );
};

export default AgreementPage2Form; 