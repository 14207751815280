const PREFIX = "NLC_"

const CONTEXT_SET = PREFIX + "CONTEXT_SET";
const contextSet = (notificationContext: any, info: string) => ({
    type: CONTEXT_SET, payload: { notificationContext, info }
});

const LOAD_DATA = PREFIX + "LOAD_DATA";
const loadData = (prefferedNotificationId: any) => ({ type: LOAD_DATA, payload: {prefferedNotificationId} });

const LOAD_DATA_DONE = PREFIX + "LOAD_DATA_DONE";
const loadDataDone = (gridData: any[], info: string) => ({
    type: LOAD_DATA_DONE, payload: { gridData, info }
});

const BUTTON_CLICKED = PREFIX + "BUTTON_CLICKED";
const buttonClicked = (notificationDTO: any, history: any) => ({ type: BUTTON_CLICKED, payload: {notificationDTO, history} });

const BUTTON_CLICKED_MARKED = PREFIX + "BUTTON_CLICKED_MARKED";
const buttonClickedMarked = (notificationId: number, markedAsButtonClicked: string ) => ({
    type: BUTTON_CLICKED_MARKED, payload: { notificationId, markedAsButtonClicked }
});

const MARK_PRESENTED = PREFIX + "MARK_PRESENTED";
const markAsPresented = (notificationId: number) => ({ type: MARK_PRESENTED, payload: {notificationId} });

const MARK_PRESENTED_DONE = PREFIX + "MARK_PRESENTED_DONE";
const markAsPresentedDone = (notificationId: number, markedAsPresented: string ) => ({
    type: MARK_PRESENTED_DONE, payload: { notificationId, markedAsPresented }
});

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const NOTIFICATIONS_LIST_ACT = {    
    contextSet,
    loadData, loadDataDone,
    buttonClicked, buttonClickedMarked, 
    markAsPresented, markAsPresentedDone,
    apiError
}

export const NOTIFICATIONS_LIST_ACT_TYP = {
    CONTEXT_SET,
    LOAD_DATA, LOAD_DATA_DONE,
    BUTTON_CLICKED, BUTTON_CLICKED_MARKED,
    MARK_PRESENTED, MARK_PRESENTED_DONE,
    API_ERROR
}