import imageCompression from 'browser-image-compression';

export function isImage(contentType) {
  return contentType.startsWith('image');
}

export async function compressImage(imageFile) {

  const options = {
    maxSizeMB: 0.75,
    maxWidthOrHeight: 2048,
    useWebWorker: true,
    exifOrientation: 1
  }
  try {
    return await imageCompression(imageFile, options);
  } catch (error) {
    console.log('compressImage', error);
    return imageFile;
  }

}
