import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import CustomSelect, { IOption, SELECT_SINGLE_DEFAULT_PLACEHOLDER } from "components/MyComponents/CustomSelect";
import HorizontalDivider from "components/MyComponents/HorizontalDivider";
import { Field, Form, Formik, FormikValues } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { translateOptions } from "helpers/text_helper";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { PROFILE_ACT } from "./store/actionsProfile";
import { IProfile } from "./store/reducerProfile";
import { DialogWrapper } from "components/Layout20/DialogWrapper";

const PROFILE_CYCLE_VALIDATOR = YUP_VALIDATION.YUP_OBJ.shape({
  settlementCycle: YUP_VALIDATION.YUP_STRING_REQ_SELECT,
  settlementCycleNextMonth: YUP_VALIDATION.YUP_STRING_REQ_SELECT,
});

const SETTLEMNT_CYCLE_DICT: IOption[] = [
  {
    "label": "CSV",
    "value": "CSV",
    "labelKey": "Profile.Data.SettlementCycle.CSV"
  },
  {
    "label": "4W",
    "value": "4W",
    "labelKey": "Profile.Data.SettlementCycle.4W"
  }, {
    "label": "2W",
    "value": "2W",
    "labelKey": "Profile.Data.SettlementCycle.2W"
  }]


const ProfilePage_04_SettlementConfig = () => {
  const dispatch = useDispatch();
  const profileStore = useSelector((state: any) => state.ProfileStore);

  const { t, i18n } = useTranslation();
  const cycleDomainTr = useMemo(() => translateOptions(SETTLEMNT_CYCLE_DICT, t), [SETTLEMNT_CYCLE_DICT, i18n.language]);

  if (!profileStore.profile.settlementCycleModificationEnabled) {
    return <></>
  }

  const onSubmit = (values: FormikValues) => {
    dispatch(PROFILE_ACT.setProfileCycle(values as IProfile));
  };

  return (
    <DialogWrapper title="Profile.Data.SettlementsDivider" dialogVisibilityKey="profileSettement">
      <Formik
        initialValues={profileStore.profile}
        validationSchema={PROFILE_CYCLE_VALIDATOR}
        onSubmit={onSubmit}
      >
        <Form>           
          <Row>
            <Col xs={12} md={4}>
              <Field name="settlementCycle" label="Profile.Data.SettlementCycle"
                placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
                options={cycleDomainTr} className="custom-select"
                component={CustomSelect}
                disabled={true} />
            </Col>
            <Col xs={12} md={4}  >
              <Field name="settlementCycleNextMonth" label="Profile.Data.SettlementCycleNextMonth"
                placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
                options={cycleDomainTr} className="custom-select"
                component={CustomSelect}
                disabled={profileStore.areChangesInProgressCycle} />
            </Col>
            <Col xs={12} md={4} className="inline-buttons input-box">
              <ButtonSubmit
                id="button-profile-settlement-cycle-change"
                isLoading={profileStore.areChangesInProgressCycle}
                label="Profile.SettlementCycle.Submit"
                iconFileSrc="/img/figma/CardSend.svg" />
            </Col>
          </Row>
        </Form>
      </Formik>
    </DialogWrapper>

  );
};


export default ProfilePage_04_SettlementConfig; 