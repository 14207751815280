import { ICompoundFile } from "components/MyComponents/custom-dropzone/FileUploader";
import { ATTACHMENT_NEW_ACT_TYP } from "./actionsAttNew";

export interface IDocumentTypeConfig {
    id: string,
    documentTypeName: string,
    expirationDateRequired: boolean,
    releaseDateRequired: boolean,
    valueRequired: boolean,
}
const DEFAULT_EMPTY_DOC: IDocumentTypeConfig = { id: '', documentTypeName: '', expirationDateRequired: false, releaseDateRequired: false, valueRequired: false };

export interface IAttachmentData {
    expirationDate: string,
    releaseDate: string,
    value: string,
    files: ICompoundFile[],
}
export const DEFAULT_EMPTY_ATT: IAttachmentData = { expirationDate: '', releaseDate: '', value: '', files: [] };

export interface IAttachmentNewReducer {
    documentTypeConfig: IDocumentTypeConfig,
    attachmentData: IAttachmentData,
    isReloadRequired: boolean,
    isLoading: boolean,
    error: string,
    info: string,
}

const INIT_STATE: IAttachmentNewReducer = {
    documentTypeConfig: DEFAULT_EMPTY_DOC,
    attachmentData: DEFAULT_EMPTY_ATT,
    isReloadRequired: true,
    isLoading: false,
    error: '',
    info: '',
};

const AttachmentNewStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case ATTACHMENT_NEW_ACT_TYP.OPEN_NEW:
            return { ...state, isReloadRequired: true, isLoading: false, error: '', info: '', documentTypeConfig: DEFAULT_EMPTY_DOC };

        case ATTACHMENT_NEW_ACT_TYP.PREPARE_NEW:
            return { ...state, isLoading: true, error: '', info: '' };
        case ATTACHMENT_NEW_ACT_TYP.PREPARE_NEW_DONE:
            return {
                ...state,
                isLoading: false,
                isReloadRequired: false,
                documentTypeConfig: action.payload.documentTypeConfig,
                info: action.payload.info,
                error: action.payload.error
            };
        case ATTACHMENT_NEW_ACT_TYP.ADD_ATTACHMENT:
            return { ...state, isLoading: true, error: '', info: '' };
        case ATTACHMENT_NEW_ACT_TYP.ADD_ATTACHMENT_DONE:
            return {
                ...state, isLoading: false, isReloadRequired: true, documentTypeConfig: DEFAULT_EMPTY_DOC, attachmentData: DEFAULT_EMPTY_ATT,
            };
        case ATTACHMENT_NEW_ACT_TYP.API_ERROR:
            return { ...state, isLoading: false, isReloadRequired: false, error: action.payload.error };
        default:
            return state;
    }
}

export default AttachmentNewStore;