import { call, put, select, takeEvery } from "redux-saga/effects";

// LoginState Redux States
import { LOGIN_AUTHORIZE_START, LOGIN_REAUTHORIZE, LOGIN_USER_START, LOGOUT_USER, RELOAD_ROLES } from "./actionTypes";
import { apiError, authorizeStart, loginAuthenticated, loginAuthorized, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { formatDateToYYYYMMDDTHH24_MI_SS } from "helpers/date_helper";
import { NOTIFICATIONS_LIST_ACT } from "pages/NotificationsList/store/actionsNotificationsList";
import { CONFIG_ACT } from "store/configuration/actionsConfig";
import { ENDPOINTS, get } from "../../../helpers/api_helper";
import { removeAuthContextFromStorage } from "../../../helpers/auth_storage_helper";
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { ROUTES } from "../../../routes";
import { getUA, isAndroid, isBrowser, isIOS, isMobile, isMobileOnly, browserName } from "react-device-detect";


const REFRESH_INTERVAL = 30 /*min */ * 60 /*sec*/ * 1000 /*ms*/;

const getDevice = () => {
  if (getUA === 'twinr-app') {
    return 'twinr';
  }
  if (isAndroid) {
    return 'android-' + browserName + (isMobile ? '-mobile' : '');
  }
  if (isIOS) {
    return 'iOS-' + browserName + (isMobile ? '-mobile' : '');
  }

  if (isBrowser) {
    return 'browser-' + browserName + (isMobile ? '-mobile' : '');
  }
  return 'unknown-' + browserName
}



function* loginUser({ payload: { credentials, history, affiliationKey } }) {

  let fbLoginOK = false;
  try {
    //step 1: authentication  
    const firebaseUser = yield call(getFirebaseBackend().loginUser, credentials.email.trim(), credentials.password);
    if (!firebaseUser.multiFactor.user.emailVerified) {
      yield put(apiError('Firebase: Email not verified'));
      return;
    }
    fbLoginOK = true;

    const emailFromFirebase = firebaseUser.multiFactor.user.email;
    const pictureUrlFromFirebase = '';

    //heartbeat
    yield call(get, ENDPOINTS.RESOURCE_HB_LOGIN + '/' + getDevice(), {})

    //ustaw odswiezanie sesji
    const refreshSessionIntervalId = yield call(setInterval, refreshSessionIntervalFunction, REFRESH_INTERVAL);

    //authentication confirmation
    yield put(loginAuthenticated(emailFromFirebase, pictureUrlFromFirebase, refreshSessionIntervalId));

  } catch (error) {
    if (fbLoginOK) {
      yield call(getFirebaseBackend().logout);
      removeAuthContextFromStorage()
    }
    yield put(apiError(error));
    return;
  }

  //step2: authorization
  yield put(authorizeStart(history, affiliationKey));
}

async function refreshSessionIntervalFunction() {

  const authUserString = localStorage.getItem("authUser");
  const user = await JSON.parse(authUserString);
  if (user) {
    const lastLoginAt = formatDateToYYYYMMDDTHH24_MI_SS(new Date(+user.lastLoginAt));
    const expirationTime = formatDateToYYYYMMDDTHH24_MI_SS(new Date(user.stsTokenManager.expirationTime));
    const refreshedUser = await getFirebaseBackend().reload();
    const expirationTimeRefreshed = formatDateToYYYYMMDDTHH24_MI_SS(new Date(refreshedUser.stsTokenManager.expirationTime));
    const device = getDevice();
    console.log(`refreshSessionIntervalFunction device: ${device} lastLoginAt: ${lastLoginAt} expirationTime: ${expirationTime} expirationTimeRefreshed: ${expirationTimeRefreshed}`)
    get(`${ENDPOINTS.RESOURCE_HB_REFRESH}/${device}/${lastLoginAt}/${expirationTime}/${expirationTimeRefreshed}`, {});
  } else {
    console.log(`refreshSessionIntervalFunction device: ${device} no user`);
  }
}


function* reautorize({ payload: { storedAuthData } }) {
  try {

    //ustaw odswiezanie sesji
    const refreshSessionIntervalId = yield call(setInterval, refreshSessionIntervalFunction, REFRESH_INTERVAL);

    //authentication confirmation
    yield put(loginAuthenticated(storedAuthData.email, storedAuthData.photoURL, refreshSessionIntervalId));
    //step 2:  authorization    
    let ctx = yield call(get, ENDPOINTS.RESOURCE_AUTH_CONTEXT, {})
    yield put(loginAuthorized(ctx.contractorId, ctx.affLinkIdentifier, ctx.suggestedApplications, ctx.extDocsToSignCount , ctx.userFullName, ctx.availableRoles, ctx.companyId, ctx.companyName));
    yield put(NOTIFICATIONS_LIST_ACT.contextSet(ctx.notificationContext));
    yield put(CONFIG_ACT.loadData());


  } catch (error) {
    console.log('reautorize error:', error)
    removeAuthContextFromStorage();
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {

    const state = yield select();

    //jesli byla odswiezaczka to ja usun
    if (state.LoginState.refreshSessionIntervalId) {
      clearInterval(state.LoginState.refreshSessionIntervalId);
    }

    //heartbeat
    yield call(get, ENDPOINTS.RESOURCE_HB_LOGOUT + '/' + getDevice(), {})

    const response = yield call(getFirebaseBackend().logout);
    removeAuthContextFromStorage()
    yield put(logoutUserSuccess(response));
    history.push('/login')
  } catch (error) {
    console.log('logoutUser error', error)
    yield put(apiError(error));
    history.push('/login')
  }
}

function* authorizeStartSaga({ payload: { history, affiliationKey } }) {
  //step2: authorization
  try {
    let ctx = yield call(get, ENDPOINTS.RESOURCE_AUTH_CONTEXT, {})
    yield put(loginAuthorized(ctx.contractorId, ctx.affLinkIdentifier, ctx.suggestedApplications, ctx.extDocsToSignCount , ctx.userFullName, ctx.availableRoles, ctx.companyId, ctx.companyName));
    yield put(NOTIFICATIONS_LIST_ACT.contextSet(ctx.notificationContext));
    yield put(CONFIG_ACT.loadData());
    history.push(ROUTES.ROUTE_DASHBOARD);
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* reloadRoles() {
  try {
    let ctx = yield call(get, ENDPOINTS.RESOURCE_AUTH_CONTEXT, {})
    yield put(loginAuthorized(ctx.contractorId, ctx.affLinkIdentifier, ctx.suggestedApplications, ctx.extDocsToSignCount , ctx.userFullName, ctx.availableRoles, ctx.companyId, ctx.companyName));
    yield put(NOTIFICATIONS_LIST_ACT.contextSet(ctx.notificationContext));
  } catch (error) {
    console.log('reloadRoles error:', error)
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER_START, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(LOGIN_REAUTHORIZE, reautorize);
  yield takeEvery(LOGIN_AUTHORIZE_START, authorizeStartSaga);
  yield takeEvery(RELOAD_ROLES, reloadRoles);

}

export default authSaga;
