import PRE_REG_ACTIONS_TYPES from "./actionTypes";


export interface IRegUserData {
    page1Data: any,
    page2Data: any,
    page3Data: any,
    page4Data: any,
    page5Data: any
}

export interface IRegDictionaries {
    cityDomain: any[] | null,
    vehicleDomainTaxi: any[] | null,
    vehicleDomainCourier: any[] | null,
    nationalityDomain: any[] | null,
    languageDomain: any[] | null,
    appsTaxiDomain: any[] | null,
    appsCourierDomain: any[] | null,
}

const preRegistrationPageBack = () => ({
    type: PRE_REG_ACTIONS_TYPES.REG_PAGE_BACK,
    payload: {},
});

///////////////////////////////////////
//step 0 - auth data

const step0StartUpdating = (regUserData: object, origValues: object, values: object, affiliationKey: string) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_0_PATCH_DATA_START,
    payload: { regUserData, origValues, values,affiliationKey },
});
const step0UpdateDone = (regUserData: IRegUserData, dictionaries: IRegDictionaries) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_0_PATCH_DATA_UPDATED,
    payload: { regUserData, dictionaries },
});
const step0UpdateNoChanges = () => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_0_PATCH_DATA_NO_CHANGES,
    payload: {},
});

///////////////////////////////////////
//step 1 - user data
const step1StartUpdating = (regUserData: object, origValues: object, values: object, affiliationKey: string) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_1_PATCH_DATA_START,
    payload: { regUserData, origValues, values,affiliationKey },
});
const step1UpdateDone = (regUserData: IRegUserData, dictionaries: IRegDictionaries) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_1_PATCH_DATA_UPDATED,
    payload: { regUserData, dictionaries },
});
const step1UpdateNoChanges = () => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_1_PATCH_DATA_NO_CHANGES,
    payload: {},
});

///////////////////////////////////////
//step 2 
const step2StartUpdating = (regUserData: object, origValues: object, values: object, affiliationKey: string) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_2_PATCH_DATA_START,
    payload: { regUserData, origValues, values,affiliationKey },
});
const step2UpdateDone = (regUserData: IRegUserData, dictionaries: IRegDictionaries) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_2_PATCH_DATA_UPDATED,
    payload: { regUserData, dictionaries },
});
const step2UpdateNoChanges = () => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_2_PATCH_DATA_NO_CHANGES,
    payload: {},
});
///////////////////////////////////////
//step 3
const step3StartUpdating = (regUserData: object, origValues: object, values: object, affiliationKey: string) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_3_PATCH_DATA_START,
    payload: { regUserData, origValues, values,affiliationKey },
});
const step3UpdateDone = (regUserData: IRegUserData, dictionaries: IRegDictionaries) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_3_PATCH_DATA_UPDATED,
    payload: { regUserData, dictionaries },
});
const step3UpdateNoChanges = () => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_3_PATCH_DATA_NO_CHANGES,
    payload: {},
});
///////////////////////////////////////
//step 4
const step4StartUpdating = (regUserData: object, origValues: object, values: object, affiliationKey: string) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_4_PATCH_DATA_START,
    payload: { regUserData, origValues, values,affiliationKey },
});
const step4UpdateDone = (regUserData: IRegUserData, dictionaries: IRegDictionaries) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_4_PATCH_DATA_UPDATED,
    payload: { regUserData, dictionaries },
});
const step4UpdateNoChanges = () => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_4_PATCH_DATA_NO_CHANGES,
    payload: {},
});

///////////////////////////////////////
//step 5
const step5StartUpdating = (regUserData: object, origValues: object, values: object, affiliationKey: string) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_5_PATCH_DATA_START,
    payload: { regUserData, origValues, values,affiliationKey },
});
const step5UpdateDone = (regUserData: IRegUserData, dictionaries: IRegDictionaries) => ({
    type: PRE_REG_ACTIONS_TYPES.STEP_5_PATCH_DATA_UPDATED,
    payload: { regUserData, dictionaries },
});

///registration finalization
const firebaseRegister = (email: string, password: string, username: string) => ({
    type: PRE_REG_ACTIONS_TYPES.FIREBASE_REGISTER,
    payload: { email, password, username },
});
const firebaseRegisterDone = () => ({
    type: PRE_REG_ACTIONS_TYPES.FIREBASE_REGISTER_DONE,
    payload: {},
});
const apiError = (error: any) => {
    return {
        type: PRE_REG_ACTIONS_TYPES.API_ERROR,
        payload: { error },
    }
}

const goToSummary = () => {
    return {
        type: PRE_REG_ACTIONS_TYPES.GO_TO_SUMMARY,
        payload: {},
    }
}

const resetAndGotoLogin = (history: any) => {
    return {
        type: PRE_REG_ACTIONS_TYPES.RESET_AND_GOTO_LOGIN,
        payload: {history},
    }
}

export const PRE_REG_ACTIONS = {
    preRegistrationPageBack,

    step0StartUpdating,
    step0UpdateDone,
    step0UpdateNoChanges,

    step1StartUpdating,
    step1UpdateDone,
    step1UpdateNoChanges,

    step2StartUpdating,
    step2UpdateDone,
    step2UpdateNoChanges,

    step3StartUpdating,
    step3UpdateDone,
    step3UpdateNoChanges,

    step4StartUpdating,
    step4UpdateDone,
    step4UpdateNoChanges,

    step5StartUpdating: step5StartUpdating,
    step5UpdateDone,
    firebaseRegister,
    firebaseRegisterDone,
    apiError,
    resetAndGotoLogin,
    goToSummary
}

