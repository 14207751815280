import SignatureCustom from "components/MyComponents/SignatureCustom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DOC2SIGN_LIST_ACT } from "./store/docs2SignList";

export interface IPDFViewer {
    extDocId: number | null;
    setExtDocId: (id: number | null) => void;
    docsToSaveCount: number;
}

const Docs2SignSignaturePage = ({ extDocId, setExtDocId , docsToSaveCount}: IPDFViewer) => {

    const history = useHistory();
    const dispatch = useDispatch();
 
    if (!extDocId) {
        return <></>
    }

    const moveBack = () => {
        setExtDocId(null);
    }
    const saveSignature = (signature: string) => {
        dispatch(DOC2SIGN_LIST_ACT.signDocumentAndClosePopup({extDocId,  setExtDocId, signature, docsToSaveCount}, history))
    }
    
    return (
        <SignatureCustom
            isLoading={false} moveBack={moveBack} saveSignature={saveSignature}
            headerText="DOC2SIGN.Signature.top_header"
            headerTextBefore="DOC2SIGN.Signature.top_header.line1"
            headerTextAfter="DOC2SIGN.Signature.top_header.line2"
        />
    );
};

export default Docs2SignSignaturePage;
