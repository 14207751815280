
import { useSelector } from "react-redux";
import RegisterPage0_AuthData from "./RegisterPage0_AuthData";
import RegisterPage1_UserData from "./RegisterPage1_UserData";
import RegisterPage2_TaxiOrCourier from "./RegisterPage2_TaxiOrCourier";
import RegisterPage4_Apps from "./RegisterPage4_Apps";
import RegisterPage99_Summary from "./RegisterPage99_Summary";
import RegisterPage99_SummaryWithoutRegister from "./RegisterPage99_SummaryWithoutRegister";
import RegisterPage5_Addons from "./RegisterPage5_Addons";
import RegisterPage3_CityData from "./RegisterPage3_CityData";

const RegisterPageStepper = () => {

  const { page, marketingConsents} = useSelector((state: any) =>{ 
    return    (    
    {
      page: state.PreRegistration.page,
      marketingConsents: state.PreRegistration.regUserData.page1Data.marketingConsents,
    })
  }
  );
  
  if (page === 0) {
    return <RegisterPage0_AuthData/>
  }
  if (page === 1) {
    return <RegisterPage1_UserData/>
  }
  if (page === 2) {
    return <RegisterPage2_TaxiOrCourier/>
  }
  if (page === 3) {
    return <RegisterPage3_CityData/>
  }
  if (page === 4) {
    return <RegisterPage4_Apps/>
  }
  if (page === 5) {
    return <RegisterPage5_Addons/>
  }

  //w trybie pełnej rejestracji wymuszone było potwierdzenie zgód marketingowych
  if(marketingConsents){
    return <RegisterPage99_Summary/>
  }
  return <RegisterPage99_SummaryWithoutRegister/>
};


export default RegisterPageStepper; 