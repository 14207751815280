export const is_in_array = (arrayList, searchedItem) => {
    if (!arrayList) return false;
    return arrayList.includes(searchedItem);
}


export const is_in_options = (options, searchedValue) => {
    if (!options) return false;

    for (const opt of options) {
        if (opt.value === searchedValue) return true;
    }

    return false;
}


export const isCanvasBlank = (canvas) => {
    return !canvas.getContext('2d')
      .getImageData(0, 0, canvas.width, canvas.height).data
      .some(channel => channel !== 0);
  }