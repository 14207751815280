import { Reducer } from "redux";
import { PROFILE_ACT_TYP } from "./actionsProfile";

export interface IProfile {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    cityId: string,
    languageId: string,
    birthDate: string | null,

    settlementCycleModificationEnabled: boolean,
    settlementCycle: string,
    settlementCycleNextMonth: string,

    bankAccountBase: string,
    bankAccountLease: string,
    bankAccountReturns: string,

}

export interface IProfileReducer {

    initlaFetch: 'LOADING' | 'LOADED' | 'TO_BE_LOADED',
    areChangesInProgress: boolean,
    areChangesInProgressCycle: boolean,
    profile: IProfile | null
}

const INIT_STATE: IProfileReducer = {
    initlaFetch: 'TO_BE_LOADED',
    profile: null,
    areChangesInProgress: false,
    areChangesInProgressCycle: false,
};

const ProfileStore = (state = INIT_STATE, action: { type: any, payload: any }) => {

    switch (action.type) {
        case PROFILE_ACT_TYP.GET_PROFILE:
            return { ...state, error: '', info: '', exclamation: '', initlaFetch: 'LOADING' };
        case PROFILE_ACT_TYP.GET_PROFILE_DONE:
            return {
                ...state,
                profile: action.payload.profile,
                initlaFetch: 'LOADED',
            };
        case PROFILE_ACT_TYP.GET_PROFILE_ERROR:
            return {
                ...state,
                profile: null,
                initlaFetch: 'LOADED',
            };
        case PROFILE_ACT_TYP.SET_PROFILE:
            return { ...state, areChangesInProgress: true };
        case PROFILE_ACT_TYP.SET_PROFILE_CYCLE:
            return { ...state, areChangesInProgressCycle: true };
        case PROFILE_ACT_TYP.SET_PROFILE_DONE:
            return {
                ...state,
                areChangesInProgress: false,
                profile: action.payload.profile,
            };
        case PROFILE_ACT_TYP.SET_PROFILE_CYCLE_DONE:
            return {
                ...state,
                areChangesInProgressCycle: false,
                profile: action.payload.profile,
            };
        case PROFILE_ACT_TYP.API_ERROR:
            return { ...state, areChangesInProgress: false, areChangesInProgressCycle: false, };
        default:
            return state;
    }
}


export default ProfileStore;