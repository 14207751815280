import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import Footer20 from "./Footer20";
import Header20Mob from "./Header20Mob";
import Header20Web from "./Header20Web";
import MobileNav from "./MobileNav";
import Sidebar20 from "./Sidebar20";

const Layout20 = props => {

  return (
    <section className="full-height">
      <div className="container-fluid p-none">
        <div className="row m-none h-100">
          <div className="col-md-2 p-none">
            <Sidebar20 />
          </div>
          <div className="col-md-10 p-none pos-rel">
            <div className="d-flex flex-column  justify-content-between h-100">
              <div className="d-flex flex-column  justify-content-start h-100">
                <Header20Web />
                <Header20Mob />
                <MobileNav />
                {props.children}
              </div>
              <Footer20 />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Layout20.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout20);
