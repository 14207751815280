import { IProfile } from "./reducerProfile";

const PREFIX = "PRF_"

const GET_PROFILE = PREFIX + "GET_PROFILE";
const getProfile = () => ({
    type: GET_PROFILE, payload: {}
});

const GET_PROFILE_DONE = PREFIX + "GET_PROFILE_DONE";
const getProfileDone = (profile: IProfile, info: string, error: string, exclamation: string) => ({
    type: GET_PROFILE_DONE, payload: { profile, info, error,exclamation }
});

const GET_PROFILE_ERROR = PREFIX + "GET_PROFILE_ERROR";
const getProfileError = (error: any) => ({
    type: GET_PROFILE_ERROR, payload: {error}
});


const SET_PROFILE = PREFIX + "SET_PROFILE";
const setProfile = (profile: IProfile) => ({
    type: SET_PROFILE, payload: {profile}
});

const SET_PROFILE_DONE = PREFIX + "SET_PROFILE_DONE";
const setProfileDone = (profile: IProfile) => ({
    type: SET_PROFILE_DONE, payload: { profile }
});


const SET_PROFILE_CYCLE = PREFIX + "SET_PROFILE_CYCLE";
const setProfileCycle = (profile: IProfile) => ({
    type: SET_PROFILE_CYCLE, payload: {profile}
});

const SET_PROFILE_CYCLE_DONE = PREFIX + "SET_PROFILE_CYCLE_DONE";
const setProfileCycleDone = (profile: IProfile) => ({
    type: SET_PROFILE_CYCLE_DONE, payload: { profile}
});

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const PROFILE_ACT = {
    getProfile, getProfileDone, getProfileError,
    setProfile, setProfileDone,
    setProfileCycle, setProfileCycleDone,
    apiError
}

export const PROFILE_ACT_TYP = {
    GET_PROFILE, GET_PROFILE_DONE, GET_PROFILE_ERROR,
    SET_PROFILE, SET_PROFILE_DONE,
    SET_PROFILE_CYCLE, SET_PROFILE_CYCLE_DONE,
    API_ERROR
}