import { ENDPOINT_ATTACHMENT, get, postMultipart } from "helpers/api_helper";
import { IDataResponse } from "helpers/IDataResponse";
import { ISagaParam } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { ROUTES } from "routes";
import { fillPlaceholders } from "routes/placeholder_helper";
import { ATTACHMENT_NEW_ACT, ATTACHMENT_NEW_ACT_TYP } from "./actionsAttNew";
import { IDocumentTypeConfig } from "./reducerAttNew";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";

function* openNew(action: ISagaParam) {
    try {
        action.payload.history.push(fillPlaceholders(ROUTES.ROUTE_ATTACHMENT_NEW, { 'docTypeId': action.payload.docTypeId, }));
    } catch (error) {
        console.log('attachmentNew.openNew error', error);
        showException(error);
        yield put(ATTACHMENT_NEW_ACT.apiError(error));
    }
}

function* prepareNewAtt(action: ISagaParam) {
    try {
        let data: IDataResponse<IDocumentTypeConfig> = yield call(get,
            fillPlaceholders(
                ENDPOINT_ATTACHMENT.RESOURCE_ATTACHMENT_NEW_BY_TYPE,
                {
                    'docTypeId': action.payload.docTypeId,
                }), {})
        yield put(ATTACHMENT_NEW_ACT.prepareNewAttDone(
            data.data,
            data.info,
            data.error,
        ));
    } catch (error) {
        console.log('attachmentNew.prepareNewAtt error', error);
        showException(error);
        yield put(ATTACHMENT_NEW_ACT.apiError(error));
    }
}

function* addAttachmentSaga(action: ISagaParam) {
    try {
        const values = action.payload.values;
        const documentTypeConfig = action.payload.documentTypeConfig;
        const formData = new FormData();
        formData.append('documentTypeId', documentTypeConfig.id);
        if (documentTypeConfig.valueRequired) {
            formData.append('value', values.value);
        }
        if (documentTypeConfig.expirationDateRequired) {
            formData.append('expirationDate', values.expirationDate);
        }
        if (documentTypeConfig.releaseDateRequired) {
            formData.append('releaseDate', values.releaseDate);
        }

        let numImagesToProcess = values.files.length;
        for (let i = 0; i < numImagesToProcess; i++) {
            const fileRaw = values.files[i].uloadedFile;            
            formData.append('files', fileRaw, fileRaw.name);
        }

        yield call(postMultipart,
            fillPlaceholders(
                ENDPOINT_ATTACHMENT.RESOURCE_ATTACHMENT_ADD__POST_MULTIPART,
                {
                    'docTypeId': documentTypeConfig.id,
                }), formData)

        yield put(ATTACHMENT_NEW_ACT.addAttachmentDone());
        action.payload.history.push(ROUTES.ROUTE_ATTACHMENT_LIST)     
        showPopupInfo('ATT_NEW.Submit.done');

    } catch (error) {
        console.log('attachmentNew.addAttachmentSaga error', error);
        showException(error);
        yield put(ATTACHMENT_NEW_ACT.apiError(error));
    }
}

export function* AttachmentNewSaga() {
    yield takeEvery(ATTACHMENT_NEW_ACT_TYP.PREPARE_NEW, prepareNewAtt);
    yield takeEvery(ATTACHMENT_NEW_ACT_TYP.OPEN_NEW, openNew);
    yield takeEvery(ATTACHMENT_NEW_ACT_TYP.ADD_ATTACHMENT, addAttachmentSaga);
}

export default AttachmentNewSaga;
