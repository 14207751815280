import { FieldArray } from 'formik';
import { hasFormikErrors } from 'helpers/formikErrors';
import { IMG_UTIL } from 'helpers/image_helper';
import HelperHeaderContent from 'pages/Agreement/FormItems/HelperHeaderContent';
import './FileUpload.scss';
import FileUploader, { ICompoundFile } from './FileUploader';

interface IShowError {
  showError: (error: string) => void;
}

function hasOnlydigitsWithExtension(filename: string) {
  // Regular expression to match digits followed by '.png'
  const regexPng = /^[\d \(\)]+\.png$/;
  const regexJpg = /^[\d \(\)]+\.jpg$/;
  return regexJpg.test(filename) || regexPng.test(filename);
}

const flatCamera = (filename: string) => {
  if (!filename || hasOnlydigitsWithExtension(filename)) {
    return <span></span>;
  }
  return filename.toLocaleLowerCase();
}

function FileUploaderWithPreview({ showError }: IShowError) {

  return <FieldArray name="files">{
    (fieldArrayProos) => {
      const { push, remove, form } = fieldArrayProos;
      const { files } = form.values;
      const hasNoFiles = !files || files.length === 0;
      const filesRequired = hasFormikErrors('files', form.errors, form.touched) === 'Validation.FilesList.AtLeastOne'

      return <>
        <div className='images-preview-flex pb-2'>
          {
            files.map((compoundFile: ICompoundFile, idx: number) => {
              if (compoundFile.type === 'upload' && compoundFile.uloadedFile) {
                const isPdf = compoundFile.uloadedFile.name.toLocaleLowerCase().endsWith('.pdf');
                return (
                  <div key={'files-' + compoundFile.uloadedFile.size + '-' + compoundFile.uloadedFile.name} className='w-100'>
                    {!isPdf &&
                      <div className='db-image'>
                        <div className='file-name img-thumbnail light-text d-flex align-items-center justify-content-start p-0 m-0 cursor-pointer prevent-select p-2'>
                          <img className='db-image-preview-upload me-2'
                            src={
                              (                                
                                compoundFile.uloadedFile.name.toLocaleLowerCase().endsWith('.png') ||
                                compoundFile.uloadedFile.name.toLocaleLowerCase().endsWith('.jpg') ||
                                compoundFile.uloadedFile.name.toLocaleLowerCase().endsWith('.jpeg')
                              ) ?                              
                               URL.createObjectURL(compoundFile.uloadedFile)
                                :
                                IMG_UTIL.DEFAULT_FILE_ERROR
                              }
                            alt={compoundFile.uloadedFile.name} />

                          <div className='d-flex justify-content-between w-100 align-items-center'>
                            <span className='text-break'>{flatCamera(compoundFile.uloadedFile.name)}</span>
                             <i
                              onClick={() => { remove(idx) }}
                              className="fa-solid fa-trash outilne-icon-light-text cursor-pointer fa-xl px-2 float-end"/>
                          </div>
                        </div>
                      </div>
                    }
                    {isPdf &&
                      <div
                        className="file-name img-thumbnail d-flex align-items-center justify-content-start p-0 m-0 cursor-pointer prevent-select p-2">
                        <i className="fa-regular fa-file-pdf cursor-pointer fa-xl me-2 text-end pdf-icon-color"></i>
                        <div className='d-flex justify-content-between w-100 align-items-center text-wrap'>
                          <span className='text-break'>{flatCamera(compoundFile.uloadedFile.name)}</span>
                            <i
                            onClick={() => { remove(idx) }}
                            className="fa-solid fa-trash outilne-icon-light-text cursor-pointer fa-xl px-2 float-end"></i>
                        </div>
                      </div>
                    }
                  </div>
                )
              }
            })
          }
          {filesRequired &&
            <div className='w-100'>
              <HelperHeaderContent headerText='Validation.FilesList.AtLeastOne.header'
                textAfter='Validation.FilesList.AtLeastOne.text' className="alert-danger" />
            </div>
          }
        </div>

        <FileUploader
          fileAdd={push}
          showError={showError}
          mobilePhotoButtonKey="ATT_NEW.Image.Mobile.Camera"
          mobileUploadButtonKey="ATT_NEW.Image.Mobile.Upload"
          webUploadButtonKey='ATT_NEW.Image.Web.Upload'
          webUploadEmptyButtonKey='ATT_NEW.Image.Web.Upload.Empty'
          isEmptyMessageRequired={hasNoFiles}
        />
      </>
    }
  }</FieldArray>
}

export default FileUploaderWithPreview;