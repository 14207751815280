import { DASHBOARD_ACTION_NAMES } from "./actionTypes";

const apiSuccess = (data: any) => ({
    type: DASHBOARD_ACTION_NAMES.API_SUCCESS,
    payload: { data },
});

const apiFail = (error: any) => ({
    type: DASHBOARD_ACTION_NAMES.API_FAIL,
    payload: { error },
});

// charts data
const dashboardGetData = () => ({
    type: DASHBOARD_ACTION_NAMES.GET_DATA,
    payload: {}
});

const dashboardForceSettlement = (contractorId: number) => ({
    type: DASHBOARD_ACTION_NAMES.FORCE_SETTLEMENT,
    payload: { contractorId }
});


const removeFromDashboard = (notificationId: number) => ({
    type: DASHBOARD_ACTION_NAMES.REMOVE_FROM_DASHBOARD, payload: { notificationId }
});


export const DASHBOARD_ACTIONS = {
    apiSuccess,
    apiFail,
    removeFromDashboard,
    dashboardGetData, dashboardForceSettlement
}