import { IOption } from "components/MyComponents/CustomSelect";
import { call, put, takeLatest } from "redux-saga/effects";
import { ROUTES } from "routes";

// Crypto Redux States
import { ENDPOINT_AGREEMENT, get } from "../../../helpers/api_helper";
import { ResponseGridData } from "../../../helpers/tsx_helper";
import { AGREEMENT_PRE_LIST_ACTION, AGREEMENT_PRE_LIST_ACTION_TYPES } from "./actionsPreAgrList";
import { showException } from "helpers/sweetalert/ShowAlert";

interface ISagaParam { type: string, payload: any}

function* getAvailableTypes(action: ISagaParam) {
    try {
        let gridResponse: ResponseGridData<IOption> = yield call(get, ENDPOINT_AGREEMENT.RESOURCE_AGREEMENT_GET_AVAILABLE_TYPES, {})
        yield put(AGREEMENT_PRE_LIST_ACTION.getAvailableTypesDone(gridResponse));
        action.payload.history.push(ROUTES.ROUTE_AGREEMENT_P0_TYPE_SELECTION);
    } catch (error) {
        console.log('getAvailableTypes error', error);
        showException(error);
        yield put(AGREEMENT_PRE_LIST_ACTION.apiFail(error));
    }
}
  
export function* PreAgrListSaga() {
    yield takeLatest(AGREEMENT_PRE_LIST_ACTION_TYPES.GET_AVAILABLE_TYPES, getAvailableTypes);
}
export default PreAgrListSaga;
