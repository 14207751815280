import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { ResponsiveBarChart } from "components/MyComponents/NivoCharts/responsiveBar";
import { useSelector } from "react-redux";

const DashboardSubDialog_03_PaymentsHistory = () => {
  const { dashboardDto } = useSelector((state: any) => ({ dashboardDto: state.Dashboard.dashboardDto, }));

  if(!dashboardDto.settlementHistory || dashboardDto.settlementHistory?.length === 0){
    return <></>
  }

  return (
    <DialogWrapper title="Dashboard.Item.PrevXSettlements.Title">
      <div className="grid-extender">
        <ResponsiveBarChart
          data={dashboardDto.settlementHistory}
          height={340}
          name='Dashboard.Chart.LastSettlemnt' />
      </div>
    </DialogWrapper>
  )
};

export default DashboardSubDialog_03_PaymentsHistory; 