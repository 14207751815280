import { MobileGridRowCompact } from "components/Common/datatable/MobileGridRowCompact";
import TableComponent from "components/Common/datatable/TableComponent";
import UploadedFilesPreview from "components/MyComponents/custom-dropzone/UploadedFilesPreview";
import { ICONS } from "helpers/IconHelper";
import { ATTACHMENT_NEW_ACT } from "pages/AttachmentNew/store/actionsAttNew";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { IGridDataExtended } from "store/IGridData";
import { ATTACHMENT_CONF } from "./AttachmentStatus";
import "./attachment.scss";
import { ATTACHMENT_LIST_ACT } from "./store/actionsAttList";
import { IAttachmentGridDto, IAttachmentListContext } from "./store/reducerAttList";
import ButtonFloating from "components/MyComponents/ButtonFloating";
import { DetailsItem } from "components/Common/datatable/DetailsItem";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import { DetailsFiles } from "components/Common/datatable/DetailsFiles";

const mapStatusToBadgeColor = (status: string) => {

  switch (status) {
    case ATTACHMENT_CONF.STATUS_VER:
      return 'green-badge';
    case ATTACHMENT_CONF.STATUS_WRN:
      return 'red-badge'
    case ATTACHMENT_CONF.STATUS_REJ:
    case ATTACHMENT_CONF.STATUS_CNC:
      return 'gray-badge'
    default:
      return 'blue-badge'
  }
}
const mapStatusToBadgeColorExp = (expiring: boolean, expired: boolean) => {

  if (expired) {
    return 'gray-badge'
  }

  if (expiring) {
    return 'red-badge'
  }

  return 'green-badge'
}


const AttachmentListPage = () => {

  const attachmentListStore: IGridDataExtended<IAttachmentGridDto, IAttachmentListContext> = useSelector((state: any) => state.AttachmentListStore);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (attachmentListStore.reloadRequired) {
      dispatch(ATTACHMENT_LIST_ACT.loadData());
    }
  }, [attachmentListStore.reloadRequired, dispatch])


  const renderMissingAttachments = (attsContext: IAttachmentListContext) => {

    const renderButtonItem = (docTypeId: string, isExpiring: boolean) => {
      const missingAttKey = 'ATT_LIST.missing.attachment.' + docTypeId;
      const translatedAttachmentLabel = t(missingAttKey);

      const buttonLabel = (missingAttKey === translatedAttachmentLabel ? t('ATT_LIST.missing.attachment') : translatedAttachmentLabel)
        + (isExpiring ? ' (' + t('Attachment.Expiring').toLocaleLowerCase() + ')' : '')
        ;
      let color = isExpiring ? 'default' : 'green';

      return (
        <ButtonFloating
          id={'button-attachment-' + (isExpiring ? 'expiring' : 'missing') + '-' + docTypeId}
          key={'missing-dt-' + docTypeId}
          color={color}
          label={buttonLabel}
          iconClass={isExpiring ? "fa-regular fa-clock fa-xl" : "fa-regular fa-circle-plus fa-xl"}
          onClick={() => dispatch(ATTACHMENT_NEW_ACT.openNew(docTypeId, history))}
        />
      )
    }

    if (
      (!attsContext.missingAttachments || attsContext.missingAttachments.length === 0)
      &&
      (!attsContext.expiringAttachments || attsContext.expiringAttachments.length === 0)
    ) {
      return <div />
    }

    return (
      <>
        {attsContext.missingAttachments.map((docTypeId: string, idx: number) => {
          return renderButtonItem(docTypeId, false);
        })}
        {attsContext.expiringAttachments.map((docTypeId: string, idx: number) => {
          return renderButtonItem(docTypeId, true);
        })}
      </>
    )
  }

  const renderContent = (row: any) => {
    const getChildrenInstant = () => {
      return (
        <>
          <DetailsItem labelKey="ATT_NEW.attachment.status" value={t('ATT_NEW.status.' + row.status)} />

          {row.expirationDate &&
            <DetailsItem labelKey="ATT_NEW.attachment.expirationDate" value={row.expirationDate} />
          }


          {row.value &&
            <DetailsItem labelKey="ATT_NEW.attachment.value" value={row.value} />
          }
          {row.releaseDate &&
            <DetailsItem labelKey="ATT_NEW.attachment.releaseDate" value={row.releaseDate} />
          }

          <DetailsItem labelKey="ATT_NEW.attachment.creationDate" value={row.creationDate} />
          {row.rejectionReason &&
            <DetailsItem labelKey="ATT_NEW.attachment.rejectionReason" value={row.rejectionReason} />
          }

        </>)
    }
    const getChildrenDeffered = () => {
      return (
        <>
          <DetailsFiles labelKey="ATT_NEW.attachment.files" files={row.files} />
          <Row className="py-2">
            {ATTACHMENT_CONF.isDeletable(row.status) &&

              <Col>
                <ButtonCustom id="button-attachment-reject"
                  label="ATT_LIST.button.rejectAwaiting" color="danger"
                  iconClassPre="fa-duotone fa-trash-can"
                  onClick={() => { dispatch(ATTACHMENT_LIST_ACT.rejectAattachment(row.id)) }} />
              </Col>
            }
          </Row>
        </>)
    }
    const getHeader = () => {
      return (
        <div className="head-with-badge prevent-select">
          <h3 className="w-100 row-item-header">{t('ATT_LIST.attachment.' + row.documentTypeId)}</h3>
          <span className={"badge " + mapStatusToBadgeColor(row.status)}>{t('ATT_NEW.status.' + row.status)}</span>
          {row.expirationDate &&
            <span className={"badge " + mapStatusToBadgeColorExp(row.expiring, row.expired)}>
              <i className={"fa-solid fa-hourglass-end px-1 " + mapStatusToBadgeColorExp(row.expiring, row.expired)}></i>
              <span>{row.expirationDate}</span>
            </span>
          }
        </div>
      )


    }

    return (
      <MobileGridRowCompact
        id={row.id}
        getHeader={getHeader} getChildrenInstant={getChildrenInstant} getChildrenDeffered={getChildrenDeffered} />
    )
  }

  return (
    <Container fluid>
      <TableComponent
        additionalHeaderRow={() => { return renderMissingAttachments(attachmentListStore.context) }}
        tableName="ATT_LIST"
        hederKey="ATT_LIST.mobile.header"
        renderContent={renderContent}
        data={attachmentListStore}
      />
    </Container>
  )
}
export default AttachmentListPage; 