import { AlertFrameInfo } from "components/Common/datatable/AlertFrameInfo";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { Form, Formik, FormikValues } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import HelperHeaderContent from "pages/Agreement/FormItems/HelperHeaderContent";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import InvoiceNewForm from "./InvoiceNewForm";
import { INVOICE_NEW_ACT } from "./store/actionsInvNew";
import { IInvoiceNewReducer } from "./store/reducerInvNew";

const NEW_INVOICE_VALIDATION =
  YUP_VALIDATION.YUP_OBJ.shape(
    {
      selfDescribed: YUP_VALIDATION.YUP_BOOL,
      invoiceNumber: YUP_VALIDATION.YUP_STRING.when('selfDescribed', { is: true, then: YUP_VALIDATION.YUP_STRING_REQ, }),
      invoiceDate: YUP_VALIDATION.YUP_DATE.when('selfDescribed', { is: true, then: YUP_VALIDATION.YUP_DATE_BEFORE_NOW, }),
      invoiceType: YUP_VALIDATION.YUP_STRING.when('selfDescribed', { is: true, then: YUP_VALIDATION.YUP_STRING_REQ, }),
      description: YUP_VALIDATION.YUP_STRING_MAX_4000,
      files: YUP_VALIDATION.YUP_FILES_REQUIRED,
      invoiceItems: YUP_VALIDATION.YUP_ARRAY.when('selfDescribed',
        {
          is: true, then:
            YUP_VALIDATION.YUP_ARRAY.of(
              YUP_VALIDATION.YUP_OBJ.shape({
                grossValue: YUP_VALIDATION.YUP_MONEY_REQUIRED_NON_ZERO,
                vatRate: YUP_VALIDATION.YUP_STRING_REQ,
                description: YUP_VALIDATION.YUP_STRING,
              })
            ).min(1, 'INV_NEW.invoice-items.required').required('INV_NEW.invoice-items.atLeastOne')
        }),
    });

export const DEFAULT_NEW_POSITION = {
  grossValue: '',
  vatRate: '23',
  description: ''
};

const NEW_INVOICE_INITIAL_VALUE =
{
  selfDescribed: true,
  invoiceNumber: '',
  invoiceDate: '',
  invoiceType: '',
  description: '',
  files: [],
  invoiceItems: [
    DEFAULT_NEW_POSITION
  ]
};


const InvouceNewPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading } = useSelector((state: any) => {
    const invoiceNewStore: IInvoiceNewReducer = state.InvoiceNewStore as IInvoiceNewReducer;
    return (
      {
        isLoading: invoiceNewStore.isLoading,
      })
  }
  );

  const onAttachmentSubmit = (values: FormikValues) => {
    dispatch(INVOICE_NEW_ACT.addInvoice(values, history))
  }

  return (
    <Container fluid>
      <DialogWrapper title="">
        <Formik
          enableReinitialize
          initialValues={NEW_INVOICE_INITIAL_VALUE}
          validationSchema={NEW_INVOICE_VALIDATION}
          onSubmit={onAttachmentSubmit}
        >
          <Form>
            <HelperHeaderContent
              headerText={'INV_NEW.add-invoice.header'}
              textAfter={'INV_NEW.add-invoice.header.textAfter'}
            />
            <AlertFrameInfo messageKey="INV_NEW.add-invoice.header.textAfterExclamation" thinMargin={true} />
            <InvoiceNewForm isLoading={isLoading} />
          </Form>
        </Formik>
      </DialogWrapper>
    </Container>
  )
}
export default InvouceNewPage; 