import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import CustomText from "../../../../components/MyComponents/CustomText";

import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { ProgressIndicator } from "components/Layout20/ProgressIndicator";
import CustomPassword from "components/MyComponents/CustomPassword";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { PRE_REG_ACTIONS } from "store/auth/pre-registration/actions";
import ButtonSubmit from "../../../../components/MyComponents/ButtonSubmit";
import { YUP_VALIDATION } from "../../../../helpers/YupHelper";
import RegisterPageWrapper from "../RegisterPageWrapper";

const formValidator = Yup.object().shape({
  email: YUP_VALIDATION.YUP_STRING_REQ_EMAIL,
  password: YUP_VALIDATION.YUP_STRING_PASS,
  passwordConfirmation: YUP_VALIDATION.YUP_STRING_REQ.oneOf([Yup.ref('password'), null], 'Validation.Password.Confirmation')
});

interface FormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
}

const RegisterPage0_AuthData = () => {
  const dispatch = useDispatch();
  const { regUserData, isLoading, error } = useSelector((state: any) => (
    {
      regUserData: state.PreRegistration.regUserData,
      isLoading: state.PreRegistration.isLoading,
      error: state.PreRegistration.error,
    })
  );
  const affiliationKey = useSelector((state: any) => state.AffiliationStore.affiliationKey);

  const onSubmit = (values: FormValues) => {
    dispatch(PRE_REG_ACTIONS.step0StartUpdating(regUserData, regUserData.page0BasicData, values, affiliationKey));
  };

  const renderForm = () => {
    return (


      <Form>
        <ProgressIndicator step={0} stepCount={6} />
        <Field name="email" label="Registration.Page0.Email" textAfter="Registration.Page0.Email.AdditionalComment" component={CustomText} isDisabled={isLoading} />
        <Field name="password" label="Registration.Page0.Password" 
        component={CustomPassword}  ac="new-password" isDisabled={isLoading} />
        <Field name="passwordConfirmation"        
        label="Registration.Page0.PasswordConfirmation" 
        component={CustomPassword} ac="new-password"        
        isDisabled={isLoading} />
        <Row>
          <Col className="pb-2">
            <ButtonSubmit id="button-register-start"
            isLoading={isLoading} label="Registration.Page0.Submit" className="w-100" />
          </Col>
        </Row>

      </Form>
    )
  };
  return (<RegisterPageWrapper>
    <AlertFrameError messageKey={error} />
    <Formik
      initialValues={regUserData.page0Data}
      validationSchema={formValidator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  </RegisterPageWrapper>
  );
};

export default RegisterPage0_AuthData; 