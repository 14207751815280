import { useField } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ROUTE_AGREEMENT_P2_FORM__URL_PARAMS } from "routes";
import FormItemDateField from "../FormItems/FormItemDateField";
import FormItemSelectSingleField from "../FormItems/FormItemSelectSingleField";
import { IFormPage } from "../IAgreement";

interface IAgreementPage2FormQuestionsSubPage {
  pageConfig: IFormPage,
}


const AgreementPage2FormQuestionsSubPage = ({ pageConfig }: IAgreementPage2FormQuestionsSubPage) => {

  useEffect(() => {
    window.scrollTo(0,70)
  },[])

  let { pageKey } = useParams<ROUTE_AGREEMENT_P2_FORM__URL_PARAMS>();
  const { t } = useTranslation();
  const [is_employed] = useField("is_employed");
  const [disability_certificate] = useField("disability_certificate");
  return (
    <Row>
      <Col xs={12}>
        <FormItemSelectSingleField         
        //autoFocus={true}
         formItem={
          pageConfig.fieldsMap['is_employed']} context="is_employed" pageKey={pageKey}
          options={
            [
              { "value": "N", "label": t(pageKey + ".is_employed.N") },
              { "value": "UP", "label": t(pageKey + ".is_employed.UP") },
              { "value": "UZ", "label": t(pageKey + ".is_employed.UZ") },
            ]
          }
        />
      </Col>
      {(is_employed.value === 'UP' || is_employed.value === 'UZ') &&
        <>
          <Col xs={12} md={4}>
            <FormItemDateField formItem={pageConfig.fieldsMap['employed_from']} context="employed_from" pageKey={pageKey} />
          </Col>
          <Col xs={12} md={8}>
            <FormItemDateField formItem={pageConfig.fieldsMap['employed_to']} context="employed_to" pageKey={pageKey} />
          </Col>
          <Col xs={12}>
            <FormItemSelectSingleField formItem={
              pageConfig.fieldsMap['employed_salary_info']} context="employed_salary_info" pageKey={pageKey}
              options={
                [
                  { "value": "BELOW", "label": t(pageKey + ".employed_salary_info.BELOW") },
                  { "value": "OVER", "label": t(pageKey + ".employed_salary_info.OVER") }
                ]
              }
            />
          </Col>
        </>
      }
      <Col xs={12}>
        <FormItemSelectSingleField formItem={
          pageConfig.fieldsMap['retired']} context="retired" pageKey={pageKey}
          options={
            [
              { "value": "N", "label": t(pageKey + ".retired.N") },
              { "value": "E", "label": t(pageKey + ".retired.E") },
              { "value": "R", "label": t(pageKey + ".retired.R") }
            ]
          }
        />
      </Col>
      <Col xs={12}>
        <FormItemSelectSingleField formItem={
          pageConfig.fieldsMap['disability_certificate']} context="disability_certificate" pageKey={pageKey}
          options={
            [              
              { "value": "N", "label": t(pageKey + ".disability_certificate.N") },
              { "value": "S", "label": t(pageKey + ".disability_certificate.S") },
              { "value": "M", "label": t(pageKey + ".disability_certificate.M") },
              { "value": "L", "label": t(pageKey + ".disability_certificate.L") },
            ]
          }
        />
      </Col>
      {
      (disability_certificate.value === 'S' || disability_certificate.value === 'M'|| disability_certificate.value === 'L')
      &&
        <>
          <Col xs={12} md={4}>
            <FormItemDateField formItem={pageConfig.fieldsMap['disability_certificate_from']} context="disability_certificate_from" pageKey={pageKey} />
          </Col>
          <Col xs={12} md={8}>
            <FormItemDateField formItem={pageConfig.fieldsMap['disability_certificate_to']} context="disability_certificate_to" pageKey={pageKey} />
          </Col>
        </>
      }
    </Row>
  );
};

export default AgreementPage2FormQuestionsSubPage; 