import { AlertFrameGeneric } from "./AlertFrameGeneric";

export const AlertFrameInfo = (
   { messageKey, messageTitle,
      buttonId, buttonTitleKey, buttonColor, buttonOnClickAction,
      iconClass = "fa-regular fa-circle-info",
      translationPlaceholdersOject,
      thinMargin
   }:
      {
         messageKey: string | undefined,
         messageTitle?: string,
         iconClass?: string;

         buttonId?: string;
         buttonTitleKey?: string;
         buttonColor?: string;
         buttonOnClickAction?: () => void,

         translationPlaceholdersOject? : object,
         thinMargin? : boolean
      }) => {
   return (
      <AlertFrameGeneric 
      messageKey={messageKey} 
      messageTitle={messageTitle}
      buttonId={buttonId}
      buttonTitleKey={buttonTitleKey}
      buttonColor={buttonColor}
      buttonOnClickAction={buttonOnClickAction}
      color="orange" 
      iconClass={iconClass}
      translationPlaceholdersOject={translationPlaceholdersOject}
      thinMargin={thinMargin}
      />
   )

}  