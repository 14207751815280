import { ResponsiveBar } from "@nivo/bar";
import { toCurrencyWithoutSuffix, toPln } from "helpers/pln_helper";
import { useTranslation } from "react-i18next";
import { createArrayOfKeys } from "./chartUtils";

interface ResponsiveBarChartPropos {
    data: any[];
    name: string;//'Dashboard.Chart.LastSettlemnt.'
    height: number;
}

export const ResponsiveBarChart = ({name, data, height }: ResponsiveBarChartPropos) => {

    const { t } = useTranslation();
    const keys = createArrayOfKeys(data);
    return (
        <div style={{ height }}>
            <ResponsiveBar
                data={data}
                enableGridX={false} enableGridY={true}
                isInteractive={true}
                animate={true}
                tooltipLabel={(data: any) =>data.id}
                
                layout="vertical"
                valueFormat={toPln}
                keys={keys}
                indexBy="group"
                layers={['grid', 'axes', 'bars', 'markers'/*, 'legends'*/]}
                margin={{ top: 0, right: 0, bottom: 30, left: 35 }}
                padding={0.1}                                                
                //borderRadius={5}
                labelTextColor='#fff'            
                axisLeft={{ tickPadding: 3, tickSize: 0, ticksPosition: 'after'}}
                colors={({ id, data }) => { let anyData: any = data; return anyData[`${id}Color`]; }}
                 

            />
        </div>
    )
}