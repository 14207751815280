const PREFIX = "ATL_"

const LOAD_DATA = PREFIX + "LOAD_DATA";
const loadData = () => ({ type: LOAD_DATA, payload: {} });

const LOAD_DATA_DONE = PREFIX + "LOAD_DATA_DONE";
const loadDataDone = (gridData: any[], context: any, info: string, error: string) => ({
    type: LOAD_DATA_DONE, payload: { gridData, context, info, error }
});

const REJECT_ATTACHMENT = PREFIX + "REJECT_ATTACHMENT";
const rejectAattachment = (attachmentId: number) => ({
    type: REJECT_ATTACHMENT, payload: { attachmentId }
});

const REJECT_ATTACHMENT_DONE = PREFIX + "REJECT_ATTACHMENT_DONE";
const rejectAattachmentDone = (info: string) => ({
    type: REJECT_ATTACHMENT_DONE, payload: { info }
});

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const ATTACHMENT_LIST_ACT = {
    loadData, loadDataDone,
    rejectAattachment, rejectAattachmentDone,
    apiError
}


export const ATTACHMENT_LIST_ACT_TYP = {
    LOAD_DATA, LOAD_DATA_DONE,
    API_ERROR,
    REJECT_ATTACHMENT, REJECT_ATTACHMENT_DONE
}