import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import CustomText from "components/MyComponents/CustomText";
import HorizontalDivider from "components/MyComponents/HorizontalDivider";
import { Field, Form, Formik } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ROUTES } from "routes";

const IBAN_VALIDATION = YUP_VALIDATION.YUP_OBJ.shape({});

const ProfilePage_06_IbanData = () => {
  const history = useHistory();
  const profileStore = useSelector((state: any) => state.ProfileStore);

  const onSubmit = () => {
    history.push(ROUTES.ROUTE_PROFILE_BANK_ACCOUNT);
  };

  const isB2B = profileStore.profile && profileStore.profile.b2b;

  const isBA1 = profileStore.profile && profileStore.profile.bankAccountBase !== '';
  const isBA2 = profileStore.profile && profileStore.profile.bankAccountLease !== '';
  const isBA3 = profileStore.profile && profileStore.profile.bankAccountReturns !== '';

  if (!isBA1 && !isBA2 && !isBA3) {
    return <></>
  }

  return (
    <DialogWrapper title="Profile.Data.BankAccountDivider" dialogVisibilityKey="profileIban">
      <Formik
        initialValues={profileStore.profile}
        validationSchema={IBAN_VALIDATION}
        onSubmit={onSubmit}
      >
        <Form>
          <Row>
            {isBA1 &&
              <Col xs={12} md={4}>
                <Field name="bankAccountBase" label={isB2B ? "Profile.Data.bankAccountB2B":"Profile.Data.bankAccountBase"} component={CustomText} isDisabled={true} />
              </Col>
            }
            {(!isB2B && isBA2) &&
              <Col xs={12} md={4}>
                <Field name="bankAccountLease" label="Profile.Data.bankAccountLease" component={CustomText} isDisabled={true} />
              </Col>
            }
            {(!isB2B && isBA3) &&
              <Col xs={12} md={4}>
                <Field name="bankAccountReturns" label="Profile.Data.bankAccountReturns" component={CustomText} isDisabled={true} />
              </Col>
            }
            <Col xs={12} md={4} className="inline-buttons input-box pb-md-3">
              <ButtonSubmit
                id="button-profile-go-2-iban-change-page"
                isLoading={false}
                label="Profile.bankAccountChange.button"
                iconFileSrc="/img/figma/MoneyBag.svg" />
            </Col>
          </Row>
        </Form>
      </Formik>
    </DialogWrapper>
  );
};


export default ProfilePage_06_IbanData; 