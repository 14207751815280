import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import SettlementListAdditionalInfo from "./SettlmentListAdditionalInfo";
import SettlmentListGrid from "./SettlmentListGrid";
import "./settlement.scss";
import { SETTLEMENT_LIST_ACT } from "./store/actionsSettList";
import SettlmentListYearlyChart from "./SettlmentListYearlyChart";
import SettlmentLumpTaxPercent from "./SettlmentLumpTaxPercent";
import { useParams } from "react-router-dom";
import { ROUTE_SETTLEMENT_WITH_ID } from "routes";


const SettlmentListPage = () => {

  const settlementListStore = useSelector((state: any) => state.SettlementListStore);
  const dispatch = useDispatch();
  useEffect(() => {
    if (settlementListStore.reloadRequired) {
      dispatch(SETTLEMENT_LIST_ACT.loadData());
    }
  }, [settlementListStore.reloadRequired, dispatch])


  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <SettlmentLumpTaxPercent />
        </Col>
        <Col xs={12} sm={6} md={5}>
          <SettlementListAdditionalInfo />
        </Col>
        <Col xs={12} sm={6} md={7} >
          <SettlmentListYearlyChart />
        </Col>
        <Col xs={12}>
          <SettlmentListGrid />
        </Col>
      </Row>
    </Container>
  )

}
export default SettlmentListPage; 