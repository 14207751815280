import { useState } from 'react';

const useToggle = () => {
    const [toggleValue, setToggleValue] = useState(false);
  
    function changeToggle() {
      setToggleValue(!toggleValue);
    }
  
    return {
      toggleValue,
      changeToggle,
    }
  };
export default useToggle;