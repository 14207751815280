import CustomSelect, { SELECT_SINGLE_DEFAULT_PLACEHOLDER } from "components/MyComponents/CustomSelect";
import { Field } from "formik";
import { IGenericFormItem } from "./GenericFormItem";

interface IFormSelect extends IGenericFormItem {
afterOnChange? (name: string, value: string | null | string[]) : void;
}

const FormItemSelectSingleField = ({ formItem, pageKey,options, afterOnChange, autoFocus }: IFormSelect) => {
    const fieldKey = pageKey + '.' + formItem.name;
    return (
        <Field   
            key={fieldKey}
            name={formItem.name}            
            label={fieldKey}         
            autoFocus={autoFocus}
            textBefore = {formItem.textBeforeSuffix ?fieldKey + '.'+formItem.textBeforeSuffix : undefined }
            textAfter = {formItem.textAfterSuffix ?fieldKey + '.'+formItem.textAfterSuffix : undefined }
            afterOnChange = {afterOnChange}
            placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
            options={options} className="custom-select" component={CustomSelect}       
      />
    )
}

export default FormItemSelectSingleField