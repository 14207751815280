import { MobileGridRowCompact } from "components/Common/datatable/MobileGridRowCompact";
import TableComponent from "components/Common/datatable/TableComponent";
import { getAppSettlementLogo } from "helpers/image_helper";
import { toPln } from "helpers/pln_helper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ROUTE_SETTLEMENT_WITH_ID } from "routes";
import MarkAsReadSettlement from "./MarkAsReadSettlement";
import { SettlementDetails } from "./SettlementDetails";
import { SETTLEMENT_CONF } from "./SettlmentStatus";
import "./settlement.scss";

const moneySummary = (income: any) => {
  return income > 0 ? '+' + toPln(income) : toPln(income);
}

const SettlmentListGrid = () => {
  
  let params = useParams<ROUTE_SETTLEMENT_WITH_ID>();
  const settlementListStore = useSelector((state: any) => state.SettlementListStore);
  const { t } = useTranslation();
  const renderContent = (row: any) => {

    const isB2b = row.b2b;
    const incomeSum = isB2b ? row.b2bTobePaidSum : row.summarySum;

    const getLogos = () => {
      const appList = row.appList.split(', ');
      return <div className={"logo-container-w" + appList.length}>
        {appList.map((item: any, key: number) => (
          <img key={key} className={"app-logo-img img_overlap" + (key)} src={getAppSettlementLogo(item)} alt={item} />
        ))
        }
      </div>
    }
    const getHeader = (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => {
      return (
        <>
          <Row className="align-items-center">
            <Col xs="auto" className="logos-container">
              {getLogos()}
            </Col>
            <Col className="px-sm-1 px-lg-2  d-flex  align-items-center justify-content-around justify-content-sm-between  px-0 mx-0">
              <h3 className="mb-0 row-item-header">{row.settlementDate}</h3>
              <div className={"profit-brdr " + (incomeSum < 0 ? "danger" : "success") + "-color"}>
                <span className="prevent-select">{moneySummary(incomeSum)}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col><div className="head-with-badge prevent-select">
              <span className={"badge " + SETTLEMENT_CONF.mapStatusToBadgeColor(row.status, isB2b)}>{

                t(
                  (row.status == 'OX' && isB2b) ? 'sett.status.B2B-self' : 'sett.status.' + row.status)
              }</span>
              <span className={"badge blue-badge"}>{t('settlement.header.setNum') + ' ' + row.id}</span>
            </div></Col>
          </Row>
        </>
      )
    }

    const getChildrenInstant = () => <></>

    const getChildrenDeffered = () => {
      return (
        <>
          <SettlementDetails settlementId={row.id} key={'sett-det-' + row.id} />
          <MarkAsReadSettlement id={row.id} markedAsPresented={row.markedAsPresented} />
        </>
      )
    }

    return (
      <MobileGridRowCompact
        id={row.id}
        getHeader={getHeader}
        getChildrenInstant={getChildrenInstant} getChildrenDeffered={getChildrenDeffered}
        defaultExpandState={row.id == params.settlementId}
        scrollToMe={row.id == params.settlementId}
        withBorder={!row.markedAsPresented}
      />
    )
  }


  return (
    <TableComponent //SettlementTableComponent
      tableName="SETT_LIST"
      data={settlementListStore}
      hederKey="SETT_LIST.mobile.header"
      renderContent={renderContent}
      isFullWidthInFullMode={true}
    />
  )

}
export default SettlmentListGrid; 