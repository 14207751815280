import { IOption } from "components/MyComponents/CustomSelect";
import { IAgreementFormItem } from "../IAgreement";
import FormItemCheckboxField from "./FormItemCheckboxField";
import FormItemDateField from "./FormItemDateField";
import FormItemPhoneField from "./FormItemPhoneField";
import FormItemSelectSingleField from "./FormItemSelectSingleField";
import FormItemTextField from "./FormItemTextField";
import HelperHeader from "./HelperHeader";
import HelperText from "./HelperText";

export interface IGenericFormItem {
  formItem: IAgreementFormItem,
  pageKey: string,  
  options?: IOption[],
  context?: string,
  autoFocus?: boolean,
}

export const isFocusableField = (name: string, kind: string) => {

  switch (kind) {    
    case "HELPER_HEADER":
    case "HELPER_TEXT": return false;
    default: return true;
  }
}

const GenericFormItem = ({ formItem, pageKey, options, autoFocus }: IGenericFormItem) => {

  const autoFocusOnField = autoFocus;

  switch (formItem.kind) {
    case "TEXT_FIELD": return <FormItemTextField formItem={formItem} pageKey={pageKey} autoFocus={autoFocusOnField} />;
    case "DATE_FIELD": return <FormItemDateField formItem={formItem} pageKey={pageKey} autoFocus={autoFocusOnField} />;
    case "PHONE_FIELD": return <FormItemPhoneField formItem={formItem} pageKey={pageKey} autoFocus={autoFocusOnField} />;
    case "SELECT_SINGLE": return <FormItemSelectSingleField formItem={formItem} pageKey={pageKey} options={options} autoFocus={autoFocusOnField} />;
    case "CHECK_BOX": return  <FormItemCheckboxField  formItem={formItem} pageKey={pageKey} autoFocus={autoFocusOnField} />
    case "HELPER_HEADER": return <HelperHeader formItem={formItem} pageKey={pageKey}/>;
    case "HELPER_TEXT": return <HelperText formItem={formItem} pageKey={pageKey} />;
    default: return <h1>Unknown kind: {formItem.kind} pageKey: {pageKey}</h1>
  }
}

export default GenericFormItem;