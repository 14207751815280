const PREFIX = "PRE_REG_"

const REG_DATA_LOAD_START = PREFIX + "DATA_LOAD_START";
const REG_DATA_LOADED = PREFIX + "DATA_LOADED";
const REG_PAGE_BACK = PREFIX + "REG_PAGE_BACK";


const STEP_0_PATCH_DATA_START = PREFIX + "STEP_0_PATCH_DATA_START";
const STEP_0_PATCH_DATA_UPDATED = PREFIX + "STEP_0_PATCH_DATA_UPDATED";
const STEP_0_PATCH_DATA_NO_CHANGES = PREFIX + "STEP_0_PATCH_NO_CHANGES";

const STEP_1_PATCH_DATA_START = PREFIX + "STEP_1_PATCH_DATA_START";
const STEP_1_PATCH_DATA_UPDATED = PREFIX + "STEP_1_PATCH_DATA_UPDATED";
const STEP_1_PATCH_DATA_NO_CHANGES = PREFIX + "STEP_1_PATCH_NO_CHANGES";

const STEP_2_PATCH_DATA_START = PREFIX + "STEP_2_PATCH_DATA_START";
const STEP_2_PATCH_DATA_UPDATED = PREFIX + "STEP_2_PATCH_DATA_UPDATED";
const STEP_2_PATCH_DATA_NO_CHANGES = PREFIX + "STEP_2_PATCH_NO_CHANGES";

const STEP_3_PATCH_DATA_START = PREFIX + "STEP_3_PATCH_DATA_START";
const STEP_3_PATCH_DATA_UPDATED = PREFIX + "STEP_3_PATCH_DATA_UPDATED";
const STEP_3_PATCH_DATA_NO_CHANGES = PREFIX + "STEP_3_PATCH_NO_CHANGES";

const STEP_4_PATCH_DATA_START = PREFIX + "STEP_4_PATCH_DATA_START";
const STEP_4_PATCH_DATA_UPDATED = PREFIX + "STEP_4_PATCH_DATA_UPDATED";
const STEP_4_PATCH_DATA_NO_CHANGES = PREFIX + "STEP_4_PATCH_NO_CHANGES";

const STEP_5_PATCH_DATA_START = PREFIX + "STEP_5_PATCH_DATA_START";
const STEP_5_PATCH_DATA_UPDATED = PREFIX + "STEP_5_PATCH_DATA_UPDATED";


const FIREBASE_REGISTER = PREFIX + "FIREBASE_REGISTER";
const FIREBASE_REGISTER_DONE = PREFIX + "FIREBASE_REGISTER_DONE";
const RESET_AND_GOTO_LOGIN = PREFIX + "RESET_AND_GOTO_LOGIN";
const GO_TO_SUMMARY = PREFIX + "GO_TO_SUMMARY";

const API_ERROR = PREFIX + "API_FAIL";

const PRE_REG_ACTIONS_TYPES = {
     REG_DATA_LOAD_START,
     REG_DATA_LOADED,
     REG_PAGE_BACK,
   
     STEP_0_PATCH_DATA_START,
     STEP_0_PATCH_DATA_UPDATED,
     STEP_0_PATCH_DATA_NO_CHANGES,

    STEP_1_PATCH_DATA_START,
    STEP_1_PATCH_DATA_UPDATED,
    STEP_1_PATCH_DATA_NO_CHANGES,
   
    STEP_2_PATCH_DATA_START,
    STEP_2_PATCH_DATA_UPDATED,
    STEP_2_PATCH_DATA_NO_CHANGES,
   
    STEP_3_PATCH_DATA_START,
    STEP_3_PATCH_DATA_UPDATED,
    STEP_3_PATCH_DATA_NO_CHANGES,


    STEP_4_PATCH_DATA_START,
    STEP_4_PATCH_DATA_UPDATED,
    STEP_4_PATCH_DATA_NO_CHANGES,

    STEP_5_PATCH_DATA_START,
    STEP_5_PATCH_DATA_UPDATED,

    FIREBASE_REGISTER,
    FIREBASE_REGISTER_DONE,
    
    API_ERROR,
    GO_TO_SUMMARY,
    RESET_AND_GOTO_LOGIN,
}

export default PRE_REG_ACTIONS_TYPES;


