import { DetailsItemWithHeader } from "components/Common/datatable/DetailsItem";
import { IMG_UTIL } from "helpers/image_helper";
import { getMoney } from "helpers/money";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

export interface ISettlementBankTransferDTO {
  id: number,
  transferTypeName: string,
  iban: string,
  bankName: string,
  amount: number,
}

export interface ISettlementDetails04_BankTransfers {
  listOfBankTransfers: ISettlementBankTransferDTO[],
  settlementStatus: string
}


export const SettlementDetails04_BankTransfers = ({ listOfBankTransfers, settlementStatus }: ISettlementDetails04_BankTransfers) => {

  const { t } = useTranslation();

  if (!listOfBankTransfers || listOfBankTransfers.length === 0) {
    return <></>
  }

  return (
    <>
      <Row className="csv-item-header align-items-center m-0">
        <Col xs="auto" className="csv-item-header-logo" >
          <img className="app-logo-img-details pe-1" src="/img/card.svg" alt='Summary' />
        </Col>
        <Col  className="csv-item-header-data">
          <span className="table-h-con">
            <h3>
              {t('settlement.summary.bank-transfers')}
            </h3>
          </span>
        </Col>
      </Row>


      {listOfBankTransfers.map((transfer: ISettlementBankTransferDTO, idx: number) => (
        <div key={`sett-transfer-${transfer.id}`} className="py-2">

          <DetailsItemWithHeader
            labelKey={transfer.iban}
            valueKey={'settlement.bank-transfer-type.' + transfer.transferTypeName}
            value={getMoney(transfer.amount)}
          />
        </div>
      ))
      }
    </>
  )
}