
interface IgetTextByLangData {
    plText: string,
    enText: string,
    uaText: string,
    geText: string,
    ruText: string,
}
export const getTextByLang = (data: IgetTextByLangData, language: string) => {
    switch (language.toUpperCase()) {
        case 'EN':
            return data.enText || data.plText;
        case 'RU':
            return data.ruText || data.plText;
        case 'UA':
            return data.uaText || data.plText;
        case 'GE':
            return data.geText || data.plText;
        default:
            return data.plText;
    }
}
