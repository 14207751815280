export const isOlderThan26 = (birthDate: string): boolean => {
  const birthDateObject = new Date(birthDate);
  const currentDateObject = new Date();
  const birthTime = birthDateObject.getTime();
  const currentTime = currentDateObject.getTime();
  const ageInDays = (currentTime - birthTime) / (1000 * 60 * 60 * 24);
  return ageInDays > 365.25 * 26;
}

export function convertYYYYMMDDtoDDMMYYYY(str: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (regex.test(str)) {
    const parts = str.split("-");
    const day = parts[2];
    const month = parts[1];
    const year = parts[0];
    return `${day}.${month}.${year}`;
  } else {
    return str;
  }
}

export function formatDateToYYYYMMDDTHH24_MI_SS(date: Date) {
  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format the date string
  const formattedDate = `${year}.${month}.${day}T${hours}:${minutes}:${seconds}`;

  return formattedDate;
}



export function yyyymmdd() {
  let d = new Date();
  var mm = d.getMonth() + 1; // getMonth() is zero-based
  var dd = d.getDate();
  return [d.getFullYear(),
  (mm > 9 ? '' : '0') + mm,
  (dd > 9 ? '' : '0') + dd
  ].join('');
};
