import { PROFILE_REMOVAL_ACT_TYP } from "./actionsProfileRemoval";

export interface IProfileRemoval {
    contractorId: number,
    description: string,        
}

export interface IProfileRemovalReducer {
    removalActionInProgress: boolean,
    removalAction: IProfileRemoval | null
    error: string,
}

const INIT_STATE: IProfileRemovalReducer = {
    removalAction: null,
    removalActionInProgress: false,
    error: '',
};

const ProfileRemovalStore = (state = INIT_STATE, action: { type: any, payload: any }) => {

    switch (action.type) {
   
        case PROFILE_REMOVAL_ACT_TYP.REMOVE_PROFILE:
            return {
                ...state,
                profile: action.payload.profile,
                removalActionInProgress: true,                
                error: '',
            };
        case PROFILE_REMOVAL_ACT_TYP.REMOVE_PROFILE_DONE:
            return {
                ...state,
                profile: null,
                removalActionInProgress: false,  
                error: '',
            };

        case PROFILE_REMOVAL_ACT_TYP.API_ERROR:
            return { ...state, removalActionInProgress: false, error: action.payload.error};

        default:
            return state;
    }
}

export default ProfileRemovalStore;