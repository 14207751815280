import languages from "common/languages";
import { getCurrentLanguageFromLocalStorage } from "components/Layout20/Header20WebLanguageChangeMenu";
import i18n from "i18n";
import { useEffect, useState } from "react";

const Unauth20WrapperLanguageChangeMenu = ({ alignment }: { alignment: "horizontal" | "horizontal-mobile" }) => {

	const [selectedLang, setSelectedLang] = useState("pl");

	useEffect(() => {
		setSelectedLang(getCurrentLanguageFromLocalStorage());
	}, [])

	const changeLanguageAction = (lang: string) => {
		i18n.changeLanguage(lang);
		localStorage.setItem("I18N_LANGUAGE", lang);
		setSelectedLang(lang);
	}

	const getFlagClass = (lang: string) => {
		if (lang === selectedLang) {
			return "flag active";
		} else {
			return "flag ";
		}
	}

	return (
		<div className={`languages-div languages-div-${alignment}`} >
			{Object.entries(languages).map(([keyName, lanObj], i) => {
				return <img key={'flag_key:' + keyName} onClick={() => changeLanguageAction(keyName)} className={getFlagClass(keyName)} src={lanObj.flag20} alt={lanObj.label} />
			}
			)}
		</div>
	)
}

export default Unauth20WrapperLanguageChangeMenu;