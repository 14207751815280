import { FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import TranslatedErrors from "./TranslatedErrors";


interface CheckboxProps extends FieldProps {
  label: string;
  textAfter?: string;
  autoFocus?: boolean;
  afterOnChange?(name: string, value: boolean): void;
}

export const CustomCheckbox = ({
  label,
  textAfter,
  field,
  form,
  afterOnChange,
  autoFocus
}: CheckboxProps) => {

  const { t } = useTranslation();
  const hasErrors = form.errors[field.name] && form.touched[field.name];

  if (field.value === undefined) {
    return <></>
  }

  return (
    <>
      <FormGroup check>
        <Label check for={field.name}>
          <span>{t(label)}</span>
          </Label>
          <Input type="checkbox"
            defaultChecked={field.value}
            value={field.value}
            name={field.name}
            id={field.name}
            key={field.name}
            autoFocus={autoFocus}
            onChange={
              (e) => {
                form.setFieldValue(field.name, e.target.checked);
                if (afterOnChange) { afterOnChange(field.name, e.target.checked); }
              }
            } />
      </FormGroup>
      {hasErrors &&
        <TranslatedErrors errors={form.errors[field.name]} />
      }
      {textAfter &&
        <FormGroup row>
          <FormText>{t(textAfter)}</FormText>
        </FormGroup>
      }
    </>
  );
};

export default CustomCheckbox;
