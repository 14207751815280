import { Col, Row } from "reactstrap";
import BarLoader from "react-spinners/BarLoader";


function LoadingContent() {
  return <Row className="align-items-center my-5">
    <Col xs={4}></Col>
    <Col xs={4} className="d-flex justify-content-center">
      <BarLoader color="#009FE3" width='90%' height="10px" /> 
    </Col>
    <Col xs={4}></Col>
  </Row>

}

export default LoadingContent;