import { ENDPOINTS, ENDPOINT_SETTLEMENT, get, post } from "helpers/api_helper";
import { ISagaParam, ResponseGridDataExtended } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { SETTLEMENT_LIST_ACT, SETTLEMENT_LIST_ACT_TYP } from "./actionsSettList";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";
import { fillPlaceholders } from "routes/placeholder_helper";
import { IDataResponse } from "helpers/IDataResponse";
import { NOTIFICATIONS_LIST_ACT } from "pages/NotificationsList/store/actionsNotificationsList";


function* loadData(action: ISagaParam) {
    try {
        let gridResponse: ResponseGridDataExtended<any, any> = yield call(get, ENDPOINT_SETTLEMENT.RESOURCE_GET_SETTLEMENT, {})
        yield put(SETTLEMENT_LIST_ACT.loadDataDone(gridResponse.gridData, gridResponse.context, gridResponse.info, gridResponse.error));
    } catch (error) {
        console.log('settlementList.loadData error', error);
        yield put(SETTLEMENT_LIST_ACT.apiError(error));
    }
}

function* lumpTaxPaid(action: ISagaParam) {
    try {
        const url = fillPlaceholders(ENDPOINT_SETTLEMENT.LUMP_TAX_PAID, { "lumpTaxId": "" + action.payload.lumpTaxId })
        yield call(post,url, {lumpTaxId: action.payload.lumpTaxId})
        yield put(SETTLEMENT_LIST_ACT.lumpTaxPaidDone());
        showPopupInfo('SETT_LIST.mobile.lump-tax.button-already-paid.success');
    } catch (error) {
        console.log('settlementList.lumpTaxPaid error', error);
        showException(error);
        yield put(SETTLEMENT_LIST_ACT.apiError(error));
    }
}


function* markAsPresented(action: ISagaParam) {
    try {
        const url = fillPlaceholders(ENDPOINT_SETTLEMENT.RESOURCE_GET_SETTLEMENT_DETAILS_PRESENTED, { 'settlementId': action.payload.settlementId });  
        let dto : IDataResponse<any>  = yield call(get, url, {})
        yield put(SETTLEMENT_LIST_ACT.markAsPresentedDone(dto.data.settlementId, dto.data.markedAsPresented));
        yield put(NOTIFICATIONS_LIST_ACT.contextSet(dto.data.notificationContext,''));
    } catch (error) {
        console.log('settlementList.markAsPresented error', error);
        showException(error);
        yield put(SETTLEMENT_LIST_ACT.apiError(error));
    }
}

export function* SettlementListSaga() {
    yield takeEvery(SETTLEMENT_LIST_ACT_TYP.LOAD_DATA, loadData);
    yield takeEvery(SETTLEMENT_LIST_ACT_TYP.MARK_PRESENTED, markAsPresented);
    yield takeEvery(SETTLEMENT_LIST_ACT_TYP.LUMP_TAX_PAID, lumpTaxPaid);
}

export default SettlementListSaga;
