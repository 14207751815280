import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { DASHBOARD_ACTION_NAMES } from "./actionTypes";
import { DASHBOARD_ACTIONS } from "./actions";
import { ENDPOINTS, get, post } from "../../helpers/api_helper";
import { ISagaParam, ResponseGenerator } from "../../helpers/tsx_helper";
import { fillPlaceholders } from "routes/placeholder_helper";
import { showException, showMessage, showPopupInfo } from "helpers/sweetalert/ShowAlert";

function* dashboardGetData() {
    try {
        let dto: ResponseGenerator = yield call(get, ENDPOINTS.RESOURCE_DASHBOARD_GET, {})
        yield put(DASHBOARD_ACTIONS.apiSuccess(dto));
    } catch (error) {
        console.log('dashboardGetData error', error);
        yield put(DASHBOARD_ACTIONS.apiFail(error));
    }
}

function* dashboardForceSettlement(action: ISagaParam) {
    try {
        const forcedSettlement = fillPlaceholders(
            ENDPOINTS.RESOURCE_DASHBOARD_FORCED_SETTLEMENT, { 'contractorId': action.payload.contractorId }
        );
        yield call(post, forcedSettlement, {})
        yield put(DASHBOARD_ACTIONS.dashboardGetData());
        showPopupInfo('Settlement.ForcePayment.Done');
    } catch (error) {
        console.log('dashboardForceSettlement error', error);
        showException(error);
        yield put(DASHBOARD_ACTIONS.apiFail(error));
    }
}

function* removeFromDashboard(action: ISagaParam) {
    try {
        const removeDashboard = fillPlaceholders(
            ENDPOINTS.RESOURCE_NOTIFICATIONS_ID_HIDE, { 'notificationId': action.payload.notificationId }
        );
        yield call(get, removeDashboard, {})

    } catch (error) {
        console.log('dashboardForceSettlement error', error);
        showException(error);
        yield put(DASHBOARD_ACTIONS.apiFail(error));
    }
}




export function* DashboardSaga() {
    yield takeEvery(DASHBOARD_ACTION_NAMES.GET_DATA, dashboardGetData);
    yield takeEvery(DASHBOARD_ACTION_NAMES.FORCE_SETTLEMENT, dashboardForceSettlement);
    yield takeEvery(DASHBOARD_ACTION_NAMES.REMOVE_FROM_DASHBOARD, removeFromDashboard);
}

export default DashboardSaga;
