import { IGridData } from "store/IGridData";
import { AGREEMENT_LIST_ACT_TYP } from "./actionsAgrList";

const INIT_STATE: IGridData<any> = {
    gridData: [],
    isLoading: false,
    reloadRequired: true,
    error: '',
    info: '',
};

const AgreementListStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case AGREEMENT_LIST_ACT_TYP.LOAD_DATA:
            return { ...state, isLoading: true, error: '' };
        case AGREEMENT_LIST_ACT_TYP.LOAD_DATA_DONE:
            return { ...state, isLoading: false, error: action.payload.error, gridData: action.payload.gridData, info: action.payload.info};
        case AGREEMENT_LIST_ACT_TYP.API_ERROR:
            return { ...state, isLoading: false, error: action.payload.error, };
        default:
            return state;
    }
}

export default AgreementListStore;