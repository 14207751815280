import { IOption } from "components/MyComponents/CustomSelect";
import { useTranslation } from "react-i18next";
import './register.scss';

interface ITaxiOrCourierToggle   {
  isSelected: boolean,
  option : IOption,
  toggleSelection: (appId: string) => void
}

const TaxiOrCourierToggle = ({option,  isSelected, toggleSelection }: ITaxiOrCourierToggle) => {

  const { t } = useTranslation();

  const onItemClicked = () => {
    toggleSelection(option.value);
  }

  return (
    <div className={"reg-taxi-or-courier-item-wrapper " + (isSelected ? "active" : "inactive")}
      onClick={onItemClicked}
    >      
      <img
        className={"reg-taxi-or-courier-item " + (isSelected ? "active" : "inactive")}
        src={'/img/'+option.value+'.png'} alt={option.label} />
       <span>{t('Registration.Page2.WorkAs'+ option.value)}</span>
     
    </div>
  )


}

export default TaxiOrCourierToggle;