import { DetailsFiles } from "components/Common/datatable/DetailsFiles";
import { MobileGridRowCompact } from "components/Common/datatable/MobileGridRowCompact";
import TableComponent from "components/Common/datatable/TableComponent";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { IGridDataExtended } from "store/IGridData";
import { DOC2SIGN_LIST_ACT } from "./store/docs2SignList";
import { IDoc2SignDto } from "./store/reducerDocs2SignList";
import { DetailsItem } from "components/Common/datatable/DetailsItem";
import Docs2SignSignaturePage from "./Docs2SignSignaturePage";

const Docs2SignListPage = () => {

  const docs2SignListStore: IGridDataExtended<IDoc2SignDto, null> = useSelector((state: any) => state.Docs2SignListStore);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedExtDocIdToSign, setSelectedExtDocIdToSign] = useState<number | null>(null)


  useEffect(() => {
    if (docs2SignListStore.reloadRequired) {
      dispatch(DOC2SIGN_LIST_ACT.loadData());
    }
  }, [docs2SignListStore.reloadRequired, dispatch])

  const renderContent = (row: any) => {
    const getChildrenInstant = () => {
      return (<>
        <DetailsItem labelKey="DOC2SIGN.documentNumber" value={row.documentNumber} />
        <DetailsItem labelKey="DOC2SIGN.documentDate" value={row.documentDate} />
        {(row.documentKind === 'rachunek_najem' || row.documentKind === 'rachunek_zlecenie') &&
          <DetailsItem labelKey="DOC2SIGN.reffId" value={row.reffId} />
        }
      </>)
    }
    const getChildrenDeffered = () => {
      return (
        <>
          <DetailsFiles labelKey="ATT_NEW.attachment.files" files={
            [
              {
                "id": row.pdfFileId,
                "fileName": row.pdfFileName,
                "contentType": "application/pdf"
              }
            ]
          } />

          <div className="py-2">
            <ButtonCustom id="button-docs2sign-sign"
              label="DOC2SIGN.button.sign"
              iconClassPre="fa-duotone fa-signature"
              onClick={() => { setSelectedExtDocIdToSign(row.id) }} />
          </div>

        </>)
    }
    const getHeader = () => {
      return (
        <div className="head-with-badge prevent-select">
          <h3 className="w-100 row-item-header">
            {t('DOC2SIGN.kind.' + row.documentKind)}
          </h3>
          <span className="badge red-badge">{t('DOC2SIGN.status.' + row.status)}</span>
          <span className={"badge green-badge"}>
            <i className={"fa-solid fa-hourglass-end px-1 "}></i>
            <span>{row.documentDate}</span>
          </span>
        </div>

      )
    }

    return (
      <MobileGridRowCompact
        id={row.id}
        getHeader={getHeader} getChildrenInstant={getChildrenInstant} getChildrenDeffered={getChildrenDeffered} />
    )
  }

  console.log('docs2SignListStore',docs2SignListStore?.gridData.length)

  return (
    <Container fluid>
      {selectedExtDocIdToSign === null &&
        <TableComponent
          tableName="DOC2SIGN"
          hederKey="DOC2SIGN.mobile.header"
          renderContent={renderContent}
          data={docs2SignListStore}
        />
      }
      {selectedExtDocIdToSign !== null &&
        <Docs2SignSignaturePage
          extDocId={selectedExtDocIdToSign}
          setExtDocId={setSelectedExtDocIdToSign}
          docsToSaveCount={docs2SignListStore?.gridData.length}
          />
      }
    </Container>
  )
}
export default Docs2SignListPage; 