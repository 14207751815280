import { IOption } from "components/MyComponents/CustomSelect";
import { AGREEMENT_PRE_LIST_ACTION_TYPES } from "./actionsPreAgrList";

export interface IPreAgreementListState {
    availableTypes: IOption[],
    availableTypesLoaded: boolean,
    isLoading: boolean,
    error: string,
    info: string,
}

const INIT_STATE: IPreAgreementListState = {

    //dostepne typy umów
    availableTypes: [],
    availableTypesLoaded: false,
    isLoading: true,
    error: '',
    info: ''
};

const AgreementPreListStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {

        //obtaining agreements types
        case AGREEMENT_PRE_LIST_ACTION_TYPES.GET_AVAILABLE_TYPES:
            return {
                ...state, isLoading: true, error: '', info: ''
            };
        case AGREEMENT_PRE_LIST_ACTION_TYPES.GET_AVAILABLE_TYPES_DONE:
            return {
                ...state, 
                availableTypes: action.payload.gridDataResponse.gridData, 
                info: action.payload.gridDataResponse.info, 
                error: action.payload.gridDataResponse.error,                 
                isLoading: false, 
                availableTypesLoaded: true,
            };
        case AGREEMENT_PRE_LIST_ACTION_TYPES.FORCE_RELOAD_AVAILABLE_TYPES:
            return {
                ...state, error: '', info: '', availableTypesLoaded: false
            };
        case AGREEMENT_PRE_LIST_ACTION_TYPES.API_FAIL:
            return {
                ...state, isLoading: false, error: action.payload.error
            };
        default:
            return state;
    }
}

export default AgreementPreListStore;