import { getCircleCompanyId, IMG_UTIL } from "helpers/image_helper";
import { negative } from "helpers/money";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { rowCsvItemMoney, rowCsvItemMoneyWithZero, rowString } from "./SettUtil";

export interface ISettlmentDTO {
  id: number,
  step_0_pre_last_settlement_balance?: number,
  step_0_pre_virtual_returns_balance?: number,
  step_1_csv_total_amount?: number,
  step_2_app_cost?: number,
  step_4_7_tax_sum_S2?: number,
  allCostsAndDepositsSum?: number,
  step_7_2_balance_s8_vat_returns?: number,
  step_9_5_all_transfer_sum?: number,
  step_9_5_b2b_to_be_paid?: number,
  step_10_1_balance_after_settlement?: number,
  step_1_csv_uber_eats_tax_to_regain?: number
  b2B: boolean
}

export interface ISettlementDetails03_Summary {
  dto: ISettlmentDTO,
}
export const SettlementDetails03_Summary = ({ dto }: ISettlementDetails03_Summary) => {
  const { t } = useTranslation();
  const hasTransferOrB2BRevenue = (dto.step_9_5_b2b_to_be_paid && dto.step_9_5_b2b_to_be_paid > 0) || (dto.step_9_5_all_transfer_sum && dto.step_9_5_all_transfer_sum > 0);
  return (
    <div className="mb-4">
      <Row className="csv-item-header align-items-center m-0">
        <Col xs="auto" className="csv-item-header-logo" >
          <img className="app-logo-img-details pe-1" src="/img/bill.svg" alt='Summary' />
        </Col>
        <Col className="csv-item-header-data">
          <span className="table-h-con">
            <h3>
              {t('settlement.summary.header')}
            </h3>
          </span>
        </Col>
      </Row>


      {rowCsvItemMoneyWithZero(t, ((dto.step_0_pre_last_settlement_balance || 0) + (dto.step_0_pre_virtual_returns_balance|| 0)), 'balance-prev', 'summary')}
      
      
      {rowCsvItemMoneyWithZero(t, (dto.step_1_csv_total_amount || 0) + (dto.step_1_csv_uber_eats_tax_to_regain || 0), 'app-revenue', 'summary')}
      
      {rowCsvItemMoneyWithZero(t, negative(dto.step_2_app_cost), 'app-cost', 'summary')}
      {rowCsvItemMoney(t, negative(dto.step_4_7_tax_sum_S2), 'zus-us', 'summary')}
      {rowCsvItemMoney(t, dto.allCostsAndDepositsSum, 'addons', 'summary')}
      {rowCsvItemMoney(t, dto.step_7_2_balance_s8_vat_returns, 'vat-returns', 'summary')}
          
      {(!dto.b2B && dto.step_1_csv_uber_eats_tax_to_regain !== undefined  && dto.step_1_csv_uber_eats_tax_to_regain !== 0) &&      
        rowCsvItemMoneyWithZero(t, dto.step_9_5_all_transfer_sum, 'transfers-sum-done', 'summary', 'summary-row-first')
      }

      {(!dto.b2B  && (dto.step_1_csv_uber_eats_tax_to_regain === undefined ||dto.step_1_csv_uber_eats_tax_to_regain === 0)) &&        
        rowCsvItemMoneyWithZero(t, dto.step_9_5_all_transfer_sum, 'transfers-sum', 'summary', 'summary-row-first')
      }

      {dto.b2B &&
        rowCsvItemMoneyWithZero(t, dto.step_9_5_b2b_to_be_paid, 'b2b-sum', 'summary', 'summary-row-first')
      }
      {
        rowCsvItemMoneyWithZero(t, dto.step_10_1_balance_after_settlement, 'balance-new', 'summary', hasTransferOrB2BRevenue ? 'summary-row' : 'summary-row-first')
      }
    </div>
  )
}