import { IOption } from "components/MyComponents/CustomSelect";
import { IDictionaries } from "pages/Agreement/IAgreement";
import { CONFIG_ACT_TYP } from "./actionsConfig";


export interface IConfig {
    invoiceTypes: IOption[],
    vatRates: IOption[],
    parameters: any,
    dictionaries: IDictionaries
}

const DEFAULT_CONFIG: IConfig = {
    invoiceTypes: [],
    vatRates: [],
    parameters: {},
    dictionaries: {}
}

const INIT_STATE = {
    config: DEFAULT_CONFIG,
    isLoading: false,
    reloadRequired: true,
    error: ''
};

const ConfigStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case CONFIG_ACT_TYP.LOAD_DATA:
            return { ...state, isLoading: true, error: '' };
        case CONFIG_ACT_TYP.LOAD_DATA_DONE:
            return { ...state, isLoading: false, config: action.payload.config };
        case CONFIG_ACT_TYP.API_ERROR:
            return { ...state, isLoading: false, error: action.payload.error, };
        default:
            return state;
    }
}

export default ConfigStore;