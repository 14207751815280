import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { compressImage, isImage } from './ImageCompressor';
import MobileCapture from './MobileCapture';
import MobileFiles from './MobileFiles';


export interface ICompoundFile {
  uloadedFile?: File,
  base64Content?: string,
  type: 'upload' | 'camera'
}

interface IFileUploader {
  fileAdd: (file: ICompoundFile) => void;
  showError: (error: string) => void;
  mobilePhotoButtonKey: string;
  mobileUploadButtonKey: string;
  webUploadButtonKey: string;
  webUploadEmptyButtonKey: string;
  isEmptyMessageRequired: boolean;
}

export const ACCEPT_IMAGES_AND_PDF = {
  'image/jpeg': ['.jpeg', '.JPEG', '.jpg', '.JPG'],
  'image/png': ['.PNG', '.png'],
  'application/pdf': ['.pdf', '.PDF'],
};

export const FileUploader = ({ fileAdd, showError, mobilePhotoButtonKey, mobileUploadButtonKey, webUploadButtonKey, webUploadEmptyButtonKey, isEmptyMessageRequired }: IFileUploader) => {

  const { t } = useTranslation();
  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    if (rejFiles && rejFiles.length > 0) {
      showError(t('FileUploader.Error.' + rejFiles[0].errors[0].code));
    } else if (accFiles && accFiles.length > 0) {
      accFiles.forEach(async function (file) {
        const fileOpt = isImage(file.type) ? await compressImage(file) : file;
        fileAdd({ uloadedFile: fileOpt, type: 'upload' });
        showError('');
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ACCEPT_IMAGES_AND_PDF,
    maxSize: 10 * 1024 * 1024, // 5MB 
  });

  const handleCapture = async (target: any) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const fileOpt = isImage(file.type) ? await compressImage(file) : file;
        fileAdd({ uloadedFile: fileOpt, type: 'upload' });
        showError('');
      }
    }
  }
  return (
    <Row>
      {isMobile &&
        <>
          <Col xs="auto">
         {/*}   <MobileCapture handleFile={handleCapture} buttonLabel={t(mobilePhotoButtonKey)} />
          </Col>
      <Col> */}
            <MobileFiles handleFile={handleCapture} buttonLabel={t(mobileUploadButtonKey)} />
          </Col>
        </>
      }
      {!isMobile &&
        <Col>
          <div className='custom-drop-zone d-flex justify-content-center align-items-center blue' {...getRootProps()}>
            <input {...getInputProps()} />
              <span className='p-2'><i className='fa-solid fa-cloud-arrow-up fa-xl' /></span>
              <span className='p-2'>{isEmptyMessageRequired ? t(webUploadEmptyButtonKey) : t(webUploadButtonKey)}</span>
          </div>
        </Col>
      }
    </Row>
  )
}
export default FileUploader;