import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import CustomCheckbox from "components/MyComponents/CustomCheckbox";
import CustomTextArea from "components/MyComponents/CustomTextArea";
import { Field, Form, Formik, FormikValues } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { PROFILE_REMOVAL_ACT } from "./store/actionsProfileRemoval";

const PROFILE_REMOVAL_VALIDATOR = YUP_VALIDATION.YUP_OBJ.shape({
  description: YUP_VALIDATION.YUP_STRING_ANY_CHARS_REQ,
  removeMe: YUP_VALIDATION.YUP_BOOL_TRUE_REQUIRED,
});

const ProfileRemovalPage = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const contractorId = useSelector((state: any) => state.LoginState.contractorId);
  const profileRemovalStore = useSelector((state: any) => state.ProfileRemovalStore);
  //const dictionaries = useSelector((state: any) => state.ConfigStore.config.dictionaries);

  const onSubmit = (values: FormikValues) => {
    dispatch(PROFILE_REMOVAL_ACT.removeProfile({
      contractorId: contractorId,
      description: values.description,
    },
      history
    ));
  };


  return (
    <Container>
      <DialogWrapper title="removeProfile.header">
        <Formik
          initialValues={{ description: '', removeMe: false }}
          validationSchema={PROFILE_REMOVAL_VALIDATOR}
          onSubmit={onSubmit}
        >
          <Form>
            <Row className="my-2 py-2">
              <Col>
                <Field name="removeMe" label="removeProfile.confirmation" component={CustomCheckbox} type="checkbox" isDisabled={profileRemovalStore.removalActionInProgress} />
              </Col>
            </Row>
            <Row>
              <Col className="py-2">
                <Field name="description" label="removeProfile.reason" component={CustomTextArea} isDisabled={profileRemovalStore.removalActionInProgress} />
              </Col>
            </Row>
            <Row className="my-2 py-2 pb-3">
              <Col><ButtonSubmit 
              id="button-profile-remove"
              color="danger" isLoading={profileRemovalStore.removalActionInProgress} label="removeProfile.button" iconClass="bx bx-trash" /></Col>
            </Row>
          </Form>
        </Formik>
      </DialogWrapper>
    </Container>

  );
};

export default ProfileRemovalPage; 