import { IGenericFormItem } from "./GenericFormItem";
import HelperHeaderContent from "./HelperHeaderContent";


const HelperHeader = ({ formItem, pageKey, context }: IGenericFormItem) => {

  if (!formItem) {
    return <h3>Config error: {context}</h3>
  }
  const fieldKey = pageKey + '.' + formItem.name;
  const textBefore = formItem.textBeforeSuffix ? fieldKey + '.' + formItem.textBeforeSuffix : undefined;
  const textAfter = formItem.textAfterSuffix ? fieldKey + '.' + formItem.textAfterSuffix : undefined;

  return (    
    <HelperHeaderContent className={formItem.className} headerText={fieldKey} textAfter={textAfter} textBefore={textBefore}/>    
  )

}

export default HelperHeader