import { ENDPOINT_INVOICE, postMultipart } from "helpers/api_helper";
import { ISagaParam } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { ROUTES } from "routes";
import { INVOICE_NEW_ACT, INVOICE_NEW_ACT_TYP } from "./actionsInvNew";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";


function* addInvoiceSaga(action: ISagaParam) {
    try {
        const formData = new FormData();

        const values = action.payload.values;
        const selfDescribed = values.selfDescribed;

        if (selfDescribed) {
            formData.append('newInvoiceDTO', JSON.stringify(
                {
                    invoiceNumber: values.invoiceNumber,
                    invoiceDate: values.invoiceDate,
                    invoiceType: values.invoiceType,
                    invoiceItems: values.invoiceItems,
                }
            ));
        }

        if (values.description) {
            formData.append('description', values.description);
        }

        let numImagesToProcess = values.files.length;
        for (let i = 0; i < numImagesToProcess; i++) {
            const fileRaw = values.files[i].uloadedFile;
            formData.append('files', fileRaw, fileRaw.name);
        }

        yield call(postMultipart,
            selfDescribed ? ENDPOINT_INVOICE.RESOURCE_POST_INVOICE_SELF : ENDPOINT_INVOICE.RESOURCE_POST_INVOICE_OFFICE,
            formData)
        yield put(INVOICE_NEW_ACT.addInvoiceDone());
        action.payload.history.push(ROUTES.ROUTE_INVOICE_LIST)
        showPopupInfo('INV_NEW.sentToValidation')
    } catch (error) {
        console.log('attachmentNew.addInvoiceSaga error', error);
        showException(error);
        yield put(INVOICE_NEW_ACT.apiError(error));
    }
}



export function* InvoiceNewSaga() {
    yield takeEvery(INVOICE_NEW_ACT_TYP.ADD_INVOICE, addInvoiceSaga);
}

export default InvoiceNewSaga;
