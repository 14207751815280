import { ENDPOINTS, get, patch } from "helpers/api_helper";
import { IDataResponse } from "helpers/IDataResponse";
import { ISagaParam } from "helpers/tsx_helper";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PROFILE_ACT, PROFILE_ACT_TYP } from "./actionsProfile";
import { IProfile } from "./reducerProfile";
import { showException, showIDataResponse, showPopupError, showPopupInfo } from "helpers/sweetalert/ShowAlert";
import { reloadRoles } from "store/actions";

function* getProfile(action: ISagaParam) {
    try {
        let profileDataResponse: IDataResponse<IProfile> = yield call(get, ENDPOINTS.RESOURCE_PROFILE, {})
        yield put(PROFILE_ACT.getProfileDone(
            profileDataResponse.data,
            profileDataResponse.info,
            profileDataResponse.error,
            profileDataResponse.exclamation
        ));
    } catch (error) {
        showException(error);
        console.log('getProfile error', error);
        yield put(PROFILE_ACT.getProfileError(error));
    }
}

function* setProfile(action: ISagaParam) {
    try {
        let profileDataResponse: IDataResponse<IProfile> = yield call(patch, ENDPOINTS.RESOURCE_PROFILE, action.payload.profile)
        yield put(PROFILE_ACT.setProfileDone(profileDataResponse.data));
        yield put(reloadRoles());
        showIDataResponse(profileDataResponse);

    } catch (error) {
        showException(error);
        console.log('setProfile error', error);
        yield put(PROFILE_ACT.apiError(error));
    }
}

function* setProfileCycle(action: ISagaParam) {
    try {
        let profileDataResponse: IDataResponse<IProfile> = yield call(patch, ENDPOINTS.RESOURCE_PROFILE_CYCLE, action.payload.profile)
        yield put(PROFILE_ACT.setProfileCycleDone(profileDataResponse.data));
        showIDataResponse(profileDataResponse);
    } catch (error) {
        showException(error);
        console.log('setProfileCycle error', error);
        yield put(PROFILE_ACT.apiError(error));
    }
}

export function* ProfileSaga() {
    yield takeLatest(PROFILE_ACT_TYP.GET_PROFILE, getProfile);
    yield takeLatest(PROFILE_ACT_TYP.SET_PROFILE, setProfile);
    yield takeLatest(PROFILE_ACT_TYP.SET_PROFILE_CYCLE, setProfileCycle);
}

export default ProfileSaga;
