import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationEN from "./locales/en/translation.json"
import translationEN_agreement_b2b from "./locales/en/translation_agr_b2b.json"
import translationEN_agreement_bsb from "./locales/en/translation_agr_bsb.json"
import translationEN_agreement_zlecenie from "./locales/en/translation_agr_zlecenie.json"
import translationEN_agreement_pre from "./locales/en/translation_agr_pre.json"
import translationEN_agreement_zx1 from "./locales/en/translation_agr_zx1.json"
import translationEN_agreement_nar from "./locales/en/translation_agr_najem_rower.json"
import translationEN_agreement_nas from "./locales/en/translation_agr_najem_auta.json"
import translationEN_agreement_uzp from "./locales/en/translation_agr_uzyczenie_prv.json"
import translationEN_agreement_uzb from "./locales/en/translation_agr_uzyczenie_b2b.json"
import translationEN_agreement_oud from "./locales/en/translation_udostepnienie_decyzji.json"

import translationEN_settlements_details from "./locales/en/translation_settlements_details.json"
import translationEN_profile from "./locales/en/translation_profile.json"
import translationEN_affiliation_link_sms from "./locales/en/translation_aff_link.json"
import translationEN_nationalities from "./locales/en/nationalities.json"
import translationEN_contactForm from "./locales/en/translation_contact_form.json"


import translationPL from "./locales/pl/translation.json"
import translationPL_agreement_b2b from "./locales/pl/translation_agr_b2b.json"
import translationPL_agreement_bsb from "./locales/pl/translation_agr_bsb.json"
import translationPL_agreement_zlecenie from "./locales/pl/translation_agr_zlecenie.json"
import translationPL_agreement_pre from "./locales/pl/translation_agr_pre.json"
import translationPL_agreement_zx1 from "./locales/pl/translation_agr_zx1.json"
import translationPL_agreement_nar from "./locales/pl/translation_agr_najem_rower.json"
import translationPL_agreement_nas from "./locales/pl/translation_agr_najem_auta.json"
import translationPL_agreement_uzp from "./locales/pl/translation_agr_uzyczenie_prv.json"
import translationPL_agreement_uzb from "./locales/pl/translation_agr_uzyczenie_b2b.json"
import translationPL_agreement_oud from "./locales/pl/translation_udostepnienie_decyzji.json"

import translationPL_settlements_details from "./locales/pl/translation_settlements_details.json"
import translationPL_profile from "./locales/pl/translation_profile.json"
import translationPL_affiliation_link_sms from "./locales/pl/translation_aff_link.json"
import translationPL_nationalities from "./locales/pl/nationalities.json"
import translationPL_contactForm from "./locales/pl/translation_contact_form.json"

import translationRU from "./locales/ru/translation.json"
import translationRU_agreement_b2b from "./locales/ru/translation_agr_b2b.json"
import translationRU_agreement_bsb from "./locales/ru/translation_agr_bsb.json"
import translationRU_agreement_zlecenie from "./locales/ru/translation_agr_zlecenie.json"
import translationRU_agreement_pre from "./locales/ru/translation_agr_pre.json"
import translationRU_agreement_zx1 from "./locales/ru/translation_agr_zx1.json"
import translationRU_agreement_nar from "./locales/ru/translation_agr_najem_rower.json"
import translationRU_agreement_nas from "./locales/ru/translation_agr_najem_auta.json"
import translationRU_agreement_uzp from "./locales/ru/translation_agr_uzyczenie_prv.json"
import translationRU_agreement_uzb from "./locales/ru/translation_agr_uzyczenie_b2b.json"
import translationRU_agreement_oud from "./locales/ru/translation_udostepnienie_decyzji.json"
import translationRU_settlements_details from "./locales/ru/translation_settlements_details.json"
import translationRU_profile from "./locales/ru/translation_profile.json"
import translationRU_affiliation_link_sms from "./locales/ru/translation_aff_link.json"
import translationRU_nationalities from "./locales/ru/nationalities.json"
import translationRU_contactForm from "./locales/ru/translation_contact_form.json"

import translationGE from "./locales/ge/translation.json"
import translationGE_agreement_b2b from "./locales/ge/translation_agr_b2b.json"
import translationGE_agreement_bsb from "./locales/ge/translation_agr_bsb.json"
import translationGE_agreement_zlecenie from "./locales/ge/translation_agr_zlecenie.json"
import translationGE_agreement_pre from "./locales/ge/translation_agr_pre.json"
import translationGE_agreement_zx1 from "./locales/ge/translation_agr_zx1.json"
import translationGE_agreement_nar from "./locales/ge/translation_agr_najem_rower.json"
import translationGE_agreement_nas from "./locales/ge/translation_agr_najem_auta.json"
import translationGE_agreement_uzp from "./locales/ge/translation_agr_uzyczenie_prv.json"
import translationGE_agreement_uzb from "./locales/ge/translation_agr_uzyczenie_b2b.json"
import translationGE_agreement_oud from "./locales/ge/translation_udostepnienie_decyzji.json"
import translationGE_settlements_details from "./locales/ge/translation_settlements_details.json"
import translationGE_profile from "./locales/ge/translation_profile.json"
import translationGE_affiliation_link_sms from "./locales/ge/translation_aff_link.json"
import translationGE_nationalities from "./locales/ge/nationalities.json"
import translationGE_contactForm from "./locales/ge/translation_contact_form.json"

import translationUA from "./locales/ua/translation.json"
import translationUA_agreement_b2b from "./locales/ua/translation_agr_b2b.json"
import translationUA_agreement_bsb from "./locales/ua/translation_agr_bsb.json"
import translationUA_agreement_zlecenie from "./locales/ua/translation_agr_zlecenie.json"
import translationUA_agreement_pre from "./locales/ua/translation_agr_pre.json"
import translationUA_agreement_zx1 from "./locales/ua/translation_agr_zx1.json"
import translationUA_agreement_nar from "./locales/ua/translation_agr_najem_rower.json"
import translationUA_agreement_nas from "./locales/ua/translation_agr_najem_auta.json"
import translationUA_agreement_uzp from "./locales/ua/translation_agr_uzyczenie_prv.json"
import translationUA_agreement_uzb from "./locales/ua/translation_agr_uzyczenie_b2b.json"
import translationUA_agreement_oud from "./locales/ua/translation_udostepnienie_decyzji.json"
import translationUA_settlements_details from "./locales/ua/translation_settlements_details.json"
import translationUA_profile from "./locales/ua/translation_profile.json"
import translationUA_affiliation_link_sms from "./locales/ua/translation_aff_link.json"
import translationUA_nationalities from "./locales/ua/nationalities.json"
import translationUA_contactForm from "./locales/ua/translation_contact_form.json"

import pdf_localeEN from '@react-pdf-viewer/locales/lib/en_US.json';
import pdf_localeRU from '@react-pdf-viewer/locales/lib/ru_RU.json';
import pdf_localePL from './locales/pl/pdf-viewer-PL.json'

// the translations
const resources = {
  pl: {
    translation: {
      ...translationPL,
      ...translationPL_agreement_b2b,
      ...translationPL_agreement_bsb,
      ...translationPL_agreement_zlecenie,
      ...translationPL_agreement_pre,
      ...translationPL_agreement_nar,
      ...translationPL_agreement_nas,
      ...translationPL_agreement_uzp,
      ...translationPL_agreement_uzb,
      ...translationPL_agreement_oud,
      ...translationPL_agreement_zx1,
      ...translationPL_settlements_details,
      ...translationPL_profile,
      ...translationPL_affiliation_link_sms,
      ...translationPL_nationalities,
      ...translationPL_contactForm,
    },
  },
  en: {
    translation: {
      ...translationEN,
      ...translationEN_agreement_b2b,
      ...translationEN_agreement_bsb,
      ...translationEN_agreement_zlecenie,
      ...translationEN_agreement_pre,
      ...translationEN_agreement_nar,
      ...translationEN_agreement_nas,
      ...translationEN_agreement_uzp,
      ...translationEN_agreement_uzb,
      ...translationEN_agreement_oud,
      ...translationEN_agreement_zx1,
      ...translationEN_settlements_details,
      ...translationEN_profile,
      ...translationEN_affiliation_link_sms,
      ...translationEN_nationalities,
      ...translationEN_contactForm,
    }
  },
  ua: {
    translation: {
      ...translationUA,
      ...translationUA_agreement_b2b,
      ...translationUA_agreement_bsb,
      ...translationUA_agreement_zlecenie,
      ...translationUA_agreement_pre,
      ...translationUA_agreement_nar,
      ...translationUA_agreement_nas,
      ...translationUA_agreement_uzp,
      ...translationUA_agreement_uzb,
      ...translationUA_agreement_oud,
      ...translationUA_agreement_zx1,
      ...translationUA_settlements_details,
      ...translationUA_profile,
      ...translationUA_affiliation_link_sms,
      ...translationUA_nationalities,
      ...translationUA_contactForm,
    },
  },
  ge: {
    translation: {
      ...translationGE,
      ...translationGE_agreement_b2b,
      ...translationGE_agreement_bsb,
      ...translationGE_agreement_zlecenie,
      ...translationGE_agreement_pre,
      ...translationGE_agreement_nar,
      ...translationGE_agreement_nas,
      ...translationGE_agreement_uzp,
      ...translationGE_agreement_uzb,
      ...translationGE_agreement_oud,
      ...translationGE_agreement_zx1,
      ...translationGE_settlements_details,
      ...translationGE_profile,
      ...translationGE_affiliation_link_sms,
      ...translationGE_nationalities,
      ...translationGE_contactForm,
    },
  },
  ru: {
    translation: {
      ...translationRU,
      ...translationRU_agreement_b2b,
      ...translationRU_agreement_bsb,
      ...translationRU_agreement_zlecenie,
      ...translationRU_agreement_pre,
      ...translationRU_agreement_nar,
      ...translationRU_agreement_nas,
      ...translationRU_agreement_uzp,
      ...translationRU_agreement_uzb,
      ...translationRU_agreement_oud,
      ...translationRU_agreement_zx1,
      ...translationRU_settlements_details,
      ...translationRU_profile,
      ...translationRU_affiliation_link_sms,
      ...translationRU_nationalities,
      ...translationRU_contactForm,
    },
  },
}

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "pl")
}

export const getPdfViewerLocale = (lang) => {  
  if (lang === 'en' || lang === 'ge') {
    return pdf_localeEN;
  }

  if (lang === 'ua' || lang === 'ru') {
    return pdf_localeRU;
  }

  return pdf_localePL;
}



i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "pl",
    fallbackLng: "pl", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
