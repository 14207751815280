import LoadingContent from "components/Common/LoadingContent";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { toPln } from "helpers/pln_helper";
import DashboardItem from "pages/Dashboard/DashboardItem";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

const AffiliationDialogSummary = () => {

  const affiliationLinkStore = useSelector((state: any) => state.AffiliationLinkStore);

  return (
    <DialogWrapper title="AffLinkSend.summary.title">
      <>
        {affiliationLinkStore.isLoading &&
          <Row><Col><LoadingContent /></Col></Row>
        }
        {!affiliationLinkStore.isLoading &&
          <>
            <DashboardItem titleKey="AffLinkSend.summary.bonusAmount"
              valueFormatted={toPln(affiliationLinkStore.context.bonusAmount)}
              valueClass={"money-positive"}
            />
            <DashboardItem titleKey="AffLinkSend.summary.bonusCount"
              valueFormatted={affiliationLinkStore.context.bonusCount}
            />
            <DashboardItem titleKey="AffLinkSend.summary.waiting"
              valueFormatted={affiliationLinkStore.context.waiting}
            />
          </>
        }
      </>
    </DialogWrapper>
  );
};


export default AffiliationDialogSummary; 