import { CONTACT_FORM_ACT_TYP } from "./actionsContactForm";

export interface IContactFormReducer {
    areChangesInProgress: boolean,
    error: string,
    info: string,
}

const INIT_STATE: IContactFormReducer = {
    areChangesInProgress: false,
    error: '',
    info: '',
};

const ContactFormStore = (state = INIT_STATE, action: { type: any, payload: any }) => {

    switch (action.type) {
        case CONTACT_FORM_ACT_TYP.SEND_CONTACT_FORM_MESSAGE:
            return { ...state, error: '', info:'', areChangesInProgress: true };
        case CONTACT_FORM_ACT_TYP.SEND_CONTACT_FORM_MESSAGE_DONE:
            return { ...state, error: '', areChangesInProgress: false, info: action.payload.info };
        case CONTACT_FORM_ACT_TYP.API_ERROR:
            return { ...state, areChangesInProgress: false, error: action.payload.error, info: '' };
        default:
            return state;
    }
}


export default ContactFormStore;