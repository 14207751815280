import { useWindowSize } from "@react-hook/window-size";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import Confetti from 'react-confetti';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { ROUTES, ROUTE_AGREEMENT_P5_SUMMARY } from "routes";

const AgreementPage5SignSummary = () => {
  let { agreementDocNumber } = useParams<ROUTE_AGREEMENT_P5_SUMMARY>();
  const agrDocNum = agreementDocNumber.replaceAll('-', '/');
  const [width, height] = useWindowSize()

  const history = useHistory();
  const { t } = useTranslation();

  return (<Container fluid>
    <Confetti width={width - 20} height={height} />    
    <DialogWrapper title="">
      <div className="px-0 px-md-2">
        <div className="helper-text-extender text-center blue">
          <h1>{t('Agreement.WizardPage.SignAgreement.AgreementCreationDone')}</h1>
          <h2 className="mt-3"><i>{agrDocNum}</i></h2>
        </div>
        <div className="text-center py-3">
          <i className="fa-duotone fa-handshake fa-6x blue"></i>          
        </div>
        <div className="py-2 text-justify">
          {t('Agreement.WizardPage.SignAgreement.AgreementCreationDone.BottomText')}
        </div>
        <div className="py-3">
          <ButtonCustom
            id="button-agreement-done-back-2-dashboard" 
            fullWidth={true}
            isLoading={false} label="Agreement.WizardPage.SignAgreement.AgreementCreationDoneGotoDashboard"
            onClick={() => { history.push(ROUTES.ROUTE_DASHBOARD) }}
          />
        </div>
      </div>
    </DialogWrapper>
  </Container >
  );
}

export default AgreementPage5SignSummary;
