import { IOption } from "components/MyComponents/CustomSelect";

export const translateOptions = (options: IOption[], t: any): IOption[] => {
    if (options) {
        for (const opt of options) {
            opt.label = t(opt.labelKey ? opt.labelKey : opt.label);
        }
    }
    return options;
}

export const orElse = (input: any, elseText: string = 'N/D'): string => {
    if (input) {
        return input;
    }
    return elseText;
}

export const translateIfHave = (text: string, prefix: string, t: any, i18n: any) => {
    const searchedKey = prefix + text;
    if (i18n.exists(searchedKey)) {
        return t(searchedKey);
    } else {
        return text;
    }
}

