import { Field, FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { FormGroup, FormText, Label } from "reactstrap";
import TranslatedErrors from "./TranslatedErrors";

interface TextProps extends FieldProps {
  placeholder?: string;
  label?: string;
  textAfter?: string;
  textBefore?: string;
  isDisabled?: boolean;
  autoFocus?: boolean;
}

export const CustomDate = ({
  placeholder,
  label,
  field,
  form,
  textBefore,
  textAfter,
  isDisabled,
  autoFocus

}: TextProps) => {

  const { t } = useTranslation();

  if (field.value === undefined) {
    return <></>
  }

  const hasError = form.errors[field.name] && form.touched[field.name];
  return (
    <>
      {textBefore &&
        <FormGroup  >
          <FormText>{t(textBefore)}</FormText>
        </FormGroup>
      }
      <FormGroup>
        {label &&
          <Label htmlFor={field.name}>{t(label)}</Label>
        }
        <Field type="date" disabled={isDisabled} autoComplete="off"
          style={{ width: '165px' }}
          name={field.name}
          id={field.name}
          placeholder={placeholder}
          autoFocus={autoFocus}
          className={"form-control" + (hasError ? " is-invalid" : "")}
        />
        {hasError &&
          <TranslatedErrors errors={form.errors[field.name]} />
        }
        {textAfter &&
          <FormText>{t(textAfter)}</FormText>
        }
      </FormGroup>

    </>
  );
};

export default CustomDate;
