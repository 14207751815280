const PREFIX = "CFA_"

const SEND_CONTACT_FORM_MESSAGE = PREFIX + "SEND_CONTACT_FORM_MESSAGE";
const sendFormData = (messageDto: any) => ({
    type: SEND_CONTACT_FORM_MESSAGE, payload: { messageDto}
});


const SEND_CONTACT_FORM_MESSAGE_DONE = PREFIX + "SEND_CONTACT_FORM_MESSAGE_DONE";
const sendFormDataDone = (info: string) => ({
    type: SEND_CONTACT_FORM_MESSAGE_DONE, payload: {info}
});


const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const CONTACT_FORM_ACT = {
    sendFormData,sendFormDataDone,
    apiError
}

export const CONTACT_FORM_ACT_TYP = {
    SEND_CONTACT_FORM_MESSAGE,SEND_CONTACT_FORM_MESSAGE_DONE,
    API_ERROR
}