import CustomSelect, { SELECT_SINGLE_DEFAULT_PLACEHOLDER } from 'components/MyComponents/CustomSelect';
import CustomText from 'components/MyComponents/CustomText';
import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  CloseButton,
  Col, Row
} from 'reactstrap';
import { DEFAULT_NEW_POSITION } from './InvoiceNew';
import ButtonFloating from 'components/MyComponents/ButtonFloating';
import ButtonCustom from 'components/MyComponents/ButtonCustom';

export interface IInvoiceNewFormItems {
  isLoading: boolean;
}

const InvoiceNewFormItems = ({ isLoading }: IInvoiceNewFormItems) => {

  const { t } = useTranslation();
  const vatRates = useSelector((state: any) => state.ConfigStore.config.vatRates);


  return <FieldArray name="invoiceItems">{
    (fieldArrayProos) => {
      const { push, remove, form } = fieldArrayProos;
      const { invoiceItems } = form.values;

      const addNewEmptyItem = () => {
        push(DEFAULT_NEW_POSITION)
      }
      return <>
        {
          invoiceItems.map((item: any, idx: number) => {
            return (
              <Row key={'inv-item-' + idx}>
                <Col xs={5}>
                  <Field name={`invoiceItems[${idx}].grossValue`} label={idx === 0 ? "INV_NEW.fields.item.grossValue" : ""}
                    component={CustomText} isDisabled={isLoading} />
                </Col>
                <Col xs={6}>
                  <Field name={`invoiceItems[${idx}].vatRate`} label={idx === 0 ? "INV_NEW.fields.item.vatRate" : ""}
                    placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
                    options={vatRates} className="custom-select" component={CustomSelect} />
                </Col>
                <Col xs={1}>
                  {idx > 0 &&
                    <CloseButton className='mt-1' onClick={() => { remove(idx) }} />
                  }
                </Col>
              </Row>
            )
          })
        }
        <div className="pb-3">
          <ButtonCustom
          id='button-invoice-item-add'          
          color="default"
          outline={true}
          className='blue'
          label='INV_NEW.items.new'
          iconClassPre="fa-regular fa-plus fa-xl blue"
          onClick={addNewEmptyItem}
        />


        </div>
      </>
    }
  }</FieldArray>
}

export default InvoiceNewFormItems;