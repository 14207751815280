import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonTripple from "components/MyComponents/ButtonTripple";
import CustomTextWithButton from "components/MyComponents/CustomTextWithButton";
import { Field, Form, Formik } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { AGREEMENT_ACTION } from "pages/Agreement/store-pre/actionsAgr";
import { IPreAgreementState } from "pages/Agreement/store-pre/reducerAgr";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ROUTE_AGREEMENT_P1_WIZARD__URL_PARAMS } from "routes";
import * as Yup from 'yup';
import HelperHeaderContent from "./FormItems/HelperHeaderContent";

const AgreementPage3SmsConfirmation = () => {

  const formValidator = Yup.object().shape({
    smsCode: YUP_VALIDATION.YUP_STRING_REQ,
  });

  interface FormValues {
    smsCode: string;
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [smsCount, setSmsCount] = useState(1);

  const history = useHistory();
  let { agreementType } = useParams<ROUTE_AGREEMENT_P1_WIZARD__URL_PARAMS>();

  const { isLoading, error, info, agreementDataLoaded } = useSelector((state: any) => {
    const agreementSignStore: IPreAgreementState = state.AgreementSignStore as IPreAgreementState;
    return (
      {
        isLoading: agreementSignStore.isLoading,
        error: agreementSignStore.error,
        info: agreementSignStore.info,
        agreementDataLoaded: agreementSignStore.agreementDataLoaded,
      })
  }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!agreementDataLoaded && error === '') {
      dispatch(AGREEMENT_ACTION.getAgreementConfigAndData(agreementType, null, history, false))
    }
  }, [])

  const onSubmit = (values: FormValues) => {
    dispatch(AGREEMENT_ACTION.smsConfirmCode(agreementType, values.smsCode, history));
  };

  const handleResend = () => {
    setSmsCount(curVal => curVal + 1);
    dispatch(AGREEMENT_ACTION.resendSMS(agreementType))
  };

  const onClickList = () => {
    dispatch(AGREEMENT_ACTION.getAgreementConfigAndData(agreementType, null /*domyślnie wizard*/, history, false));
  };

  const renderForm = () => {
    return (
      <Form>
        <Row>
          <Col xs={12}>
            <HelperHeaderContent
              headerText={'Agreement.WizardPage.SignAgreement.SMS.top_header'}
              textBefore={'Agreement.WizardPage.SignAgreement.SMS.top_header.line1'}
            />
          </Col>
          <Col xs={12}>
            <Field name="smsCode"
              label="Agreement.WizardPage.SignAgreement.SmsConfirmInput"
              component={CustomTextWithButton}
              isDisabled={isLoading}
              textAfter={info ? info : "Agreement.WizardPage.SignAgreement.SMS.fieldCode.line1"}
              buttonColor={smsCount ? "secondary" : "default"}
              isButtonDisabled={smsCount > 2 || isLoading}
              buttonLabel="Agreement.WizardPage.SignAgreement.SMS.fieldCode.resend"
              buttonOnClick={handleResend} isLoading={false}
            />
          </Col>
        </Row>
        <ButtonTripple
          id={`button-wizard-sms-confirm`}
          isLoading={isLoading}
          label="Agreement.WizardPage.SignAgreement.SmsConfirmButton"
          onClickList={onClickList}
        />
      </Form>
    )
  };

  return (<Container fluid>
    <DialogWrapper title="">
      <Formik
        initialValues={{ smsCode: '' }}
        validationSchema={formValidator}
        render={renderForm}
        onSubmit={onSubmit}
      />
    </DialogWrapper>
  </Container>
  );
};

export default AgreementPage3SmsConfirmation; 