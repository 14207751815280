import { getCurrentLanguage } from "components/CommonForBoth/TopbarDropdown/LanguageUtil";
import { downloadPdf } from "helpers/download_helper";
import { IPreAgreementData } from "pages/Agreement/IAgreement";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { ROUTES } from "routes";
import { fillPlaceholders } from "routes/placeholder_helper";
import { reloadRoles } from "store/actions";
import { ENDPOINT_AGREEMENT, get, patch, post } from "../../../helpers/api_helper";
import { ResponseGenerator } from "../../../helpers/tsx_helper";
import { AGREEMENT_PRE_LIST_ACTION } from "../store-pre-list/actionsPreAgrList";
import { AGREEMENT_ACTION, AGREEMENT_ACTION_TYPES } from "./actionsAgr";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";

interface ISagaParam {
    type: string,
    payload: any
}

function* getAgreementConfigAndData(action: ISagaParam) {
    try {
        const agreementType = action.payload.agreementType;
        let loadedData: IPreAgreementData = yield call(get,
            fillPlaceholders(
                ENDPOINT_AGREEMENT.RESOURCE_PRE_AGREEMENT_LOAD,
                {
                    'agreementType': agreementType,
                    'language': getCurrentLanguage(),
                }), {})
        yield put(AGREEMENT_ACTION.getAgreementConfigAndDataDone(
            loadedData.config,
            loadedData.data,
            loadedData.dictionaries,
            loadedData.canGeneratePreview,
            loadedData.canSign,
        ));
        const pageKey = action.payload.pageKey;
        if (!pageKey) {
            action.payload.history.push(
                fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P1_WIZARD, { 'agreementType': agreementType }));
        } else {
            action.payload.history.push(
                fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P2_FORM, { 'agreementType': agreementType, 'pageKey': pageKey }));
        }
    } catch (error) {
        console.log('getAgreementConfigAndData error', error);
        showException(error);
        yield put(AGREEMENT_ACTION.apiFail(error));
        if (action.payload.isAgreementTypeList) {
            //przy pierwszym wejsciu na stronę
            yield put(AGREEMENT_PRE_LIST_ACTION.apiFail(error));
        }
    }
}

function* openFormPage(action: ISagaParam) {
    action.payload.history.push(
        fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P2_FORM,
            {
                'agreementType': action.payload.agreementType,
                'pageKey': action.payload.pageKey,
            }));
}

function* saveData(action: ISagaParam) {
    try {
        const agreementType = action.payload.agreementType;

        let savedData: IPreAgreementData = yield call(patch,
            fillPlaceholders(
                ENDPOINT_AGREEMENT.RESOURCE_PRE_AGREEMENT_SAVE,
                {
                    'agreementType': agreementType,
                    'pageKey': action.payload.pageKey,
                    'language': getCurrentLanguage(),
                })
            , action.payload.values)


        yield put(AGREEMENT_ACTION.saveDataDone(
            savedData.config,
            savedData.data,
            savedData.dictionaries,
            savedData.canGeneratePreview,
            savedData.canSign,
        ));

        if (!action.payload.pageNextKey) {
            action.payload.history.push(fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P1_WIZARD, { 'agreementType': agreementType }));
        } else {
            action.payload.history.push(
                fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P2_FORM,
                    {
                        'agreementType': action.payload.agreementType,
                        'pageKey': action.payload.pageNextKey,
                    }));
        }

    } catch (error) {
        console.log('saveData error', error);
        showException(error);
        yield put(AGREEMENT_ACTION.apiFail(error));
    }
}

function* resendSMS(action: ISagaParam) {
    try {
        let startAgrUrl =
            fillPlaceholders(
                ENDPOINT_AGREEMENT.RESOURCE_PRE_AGREEMENT_SMS_RESEND,
                {
                    'agreementType': action.payload.agreementType,
                    'language': getCurrentLanguage(),
                })
        let startConfirm: ResponseGenerator = yield call(patch, startAgrUrl, {})
        yield put(AGREEMENT_ACTION.resendSMSDone("Agreement.WizardPage.SignAgreement.TextCommentResendMessage"));
        showPopupInfo("Agreement.WizardPage.SignAgreement.TextCommentResendMessage");
    } catch (error) {
        console.log('resendSMS error', error);
        showException(error);
        yield put(AGREEMENT_ACTION.apiFail(error));
    }
}

function* openSignDocument(action: ISagaParam) {
    try {
        let startAgrUrl =
            fillPlaceholders(
                ENDPOINT_AGREEMENT.RESOURCE_PRE_AGREEMENT_VALIDATION,
                {
                    'agreementType': action.payload.agreementType,
                    'language': getCurrentLanguage(),
                })
        let startConfirm: ResponseGenerator = yield call(patch, startAgrUrl, {})
        console.log('startConfirm', startConfirm)
        action.payload.history.push(fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P3_SMS_CONFIRM, { 'agreementType': action.payload.agreementType, }));
    } catch (error: any) {
        console.log('openSignDocument error', error);
        if (error.message === 'Agreement.WizardPage.Ok.AlreadyConfirmed') {
            action.payload.history.push(fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P4_SIGN, { 'agreementType': action.payload.agreementType, }));
            return;
        }
        showException(error);
        yield put(AGREEMENT_ACTION.apiFail(error));
    }
}

function* smsConfirmCode(action: ISagaParam) {
    try {
        let url = fillPlaceholders(
            ENDPOINT_AGREEMENT.RESOURCE_PRE_AGREEMENT_SMS_CONFIRM,
            {
                'agreementType': action.payload.agreementType,
                'language': getCurrentLanguage(),
                'sms-code': action.payload.smsCode,
            })
        let startConfirm: ResponseGenerator = yield call(patch, url, {})
        console.log('startConfirm', startConfirm)
        yield put(AGREEMENT_ACTION.smsConfirmCodeDone());

        action.payload.history.push(fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P4_SIGN, { 'agreementType': action.payload.agreementType, }));
    } catch (error: any) {
        console.log('smsConfirmCode error', error);
        if (error === 'Agreement.WizardPage.Ok.AlreadyConfirmed') {
            action.payload.history.push(fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P4_SIGN, { 'agreementType': action.payload.agreementType, }));
            return;
        }

        if (error.message) {
            showException(error.message);
            yield put(AGREEMENT_ACTION.apiFail(error.message));
        } else {
            showException(error);
            yield put(AGREEMENT_ACTION.apiFail(error));
        }
    }
}

function* previewAgreement(action: ISagaParam) {
    try {
        let url = fillPlaceholders(
            ENDPOINT_AGREEMENT.RESOURCE_PRE_AGREEMENT_PREVIEW,
            {
                'agreementType': action.payload.agreementType,
                'language': getCurrentLanguage(),
            })
        let base64String: ResponseGenerator = yield call(get, url, {})
        downloadPdf(base64String, action.payload.t('AgreementFileName.Test.' + action.payload.agreementType) + '.pdf')
        yield put(AGREEMENT_ACTION.previewAgreementDone());
    } catch (error) {
        console.log('previewAgreement error', error);
        showException(error);
        yield put(AGREEMENT_ACTION.apiFail(error));
    }
}

function* signAgreement(action: ISagaParam) {
    try {
        let url = fillPlaceholders(
            ENDPOINT_AGREEMENT.RESOURCE_PRE_AGREEMENT_SIGN,
            {
                'agreementType': action.payload.agreementType,
                'language': getCurrentLanguage(),
            })
        let agreementDocNumber: string = yield call(post, url, { imageBase64: action.payload.signature })
        yield put(AGREEMENT_ACTION.signAgreementDone('', ''));
        yield put(AGREEMENT_PRE_LIST_ACTION.forceReload());
        yield put(reloadRoles());
        action.payload.history.push(
            fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P5_SIGN_SUMMARY,
                {
                    'agreementDocNumber': agreementDocNumber.replace(/\//g, '-'),
                }));

    } catch (error: any) {
        console.log('signAgreement error', error);
        if (error?.message === 'Mail.ApiException' || error?.message === 'Mail.MissingTemplate') {
            yield put(AGREEMENT_ACTION.signAgreementDone('', 'Agreement.' + error.message));
            yield put(AGREEMENT_PRE_LIST_ACTION.forceReload());
            yield put(reloadRoles());
            action.payload.history.push(fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P5_SIGN_SUMMARY, { 'agreementDocNumber': error.additionalData.replace(/\//g, '-'), }));
        }
        else if (error?.message === 'AgreementType.AlreadySinged') {
            yield put(AGREEMENT_ACTION.signAgreementDone(error.message, ''));
            yield put(AGREEMENT_PRE_LIST_ACTION.forceReload());
            yield put(reloadRoles());
            action.payload.history.push(fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P1_WIZARD, { 'agreementType': action.payload.agreementType }));
        } else {
            showException(error);
            yield put(AGREEMENT_ACTION.apiFail(error));
        }
    }
}

export function* AgreementTypesSaga() {

    yield takeEvery(AGREEMENT_ACTION_TYPES.SIGN_AGREEMENT, signAgreement);
    yield takeEvery(AGREEMENT_ACTION_TYPES.PREVIEW_AGREEMENT, previewAgreement);
    yield takeEvery(AGREEMENT_ACTION_TYPES.SMS_CONFIRM_CODE, smsConfirmCode);
    yield takeEvery(AGREEMENT_ACTION_TYPES.RESEND_SMS, resendSMS);
    yield takeEvery(AGREEMENT_ACTION_TYPES.OPEN_SIGN_DOCUMENT, openSignDocument);
    yield takeEvery(AGREEMENT_ACTION_TYPES.OPEN_FORM_PAGE, openFormPage);
    yield takeEvery(AGREEMENT_ACTION_TYPES.SAVE_DATA, saveData);
    yield takeLatest(AGREEMENT_ACTION_TYPES.GET_AGREEMENT_CONFIG_AND_DATA, getAgreementConfigAndData);
}

export default AgreementTypesSaga;
