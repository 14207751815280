//login
export const LOGIN_USER_START = "LOGIN_USER_START"
export const LOGIN_AUTHENTICATED = "LOGIN_AUTHENTICATED"
export const LOGIN_AUTHORIZE_START = "LOGIN_AUTHORIZE_START"
export const LOGIN_AUTHORIZED = "LOGIN_AUTHORIZED"

//reauth
export const LOGIN_REAUTHORIZE = "LOGIN_REAUTHORIZE"

//logout
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const RELOAD_ROLES = "RELOAD_ROLES"

