import { ENDPOINTS, post } from "helpers/api_helper";
import { showException, showPopupInfo } from "helpers/sweetalert/ShowAlert";
import { ISagaParam } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { DEAL_ADD_ACT, DEAL_ADD_ACT_TYP } from "./actionsDealAdd";
import { reloadRoles } from "store/actions";
import { ROUTES } from "routes";

export interface IDealAdd {
    appIds: string[];
    bagTypeId: string;
    contractorId: any;
}

function* dealAdd(action: ISagaParam) {
    try {
        yield call(post, ENDPOINTS.DEAL_ADD, action.payload.dealAddDto)
        yield put(DEAL_ADD_ACT.dealAddDone());
        yield put(reloadRoles());
        action.payload.history.push(ROUTES.ROUTE_DASHBOARD);
        showPopupInfo('dealAdd.submit.done');
    } catch (error) {
        console.log('dealAdd error', error);
        showException(error);
        yield put(DEAL_ADD_ACT.apiError(error));
    }
}

export function* DealAddSaga() {
    yield takeEvery(DEAL_ADD_ACT_TYP.DEAL_ADD, dealAdd);
}

export default DealAddSaga;
