import ButtonTripple, { ITrippeButton } from "components/MyComponents/ButtonTripple";
import { Form, useField, useFormikContext } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, FormGroup, Row } from "reactstrap";
import { ROUTE_AGREEMENT_P2_FORM__URL_PARAMS } from "routes";
import FormItemCheckboxField from "../FormItems/FormItemCheckboxField";
import FormItemMaskedTextField, { ZIP_CODE_MASK } from "../FormItems/FormItemMaskedTextField";
import FormItemSelectSingleField from "../FormItems/FormItemSelectSingleField";
import FormItemTextField from "../FormItems/FormItemTextField";
import HelperHeader from "../FormItems/HelperHeader";
import { IDictionaries, IFormPage } from "../IAgreement";

export const ADDRESS_DATA_FORM_VALIDATION_RULES_BASIC = YUP_VALIDATION.YUP_OBJ.shape({
  ca_cit: YUP_VALIDATION.YUP_STRING_REQ,
  ca_zc: YUP_VALIDATION.YUP_STRING_ZIP_CODE,
  ca_str: YUP_VALIDATION.YUP_STRING_REQ,
  //ca_voi: YUP_VALIDATION.YUP_STRING_REQ,
  ca_str_no: YUP_VALIDATION.YUP_STRING_REQ,
  ca_ap_no: YUP_VALIDATION.YUP_STRING,
});


export const ADDRESS_DATA_FORM_VALIDATION_RULES_BASIC_NOT_REQUIRED = YUP_VALIDATION.YUP_OBJ.shape({
  ca_cit: YUP_VALIDATION.YUP_STRING,
  ca_zc: YUP_VALIDATION.YUP_STRING_ZIP_CODE_NOT_REQUIRED,
  ca_str: YUP_VALIDATION.YUP_STRING,
  //ca_voi: YUP_VALIDATION.YUP_STRING_REQ,
  ca_str_no: YUP_VALIDATION.YUP_STRING,
  ca_ap_no: YUP_VALIDATION.YUP_STRING,
});


export const ADDRESS_DATA_FORM_VALIDATION_RULES_EXTENDED = YUP_VALIDATION.YUP_OBJ.shape({
  ca_cit: YUP_VALIDATION.YUP_STRING_REQ,
  ca_zc: YUP_VALIDATION.YUP_STRING_ZIP_CODE,
  ca_str: YUP_VALIDATION.YUP_STRING_REQ,
  ca_voi: YUP_VALIDATION.YUP_STRING_REQ,
  ca_str_no: YUP_VALIDATION.YUP_STRING_REQ,
  ca_ap_no: YUP_VALIDATION.YUP_STRING,
  ta_ca_equal: YUP_VALIDATION.YUP_BOOL,
  ta_cit: YUP_VALIDATION.YUP_STRING.when('ta_ca_equal', { is: false, then: YUP_VALIDATION.YUP_STRING_REQ, }),
  ta_voi: YUP_VALIDATION.YUP_STRING.when('ta_ca_equal', { is: false, then: YUP_VALIDATION.YUP_STRING_REQ, }),
  ta_zc: YUP_VALIDATION.YUP_STRING.when('ta_ca_equal', { is: false, then: YUP_VALIDATION.YUP_STRING_ZIP_CODE, }),
  ta_str: YUP_VALIDATION.YUP_STRING.when('ta_ca_equal', { is: false, then: YUP_VALIDATION.YUP_STRING_REQ, }),
  ta_str_no: YUP_VALIDATION.YUP_STRING.when('ta_ca_equal', { is: false, then: YUP_VALIDATION.YUP_STRING_REQ, }),
  ta_ap_no: YUP_VALIDATION.YUP_STRING.when('ta_ca_equal', { is: false, then: YUP_VALIDATION.YUP_STRING, }),
  ta_tax_office: YUP_VALIDATION.YUP_STRING_REQ,
  ta_nfz: YUP_VALIDATION.YUP_STRING_REQ,
});

interface ICustomFormInterface extends ITrippeButton {
  isLoading: boolean,  
  pageConfig: IFormPage,
  dictionaries: IDictionaries
}

const AgreementPage2FormAdressData = ({ isLoading,  pageConfig, dictionaries, onClickBack, onClickList }: ICustomFormInterface) => {

  let { pageKey } = useParams<ROUTE_AGREEMENT_P2_FORM__URL_PARAMS>();
  const hasTaxData = pageConfig.fieldsMap.hasOwnProperty('ta_ca_equal')
  const hasTaxOffice = pageConfig.fieldsMap.hasOwnProperty('ta_tax_office')
  const hasNfzOffice = pageConfig.fieldsMap.hasOwnProperty('ta_nfz')
  const [isAddrEqualField] = useField("ta_ca_equal");
  const hasTaxDataAndDifferentAddreses = hasTaxData && !isAddrEqualField.value;
  const [caVoi] = useField("ca_voi");
  const [taVoi] = useField("ta_voi");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const voi = caVoi.value;
  //jednak dane US/NFZ bierzemy z adresu zamieszkania  
  //const voi = hasTaxDataAndDifferentAddreses ? taVoi.value : caVoi.value;

  const formik = useFormikContext();



  const resetOfficeData = () => {
    if (hasNfzOffice) {
      formik.setFieldValue('ta_nfz', '');
    }
    if (hasTaxOffice) {
      formik.setFieldValue('ta_tax_office', '');
    }
  }

  const voiModContact = (name: string, value: string | null | string[]) => {
    if (!hasTaxData || isAddrEqualField.value) {
      resetOfficeData();
    }
  }
  const voiModTax = (name: string, value: string | null | string[]) => {
    resetOfficeData();
  }

  const voidModCheck = (name: string, value: boolean) => {
    //to jeżeli są różne to musimy zresetować pola 
    if (taVoi.value !== caVoi.value) {
      resetOfficeData();
    }
  }

  return (
    <Form autoComplete="off" autoCorrect="off" key={pageKey + "address-data-form"} >
      <Row>
        <Col xs={12}>
          <HelperHeader formItem={pageConfig.fieldsMap['top_header']} context="top_header" pageKey={pageKey} />
        </Col>
        {hasTaxData &&
          <Col xs={12} md={4}>
            <FormItemSelectSingleField formItem={
              pageConfig.fieldsMap['ca_voi']} context="ca_voi" pageKey={pageKey}
              options={dictionaries['VOIVODESHIP_DICT']}
              afterOnChange={voiModContact}
            />
          </Col>
        }
        <Col xs={12} md={2}>
          <FormItemMaskedTextField mask={ZIP_CODE_MASK}
            formItem={pageConfig.fieldsMap['ca_zc']} context="ca_zc" pageKey={pageKey} />
        </Col>
        <Col xs={12} md={6}>
          <FormItemTextField formItem={pageConfig.fieldsMap['ca_cit']} context="ca_cit" pageKey={pageKey} />
        </Col>
      
        <Col xs={12} md={8}>
          <FormItemTextField formItem={pageConfig.fieldsMap['ca_str']} context="ca_str" pageKey={pageKey} />
        </Col>
        <Col xs={12} md={2}>
          <FormItemTextField formItem={pageConfig.fieldsMap['ca_str_no']} context="ca_str_no" pageKey={pageKey} />
        </Col>
        <Col xs={12} md={2}>
          <FormItemTextField formItem={pageConfig.fieldsMap['ca_ap_no']} context="ca_ap_no" pageKey={pageKey} />
        </Col>
      </Row>
      <Row>
        {hasTaxData &&
          <Col xs={12}>
            <FormItemCheckboxField
              formItem={pageConfig.fieldsMap['ta_ca_equal']} context="ta_ca_equal" pageKey={pageKey}
              afterOnChange={voidModCheck}
            />
          </Col>
        }
      </Row>
      <Row>
        {hasTaxDataAndDifferentAddreses &&
          <>
            <Col xs={12}>
              <HelperHeader formItem={pageConfig.fieldsMap['ta_header']} context="ta_header" pageKey={pageKey} />
            </Col>
            <Col xs={12} md={2}>
              <FormItemMaskedTextField mask={ZIP_CODE_MASK} formItem={pageConfig.fieldsMap['ta_zc']} context="ta_zc" pageKey={pageKey} />
            </Col>
            <Col xs={12} md={6}>

              <FormItemTextField formItem={pageConfig.fieldsMap['ta_cit']} context="ta_cit" pageKey={pageKey} />
            </Col>
            <Col xs={12} md={4}>
              <FormGroup>
                <div>
                  <FormItemSelectSingleField formItem={pageConfig.fieldsMap['ta_voi']} context="ta_voi" pageKey={pageKey} options={dictionaries['VOIVODESHIP_DICT']}
                    afterOnChange={voiModTax} />
                </div>
              </FormGroup>
            </Col>
            <Col xs={12} md={8}>

              <FormItemTextField formItem={pageConfig.fieldsMap['ta_str']} context="ta_str" pageKey={pageKey} />
            </Col>
            <Col xs={12} md={2}>
              <FormItemTextField formItem={pageConfig.fieldsMap['ta_str_no']} context="ta_str_no" pageKey={pageKey} />
            </Col>
            <Col xs={12} md={2}>
              <FormItemTextField formItem={pageConfig.fieldsMap['ta_ap_no']} context="ta_ap_no" pageKey={pageKey} />
            </Col>
          </>
        }
      </Row>
      <Row>
        {(hasTaxOffice || hasNfzOffice) &&
          <Col xs={12}>
            <HelperHeader formItem={pageConfig.fieldsMap['nfz_us_header']} context="nfz_us_header" pageKey={pageKey} />
          </Col>
        }
        {hasTaxOffice &&
          <Col xs={12} md={6}>
            <FormItemSelectSingleField formItem={pageConfig.fieldsMap['ta_tax_office']} context="ta_tax_office" pageKey={pageKey} options={dictionaries['TAX_OFFICE_DICT.' + voi]} />
          </Col>
        }
        {hasNfzOffice &&
          <Col xs={12} md={6}>
            <FormItemSelectSingleField formItem={pageConfig.fieldsMap['ta_nfz']} context="ta_nfz" pageKey={pageKey} options={dictionaries['NFZ_OFFICE_DICT.' + voi]} />
          </Col>
        }
      </Row>
      <ButtonTripple
      id={`button-address-data-${pageKey}`}
        isLoading={isLoading} label={pageKey + ".Submit"}
        onClickBack={onClickBack}
        onClickList={onClickList}
      />
    </Form >
  );
};

export default AgreementPage2FormAdressData; 