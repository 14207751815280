import { useTranslation } from "react-i18next";
import { FormGroup } from "reactstrap";

import './HelperHeaderContent.scss'
import { AlertFrameInfo } from "components/Common/datatable/AlertFrameInfo";

export interface IHelperHeaderContent {
  headerText: string,
  className?: string,
  textBefore?: string,
  textAfter?: string,
  textAfterWithClass?: string,
  textAfterClass?: string,
  placeholders?: object,
  warningIconClass?: string;
}

const replaceText = (text: string, placeholders: any, t: any) => {
  let translatedText = t(text);
  if (placeholders) {
    for (const [k, v] of Object.entries(placeholders)) {
      translatedText = translatedText.replaceAll('#' + k + '#', v)
    }
  }
  return translatedText;
}

const HelperHeaderContent = ({ warningIconClass, headerText, textBefore, textAfter,
  textAfterWithClass, textAfterClass,
  placeholders }: IHelperHeaderContent) => {

  const { t } = useTranslation();

  if (headerText.indexOf('warning') > 0) {
    return <AlertFrameInfo messageKey={textBefore} messageTitle={headerText} iconClass={warningIconClass} />
  }

  const classNameNew = "alert basic-light";
  const headerLine1 = (textBefore ? t(textBefore) : '').trim();
  const headerLine2 = (textAfter ? t(textAfter) : '').trim();
  const headerLine3 = (textAfterWithClass ? t(textAfterWithClass) : '').trim();
  const hasSubInfo = headerLine1 || headerLine2 || headerLine3;


  return (
    <div className="helper-text-extender">
      <FormGroup className="mt-0">
        <div className={'helper-header ' + classNameNew}  >
          <h2 className={"prevent-select " + (hasSubInfo ? "" : " mb-0")}>
            {replaceText(headerText, placeholders, t)}
          </h2>
          {(textBefore && t(textBefore)) &&
            <p className="mb-1">{replaceText(textBefore, placeholders, t)}</p>
          }
          {(textAfter && t(textAfter)) &&
            <p className="mb-0 pt-1 border-top" >{replaceText(textAfter, placeholders, t)}
              {headerLine3 &&
                <span className={textAfterClass}> {headerLine3}</span>
              }
            </p>

          }
        </div>
      </FormGroup>
    </div>
  )
}

export default HelperHeaderContent