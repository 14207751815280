import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

interface ButtonProps {
  id: string;
  label: string;
  iconClass?: string;
  iconClassPre?: string;
  iconClassPre2?: string;
  iconFileSrc?: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  outline?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>
  color?: string;
  className?: string;
}

export const ButtonCustom = ({ id, iconFileSrc, iconClass, iconClassPre,iconClassPre2, label, outline = false, fullWidth = false, isLoading = false, onClick, color = "default", className = "" }: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button id={id} type="button" color={color}
      outline={outline}
      className={"btn btn-color-" + color + (isLoading ? " fade-in-out-animation" : "") + (fullWidth ? " btn-lg btn-block w-100" : "") + " " + className}
      onClick={onClick}>
      {iconClassPre &&
        <i className={'icon-left ' + iconClassPre}></i>
      }
      {iconClassPre2 &&
        <i className={'icon-left ' + iconClassPre2}></i>
      }

      {t(label)}
      {iconFileSrc &&
        <img src={iconFileSrc} className="to-white px-2" alt=""></img>
      }
      {iconClass &&
        <i className={'icon-right ' + iconClass}></i>
      }



    </Button>
  );
};

export default ButtonCustom;
