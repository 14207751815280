import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import { LAYOUT20_ACT } from "store/layout/actionsLayout20";
import { getLogoImage20, getLogoImage20Width } from "./Images20Helper";
import NotificationsMenu from "./NotificationsMenu";


const Header20Mob = () => {

	const dispatch = useDispatch();
	const companyId = useSelector((state) => { return state.LoginState.companyId });
	const { sidebarVisible } = useSelector(state => ({ sidebarVisible: state.Layout20Store.sidebarVisible }));

	const openSidebar = () => {
		if (!sidebarVisible) {
			dispatch(LAYOUT20_ACT.openSidebar())
		}
	}


	const toogleSidebar = () => {
		if (!sidebarVisible) {
			dispatch(LAYOUT20_ACT.openSidebar())
		} else {
			dispatch(LAYOUT20_ACT.closeSidebar())
		}
	}

	return (
		<React.Fragment>
			<section className="header-mob ">
				<div className="container">
					<div className="row items-center">
						<div className="col-6">
							<div className="logo" onClick={toogleSidebar}>
								<img src={getLogoImage20(companyId, false)} width={getLogoImage20Width(companyId, false)} />
							</div>
						</div>
						<div className="col-6">
							<div className="mob-nav">
								<div className="m-header">									
									<NotificationsMenu/>
								</div>
								<div className="menu-div">
									<button className="menu-open" onClick={openSidebar}>
										<img src="/img/menu.svg" alt="" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


		</React.Fragment>
	);
};

Header20Mob.propTypes = {
	type: PropTypes.string,
};

const mapStatetoProps = state => {
	return {
		layout20Store: state.Layout20Store,
	};
};
export default connect(
	mapStatetoProps,
	{}
)(withRouter(withTranslation()(Header20Mob)));
