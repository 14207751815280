import { DASHBOARD_ACTION_NAMES } from "./actionTypes";

const INIT_STATE = {

    dashboardDto: {
        notifications: []
    },
    isLoading: true,
    error: ''
};

const Dashboard = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case DASHBOARD_ACTION_NAMES.GET_DATA:
            return {
                ...state,
                isLoading: true,
                error: ''
            };
        case DASHBOARD_ACTION_NAMES.API_SUCCESS:
            return {
                ...state,
                dashboardDto: action.payload.data,
                isLoading: false,
                error: '',
            };
        /*
        {
                        ...state,
                        notificationContext: action.payload.notificationContext,
                        gridData: state.gridData.map((item: any) =>
                            item.id === action.payload.notificationId ? 
                            { ...item, markedAsPresented: action.payload.markedAsPresented } : item
                        ),
                    };
        */
        case DASHBOARD_ACTION_NAMES.REMOVE_FROM_DASHBOARD:
            return {
                ...state,
                dashboardDto: {
                    ...state.dashboardDto,
                    notifications:
                        state.dashboardDto.notifications.filter((i: any) => i.id != action.payload.notificationId)

                }
            };
        case DASHBOARD_ACTION_NAMES.API_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}


export default Dashboard;