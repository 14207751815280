
export interface ResponseGenerator {
    config?: any,
    data?: any,
    info?: any,
    dictionaries?: any,
    headers?: any,
    request?: any,
    status?: number,
    statusText?: string
}


export interface ResponseGridData<T> {
    gridData: T[],
    info: string
    error: string
}

export interface ResponseGridDataExtended<T,C> {
    gridData: T[],
    context: C,
    info: string
    error: string
}

export interface ISagaParam {
    type: string,
    payload: any
}


export interface ICommonFileB64Result {
    base64Data: string,
    fileName: string,
    contentType: string,
}
 
export const EMPTY_FILE : ICommonFileB64Result = {
    base64Data: '',
    fileName: '',
    contentType: '',
}
 


export const sleepMs = async (milliseconds: number) => {
    await new Promise(resolve => {
        return setTimeout(resolve, milliseconds)
    });
  };