import {
  LOGIN_USER_START, LOGIN_AUTHENTICATED, LOGIN_AUTHORIZED,
  LOGIN_REAUTHORIZE,
  LOGOUT_USER, LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_AUTHORIZE_START,
  RELOAD_ROLES,
} from "./actionTypes"

export const loginUserStart = (credentials, history, affiliationKey) => {
  return {
    type: LOGIN_USER_START,
    payload: { credentials, history,affiliationKey },
  }
}

export const loginAuthenticated = (email, pictureUrl, refreshSessionIntervalId) => {
  return {
    type: LOGIN_AUTHENTICATED,
    payload: { email, pictureUrl,refreshSessionIntervalId },
  }
}

export const loginAuthorized = (
  contractorId,affLinkIdentifier,suggestedApplications, extDocsToSignCount ,userFullName, availableRoles, companyId, companyName) => {
  return {
    type: LOGIN_AUTHORIZED,
    payload: {contractorId,affLinkIdentifier, suggestedApplications, extDocsToSignCount,userFullName, availableRoles, companyId, companyName },
  }
}

export const reautorize = (storedAuthData) => {
  return {
    type: LOGIN_REAUTHORIZE,
    payload: { storedAuthData },
  }
}

export const authorizeStart = (history, affiliationKey) => {
  return {
    type: LOGIN_AUTHORIZE_START,
    payload: { history,affiliationKey},
  }
}

//-------------------


export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: (error.message ? error.message : error),
  }
}
 


export const reloadRoles = () => {
  return {
    type: RELOAD_ROLES,
    payload: {},
  }
}
