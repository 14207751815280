import { AFFILIATION_ACT_TYP } from "./actionsAffiliation";

const INIT_STATE = {
    affiliationKey: ''
};

const AffiliationStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case AFFILIATION_ACT_TYP.SET_UP_AFFILIATION:
            return { ...state, affiliationKey: action.payload.affiliationKey};
        default:
            return state;
    }
}

export default AffiliationStore;