import { Field, FieldProps } from "formik";
import { hasFormikErrors } from "helpers/formikErrors";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, FormText, Input, InputGroup, Label } from "reactstrap";
import TranslatedErrors from "./TranslatedErrors";

interface TextProps extends FieldProps {
  placeholder?: string;
  label?: string;
  textAfter?: string;
  textBefore?: string;
  isDisabled?: boolean;
  autoFocus: boolean;
  inputGroupPrefixContent?: ReactElement
  forcedId?: string;
  inputType?: "text" | "tel";
  pattern?: string;
  maxLength? : number;
}

export const CustomText = ({
  placeholder,
  label,
  field,
  form,
  textBefore,
  textAfter,
  isDisabled,
  autoFocus,
  inputGroupPrefixContent,
  forcedId,
  inputType = "text",
  pattern,
  maxLength

}: TextProps) => {
  const { t } = useTranslation();
  if (field.value === undefined) {
    return <></>
  }
 
  const error = hasFormikErrors(field.name, form.errors, form.touched);

  return (
    <>
      {textBefore &&
        <FormGroup  >
          <FormText>{t(textBefore)}</FormText>
        </FormGroup>
      }
      <FormGroup>
        {label &&
          <Label htmlFor={field.name}>{t(label)}</Label>
        }

        <InputGroup>
          {inputGroupPrefixContent &&
            <div className="input-group-text">{inputGroupPrefixContent}</div>
          }

          {isDisabled && //DRUT 
            <Input type={inputType} disabled={isDisabled} autoComplete="off"              
              autoFocus={autoFocus}
              value={field.value}
              name={field.name}
              id={forcedId ? forcedId : field.name}
              placeholder={placeholder}
              className={"form-control" + (inputGroupPrefixContent ? " input-left-button" : "") + (error ? " is-invalid" : "")}
            />

          }
          {!isDisabled &&
            <Field type="text" disabled={isDisabled} autoComplete="off"
              autoFocus={autoFocus}
              pattern={pattern}
              name={field.name}
              maxLength={maxLength}
              id={forcedId ? forcedId : field.name}
              placeholder={placeholder}
              className={"form-control"+ (inputGroupPrefixContent ? " input-left-button" : "") + (error ? " is-invalid" : "")}
            />
          }
        </InputGroup>
        {error &&
          <TranslatedErrors errors={error} />
        }
        {textAfter &&
          <FormText>{t(textAfter)}</FormText>
        }
      </FormGroup>

    </>
  );
};

export default CustomText;
