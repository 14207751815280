import CustomCheckbox from "components/MyComponents/CustomCheckbox";
import { Field } from "formik";
import { IGenericFormItem } from "./GenericFormItem";

interface IFormItemCheckboxField extends IGenericFormItem {
    afterOnChange?(name: string, value: boolean): void;
}

const FormItemCheckboxField = ({ formItem, pageKey, context, afterOnChange, autoFocus }: IFormItemCheckboxField) => {
    
    if (!formItem) {
        return <h3>conf error: {context}</h3>
    }
 
    const fieldKey = pageKey + '.' + formItem.name;
    return (
        <div className="pb-1 pt-0 pb-md-2 pt-md-0">
            <Field name={formItem.name} label={fieldKey}
                textBefore={formItem.textBeforeSuffix ? fieldKey + '.' + formItem.textBeforeSuffix : undefined}
                textAfter={formItem.textAfterSuffix ? fieldKey + '.' + formItem.textAfterSuffix : undefined}
                autoFocus={autoFocus}
                component={CustomCheckbox} type="checkbox"
                afterOnChange={afterOnChange}
            />
        </div>
    )
}
 
export default FormItemCheckboxField