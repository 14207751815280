import { LocalizationMap, Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen'
import '@react-pdf-viewer/full-screen/lib/styles/index.css'
import { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar'
import { getAuthHeaders, getPdfFileUrl } from 'helpers/api_helper'
import { getPdfViewerLocale } from 'i18n'
import { useTranslation } from 'react-i18next'
import './PdfViewer.scss'
import LoadingContent from 'components/Common/LoadingContent'
import { Col, Container, Row } from 'reactstrap'
import ButtonCustom from 'components/MyComponents/ButtonCustom'
import { useHistory } from 'react-router-dom'


export interface IPDFViewer {
    pdfFileId?: number;
    pdfFileName?: string;
    agreementType?: string;
    context: string;
    closeFunction?: ()=>void;

}

const PDFViewer = ({ pdfFileId, pdfFileName, agreementType, context, closeFunction }: IPDFViewer) => {

    const fullScreenPluginInstance = fullScreenPlugin({
        enableShortcuts: false
    }
    );

    const { i18n } = useTranslation();
    const history = useHistory();

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        ShowSearchPopover: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
        SwitchScrollModeMenuItem: () => <></>,
        SwitchScrollMode: () => <></>,
        SwitchViewModeMenuItem: () => <></>,
        SwitchViewMode: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: (props) => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
    });

    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) => [],
    });

    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    let url = getPdfFileUrl(pdfFileId, pdfFileName, i18n.language, agreementType, context)
    let locale = getPdfViewerLocale(i18n.language);

    const renderLoader = (percent: number) => <Container fluid><LoadingContent /></Container>

    return ( 
        <Row className='bg-white'>
            <Col xs={12} className='px-0'>

                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={url}
                        localization={locale as unknown as LocalizationMap}
                        renderLoader={renderLoader}
                        plugins={[
                            defaultLayoutPluginInstance,
                            fullScreenPluginInstance]} httpHeaders={getAuthHeaders() as Record<string, string | string[]>} />
                </Worker>
            </Col>
            <Col xs ={12}  className='text-center px-0 py-3'>
                <ButtonCustom
                id="button-pdf-preview-close" 
                onClick={closeFunction? closeFunction : history.goBack} label='PdfPreview.ClosePreview' color='default'   />
            </Col>
        </Row>
    )
}



export default PDFViewer; 