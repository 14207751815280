import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import ContactFormPageContent from "./ContactFormPageContent";
import { DialogWrapper } from "components/Layout20/DialogWrapper";

const ContactFormPage = () => {
  const contactFormStore = useSelector((state: any) => state.ContactFormStore);

  return ( 
      <Container fluid>
        <AlertFrameError messageKey={contactFormStore.error}/>
        <DialogWrapper title="ContactForm.Page.Header">
        <ContactFormPageContent />
        </DialogWrapper>
      </Container>
  );
};


export default ContactFormPage; 