
const STATUS_W4P = 'W4P'; //rozliczenie oczekuje na zapłatę
const STATUS_ROL = 'ROL'; //rozliczenie wycofane
const STATUS_PAY = 'PAY'; //rozliczenie zapłacone
const STATUS_OX = 'OX'; //rozliczenie bez przelewu
const STATUS_DEB = 'DEB'; //rozliczenie z debetem

const mapStatusToBadgeColor = (status: string, isB2B: boolean) => {
    switch (status) {
        case STATUS_PAY:
            return 'green-badge';
        case STATUS_DEB:
            return 'red-badge'
        case STATUS_OX:            
                return isB2B ? 'green-badge' :'gray-badge'            
        case STATUS_ROL:
            return 'gray-badge'
        case STATUS_W4P:
            return 'orange-badge'
        default:
            return 'blue-badge'
    }
}

export const SETTLEMENT_CONF = {
    mapStatusToBadgeColor,
    STATUS_W4P,
    STATUS_ROL,
    STATUS_PAY,
    STATUS_OX,
    STATUS_DEB,
}