import CustomText from "components/MyComponents/CustomText";
import { Field } from "formik";
import { IGenericFormItem } from "./GenericFormItem";
import { useSelector } from "react-redux";
import { isForceEditEnabled } from "./FormItemsDisableExclusions";


const FormItemTextField= ({ formItem, pageKey, context, autoFocus }: IGenericFormItem) => {

    if(!formItem){
        return <h3>conf error: {context}</h3>
    }
    const fieldKey = pageKey + '.' + formItem.name;

    const email = useSelector((state: any) => state.LoginState.email);
    let isForceEditEnabledFlag = false;
    if(formItem.disabled){
        isForceEditEnabledFlag = isForceEditEnabled(email, fieldKey)
    }
    
    

    return (
        <Field name={formItem.name}     
        isDisabled={formItem.disabled && !isForceEditEnabledFlag}
        label={fieldKey} 
        textBefore = {formItem.textBeforeSuffix ?fieldKey + '.'+formItem.textBeforeSuffix : undefined }
        textAfter = {formItem.textAfterSuffix ?fieldKey + '.'+formItem.textAfterSuffix : undefined }
        autoFocus={autoFocus}
        component={CustomText} />
    )
}
export default FormItemTextField