const AGREEMENT_ADD = "AGREEMENT_ADD";
const AGREEMENT_LIST = "AGREEMENT_LIST";


const ATTACHMENT_LIST = "ATTACHMENT_LIST";

const SETTLEMENT_LIST = "SETTLEMENT_LIST";

const INVOICE_LIST = "INVOICE_LIST";
const INVOICE_ADD = "INVOICE_ADD";

const PROFILE = "PROFILE";
const AFF_LINK_SEND = "AFF_LINK_SEND";

export const USER_ROLES = {
    AGREEMENT_ADD,
    PROFILE,
    AGREEMENT_LIST,
    ATTACHMENT_LIST,
    SETTLEMENT_LIST,
    INVOICE_LIST,
    INVOICE_ADD,
    AFF_LINK_SEND

}