import ButtonCustom from "components/MyComponents/ButtonCustom";
import { useHistory } from "react-router-dom";


const PrivacyPolicy = () => {

  const history = useHistory();

  return (
    <div className="container fluid privacy-policy">
      <h1>Polityka prywatności oraz wykorzystania plików Cookies w serwisie internetowym firmy Avalon Logistics Group Sp. z o. o.</h1>
      <p>
        Niniejszy dokument dotyczy przetwarzania oraz ochrony danych osobowych dotyczących Użytkowników w związku z korzystaniem przez nich z serwisu: www.spirit24.pl (dalej „Serwis”).
      </p>
      <p>
        Avalon Logistics Group Sp. z o. o. szanuje prawo do prywatności Użytkowników serwisu www.spirit24.pl. W szczególności dba o ochronę ich danych osobowych oraz stosuje odpowiednie rozwiązania organizacyjne i techniczne zapobiegające ingerencji w prywatność Użytkowników przez osoby trzecie. Nasze działania są ukierunkowane na zagwarantowanie Użytkownikowi poczucia pełnego bezpieczeństwa na poziomie odpowiednim do
        obowiązującego prawa, w tym:
        <br />
        1. Rozporządzenia Parlamentu Europejskiego i Rady 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych  osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych);
        <br />
        2. Ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną;
        <br />
        3. Ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne.
        <br />
      </p>
      <p>
        Korzystanie z Serwisu firmy Avalon Logistics Group Sp. z o. o. jest realizowane za pomocą  bezpiecznego protokołu SSL, który istotnie zwiększa ochronę transmisji danych w sieci Internet.
      </p>
      <p>
        Informujemy, że w ramach Serwisu mogą zostać zamieszczone zewnętrzne linki  umożliwiające jego Użytkownikom bezpośrednie dotarcie do innych stron internetowych bądź też podczas korzystania z Serwisu w Państwa urządzeniu mogą być dodatkowo umieszczone pliki Cookies pochodzące od innych podmiotów w szczególności od dostawców takich jak: Facebook, Twitter, Google+, w celu umożliwienia Państwu skorzystania z funkcjonalności Serwisu zintegrowanych z tymi serwisami. Każdy z dostawców określa zasady korzystania z plików Cookies w swojej polityce prywatności w związku z czym nie
        mamy wpływu na prowadzoną przez dostawców politykę prywatności oraz wykorzystywania plików Cookies. Ze względów bezpieczeństwa zalecamy, aby przed skorzystaniem z zasobów oferowanych przez inne strony internetowe lub serwisy, każdy Użytkownik zapoznał się z dokumentem dotyczącym polityki prywatności oraz wykorzystywania plików Cookies, jeżeli zostały one udostępnione, a w razie ich braku skontaktował się z administratorem danej
        strony lub serwisu w celu uzyskania informacji w tym zakresie.
      </p>
      <p>

        Słownik pojęć:
        <br />
        <strong>• cookie</strong> - jest to niewielka informacja zapisywana przez serwer na komputerze
        Użytkownika, którą serwer może odczytać przy ponownym połączeniu się z tego
        komputera,
        <br />
        <strong>• log systemowy</strong> - jest to informacja, jaką przekazuje serwerowi komputer Użytkownika przy każdym połączeniu, może zawierać różne dane (np. numer IP), z których można mniej lub bardziej dokładnie ustalić skąd nastąpiło połączenie,
        <br />
        <strong>• adres IP</strong> - indywidualny numer, który z reguły posiada każdy komputer podłączony do Internetu, numer IP może być na stałe związany z danym komputerem (statyczny) lub przydzielony mu na dane połączenie (dynamiczny),
        <br />
        <strong>• protokół SSL</strong> - specjalny standard przesyłania danych w Internecie, w którym transmisja jest kodowana, w odróżnieniu od zwykłego przesyłania, gdzie transmisja  odbywa się otwartym tekstem.
      </p>
      <p>
        <h2> I. Gromadzenie danych</h2>
        <br />
        W zakresie korzystania z Serwisu przez Użytkownika przechowujemy zapytania http kierowane do serwera. Przeglądane zasoby identyfikowane są poprzez adresy URL i dotyczą:
        <br />
        • publicznego adresu IP urządzenia końcowego, z którego nadeszło zapytanie;
        <br />
        • nazwy stacji Użytkownika – identyfikacja realizowana przez protokół http, o ile jest możliwa;
        <br />
        • nazwy Użytkownika podawana w procesie autoryzacji;
        <br />
        • czasu nadejścia zapytania;
        <br />
        • pierwszego wiersza żądania http;
        <br />
        • kodu odpowiedzi http;
        <br />
        • liczby wysłanych przez serwer danych;
        <br />
        • adresu URL strony poprzednio odwiedzanej przez Użytkownika (tzw. referer link) – w przypadku gdy przejście do Serwisu nastąpiło przez zewnętrzny odnośnik;
        <br />
        • informacji o przeglądarce Użytkownika;
        <br />
        • informacji o błędach jakie nastąpiły przy realizacji transakcji http.
        <br />
      </p>
      <p>
        Powyżej wskazane dane nie są kojarzone z konkretnymi osobami przeglądającymi strony Serwisu.  Serwisy nie zbierają w sposób automatyczny żadnych informacji za wyjątkiem informacji zawartych w plikach Cookies. Gromadzone w ten sposób informacje wykorzystywane są m.in. do:
        <br />
        1. zarządzania Serwisem;
        <br />
        2. stwierdzania ewentualnych zagrożeń bezpieczeństwa;
        <br />
        3. badania zagregowanego ruchu Użytkowników w ramach Serwisu oraz w celach statystycznych, w tym z wykorzystaniem narzędzi Google Analytics oraz  Bitrix24 jedynie w przypadku gdy dodatkowo prowadzone są kampanie mailingowe;
        <br />
        Użytkownik w zakresie korzystania z funkcjonalności Serwisu może jednak zostać poproszony o podanie następujących danych osobowych:
        <br />
        • imię i nazwisko;
        <br />
        • adres poczty elektronicznej (adres e-mail);
        <br />
        • numer telefonu;
        <br />
        • adres;
        <br />
        • treść wiadomości kontaktowej;
        <br />
        • numer konta bankowego;
        <br />
        Podanie danych wskazanych powyżej jest dobrowolne jednak bez ich podania może nie być możliwe korzystanie z Serwisu w zakresie funkcjonalności, które tego wymagają (np. obsłużenie zamówienia publikacji)
        <br />
      </p>
      <h2>II. Przetwarzanie danych osobowych</h2>
      <p>
        Zebrane za pośrednictwem Serwisu dane dotyczące Użytkownika mogą być wykorzystane w celu ustalania dostosowania zawartości Serwisu do potrzeb Użytkownika oraz w celu udzielenia odpowiedzi na skierowane z wykorzystaniem formularza kontaktowego zapytanie przez Użytkownika.
        <br />
        Gromadzone dane osobowe Użytkowników nie są przekazywane organizacjom międzynarodowym czy do państw trzecich. Dane osobowe mogą być przetwarzane przez inne podmioty zgodnie z prawem Unii lub prawem krajowym.
        <br />
        Dane osobowe zebrane za pośrednictwem formularzy, na które Użytkownik wyraził zgodę przetwarzane są w celu i okresie niezbędnym do prawidłowego obsłużenia zadania. Szczegóły określają odpowiednie klauzule znajdujące się pod konkretnymi formularzami.
        <br />
      </p>
      <h2>Prawa Użytkownika</h2>
      <p>
        Użytkownik w zakresie procesu przetwarzania jego danych posiada uprawnienia:
        <br />
        • dostępu do danych;
        <br />
        • sprostowania danych;
        <br />
        • usunięcia lub ograniczenia przetwarzania;
        <br />
        • wniesienia skargi do organu nadzorczego w zakresie ochrony danych osobowych – Prezesa Urzędu Ochrony Danych Osobowych;
        <br />
      </p>
      <h2>III. Wykorzystanie plików Cookies</h2>
      <p>
        Serwis, zgodnie z art. 173 Ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne, korzysta z plików Cookies stanowiących dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika. Pliki Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
        <br />
        <br />
        Pliki Cookies wykorzystywane są w celu:
        <br />
        1. ułatwienia Użytkownikowi korzystania z Serwisu podczas jego przeglądania;
        <br />
        2. późniejszego skojarzenia Użytkownika w przypadku ponownego połączenia Serwisu z urządzeniem na którym zostały zapisane;
        <br />
        3. tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;
        <br />
        4. dostosowania zawartości stron internetowych Serwisu do określonych preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych, dostosowanych do indywidualnych potrzeb Użytkownika;
        <br />
      </p>
      <p>
        W ramach Serwisu wykorzystujemy następujące rodzaje plików Cookies:
        <br />
        • „sesyjne” - przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia przeglądarki  internetowej;
        <br />
        • „stałe” - przechowywane w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików Cookies lub do czasu ich usunięcia przez Użytkownika;
        <br />
        • „wydajnościowe” - umożliwiają zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;
        <br />
        • „niezbędne” - umożliwiające korzystanie z usług dostępnych w ramach Serwisu;
        <br />
        • „funkcjonalne” - umożliwiające zapamiętanie wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika;
        <br />
        • „własne” - zamieszczane przez Serwis;
        <br />
        • „zewnętrzne” - pochodzące z witryny zewnętrznej niż Serwis;
        <br />
      </p>
      <p>
        Informacje w żaden sposób nie są łączone z danymi osobowymi Użytkownika Serwisu i nie są wykorzystywane do określenia tożsamości Użytkownika. Zakres informacji zbieranych automatycznie zależy od ustawień przeglądarki internetowej Użytkownika. Użytkownik powinien sprawdzić ustawienia swojej przeglądarki, aby dowiedzieć się jakie informacje są udostępniane przez przeglądarkę automatycznie lub w celu dokonania zmiany tych ustawień. W tym celu zalecamy zapoznanie się z treścią „Pomocy” używanej przeglądarki internetowej.
      </p>
      <p>
        Zmiana warunków przechowywania lub otrzymywania plików cookies jest możliwa poprzez konfigurację ustawień w przeglądarkach internetowych, np.:
        <br />
        • w przeglądarce Internet Explorer
        <br />
        • w przeglądarce Microsoft Edge
        <br />
        • w przeglądarce Mozilla Firefox
        <br />
        • w przeglądarce Chrome
        <br />
        • w przeglądarce Opera
        <br />
        • w przeglądarce Safari
        <br />
      </p>
      <p>
        Oprogramowanie do przeglądania stron internetowych tj. przeglądarka internetowa, zazwyczaj domyślnie dopuszcza przechowywanie plików Cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików Cookies. Możliwe jest także automatyczne blokowanie plików Cookies. Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja stosowanej przez Użytkownika przeglądarki internetowej.
      </p>
      <p>
        Jeśli Użytkownik nie chce otrzymywać plików Cookies, może zmienić ustawienia swojej przeglądarki. Jednak wyłączenie obsługi plików Cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa lub utrzymania preferencji Użytkownika może utrudnić, a w skrajnych przypadkach także uniemożliwić korzystanie z Serwisu.
      </p>
      <p>
        <ButtonCustom id="privacy-policy-back" className="w-100" label="privacy-policy.back" onClick={()=> 
          {
            history.goBack(); history.goBack();
          }
          } />
      </p>
    </div>
  );
};


export default PrivacyPolicy; 