import PDFViewer from "pages/PdfViewer/PdfViewer";
import { isMobile } from "react-device-detect";
import { CloseButton, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

type ResourceModalProps = {
  showModal: boolean,
  pdfFileId: number,
  pdfFileName: string,
  close: () => void
}

const PdfModalViewer = ({ showModal, pdfFileId, pdfFileName, close }: ResourceModalProps) => {

  if (!showModal) {
    return <></>
  }

  return (
    <Modal     
    toggle={close} backdrop={true} isOpen={showModal && pdfFileId != null} size="xl" fullscreen={isMobile} >
      <ModalBody className="pt-xs-0 pt-sm-3" style={{backgroundColor: '#eeeeee'}}>
        <Row>
          <Col className="px-0">

            <div className="d-flex align-items-center justify-content-between pdf-viewer-top-bar pl-2">
              <span className="text-break word-break">{pdfFileName}</span>
              <i className="fa-solid fa-xmark fa-2x pdf-viewer-top-bar-close" onClick={close}></i>
            </div>

          </Col>
        </Row>
        <PDFViewer
          context="file" agreementType=""
          pdfFileId={pdfFileId} pdfFileName={pdfFileName || 'PDF'}
          closeFunction={close}
        ></PDFViewer>
      </ModalBody>
    </Modal >
  );
}


export default PdfModalViewer;