import { IPreAgreementState } from "pages/Agreement/store-pre/reducerAgr";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "routes";
import { fillPlaceholders } from "routes/placeholder_helper";
import { NOT_FOUND_TYPE } from "./IAgreement";

const AgreementPage0Navbar = () => {
  const history = useHistory();
  const { selectedAgreementType } = useSelector((state: any) => {
    const agreementSignStore: IPreAgreementState = state.AgreementSignStore as IPreAgreementState;
    return {
      selectedAgreementType: agreementSignStore.agreementConfig?.agreementType || '',
    }
  }
  );
  //pzrzekierowanie na otwartą umowę
  if (selectedAgreementType && selectedAgreementType !== NOT_FOUND_TYPE && selectedAgreementType !== '') {
    history.push(fillPlaceholders(ROUTES.ROUTE_AGREEMENT_P1_WIZARD, { 'agreementType': selectedAgreementType }))
  } else {
    history.push(ROUTES.ROUTE_AGREEMENT_P0_TYPE_SELECTION)
  }
  
  return <></>
}
export default AgreementPage0Navbar; 