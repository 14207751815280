import PropTypes from "prop-types";

//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// Formik validation
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

// actions
import { loginUserStart } from "../../../store/actions";

//Import config
import ButtonSubmit from "../../../components/MyComponents/ButtonSubmit";
import CustomPassword from "../../../components/MyComponents/CustomPassword";
import CustomText from "../../../components/MyComponents/CustomText";
import { YUP_VALIDATION } from "../../../helpers/YupHelper";

import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { Unauth20Wrapper } from "../Unauth20Wrapper";
import HorizontalDivider from "components/MyComponents/HorizontalDivider";

const Login = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const affiliationKey = useSelector((state) => state.AffiliationStore.affiliationKey);

  const formValidator = Yup.object({
    email: YUP_VALIDATION.YUP_STRING_REQ_EMAIL,
    password: YUP_VALIDATION.YUP_STRING_REQ,
  });

  const onSubmit = (values) => {
    dispatch(loginUserStart(values, props.history, affiliationKey));
  }

  const { error, loading } = useSelector(state => ({
    error: state.LoginState.error,
    loading: state.LoginState.loading,
  }));

  return (<Unauth20Wrapper>

    <div className="form-title">
      <h2>
        <span className="blue">{t("Login.Header.Text1")} </span>
        {t("Login.Header.Text2")}</h2>
    </div>

    <Formik
      initialValues={{ email: "", password: "" }}
      enableReinitialize={true}
      validationSchema={formValidator}
      onSubmit={onSubmit}
    >
      <Form id="login-form">
        <AlertFrameError messageKey={error} />{error && <div id="login-auth-error" />}
        <Row>
          <Col xs={12} >
            <Field forcedId="login-form-email" name="email" label="Login.Email" component={CustomText} isDisabled={loading} id="login-email" autoComplete="email" />
          </Col>
          <Col xs={12} >
            <Field forcedId="login-form-password" name="password" label="Login.Password" component={CustomPassword} 
            ac="current-password"
            isDisabled={loading} id="login-password" autoComplete="current-password" />
          </Col>
          <Col xs={12} className="py-2">
            <ButtonSubmit className="fw-bold" forcedId="login-form-submit" 
              isLoading={loading} 
              label={t("Login.Submit")} 
              id="login-submit-button"
              fullWidth={true} />
          </Col>
          <HorizontalDivider labelKey="separator.or" className="text-uppercase" />
          <Col xs={12} className="pt-2">
            <div className="signin-btn">
              <Link to="/register" id="sign-up-now-button" >{t("Login.SignUpNow")}</Link>
            </div>
          </Col>
          <Col xs={12}>
            <div className="forget-password">
              <p>
                <span>{t("Login.ForgotPassword")} </span>
                <Link to="/forgot-password" id="forgot-password-button">{t("Login.ForgotPassword.Reset")}</Link>
              </p>
            </div>
          </Col>
        </Row>
      </Form>
    </Formik>
  </Unauth20Wrapper >
  );
};


export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
