import { IDealAdd } from "./sagaDealAdd";

const PREFIX = "DAD_"

const DEAL_ADD = PREFIX + "DEAL_ADD";
const dealAdd = (dealAddDto: IDealAdd, history: any) => ({
    type: DEAL_ADD, payload: { dealAddDto, history }
});

const DEAL_ADD_DONE = PREFIX + "DEAL_ADD_DONE";
const dealAddDone = () => ({
    type: DEAL_ADD_DONE, payload: {}
});

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const DEAL_ADD_ACT = {
    dealAdd, dealAddDone,
    apiError
}

export const DEAL_ADD_ACT_TYP = {
    DEAL_ADD, DEAL_ADD_DONE,
    API_ERROR
}