import { IGridDataExtended } from "store/IGridData";
import { ATTACHMENT_LIST_ACT_TYP } from "./actionsAttList";


export interface IAttachmentListContext {
    missingAttachments: string[],
    expiringAttachments: string[],
}

export interface IAttachmentGridDto {
    id: number,
    documentTypeId: string,
    status: string,
    value: string,
    description: string,
    expirationDate: any,
    rejectedDate: any,
    releaseDate: any,
    verifiedDate: any,
    creationDate: any,
}

const INIT_STATE: IGridDataExtended<IAttachmentGridDto, IAttachmentListContext> = {
    gridData: [],
    context: { missingAttachments: [], expiringAttachments: []},
    isLoading: false,
    reloadRequired: true,
    error: '',
    info: '',
};

const AttachmentListStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case ATTACHMENT_LIST_ACT_TYP.LOAD_DATA:
            return { ...state, isLoading: true, error: ''};
        case ATTACHMENT_LIST_ACT_TYP.LOAD_DATA_DONE:
            return {
                ...state, isLoading: false,
                gridData: action.payload.gridData,
                context: action.payload.context,
                info: action.payload.info,  
                error: action.payload.error
            };
        case ATTACHMENT_LIST_ACT_TYP.REJECT_ATTACHMENT:
            return { ...state, isLoading: true, error: '', info: '' };
        case ATTACHMENT_LIST_ACT_TYP.REJECT_ATTACHMENT_DONE:
            return { ...state, info: action.payload.info };

        case ATTACHMENT_LIST_ACT_TYP.API_ERROR:
            return { ...state, isLoading: false, error: action.payload.error, };
        default:
            return state;
    }
}

export default AttachmentListStore;