import { ReactElement } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import i18next from 'i18next';
import { IDataResponse } from 'helpers/IDataResponse';
const MySwal = withReactContent(Swal)
const t = i18next.t;

export const showIDataResponse = (dataResponse: IDataResponse<any>) => {

    if (dataResponse.error) {
        return showPopupError(dataResponse.error, '')
    }
    if (dataResponse.info) {
        return showPopupInfo(dataResponse.info, '')
    }
}

export const showException = (exception: any) => {

    if (exception.uuid && exception.date && exception.message) {
        return showPopupError(exception.message)
    }

    if (exception.message && exception.message !== "Request failed with status code 500" &&
        exception.name !== "AxiosError"
    ) {
        return showPopupError(exception.message)
    }
    return showPopupError("Technical.Exception.Header", "Technical.Exception")
}

export const showPopupError = (titleKey: string, messageKey?: string) => {
    return MySwal.fire({
        title: t(titleKey),
        html: messageKey ? t(messageKey) : undefined,
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#E72749",
        icon: 'error'
    });
}

export const showPopupInfo = (titleKey: string, messageKey?: string) => {
    return MySwal.fire({
        title: t(titleKey),
        html: messageKey ? t(messageKey) : undefined,
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#009FE3",
        icon: 'success'
    });
}

interface ISweetAlertOptions {
    title: string;
    html: ReactElement,
    confirmButtonText: string,
    denyButtonText?: string,
    cancelButtonText?: string,
    footer?: ReactElement,
    closeButtonHtml?: ReactElement,
    iconHtml?: ReactElement,
    loaderHtml?: ReactElement,
}

export const showMessage = (options: ISweetAlertOptions) => {
    return MySwal.fire(options)
}



export const showAppStorePopup = (
    title: string,
    html: string,
    buttonCancelTitle: string,
    onHideFunction: () => void
) => {
    Swal.fire({
        title: title,
        html: html,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: buttonCancelTitle,
        cancelButtonColor: "#009FE3",
        allowOutsideClick: false
    }).then((result) => {
        if (result.isDenied || result.isDismissed) {
            onHideFunction();
        }
    });


}