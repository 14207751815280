import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import LoadingPage from "../components/Common/LoadingPage";
import { getAuthContextFromStorage } from "../helpers/auth_storage_helper";
import { reautorize } from "../store/actions";
import { sprirt24BuildVersionNumber } from "components/Layout20/Footer20";

const STORAGE_BUILD_KEY = "LAST_LOADED_BUILD_VERSION"
 
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isAccessGranted,
  reauthenticating,
  ...rest
}) => {
  
  // page reload in case of build version cache
  const storedVersion = localStorage.getItem(STORAGE_BUILD_KEY)
  if(sprirt24BuildVersionNumber !== storedVersion){
    console.log('current build: ' + sprirt24BuildVersionNumber)
    console.log('storedVersion: ' + storedVersion)
    localStorage.setItem(STORAGE_BUILD_KEY, sprirt24BuildVersionNumber)
    window.location.reload();
  }

  const dispatch = useDispatch();
  if (reauthenticating) {
    return (<div id="not-logged-in"><LoadingPage /></div>)
  }

  if (isAuthProtected && !isAccessGranted) {
    const loggedInContext = getAuthContextFromStorage();
    if (loggedInContext && loggedInContext.email) {
      dispatch(reautorize(loggedInContext));
      return (<div id="not-logged-in"><LoadingPage /></div>)
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !isAccessGranted) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        return (
          <><div id={isAccessGranted ? "logged-in" : "not-logged-in"} />
            <Layout>
              <Component {...props} />
            </Layout>
          </>
        )
      }}
    />
  )
}


Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  history: PropTypes.object,

}

const mapStatetoProps = state => {
  const { isAccessGranted, reauthenticating } = state.LoginState;
  return { isAccessGranted, reauthenticating};
};

export default connect(mapStatetoProps)(Authmiddleware);