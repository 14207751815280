import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import LoadingContent from "../../components/Common/LoadingContent";
import { DASHBOARD_ACTIONS } from "../../store/actions";
import DashboardPageContent from "./DashboardPageContent";

const DashboardPage = () => {

  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state: any) => (
    {
      isLoading: state.Dashboard.isLoading,
      error: state.Dashboard.error,
      dashboardDto: state.Dashboard.dashboardDto,
    })
  );

  useEffect(() => {
    dispatch(DASHBOARD_ACTIONS.dashboardGetData());
  }, [dispatch]);


  const getContent = () => {
    if (isLoading) return <LoadingContent />
    if (error) return <AlertFrameError messageKey="error"/>
    return <DashboardPageContent />
  }

  return  <Container fluid>{getContent()}</Container>
};


export default DashboardPage; 