import { ResponsivePie } from "@nivo/pie";
import LoadingContent from "components/Common/LoadingContent";
import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { IMG_UTIL, getAppSettlementLogo } from "helpers/image_helper";
import { toPercentage } from "helpers/pln_helper";
import { useSelector } from "react-redux";
import "./settlement.scss";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const SettlmentListYearlyChart = () => {

  const settlementListStore = useSelector((state: any) => state.SettlementListStore);
  const { t } = useTranslation();

  return (
    <DialogWrapper title="SETT_LIST.mobile.additional-chart.year.summary">
      <>
        {settlementListStore.isLoading &&
          <Row><Col><LoadingContent /></Col></Row>
        }
        {(!settlementListStore.isLoading) &&
          <div className="apps-chart-row">
            <>
              {(settlementListStore.context?.percentageAppsInLasyYear?.length === 0) &&
                <div className="w-100">
                  <h6 className="text-center p-3 no-data-info">
                    <img src={IMG_UTIL.empty_icon} height={60} />
                    <div>{t('SETT_LIST.no-data-this-year')}</div>
                  </h6>
                </div>
              }
              {settlementListStore.context?.percentageAppsInLasyYear?.length > 0 &&
                <>
                  <div className="apps-chart-row-apps">
                    {settlementListStore.context.percentageAppsInLasyYear.map((item: any, idx: number) => (
                      <img key={idx} src={getAppSettlementLogo(item.key)} alt={item.label} />
                    ))
                    }
                  </div>
                  <div className="apps-chart-row-chart">
                    <ResponsivePie
                      data={settlementListStore.context.percentageAppsInLasyYear}
                      margin={{ top: 10, right: 20, bottom: 20, left: 10 }}
                      valueFormat={toPercentage}
                      innerRadius={0.35}

                      //startAngle={180} endAngle={270}
                      padAngle={2}
                      cornerRadius={1}
                      arcLabelsSkipAngle={20}
                      activeInnerRadiusOffset={4}
                      activeOuterRadiusOffset={8}
                      borderWidth={0}
                      colors={({ id, data }) => { let anyData: any = data; return anyData.color; }}
                      fit={true}
                      sortByValue={true}
                      enableArcLinkLabels={false}
                      /*arcLinkLabelsDiagonalLength={10}
                      arcLinkLabelsStraightLength={5}
                      arcLinkLabelsSkipAngle={0}
                      arcLinkLabelsTextOffset={0}
                      arcLinkLabelsTextColor="#333333"
                      arcLinkLabelsOffset={2}                    
                      arcLinkLabelsThickness={1}
                      arcLinkLabelsColor={{ from: 'color' }}
                      
  */

                      arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                          [
                            'brighter',
                            8
                          ]
                        ]
                      }}

                      legends={[]}
                    />
                  </div>
                </>
              }
            </>

          </div>
        }
      </>
    </DialogWrapper>
  )
}
export default SettlmentListYearlyChart; 