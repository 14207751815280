import { useTranslation } from "react-i18next";
import { FormGroup, FormText } from "reactstrap";
import { IGenericFormItem } from "./GenericFormItem";

const HelperText = ({ formItem, pageKey }: IGenericFormItem) => {
  const fieldKeyPrefix = pageKey + '.' + formItem.name + '.';
  const textBefore = formItem.textBeforeSuffix ? fieldKeyPrefix + formItem.textBeforeSuffix : undefined;
  const url = formItem.url ? fieldKeyPrefix + formItem.url : undefined;
  const urlLabel = formItem.urlLabel ? fieldKeyPrefix + formItem.urlLabel : undefined;
  const textAfter = formItem.textAfterSuffix ? fieldKeyPrefix + formItem.textAfterSuffix : undefined;

  const { t } = useTranslation();
  return (<FormGroup  >
    <FormText>
      {textBefore &&
        <span>{t(textBefore)}{" "}</span>
      }
      {url &&
        <span>
          {" "}
          <a href={url} target="blank" rel="noreferrer">{t(urlLabel || url)}</a>{" "}
        </span>
      }
      {textAfter &&
        <span>{t(textAfter)}{" "}</span>
      }
    </FormText>
  </FormGroup>
  )
}

export default HelperText