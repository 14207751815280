
export const hasFormikErrors = (fieldName, formErrors, formTouched) => {

    if (!fieldName.includes("[")) {
        if (formErrors[fieldName] && formTouched[fieldName]){
            return formErrors[fieldName];
        } 
        return '';
    }

    //invoiceItems[2].grossValue     
    const tmp1 = fieldName.split('[');  //invoiceItems    [2].grossValue     

    const arrayName = tmp1[0];  //invoiceItems
    const arrayRest = tmp1[1];  //invoiceItems
    const tmp2 = arrayRest.split('].')
    const arrayIndex = Number(tmp2[0])
    const arrayField = tmp2[1];

    //console.log('arrayName:' + arrayName)
    //console.log('arrayIndex:' + arrayIndex)
    //console.log('arrayField:' + arrayField)
    //console.log('formErrors:', formErrors)
    //console.log('formTouched:', formTouched)

    if (!formErrors[arrayName]
        ||
        !formErrors[arrayName][arrayIndex]
        ||
        !formErrors[arrayName][arrayIndex][arrayField]) {
        return '';
    }

    if (!formTouched[arrayName]
        ||
        !formTouched[arrayName][arrayIndex]
        ||
        !formTouched[arrayName][arrayIndex][arrayField]) {
        return '';
    }

  //  console.log('formErrors['+arrayName+']:', formErrors[arrayName])
//    console.log('formErrors['+arrayName+']['+arrayIndex+']:', formErrors[arrayName][arrayIndex])
    console.log('formErrors['+arrayName+']['+arrayIndex+']['+arrayField+']:', formErrors[arrayName][arrayIndex][arrayField])

    return formErrors[arrayName][arrayIndex][arrayField];
}



export const hasFormikErrorsNotTouched = (fieldName, formErrors ) => {

    if (!fieldName.includes("[")) {
        if (formErrors[fieldName]){
            return formErrors[fieldName];
        } 
        return '';
    }

    //invoiceItems[2].grossValue     
    const tmp1 = fieldName.split('[');  //invoiceItems    [2].grossValue     

    const arrayName = tmp1[0];  //invoiceItems
    const arrayRest = tmp1[1];  //invoiceItems
    const tmp2 = arrayRest.split('].')
    const arrayIndex = Number(tmp2[0])
    const arrayField = tmp2[1];

    //console.log('arrayName:' + arrayName)
    //console.log('arrayIndex:' + arrayIndex)
    //console.log('arrayField:' + arrayField)
    //console.log('formErrors:', formErrors)
    //console.log('formTouched:', formTouched)

    if (!formErrors[arrayName]
        ||
        !formErrors[arrayName][arrayIndex]
        ||
        !formErrors[arrayName][arrayIndex][arrayField]) {
        return '';
    }
  //  console.log('formErrors['+arrayName+']:', formErrors[arrayName])
//    console.log('formErrors['+arrayName+']['+arrayIndex+']:', formErrors[arrayName][arrayIndex])
    console.log('formErrors['+arrayName+']['+arrayIndex+']['+arrayField+']:', formErrors[arrayName][arrayIndex][arrayField])

    return formErrors[arrayName][arrayIndex][arrayField];
}