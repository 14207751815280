import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import CustomDate from "components/MyComponents/CustomDate";
import CustomText from "components/MyComponents/CustomText";
import FileUploaderWithPreview from "components/MyComponents/custom-dropzone/FileUploaderPreview";
import { Field, useField } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { ATTACHMENT_NEW_ACT } from "./store/actionsAttNew";
import { IDocumentTypeConfig } from "./store/reducerAttNew";

export interface IAttachemntNewForm {
  documentTypeConfig: IDocumentTypeConfig;
  isLoading: boolean;
}

const AttachmentNewForm = ({ isLoading, documentTypeConfig }: IAttachemntNewForm) => {

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [files] = useField("files");

  if (!documentTypeConfig.id) {
    return <></>
  }

  const showError = (error: string) => {
    dispatch(ATTACHMENT_NEW_ACT.apiError(error))
  }

  return (
    <>
      <Row>
        {documentTypeConfig.valueRequired &&
          <Col xs={12} md={4}>
            <Field name="value" label="ATT_NEW.attachment.value" component={CustomText} isDisabled={isLoading} />
          </Col>
        }
        {documentTypeConfig.releaseDateRequired &&
          <Col xs={12} md={4}>
            <Field name="releaseDate" label="ATT_NEW.attachment.releaseDate" component={CustomDate} isDisabled={isLoading} />
          </Col>
        }
        {documentTypeConfig.expirationDateRequired &&
          <Col xs={12} md={4}>
            <Field name="expirationDate" label="ATT_NEW.attachment.expirationDate" component={CustomDate} isDisabled={isLoading} />
          </Col>
        }
      </Row>


        <h4 className="prevent-select">
          {t('ATT_NEW.divider.files')}
        </h4>

      <FileUploaderWithPreview showError={showError} />

      <Row className="py-3">
        <Col>
          <ButtonSubmit
            iconClassPre="fa-regular fa-circle-check fa-xl"
            disabled={files.value.length === 0}
            id="button-attachment-new"
            isLoading={isLoading} label="ATT_NEW.Submit" />
        </Col>
      </Row>
    </>
  )

}
export default AttachmentNewForm; 