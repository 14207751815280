import { DialogEnum } from "./reducerLayout20";

const PREFIX = "L20_"

const OPEN_SIDEBAR = PREFIX + "OPEN_SIDEBAR";
const openSidebar = () => ({
    type: OPEN_SIDEBAR, payload: {}
});

const CLOSE_SIDEBAR = PREFIX + "CLOSE_SIDEBAR";
const closeSidebar = () => ({
    type: CLOSE_SIDEBAR, payload: {}
});

const TOGGLE_DIALOG = PREFIX + "TOGGLE_DIALOG";
const toggleDialog = (dialogEnum: DialogEnum) => (
{    
    type: TOGGLE_DIALOG, payload: {dialogEnum}
});

export const LAYOUT20_ACT = {
    openSidebar, closeSidebar, toggleDialog
}

export const LAYOUT20_ACT_TYP = {
    OPEN_SIDEBAR, CLOSE_SIDEBAR, TOGGLE_DIALOG
}