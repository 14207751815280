
const STATUS_W4C = 'W4C'; //oczekuje na zatwierdzenie
const STATUS_W4S = 'W4S'; //oczekuje na rozliczenie
const STATUS_CNC = 'CNC'; //odrzucona
const STATUS_AS = 'AS'; //rozliczona
const STATUS_ASP = 'ASP'; //częściowo rozliczona
const STATUS_AC = 'AC'; //rozliczona w gotówce
const STATUS_ARC = 'ARC'; //archiwum


const getStatusIcon = (status: string) => {
    switch (status) {
        case STATUS_W4C:
            return <i className="inv-W4C bx bx-help-circle bx-tada"></i>
        case STATUS_W4S:
            return <i className="inv-W4S bx bx-hourglass"></i>
        case STATUS_CNC:
            return <i className="inv-cnc bx bx-error-circle"></i>
        case STATUS_AS:
            return <i className="inv-AS bx bx-dollar-circle"></i>
        case STATUS_ASP:
            return <i className="inv-ASP bx bx-dollar-circle"></i>
        case STATUS_AC:
            return <i className="inv-AC bx bx-dollar-circle"></i>
        case STATUS_ARC:
            return <i className="inv-OK bx bx-down-arrow-circle"></i>
        default:
            return <i className="unknown-icon bx bx-dizzy"></i>
    }
}

export const INVOICE_CONF = {
    getStatusIcon,
    STATUS_W4C,
    STATUS_W4S,
    STATUS_CNC,
    STATUS_AS,
    STATUS_ASP,
    STATUS_AC,
    STATUS_ARC,
}