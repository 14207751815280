import { AlertFrameGeneric } from "./AlertFrameGeneric";

export const AlertFrameError = ({ messageKey, messageTitle, translationPlaceholdersOject, iconClass = "fa-regular fa-circle-exclamation"}: { 
    messageKey: string | undefined, messageTitle? : string, 
    translationPlaceholdersOject?: object,
    iconClass?: string
}) => {
    return (
        <AlertFrameGeneric messageKey={messageKey} messageTitle={messageTitle} color="red" iconClass={iconClass} translationPlaceholdersOject={translationPlaceholdersOject} />
    )

}