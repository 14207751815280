import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface IDashboardItem {
    titleKey: string,
    valueFormatted: string | ReactElement,
    valueClass?: string,
    percentageChange?: number,
    fixedValue?: string,
}

const DashboardItem = ({ titleKey, 
    valueFormatted, valueClass = "",    
     percentageChange = 0, fixedValue }: IDashboardItem) => {

    const { t } = useTranslation();

    return (

        <div className="info-box">
            <div className="info-con">
                <span>{t(titleKey)}</span>
                <h3 className={valueClass}>{valueFormatted}</h3>
            </div>
            {(percentageChange !== 0 ) &&
                <div className={percentageChange > 0 ? "profit" : "loss"}>
                    <img src={percentageChange > 0 ? "/img/g-up.svg" : "/img/g-down.svg"} />
                   <p>{Math.round(percentageChange)}%</p> 
                </div>
            }

            {(fixedValue) &&
                <div className="const">                                        
                    {fixedValue && <p>{fixedValue}</p>}
                </div>
            }

        </div>
    )

}

export default DashboardItem;