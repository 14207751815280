import { ENDPOINT_CONFIG, get } from "helpers/api_helper";
import { ISagaParam } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { CONFIG_ACT, CONFIG_ACT_TYP } from "./actionsConfig";
import { IConfig } from "./reducerConfig";


function* loadData(action: ISagaParam) {
    try {
        let config: IConfig = yield call(get, ENDPOINT_CONFIG.RESOURCE_GET_CONFIG, {})
        yield put(CONFIG_ACT.loadDataDone(config));
    } catch (error) {
        console.log('config.loadData error', error);
        yield put(CONFIG_ACT.apiError(error));
    }
}

export function* ConfigSaga() {
    yield takeEvery(CONFIG_ACT_TYP.LOAD_DATA, loadData);
}

export default ConfigSaga;
