import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { BooleanLocale } from "yup/lib/locale";

interface ButtonProps {
  id: string;
  label: string;
  color?: string,
  iconClass?: string;
  iconClassPre?: string;
  iconFileSrc?: string;
  isLoading: boolean;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
}

export const ButtonSubmit = ({ id, label, isLoading, disabled = false, iconClass, iconClassPre, color = "default", fullWidth = false, iconFileSrc, className = "" }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      disabled={isLoading || disabled}
      type="submit"
      color={color}
      className={"btn btn-block btn-color-" + color + (isLoading ? " fade-in-out-animation" : "") + (fullWidth ? " btn-lg btn-block w-100" : "") + " " + className}
      id={id}
    >
      {iconClassPre &&
        <i className={'icon-left ' + iconClassPre}></i>
      }

      {t(label)}
      {iconFileSrc &&
        <img className="to-white px-2" src={iconFileSrc} alt=""></img>
      }

      {iconClass &&
        <i className={'icon-right ' + iconClass}></i>
      }
    </Button>
  );
};

export default ButtonSubmit;
