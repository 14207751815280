import { DetailsItem } from "components/Common/datatable/DetailsItem";
import { MobileGridRowCompact } from "components/Common/datatable/MobileGridRowCompact";
import TableComponent from "components/Common/datatable/TableComponent";
import ButtonCustom from "components/MyComponents/ButtonCustom";
import { USER_ROLES } from "constants/UserRoles";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ROUTES } from "routes";
import { fillPlaceholders } from "routes/placeholder_helper";
import { AGREEMENT_CONF } from "./AgreementStatus";
import "./agreement.scss";
import { AGREEMENT_LIST_ACT } from "./store/actionsAgrList";
import ButtonFloating from "components/MyComponents/ButtonFloating";
import { ButonCustomPdfModal } from "components/MyComponents/PdfModal/ButonCustomPdfModal";

const AgreementListPage = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const availableRoles = useSelector((state: any) => state.LoginState.availableRoles);
  const agreementListStore = useSelector((state: any) => state.AgreementListStore);

  const mapStatusToBadgeColor = (status: string) => {

    switch (status) {
      case AGREEMENT_CONF.STATUS_SIG:
        return 'green-badge';
      case AGREEMENT_CONF.STATUS_REJ:
        return 'red-badge'
      case AGREEMENT_CONF.STATUS_FIN:
        return 'gray-badge'
      case AGREEMENT_CONF.STATUS_W4C:
        return 'orange-badge'
      default:
        return 'blue-badge'
    }

  }

  useEffect(() => {
    if (agreementListStore.reloadRequired) {
      dispatch(AGREEMENT_LIST_ACT.loadData());
    }
  }, [agreementListStore.reloadRequired, dispatch])

  const renderContent = (row: any) => {

    const getChildrenInstant = () => {
      return <>
        <DetailsItem labelKey="AGR_LIST.agreementNumber" value={row.agreementNumber} />
        <DetailsItem labelKey="AGR_LIST.status" value={t("AgreementStatus." + row.status)} />
        {(
          row.status === AGREEMENT_CONF.STATUS_SIG ||
          row.status == AGREEMENT_CONF.STATUS_W4C ||
          row.status == AGREEMENT_CONF.STATUS_REJ ||
          row.status == AGREEMENT_CONF.STATUS_FIN) &&
          <DetailsItem labelKey="AGR_LIST.signDate" value={row.signDate} />
        }
        {row.status == AGREEMENT_CONF.STATUS_W4C &&
          <DetailsItem labelKey="AGR_LIST.verificationDate" value={t('AGR_LIST.verificationDate.waiting')} />
        }
        {(row.status == AGREEMENT_CONF.STATUS_SIG || row.status == AGREEMENT_CONF.STATUS_FIN) &&
          <DetailsItem labelKey="AGR_LIST.verificationDate" value={row.verificationDate} />
        }
        {(row.status == AGREEMENT_CONF.STATUS_FIN) &&
          <DetailsItem labelKey="AGR_LIST.finishDate" value={row.finishDate} />
        }
        {row.description &&
          <DetailsItem labelKey="AGR_LIST.description" value={row.description} inline={false} />
        }
        {(row.status == AGREEMENT_CONF.STATUS_REJ) &&
          <>
            <DetailsItem labelKey="AGR_LIST.verificationDate" value={row.verificationDate} />
            {row.rejectionReasonIdTranslated &&
              <DetailsItem labelKey="AGR_LIST.rejectionReasonIdTranslated" value={row.rejectionReasonIdTranslated} inline={false} />
            }
            {row.rejectionReason &&
              <DetailsItem labelKey="AGR_LIST.rejectionReason" value={row.rejectionReason} inline={false} />
            }
          </>
        }
        {(row.status == AGREEMENT_CONF.STATUS_FIN) &&
          <>
            {row.finishReasonIdTranslated &&
              <DetailsItem labelKey="AGR_LIST.finishReasonIdTranslated" value={row.finishReasonIdTranslated} inline={false} />
            }
            {row.finishReason &&
              <DetailsItem labelKey="AGR_LIST.finishReason" value={row.finishReason} inline={false} />
            }
          </>
        }
        <Row className="py-2">
          <Col>
            <ButonCustomPdfModal id="button-agreement-pdf-preview" label='AGR_LIST.button.downloadPdf'
              pdfFileId={row.pdfFileId}
              pdfFileName={row.pdfFileName}
            />
          </Col>
          {row.status == AGREEMENT_CONF.STATUS_W4C &&
            <Col>
              <ButtonCustom id="button-agreement-reject"
                label="AGR_LIST.button.rejectAwaiting" color="danger"
                iconClassPre="fa-duotone fa-trash-can"
                onClick={() => { dispatch(AGREEMENT_LIST_ACT.rejectAgreement(row.id)) }} />
            </Col>
          }
        </Row>
      </>

    }
    const getHeader = () => {
      return (
        <>
          <div>
            <div className="head-with-badge prevent-select">
              <h3 className="w-100 row-item-header">{t('Agreement.grid.kind.' + row.agreementKind)}</h3>
              <span className={"badge " + mapStatusToBadgeColor(row.status)}>{t("AgreementStatus." + row.status)}</span>
              <span className="badge blue-badge">{row.agreementNumber}</span>
            </div>
          </div>
        </>
      )
    }

    return (
      <MobileGridRowCompact
        id={row.id}
        getHeader={getHeader} getChildrenInstant={getChildrenInstant} />
    )
  }

  const renderNewAgreementButton = () => {

    const canCreateInvoices = availableRoles.includes(USER_ROLES.AGREEMENT_ADD);
    if (!canCreateInvoices) {
      return <></>;
    }
    const openNewAgreement = () => {
      history.push(ROUTES.ROUTE_AGREEMENT_P0_NAVBAR);
    }

    return (


      <ButtonFloating
        id="button-agreement-new"
        color="green"
        label={'Agreement.New.NAV'}
        onClick={openNewAgreement}
        iconClass="fa-regular fa-circle-plus fa-xl"
      />

    )
  }


  return (
    <Container fluid>
      <TableComponent
        tableName="AGR_LIST"
        additionalHeaderRow={() => { return renderNewAgreementButton() }}
        hederKey="AGR_LIST.mobile.header"
        renderContent={renderContent}
        data={agreementListStore}
      />
    </Container>
  )

}
export default AgreementListPage; 