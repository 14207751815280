import {
  useWindowSize,
} from '@react-hook/window-size';
import { AlertFrameError } from "components/Common/datatable/AlertFrameError";
import { isCanvasBlank } from "helpers/predicate_helper";
import HelperHeaderContent from "pages/Agreement/FormItems/HelperHeaderContent";
import React, { useEffect, useRef, useState } from "react";
import { default as SignatureCanvas, default as SignaturePad } from "react-signature-canvas";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import ButtonTripple from "./ButtonTripple";
import "./singatureCustom.scss";

interface SignatureActions {
  saveSignature: (signature: string) => void;
  moveBack: () => void;
  isLoading: boolean;
  headerText: string;
  headerTextBefore: string;
  headerTextAfter: string;
}

export default function SignatureCustom({ saveSignature, moveBack, isLoading ,headerText, headerTextBefore, headerTextAfter}: SignatureActions) {

  let [width, height] = useWindowSize()
  const canvaDivRef = useRef(null);
  let padRef = React.useRef<SignatureCanvas>(null);
  const [sigError, setSigError] = useState('')

  const [canvaDimension, setCanvaDimension] = useState({ w: 0, h: 0 })
  let isLandscape = window.innerWidth / window.innerHeight > 1
  let currentDiv: any = canvaDivRef.current;

  const geScreentWidth = () => {

    if (!currentDiv) {
      return 320;
    }

    if (isLandscape) {
      return currentDiv.getBoundingClientRect()?.width
    } else {
      return currentDiv.getBoundingClientRect()?.width
    }
  }

  useEffect(() => {
    const canvaWidth = geScreentWidth();
    const canvaHeigth = canvaWidth / 3 - 40;
    setCanvaDimension({ w: canvaWidth, h: canvaHeigth })
  }, [currentDiv?.getBoundingClientRect()?.width, isLandscape])

  const clear = () => {
    padRef.current?.clear();
  };

  const save = () => {
    const isBlank = isCanvasBlank(padRef.current?.getTrimmedCanvas())
    const url = padRef.current?.getTrimmedCanvas().toDataURL("image/png");
    if (url && !isBlank) {
      setSigError('')
      saveSignature(url);
    } else {
      setSigError('Signature.NoSignature')
    }
  };

  const isRotationSuggested = width < 800 && width < height;
  const isModalRequired = height < 500;
  const isHighEnougth = height > 500;

  return (
    <>
      <AlertFrameError messageKey={sigError} />
      {isHighEnougth &&
        <Row>
          <Col>
            <HelperHeaderContent
              headerText={headerText}
              textBefore={headerTextBefore}
              textAfter={headerTextAfter}
            />
  

          </Col>
        </Row>
      }
      {isRotationSuggested &&
        <Row>
          <Col>
            <HelperHeaderContent
              headerText="Agreement.WizardPage.SignAgreement.Signature.warning.rotate_screen"
              textBefore="Agreement.WizardPage.SignAgreement.Signature.warning.rotate_screen.line1"
              warningIconClass="fa-duotone fa-mobile-screen-button fa-rotate-90"
            />
          </Col>
        </Row>
      }
      {isModalRequired &&
        <Modal isOpen={!isRotationSuggested} fullscreen >
          <ModalBody title={''}>
            <div className="d-flex justify-content-md-center align-items-center text-center">
              <div className="signature-canva w-100" ref={canvaDivRef}>
                <SignaturePad ref={padRef}
                  clearOnResize={false}
                  canvasProps={{ width: (canvaDimension.w) + 'px', height: canvaDimension.h + 'px' }}
                  penColor="rgb(40,66,131)"
                />
              </div>
            </div>
            <div className="w-100 p0 m0">
              <ButtonTripple
                id='button-wizard-signature'
                isLoading={isLoading}
                label="Agreement.WizardPage.SignAgreement.StepperButton"
                onClickList={moveBack}
                onClickBack={clear}
                onClickBackIcon="fa-duotone fa-eraser blue  fa-xl"
                onNext={save}
              />
            </div>
          </ModalBody>
        </Modal >
      }
      {(!isModalRequired && !isRotationSuggested) &&
        <>
          <div className="d-flex justify-content-md-center align-items-center text-center">
            <div className="signature-canva w-100" ref={canvaDivRef}>
              <SignaturePad ref={padRef}
                clearOnResize={false}
                canvasProps={{ width: canvaDimension.w + 'px', height: canvaDimension.h + 'px' }}
                penColor="rgb(40,66,131)"
              />
            </div>
          </div>
          <div className="w-100 p0 m0">
            <ButtonTripple
              id='button-wizard-signature'
              isLoading={isLoading}
              label="Agreement.WizardPage.SignAgreement.StepperButton"
              onClickList={moveBack}
              onClickBack={clear}
              onClickBackIcon="fa-duotone fa-eraser blue fa-xl"
              onNext={save}
            />
          </div>
        </>
      }
    </>

  );
}
