import { FieldProps } from "formik";
import { hasFormikErrors } from "helpers/formikErrors";
import { is_in_options } from "helpers/predicate_helper";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { FormGroup, FormText } from "reactstrap";
import TranslatedErrors from "./TranslatedErrors";
import './CustomSelect.scss'
export const SELECT_SINGLE_DEFAULT_PLACEHOLDER = "Common.SelectSingle.DefaultPlaceholder";
export const SELECT_MULTI_DEFAULT_PLACEHOLDER = "Common.SelectMulti.DefaultPlaceholder";
import { isMobile } from "react-device-detect";

export interface IOption {
    label: string;
    value: string;
    icon?: string;
    labelKey?: string;
    reportValue?: string;
}
interface CustomSelectProps extends FieldProps {
    options: IOption[];
    isMulti?: boolean;
    className?: string;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    textAfter: string;
    autoFocus: boolean;
    afterOnChange?(name: string, value: string | null | string[]): void;
}


const { Option } = components;
const IconOption = (props: any) => (
    <Option {...props}>
        {(props.data.icon && props.data.icon.includes('.')) &&
            <img
                src={props.data.icon}
                className="option-boundary"
                alt={props.data.label}
            />}
        {(props.data.icon && !props.data.icon.includes('.')) &&
            <i className={'pe-2 blue ' + props.data.icon} />
        }
        {props.data.label}
    </Option>
);




export const translateLabels = (options: IOption[], t: any) => {
    options.forEach(option => {
        if (option.labelKey) {
            const translatedValue = t(option.labelKey);
            if (translatedValue !== option.labelKey) {
                option.label = translatedValue;
            }
        }
    })
    return options;
}


function cutToBraces(inputString: string) {

    if (!isMobile) {
        return inputString;
    }

    const index = inputString.indexOf("(");
    if (index !== -1) {
        return inputString.substring(0, index);
    } else {
        return inputString;
    }
}

export const CustomSelect = ({
    className,
    placeholder,
    label,
    field,
    form,
    options,
    isMulti = false,
    textAfter,
    disabled = false,
    afterOnChange,
    autoFocus,
}: CustomSelectProps) => {


    const { t, i18n } = useTranslation();
    if (field.value === undefined || field.name === undefined) {
        return <></>
    }

    const onChange = (option: IOption | null | IOption[]) => {

        const newValue = isMulti
            ? (option as IOption[]).map((item: IOption) => item.value)
            : (option as IOption).value;

        form.setFieldValue(field.name, newValue);
        if (afterOnChange) {
            afterOnChange(field.name, newValue);
        }
    };

    const getValue = () => {
        if (options) {

            if (!isMulti) {
                //TODO dla multiselecta jeśli będzie zmiana na jednym ekranie z rerenderem to tez trzeba to dodać
                if (!is_in_options(field.value)) {
                    return "";
                }
            }

            return isMulti
                ? options.filter(option => field.value.indexOf(option.value) >= 0)
                : options.find(option => option.value === field.value);
        } else {
            return isMulti ? [] : ("" as any);
        }
    };

    //const hasError = form.errors[field.name] && form.touched[field.name];
    const errors = hasFormikErrors(field.name, form.errors, form.touched);
    return (
        <>
            <FormGroup key={'group-' + field.name + '-' + i18n.language}>
                <span className=" mb-3 select2-container">
                    {label &&
                        <label className="control-label">{t(label)}</label>
                    }
                    <Select
                        id={field.name}
                        key={'select-' + field.name + '-' + i18n.language}
                        className={className + (errors ? " select-with-errors" : "")}
                        name={field.name}
                        isSearchable={options && options.length > 10}
                        value={getValue()}
                        onChange={onChange}
                        placeholder={placeholder ? t(placeholder) : undefined}
                        options={options}
                        isMulti={isMulti}
                        autoFocus={autoFocus}
                        isDisabled={disabled}
                        components={{ Option: IconOption }}

                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                boxShadow: "none",
                                border: "1px solid",
                                color: "#000000",
                                width: "100%",
                                borderRadius: '8px'
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                border: state.isSelected ? '1px solid #009FE3' : 'inherit',
                            })
                        }}


                        formatOptionLabel={item => (
                            <div>
                                {(item.icon && item.icon.includes('.')) &&
                                    <img className="option-boundary" src={item.icon} alt={item.value} />
                                }
                                {(item.icon && !item.icon.includes('.')) &&
                                    <i className={'pe-2 blue ' + item.icon} />
                                }
                                <span>{cutToBraces(item.label)}</span>
                            </div>
                        )}

                    />
                    {errors &&
                        <TranslatedErrors errors={errors} />
                    }
                    {textAfter &&
                        <FormText>{t(textAfter)}</FormText>
                    }

                </span>

            </FormGroup>

        </>
    );
};

export default CustomSelect;
