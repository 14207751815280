import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import LoadingContent from "../../components/Common/LoadingContent";
import ProfilePage_02_ProfiletData from "./ProfilePage_02_ProfiletData";
import ProfilePage_04_SettlementConfig from "./ProfilePage_04_SettlementConfig";
import ProfilePage_06_IbanData from "./ProfilePage_06_IbanData";
import ProfilePage_08_Admin from "./ProfilePage_08_Admin";
import { PROFILE_ACT } from "./store/actionsProfile";

const ProfilePage = () => {

  const dispatch = useDispatch();
  const profileStore = useSelector((state: any) => state.ProfileStore);
  const storeInitialFetch = profileStore.initlaFetch

  useEffect(() => {
    if (storeInitialFetch == 'TO_BE_LOADED') {
      dispatch(PROFILE_ACT.getProfile())
    }
  }, [storeInitialFetch])


  return (
    <Container fluid>
      {storeInitialFetch != 'LOADED' &&
        <LoadingContent />
      }
      {storeInitialFetch === 'LOADED' &&
        <>
          <ProfilePage_02_ProfiletData />        
          <ProfilePage_06_IbanData />
          <ProfilePage_04_SettlementConfig />
          <ProfilePage_08_Admin />
        </>
      }
    </Container>
  );
};


export default ProfilePage; 