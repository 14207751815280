import { ICONS } from "helpers/IconHelper";
import { getCircleCompanyId } from "helpers/image_helper";
import { getMoney } from "helpers/money";
import { translateIfHave } from "helpers/text_helper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { rowCsvItemMoney } from "./SettUtil";
import { DetailsItemWithHeader } from "components/Common/datatable/DetailsItem";

export interface IAllCostsDTO {
  id: number,
  costTypeLabel: string,
  info: string,
  amount: number,
}

export interface ISettlementDetails02_ListOfAllCosts {
  listOfAllCosts: IAllCostsDTO[],
  allCostsAndDepositsSum: number
}


export const SettlementDetails02_ListOfAllCosts = ({ allCostsAndDepositsSum, listOfAllCosts }: ISettlementDetails02_ListOfAllCosts) => {

  const companyId = useSelector((state: any) => { return state.LoginState.companyId });
  const { t, i18n } = useTranslation();

  if (!listOfAllCosts || listOfAllCosts.length === 0) {
    return <></>
  }

  return (
    <div className="mb-4">
      <Row className="csv-item-header align-items-center m-0 word-break">
        <Col xs="auto" className="csv-item-header-logo" >
          <img className="app-logo-img-details pe-1" src={getCircleCompanyId(companyId)} alt={companyId} />
        </Col>
        <Col className="csv-item-header-data">
          <span className="table-h-con">
            <h3>
              {t('settlement.addons.header')}
            </h3>
          </span>
        </Col>
      </Row>

      {listOfAllCosts.map((dto: IAllCostsDTO, key: number) => (
        <div key={`sett-all-cost-item-${dto.id}`}>
          <DetailsItemWithHeader
            labelKey={translateIfHave(dto.info, 'settlement.cost-info.', t, i18n)}
            valueKey={translateIfHave(dto.costTypeLabel, 'settlement.cost-type.', t, i18n)}
            value={getMoney(dto.amount)}
          />
        </div>
      ))
      }
      {rowCsvItemMoney(t, allCostsAndDepositsSum, 'summary', 'addons', 'summary-row-first')}

    </div>
  )
}