import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "routes";

const NotificationsMenu = () => {

    const history = useHistory();
    const notificationContext = useSelector((state: any) => state.NotificationsListStore.notificationContext);

    return (
        <div className="bell-icon-container" onClick={() => history.push(ROUTES.ROUTE_NOTIFICATIONS_LIST)}>
            {notificationContext.activeCount > 0 &&
                <div className="bell-icon-badge">{notificationContext.activeCount}</div>
            }
            <i className={"fa-regular fa-bell bell-icon" + (notificationContext.activeCount > 0 ? " fa-beat-fade" : "")} />
        </div>
    )
}


export default NotificationsMenu;