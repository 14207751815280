export const getCompanyContext = () => {
    let origin = window.location.origin as string;

    if (origin.toLocaleLowerCase().includes('quick')) {
        return 'QA';
    }

    if (origin.toLocaleLowerCase().includes('avalon')) {
        return 'ALG'
    }

    return 'ALG';
}

export const getCompanyContextLong = () => {
    /* kontekst domeny
    let origin = window.location.origin as string;
    if (origin.toLocaleLowerCase().includes('subdomena')) {
        return 'Subdomena';
    }    
    */
    return 'Avalon Logistics Group';
}

export const getCompanyHrefByContext = () => {
    /* kontekst domeny
    let origin = window.location.origin as string;
    if (origin.toLocaleLowerCase().includes('avalon')) {
        return 'https://avalon-logistics.pl/'
    }
    */
    return 'https://avalon-logistics.pl/';
}