import { IOption } from "components/MyComponents/CustomSelect";
import { ResponseGridData } from "helpers/tsx_helper";
const PREFIX = "AGR_PRE_LIST_"

/////////////////////////////////////////////////

//get agreement available types
const GET_AVAILABLE_TYPES = PREFIX + "GET_AVAILABLE_TYPES";
const getAvailableTypes = (history: any) => ({ type: GET_AVAILABLE_TYPES, payload: { history } });

const GET_AVAILABLE_TYPES_DONE = PREFIX + "GET_AVAILABLE_TYPES_DONE";
const getAvailableTypesDone = (gridDataResponse: ResponseGridData<IOption>) => (
    {type: GET_AVAILABLE_TYPES_DONE, payload: {gridDataResponse}});

const FORCE_RELOAD_AVAILABLE_TYPES = PREFIX + "FORCE_RELOAD_AVAILABLE_TYPES";
const forceReload = () => ({ type: FORCE_RELOAD_AVAILABLE_TYPES, payload: {} });

const API_FAIL = PREFIX + "API_FAIL";
const apiFail = (error: any) => ({
    type: API_FAIL, payload: { error },
});


export const AGREEMENT_PRE_LIST_ACTION = {
    getAvailableTypes,
    getAvailableTypesDone,
    forceReload,
    apiFail,
}

export const AGREEMENT_PRE_LIST_ACTION_TYPES = {
    GET_AVAILABLE_TYPES,
    GET_AVAILABLE_TYPES_DONE,
    FORCE_RELOAD_AVAILABLE_TYPES,
    API_FAIL
}