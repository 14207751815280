import { Field, FieldProps } from "formik";
import { hasFormikErrors } from "helpers/formikErrors";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, FormText, Input, InputGroup, Label } from "reactstrap";
import TranslatedErrors from "./TranslatedErrors";
import "./CustomTextWithButton.scss";

interface TextProps extends FieldProps {
  placeholder?: string;
  label?: string;
  textAfter?: string;
  textBefore?: string;
  isDisabled?: boolean;
  isButtonDisabled?: boolean;
  autoFocus: boolean;
  inputGroupPrefixContent?: ReactElement
  forcedId?: string;
  buttonLabel: string;
  buttonColor: string;
  buttonOnClick: () => void;
}

export const CustomTextWithButton = ({
  placeholder,
  label,
  field,
  form,
  textBefore,
  textAfter,
  isDisabled,
  autoFocus,
  inputGroupPrefixContent,
  forcedId,
  buttonLabel,
  buttonOnClick,
  isButtonDisabled = false,
  buttonColor = 'default'
}: TextProps) => {
  const { t } = useTranslation();
  if (field.value === undefined) {
    return <></>
  }

  const error = hasFormikErrors(field.name, form.errors, form.touched);

  return (
    <>
      {textBefore &&
        <FormGroup  >
          <FormText>{t(textBefore)}</FormText>
        </FormGroup>
      }
      <FormGroup>
        {label &&
          <Label htmlFor={field.name}>{t(label)}</Label>
        }
        <InputGroup>
          {inputGroupPrefixContent &&
            <div className="input-group-text">{inputGroupPrefixContent}</div>
          }
          
            {isDisabled && //DRUT 
              <Input type="text" disabled={isDisabled} autoComplete="off"
                autoFocus={autoFocus}
                value={field.value}
                name={field.name}
                id={forcedId ? forcedId : field.name}
                placeholder={placeholder}
                className={"form-control input-rigth-button" + (error ? " is-invalid" : "")}
              />
            }
            {!isDisabled &&
              <Field type="text" disabled={isDisabled} autoComplete="off"
                autoFocus={autoFocus}
                name={field.name}
                id={forcedId ? forcedId : field.name}
                placeholder={placeholder}
                className={"form-control input-rigth-button" + (error ? " is-invalid" : "")}
              />
            }
            <div className="input-group-append">
              <button className={`btn btn-color-${buttonColor} btn-${buttonColor} btn-right-glued`} type="button"
                disabled={isButtonDisabled}
                onClick={() => {
                  if (!isButtonDisabled) {
                    buttonOnClick()
                  } 
                  
                }}
              >{t(buttonLabel)}</button>
            </div>
        </InputGroup>
        {error &&
          <TranslatedErrors errors={error} />
        }
        {textAfter &&
          <FormText>{t(textAfter)}</FormText>
        }
      </FormGroup>
    </>
  );
};

export default CustomTextWithButton;
