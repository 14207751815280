import { useWindowSize } from "@react-hook/window-size";
import { yyyymmdd } from "helpers/date_helper";
import { IMG_UTIL } from "helpers/image_helper";
import { showAppStorePopup } from "helpers/sweetalert/ShowAlert";
import { useEffect, useState } from "react";
import { getUA, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const showStore = (deviceType: 'android' | 'iphone', width: number) => {
    if (getUA === 'twinr-app') {
        return false;
    }
    return getUA.toLocaleLowerCase().includes(deviceType) || width > 1200;
}

const APP_STORE_LS_KEY = "spirit24-app-store-popup-submit-date";
type AppStoreButtonsProps = {
    align: 'text-start' | 'text-center'
}

const AppStoreButtons = ({ align }: AppStoreButtonsProps) => {

    const affiliationKey = useSelector((state: any) => state.AffiliationStore.affiliationKey);
    const { t } = useTranslation();
    const [width] = useWindowSize()
    const showAppleStore = showStore('iphone', width);
    const showAndroidStore = showStore('android', width);

    const [isFirstRun, setFirstRun] = useState(true)

    //załaduj opóźnione dane
    useEffect(() => {

        //jeżlie już jest w twinr-app
        if (getUA === 'twinr-app') {
            //console.log('skip buttons: twinr-app');
            return;
        }
        //jeżlie nie komórka
        if (!isMobile) {
            //console.log('skip buttons: !isMobile');
            return;
        }

        //Z linka afiliacyjnego nie rzucam popupa żeby nie tracić kontekstu afiliacji.
        if (affiliationKey) {
            //console.log('skip buttons: affiliationKey:' + affiliationKey);
            return;
        }

        //skip first run to force affiliation key
        if (isFirstRun) {
            //console.log('skip first run');
            setFirstRun(false);
            return;
        }

        const currDate = yyyymmdd();
        const appStoreProposedDate = localStorage.getItem(APP_STORE_LS_KEY);
        if (currDate === appStoreProposedDate) {
            console.log('skip buttons: already have');
            return;
        }

        const html = showAppleStore ?
            `<div class="swal2-html-container-div"><a href="https://apps.apple.com/pl/app/spirit24/id6448918857?l=pl" class="py-5 href-app-store">
                        <img src="/img/app-store-apple.png" alt="Spirit24 on Apple Store"/>
                      </a>
                      </div>
                    `
            :
            `<div class="swal2-html-container-div"><a href="https://play.google.com/store/apps/details?id=pl.spirit24.mobile&pli=1" class="py-5 href-app-store">
                       <img src="/img/app-store-google.png"  alt="Spirit24 on Google Play"/>
                     </a></div>`;

        showAppStorePopup(
            t('app-store-popup.title'),
            html,
            t('app-store-popup.buttonCancelTitle'),
            () => { localStorage.setItem(APP_STORE_LS_KEY, currDate) }
        )

    }, [affiliationKey, isFirstRun])

    return (
        <div className={"hidden-in-mobile-app " + align}>
            {showAndroidStore &&
                <a href="https://play.google.com/store/apps/details?id=pl.spirit24.mobile&pli=1" className="me-2" >
                    <img height={40} src={IMG_UTIL.mob_app_shop_android} alt="Google Play" />
                </a>
            }
            {showAppleStore &&
                <a href="https://apps.apple.com/pl/app/spirit24/id6448918857?l=pl" >
                    <img height={40} src={IMG_UTIL.mob_app_shop_apple} alt="Apple Store" />
                </a>
            }
        </div>

    )
}



export default AppStoreButtons
