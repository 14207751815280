const PREFIX = "INN_"

 

const ADD_INVOICE = PREFIX + "ADD_INVOICE";
const addInvoice = (values: any,  history: any) => ({
    type: ADD_INVOICE, payload: {  values, history}
});

const ADD_INVOICE_DONE = PREFIX + "ADD_INVOICE_DONE";
const addInvoiceDone = () => ({
    type: ADD_INVOICE_DONE, payload: {}
});

const API_ERROR = PREFIX + "API_FAIL";
const apiError = (error: any) => {
    return { type: API_ERROR, payload: { error }, }
}

export const INVOICE_NEW_ACT = {
    addInvoice, addInvoiceDone,
    apiError
}

export const INVOICE_NEW_ACT_TYP = {
    ADD_INVOICE, ADD_INVOICE_DONE,
    API_ERROR
}