 
const baner_aff_link_PL = require('assets/images/banners/polecaj_i_zarabiaj_280px_PL.png');
const baner_aff_link_EN = require('assets/images/banners/polecaj_i_zarabiaj_280px_EN.png');
const baner_aff_link_RU = require('assets/images/banners/polecaj_i_zarabiaj_280px_RU.png');
const baner_aff_link_UA = require('assets/images/banners/polecaj_i_zarabiaj_280px_UA.png');
const baner_aff_link_GE = require('assets/images/banners/polecaj_i_zarabiaj_280px_GE.png');


const getBanner = (banner: string, language: string, imgClassName : string) => {

    if (banner === 'aff-link') {
        return getAffLinkDashboardBanner(language, imgClassName);
    }
    return undefined;
}

const getAffLinkDashboardBanner = (language: string, imgClassName : string) => {
    if (language === 'en') {
        return <img src={baner_aff_link_EN} className={imgClassName} alt="Refer and earn" />
    }
    if (language === 'ru') {
        return <img src={baner_aff_link_RU} className={imgClassName} alt="Рекомендуй и зарабатывай" />
    }
    if (language === 'ua') {
        return <img src={baner_aff_link_UA} className={imgClassName} alt="Рекомендуйте та заробляйте" />
    }
    if (language === 'ge') {
        return <img src={baner_aff_link_GE} className={imgClassName} alt="ურჩიე და გამოიმუშავე" />
    }
    return <img src={baner_aff_link_PL} className={imgClassName} alt="Polecaj i zababiaj" />
}

//const empty_icon = require('assets/images/components/icons8-empty-box-64.png');
const empty_icon = require('assets/images/components/eligrafia_empty_box.png');

const getSettlementRoundedIcon = (image: string, alt: string) => {
    return (<div className="rounded-circle sett-rounded-icon-wrapper">
        <img src={image} className="rounded-circle mx-auto d-block sett-rounded-icon" alt={alt} />
    </div>
    )
}

const sett_summary = require('assets/images/components/eligrafia_summary.png');
const sett_transfers = require('assets/images/components/eligrafia_transfers.png');


const getSettlementSummary = () => {
    return getSettlementRoundedIcon(sett_summary, 'Saldo');
}
const getSettlementTransfers = () => {
    return getSettlementRoundedIcon(sett_transfers, 'Bank');
}

const checkbox_checked = require('assets/images/components/checkbox.png');
const checkbox_unchecked = require('assets/images/components/checkbox_unchecked.png');

export const getCheckboxIcon = (checkboxState: boolean) => {
    if (checkboxState) {
        return checkbox_checked;
    } else {
        return checkbox_unchecked;
    }
}

const navbarLogoDarkW200 = require('assets/images/navbar/logo-dark-wide_w200_h30.png');
const navbarLogoDarkW300 = require('assets/images/navbar/logo-dark-wide_w300_h30.png');
const navbarLogoDarkIconicW30 = require('assets/images/navbar/logo-dark-iconic_w30_h30.png');

const navbarLogoDarkSidebarW200 = require('assets/images/navbar/logo-dark-sidebar_w200_h30.png');

const navbarLogoLightW200 = require('assets/images/navbar/logo-light-wide_w200_h30.png');
const navbarLogoLightW300 = require('assets/images/navbar/logo-light-wide_w300_h30.png');
const navbarLogoLightIconicW30 = require('assets/images/navbar/logo-light-iconic_w30_h30.png');

export const NAVBAR_LOGOS = {
    navbarLogoDarkW200,
    navbarLogoDarkW300,
    navbarLogoDarkIconicW30,
    navbarLogoDarkSidebarW200,
    navbarLogoLightW200,
    navbarLogoLightW300,
    navbarLogoLightIconicW30
}

const logo_log_in = require('assets/images/logos/Logo_Avalon_Logistics_group_spirit.svg');
const logo_log_in_ALG = require('assets/images/logos/Logo_Avalon_Logistics_group_spirit.svg');

const forget_pass = require('assets/images/logos/Logo_Avalon_Logistics_group_spirit.svg');
const forget_pass_ALG = require('assets/images/logos/Logo_Avalon_Logistics_group_spirit.svg');

const logo_register_ALG = require('assets/images/logos/Logo_Avalon_Logistics_group_spirit.svg');
const logo_register = require('assets/images/logos/Logo_Avalon_Logistics_group_spirit.svg');

const COMPANY_CONTEXT_IMAGES = {

    'log_in_ALG': logo_log_in_ALG,
    'log_in_QA': logo_log_in_ALG,
    'log_in_SPIRIT': logo_log_in,

    'forget_pass_ALG': forget_pass_ALG,
    'forget_pass_QA': forget_pass_ALG,
    'forget_pass_SPIRIT': forget_pass,

    'register_ALG': logo_register_ALG,
    'register_QA': logo_register_ALG,
    'register_SPIRIT': logo_register,

}

const getCompanyLogIn = (companyId: string) => {
    const key = "log_in_" + companyId;
    if (COMPANY_CONTEXT_IMAGES.hasOwnProperty(key)) {
        let appKey = key as keyof typeof COMPANY_CONTEXT_IMAGES;
        return COMPANY_CONTEXT_IMAGES[appKey];
    } else {
        return COMPANY_CONTEXT_IMAGES['log_in_SPIRIT'];
    }
}

const getCompanyLogoHeigth = (companyId: string) => {
     if(companyId==='QA'){
        return 120;
     }
     return 283;
}


const getCompanyRegister = (companyId: string) => {
    const key = "register_" + companyId;
    if (COMPANY_CONTEXT_IMAGES.hasOwnProperty(key)) {
        let appKey = key as keyof typeof COMPANY_CONTEXT_IMAGES;
        return COMPANY_CONTEXT_IMAGES[appKey];
    } else {
        return COMPANY_CONTEXT_IMAGES['register_SPIRIT'];
    }
}

const getCompanyForgetPassword = (companyId: string) => {
    const key = "forget_pass_" + companyId;
    if (COMPANY_CONTEXT_IMAGES.hasOwnProperty(key)) {
        let appKey = key as keyof typeof COMPANY_CONTEXT_IMAGES;
        return COMPANY_CONTEXT_IMAGES[appKey];
    } else {
        return COMPANY_CONTEXT_IMAGES['forget_pass_SPIRIT'];
    }
}

const reg_logo_bolt_food = require('assets/images/apps/BF.png');
const reg_logo_bolt_taxi = require('assets/images/apps/BT.png');
const reg_logo_free_now = require('assets/images/apps/FN.png');
const reg_logo_glovo = require('assets/images/apps/GL.png');
const reg_logo_glovoxl = require('assets/images/apps/GX.png');
const reg_logo_jush = require('assets/images/apps/JU.png');
const reg_logo_delio = require('assets/images/apps/DE.png');
const reg_logo_stuart = require('assets/images/apps/ST.png');
const reg_logo_uber_eats = require('assets/images/apps/UE.png');
const reg_logo_uber_taxi = require('assets/images/apps/UT.png');
const reg_logo_wolt = require('assets/images/apps/WO.png');
const reg_logo_express_delivery = require('assets/images/apps/XD.png');
const reg_logo_lola_market = require('assets/images/apps/LO.png');


const reg_logo_default = require('assets/images/app/default.png');

const REG_LOGOS_MAP = {
    'BF': reg_logo_bolt_food,
    'BT': reg_logo_bolt_taxi,
    'FN': reg_logo_free_now,
    'GL': reg_logo_glovo,
    'GX': reg_logo_glovoxl,
    'JU': reg_logo_jush,    
    'DE': reg_logo_delio,
    'ST': reg_logo_stuart,
    'UE': reg_logo_uber_eats,
    'UT': reg_logo_uber_taxi,
    'WO': reg_logo_wolt,
    'XD': reg_logo_express_delivery,
    'LO': reg_logo_lola_market,
}

/*

const agreement_type_pre_ALG = require('assets/images/agreement/pre/pre_preview_img_ALG.png');
const agreement_type_pre_QA = require('assets/images/agreement/pre/pre_preview_img_QA.png');
const agreement_type_pre_AC = require('assets/images/agreement/pre/pre_preview_img_AC.png');

const agreement_type_B2B_ALG = require('assets/images/agreement/b2b/b2b_preview_img_ALG.png');
const agreement_type_B2B_QA = require('assets/images/agreement/b2b/b2b_preview_img_QA.png');
const agreement_type_B2B_AC = require('assets/images/agreement/b2b/b2b_preview_img_AC.png');

const agreement_type_zlecenie_ALG = require('assets/images/agreement/zlecenie/zlecenie_preview_img_ALG.png');
const agreement_type_zlecenie_QA = require('assets/images/agreement/zlecenie/zlecenie_preview_img_QA.png');
const agreement_type_zlecenie_AC = require('assets/images/agreement/zlecenie/zlecenie_preview_img_AC.png');
*/




const REGISTERED_PDF_THUMBNAIL_IMAGES = {
    /*
    'PRE__AC': agreement_type_pre_AC,
    'PRE__QA': agreement_type_pre_QA,
    'PRE__ALG': agreement_type_pre_ALG,
    
    'B2B__AC': agreement_type_B2B_AC,
    'B2B__QA': agreement_type_B2B_QA,
    'B2B__ALG': agreement_type_B2B_ALG,
    
    'ZLE__AC': agreement_type_zlecenie_AC,
    'ZLE__QA': agreement_type_zlecenie_QA,
    'ZLE__ALG': agreement_type_zlecenie_ALG,
    */
}
const REGISTERED_IMAGES = {
}


const DEFAULT_PDF_ICON = require('assets/images/components/icons8-pdf-65.png');
const DEFAULT_FILE_ERROR = require('assets/images/components/file-error.png');






const logo_bolt_food = require('assets/images/apps/BF.png');
const logo_bolt_taxi = require('assets/images/apps/BT.png');
const logo_free_now = require('assets/images/apps/FN.png');
const logo_glovo = require('assets/images/apps/GL.png');
const logo_glovoxl = require('assets/images/apps/GX.png');
const logo_jush = require('assets/images/apps/JU.png');
const logo_lola = require('assets/images/apps/LO.png');
const logo_delio = require('assets/images/apps/DE.png');
const logo_stuart = require('assets/images/apps/ST.png');
const logo_uber_eats = require('assets/images/apps/UE.png');
const logo_uber_taxi = require('assets/images/apps/UT.png');
const logo_wolt = require('assets/images/apps/WO.png');
const logo_express_delivery = require('assets/images/apps/XD.png');
const logo_default = require('assets/images/apps/default.png');

const LOGOS_MAP = {
    'BF': logo_bolt_food,
    'Bolt Food': logo_bolt_food,
    'BT': logo_bolt_taxi,
    'Bolt': logo_bolt_taxi,
    'FN': logo_free_now,
    'Free Now': logo_free_now,
    'GL': logo_glovo,
    'Glovo': logo_glovo,
    'GX': logo_glovoxl,
    'Glovo XL': logo_glovoxl,
    'JU': logo_jush,    
    'jush!': logo_jush,
    'DE': logo_delio,
    'Delio': logo_delio,
    'Stuart': logo_stuart,
    'ST': logo_stuart,
    'UE': logo_uber_eats,
    'Uber Eats': logo_uber_eats,
    'UT': logo_uber_taxi,
    'Uber Taxi': logo_uber_taxi,
    'WO': logo_wolt,
    'Wolt': logo_wolt,
    'xpress delivery': logo_express_delivery,
    'XD': logo_express_delivery,
    'Lola': logo_lola,
    'LO': logo_lola,
}

export const getAppSettlementLogo = (appId: string) => {

    if (LOGOS_MAP.hasOwnProperty(appId)) {
        let appKey = appId as keyof typeof LOGOS_MAP;
        return LOGOS_MAP[appKey];
    } else {
        return logo_default;
    }
}

const circle_ALG = require('assets/images/circle/ALG.png');
const circle_QA = require('assets/images/circle/QA.png');

const CIRCLE_OWN_MAP = {
    'ALG': circle_ALG,
    'QA': circle_QA,
    'AC': circle_ALG,
}

export const getCircleCompanyId = (companyId: string) => {

    if (CIRCLE_OWN_MAP.hasOwnProperty(companyId)) {
        let companyIdKey = companyId as keyof typeof CIRCLE_OWN_MAP;
        return CIRCLE_OWN_MAP[companyIdKey];
    } else {
        return circle_ALG;
    }
}

export const getAppRegistrationLogoByAppId = (appId: string) => {

    if (REG_LOGOS_MAP.hasOwnProperty(appId)) {
        let appKey = appId as keyof typeof REG_LOGOS_MAP;
        return REG_LOGOS_MAP[appKey];
    } else {
        return reg_logo_default;
    }
}
 

export const getRegisteredImage = (image: string) => {

    if (REGISTERED_IMAGES.hasOwnProperty(image)) {
        let appKey = image as keyof typeof REGISTERED_IMAGES;
        return REGISTERED_IMAGES[appKey];
    } else {
        return undefined;
    }
}


export const getRegisteredPdfThumbnail = (image: string) => {

    if (REGISTERED_PDF_THUMBNAIL_IMAGES.hasOwnProperty(image)) {
        let appKey = image as keyof typeof REGISTERED_PDF_THUMBNAIL_IMAGES;
        return REGISTERED_PDF_THUMBNAIL_IMAGES[appKey];
    } else {
        return DEFAULT_PDF_ICON;
    }
}


const mob_app_shop_android = require('assets/images/google_play.png');
const mob_app_shop_apple = require('assets/images/app_store.png');


export const IMG_UTIL = {
    getCompanyLogIn,
    getCompanyLogoHeigth,
    getCompanyRegister,
    getCompanyForgetPassword,
    empty_icon,
    getCircleCompanyId,
    DEFAULT_PDF_ICON,
    DEFAULT_FILE_ERROR,
    getSettlementSummary,
    getSettlementTransfers,
    getBanner,
    mob_app_shop_android,
    mob_app_shop_apple

}