import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import PDFViewer from "./PdfViewer";

export default function PdfViewerPage() {
    let { pdfFileId, pdfFileName } = useParams<any>();
    return (
        <>
            <div className="px-0">
                <Container fluid className="p-0">
                    <PDFViewer 
                    context="file" agreementType=""
                    pdfFileId={pdfFileId} pdfFileName={pdfFileName}></PDFViewer>
                </Container>
                </div>

        </>
    );
}