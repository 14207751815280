import { IGridDataExtended } from "store/IGridData";
import { SETTLEMENT_LIST_ACT_TYP } from "./actionsSettList";
import { yyyymmdd } from "helpers/date_helper";

const INIT_STATE: IGridDataExtended<any, any> = {
    gridData: [],
    context: {
        thisYearPayments: 0,
        thisYearSettlementsCount: 0
    },
    isLoading: false,
    reloadRequired: true,
    error: '',
    info: '',
};

const SettlementListStore = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case SETTLEMENT_LIST_ACT_TYP.LOAD_DATA:
            return { ...state, isLoading: true, error: '' };
        case SETTLEMENT_LIST_ACT_TYP.LOAD_DATA_DONE:
            return { ...state, isLoading: false, reloadRequired: false, error: action.payload.error, gridData: action.payload.gridData, context: action.payload.context, info: action.payload.info };
        case SETTLEMENT_LIST_ACT_TYP.LUMP_TAX_PAID_DONE:
            return {
                ...state, context: {
                    ...state.context,
                    leaseLumpSumTaxInfo: {
                        ...state.context.leaseLumpSumTaxInfo,
                        paidDate: yyyymmdd(),
                    },
                },
            };
        case SETTLEMENT_LIST_ACT_TYP.MARK_PRESENTED_DONE:
            return {
                ...state,
                gridData: state.gridData.map((item: any) =>
                    item.id === action.payload.settlementId ?
                        { ...item, markedAsPresented: action.payload.markedAsPresented } : item
                ),
            };
        case SETTLEMENT_LIST_ACT_TYP.API_ERROR:
            return { ...state, isLoading: false, error: action.payload.error, };
        default:
            return state;
    }
}

export default SettlementListStore;