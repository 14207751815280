import languages from "common/languages";
import i18n from "i18n";
import { get } from "lodash";
import { useEffect, useState } from "react";

export const getCurrentLanguageFromLocalStorage = () => {
	const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
	if (currentLanguage && languages.hasOwnProperty(currentLanguage)) {
		return currentLanguage || 'pl';
	}
	return 'pl';
}

const Header20WebLanguageChangeMenu = () => {

	const [selectedLang, setSelectedLang] = useState("pl");
	const [allFlagsVisibility, setAllFlagsVisibility] = useState(false);

	useEffect(() => {
		setSelectedLang(getCurrentLanguageFromLocalStorage());
	}, [])

	const changeLanguageAction = (lang: string) => {

		if (allFlagsVisibility) {
			//set language as i18n
			i18n.changeLanguage(lang);
			localStorage.setItem("I18N_LANGUAGE", lang);
			setSelectedLang(lang);
			setAllFlagsVisibility(false)
		} else {
			setAllFlagsVisibility(true)
		}
	}

	const getFlagClass = (lang: string) => {
		if (allFlagsVisibility) {
			return "flag";
		}
		if (lang !== selectedLang) {
			return "d-none";
		} else {
			return "flag";
		}
	}

	const getCurrentLanguageFlagIcon = () => {
		let dummy: any = languages;
		return dummy[selectedLang].flag20;
	}
	const getCurrentLanguageFlagLabel = () => {
		let dummy: any = languages;
		return dummy[selectedLang].label;
	}
	return (
		<div className="language-web">

			{Object.entries(languages).map(([keyName, lanObj], i) => {
				if (keyName === selectedLang) {
					return <div  key={'flag_key:'+keyName} ></div>
				}
				return <img key={'flag_key:'+keyName} onClick={() => changeLanguageAction(keyName)} className={getFlagClass(keyName)} src={lanObj.flag20} alt={lanObj.label} />
			}
			)}
			<img onClick={() => changeLanguageAction(selectedLang)} className="flag" src={getCurrentLanguageFlagIcon()} alt={getCurrentLanguageFlagLabel()} />
		</div>
	)
}

export default Header20WebLanguageChangeMenu;