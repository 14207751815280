import { DialogWrapper } from "components/Layout20/DialogWrapper";
import { toPln } from "helpers/pln_helper";
import { useSelector } from "react-redux";
import DashboardItem from "./DashboardItem";

const DashboardSubDialog01Summary = () => {
 
  const { dashboardDto } = useSelector((state: any) => ({ dashboardDto: state.Dashboard.dashboardDto, }));

 
  return  (
    <DialogWrapper title="Dashboard.Summary">
    <>
      <DashboardItem titleKey="Dashboard.Item.Balance"
        valueFormatted={toPln(dashboardDto.balance)}
        valueClass={dashboardDto.balance >= 0 ? "money-positive" : "money-negative"}
      />
      {dashboardDto.lastPaymentDate &&
        <DashboardItem titleKey="Dashboard.Item.LastPayment"
          valueFormatted={toPln(dashboardDto.lastPaymentAmount)}
          fixedValue={dashboardDto.lastPaymentDate}
        />
      }
    </>
  </DialogWrapper>
  )
};


export default DashboardSubDialog01Summary; 