import { ENDPOINT_INVOICE, get } from "helpers/api_helper";
import { ISagaParam, ResponseGridData } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { INVOICE_LIST_ACT, INVOICE_LIST_ACT_TYP } from "./actionsInvList";
import { showException } from "helpers/sweetalert/ShowAlert";

function* loadData(action: ISagaParam) {
    try {
        let gridResponse: ResponseGridData<any> = yield call(get, ENDPOINT_INVOICE.RESOURCE_GET_INVOICE, {})
        yield put(INVOICE_LIST_ACT.loadDataDone(gridResponse.gridData, gridResponse.info, gridResponse.error));
    } catch (error) {
        console.log('invoiceList.loadData error', error);
        showException(error);
        yield put(INVOICE_LIST_ACT.apiError(error));
    }
}

export function* InvoiceListSaga() {
    yield takeEvery(INVOICE_LIST_ACT_TYP.LOAD_DATA, loadData);
}

export default InvoiceListSaga;
