import UploadedFilesPreview from "components/MyComponents/custom-dropzone/UploadedFilesPreview";
import { useTranslation } from "react-i18next"

interface DetailsItemProps {
    labelKey: string,
    files: any;
}

export const DetailsFiles = ({  files }: DetailsItemProps) => {
    if (!files || files.length == 0) {
        return <></>
    }
    return <UploadedFilesPreview files={files} />
}

