import firebase from 'firebase/compat/app'

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { sleepMs } from './tsx_helper';
class FirebaseAuthBackend {

  constructor(firebaseConfig) {

    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().languageCode = 'pl';
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user));
          //console.log('onAuthStateChanged [+]')
        } else {
          localStorage.removeItem("authUser");
          //console.log('onAuthStateChanged [-]')
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase.auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          userCredential => {
            resolve(userCredential.user.sendEmailVerification());
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };


  /**
   * Remove user the user with given details
   */
  deleteCurrentUser = () => {
    firebase.auth().currentUser.delete()
  };


  loginUser = async (email, password) => {

    //stałe zapamiętywanie sesji
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    const response = await firebase.auth().signInWithEmailAndPassword(email, password)
      .then(function (result) {
        return result;
      })
      .catch(function (error) {
        throw error;
      });
    return response.user;
  };



  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    firebase.auth().languageCode = 'pl'; //TODO ustawienia jezykowe
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };


  reload = async () => {
    try {
      //miał zapisanego usera to poczekaj na ustawienie stanu autentykacji przez firebase.auth().onAuthStateChanged
      if (localStorage.getItem("authUser") !== null) {
        //oczekiwanie na przeładowanie - czekamy max 10s (50 iteracji po 200ms)
        let wiatIterationCnt = 0;
        while (!firebase.auth().currentUser) {
          //wywalony przez  firebase.auth().onAuthStateChanged
          if (localStorage.getItem("authUser") === null) {
            break;
          }
          //przekroczono limit oczekiwania na  firebase.auth().onAuthStateChanged
          if (wiatIterationCnt++ >= 50) {
            break;
          }
          await sleepMs(200);
        }
      }

      //jeśli znalazł to przeładowujemy token
      if (firebase.auth().currentUser) {
        await firebase.auth().currentUser.getIdTokenResult(true)
        const user = await firebase.auth().currentUser.multiFactor.user;
        localStorage.setItem("authUser", JSON.stringify(user));
        //console.log('REFRESH 1 user refreshed json ', JSON.stringify(user));
        return user;
      } else {
        console.log('firebase.refresh - user not found after 15s');
      }

    } catch (e) {
      console.log('firebase.reload getIdTokenResult error:', e)
    }
    return null;
  };


  /**
   * Logout the user
   */
  logout = async () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("x-spirit-id");

    firebase.auth().languageCode = 'pl'; //TODO ustawienia jezykowe
    await firebase
      .auth()
      .signOut()
      .then(() => { })
      .catch(error => {
        console.log('firebase.logout error:', error)
      });
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
