import { YUP_VALIDATION } from 'helpers/YupHelper';
import * as Yup from 'yup';
import { ADDRESS_DATA_FORM_VALIDATION_RULES_BASIC, ADDRESS_DATA_FORM_VALIDATION_RULES_BASIC_NOT_REQUIRED, ADDRESS_DATA_FORM_VALIDATION_RULES_EXTENDED } from '../customFormAddressData/AgreementPage2FormAdressData';
import { QUESTIONS_VALIDATION_AC_ALG, QUESTIONS_VALIDATION_QA } from '../customFormQuestions/AgreementPage2FormQuestions';
import { FORM_ADDRESS_DATA_BASIC, FORM_ADDRESS_DATA_BASIC_SECOND_NOT_REQUIRED, FORM_ADDRESS_DATA_EXTENDED, FORM_QUESTIONS_ALG_AC, FORM_QUESTIONS_QA } from '../CustomForms';
import { get_single_value, IAgreementFormItem, IDataMap, IFormPage, IPageDataItemValue, PHONE_TYPE_PREFIX, PHONE_TYPE_SUFFIX } from "../IAgreement";

const isFixedCondition = (f: IAgreementFormItem, pageConfig: IFormPage, allData: IDataMap) => {

    if (!f.dependentOn) {
        return false;
    }

    if (pageConfig.fieldsMap.hasOwnProperty(f.dependentOn)) {
        return false;
    }

    const fieldMetaValue = get_single_value(allData[f.dependentOn], f.name);

    if (f.dependentOnEqual) {
        return '' + fieldMetaValue == '' + f.dependentOnEqual;
    }
    if (f.dependentOnNotEqual) {
        return '' + fieldMetaValue != '' + f.dependentOnNotEqual;
    }
    return false;
}

export const getDynamicFormValidation = (pageConfig: IFormPage, allData: IDataMap) => {

    if (pageConfig.customForm === FORM_ADDRESS_DATA_BASIC) {
        return ADDRESS_DATA_FORM_VALIDATION_RULES_BASIC;
    }
    if (pageConfig.customForm === FORM_ADDRESS_DATA_BASIC_SECOND_NOT_REQUIRED) {
        return ADDRESS_DATA_FORM_VALIDATION_RULES_BASIC_NOT_REQUIRED;
    }
    if (pageConfig.customForm === FORM_ADDRESS_DATA_EXTENDED) {
        return ADDRESS_DATA_FORM_VALIDATION_RULES_EXTENDED;
    }

    if (pageConfig.customForm === FORM_QUESTIONS_ALG_AC) {
        return QUESTIONS_VALIDATION_AC_ALG;
    }
    if (pageConfig.customForm === FORM_QUESTIONS_QA) {
        return QUESTIONS_VALIDATION_QA;
    }

    var validators: Record<string, any> = {}
    pageConfig.formikFields.forEach(f => {

        const ifFix = isFixedCondition(f, pageConfig, allData);

        //nie ma zależności lub ma spoza pól posiadanych
        if (!f.dependentOn || ifFix === true) {
            switch (f.validation) {
                case 'STRING_REQ':
                case 'STRING_REQ_SELECT':
                case 'STRING_REQ_MULTISELECT':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING_REQ;
                    break;
                case 'DATE_REQ':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE_REQ;
                    break;
                case 'DATE_BEFORE_NOW':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE_BEFORE_NOW;
                    break;
                case 'DATE_AFTER_NOW':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE_AFTER_NOW;
                    break;
                case 'DATE_AFTER_NOW_OR_EMPTY':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE_AFTER_NOW_OR_EMPTY;
                    break;
                case 'DATE_AT_LEAST_16Y_OLD':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE_AT_LEAST_16Y_OLD;
                    break;
                case 'DATE_AT_LEAST_18Y_OLD':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE_AT_LEAST_18Y_OLD;
                    break;

                case 'STRING_REQ_EMAIL':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING_REQ_EMAIL;
                    break;
                case 'STRING_REQ_PHONE':
                    validators[f.name + PHONE_TYPE_PREFIX] = YUP_VALIDATION.YUP_STRING_REQ_PHONE_PREFIX;
                    validators[f.name + PHONE_TYPE_SUFFIX] = YUP_VALIDATION.YUP_STRING_REQ_PHONE_SUFFIX;
                    break;
                case 'STRING_PHONE':
                    validators[f.name + PHONE_TYPE_PREFIX] = YUP_VALIDATION.YUP_STRING_PHONE_PREFIX;
                    validators[f.name + PHONE_TYPE_SUFFIX] = YUP_VALIDATION.YUP_STRING_PHONE_SUFFIX;
                    break;
                case 'STRING_REGEXP_REQUIRED':
                    validators[f.name] = YUP_VALIDATION.YUP_REGEXP_REQUIRED(f.regexpFormula);
                    break;
                case 'STRING_REGEXP':
                    validators[f.name] = YUP_VALIDATION.YUP_REGEXP(f.regexpFormula);
                    break;
            }

            //ma dependentOn z warunkiem equal i posiada warunkowe property
        } else if (f.dependentOnEqual && pageConfig.fieldsMap.hasOwnProperty(f.dependentOn)) {
            switch (f.validation) {
                case 'STRING_REQ':
                case 'STRING_REQ_SELECT':
                case 'STRING_REQ_MULTISELECT':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_STRING_REQ, });
                    break;
                case 'DATE_REQ':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_DATE_REQ, });
                    break;
                case 'DATE_BEFORE_NOW':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_DATE_BEFORE_NOW, });
                    break;
                case 'DATE_AFTER_NOW':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_DATE_AFTER_NOW, });
                    break;
                case 'DATE_AFTER_NOW_OR_EMPTY':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_DATE_AFTER_NOW_OR_EMPTY, });
                    break;
                case 'DATE_AT_LEAST_16Y_OLD':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_DATE_AT_LEAST_16Y_OLD, });
                    break;
                case 'DATE_AT_LEAST_18Y_OLD':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_DATE_AT_LEAST_18Y_OLD, });
                    break;
                case 'STRING_REQ_EMAIL':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_STRING_REQ_EMAIL, });
                    break;
                case 'STRING_REQ_PHONE':
                    validators[f.name + PHONE_TYPE_PREFIX] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_STRING_REQ_PHONE_PREFIX, });
                    validators[f.name + PHONE_TYPE_SUFFIX] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_STRING_REQ_PHONE_SUFFIX, });
                    break;
                case 'STRING_PHONE':
                    validators[f.name + PHONE_TYPE_PREFIX] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_STRING_PHONE_PREFIX, });
                    validators[f.name + PHONE_TYPE_SUFFIX] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_STRING_PHONE_SUFFIX, });
                    break;
                case 'STRING_REGEXP_REQUIRED':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_REGEXP_REQUIRED(f.regexpFormula), });
                    break;
                case 'STRING_REGEXP':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnEqual, then: YUP_VALIDATION.YUP_REGEXP(f.regexpFormula), });
                    break;


            }
            //ma dependentOn z warunkiem not equal i posiada warunkowe property
        } else if (f.dependentOnNotEqual && pageConfig.fieldsMap.hasOwnProperty(f.dependentOn)) {
            switch (f.validation) {
                case 'STRING_REQ':
                case 'STRING_REQ_SELECT':
                case 'STRING_REQ_MULTISELECT':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_STRING_REQ, });
                    break;
                case 'DATE_REQ':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_DATE_REQ, });
                    break;
                case 'DATE_BEFORE_NOW':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_DATE_BEFORE_NOW, });
                    break;
                case 'DATE_AFTER_NOW':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_DATE_AFTER_NOW, });
                    break;
                case 'DATE_AFTER_NOW_OR_EMPTY':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_DATE_AFTER_NOW_OR_EMPTY, });
                    break;
                case 'DATE_AT_LEAST_16Y_OLD':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_DATE_AT_LEAST_16Y_OLD, });
                    break;
                case 'DATE_AT_LEAST_18Y_OLD':
                    validators[f.name] = YUP_VALIDATION.YUP_DATE.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_DATE_AT_LEAST_18Y_OLD, });
                    break;
                case 'STRING_REQ_EMAIL':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_STRING_REQ_EMAIL, });
                    break;
                case 'STRING_REQ_PHONE':
                    validators[f.name + PHONE_TYPE_PREFIX] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_STRING_REQ_PHONE_PREFIX, });
                    validators[f.name + PHONE_TYPE_SUFFIX] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_STRING_REQ_PHONE_SUFFIX, });
                    break;
                case 'STRING_PHONE':
                    validators[f.name + PHONE_TYPE_PREFIX] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_STRING_PHONE_PREFIX, });
                    validators[f.name + PHONE_TYPE_SUFFIX] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_STRING_PHONE_SUFFIX, });
                    break;

                case 'STRING_REGEXP_REQUIRED':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_REGEXP_REQUIRED(f.regexpFormula), });
                    break;
                case 'STRING_REGEXP':
                    validators[f.name] = YUP_VALIDATION.YUP_STRING.when(f.dependentOn, { is: f.dependentOnNotEqual, otherwise: YUP_VALIDATION.YUP_REGEXP(f.regexpFormula), });
                    break;
            }
        }
    });
    return Yup.object().shape(validators);
}

//kind: "TEXT_FIELD" | "DATE_FIELD" | "CHECK_BOX" | "SELECT_SINGLE" | "SELECT_MULTI" | "NUMBER_FIELD" | "PHONE_FIELD",
const getValue = (kind: string, value: IPageDataItemValue) => {
    if (kind === "CHECK_BOX") {
        return value.booleanValue;
    }
    if (kind === "NUMBER_FIELD") {
        return value.numberValue;
    }
    if (kind === "PHONE_FIELD" + PHONE_TYPE_PREFIX) {
        return value.phonePrefixValue;
    }
    if (kind === "PHONE_FIELD" + PHONE_TYPE_SUFFIX) {
        return value.phoneSuffixValue;
    }
    if (kind === "DATE_FIELD") {
        return value.dateValue;
    }
    return value.stringValue;
}

export const getDynamicFormData = (fields: IAgreementFormItem[], values: { [index: string]: IPageDataItemValue }) => {


    var initialData: Record<string, any> = {}
    fields.forEach(f => {
        if (f.isFormik) {

            if (f.kind == "PHONE_FIELD") {
                initialData[f.name + PHONE_TYPE_PREFIX] = getValue(f.kind + PHONE_TYPE_PREFIX, values[f.name]);
                initialData[f.name + PHONE_TYPE_SUFFIX] = getValue(f.kind + PHONE_TYPE_SUFFIX, values[f.name]);
            }
            else {
                initialData[f.name] = getValue(f.kind, values[f.name]);
            }
        }
    })

    return initialData;
}
