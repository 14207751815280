import { FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import MaskedInput, { Mask } from "react-text-mask";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import { boolean } from "yup";
import TranslatedErrors from "./TranslatedErrors";

interface IMaskedTextProps extends FieldProps {
  placeholder?: string;
  label?: string;
  textAfter?: string;
  textBefore?: string;
  isDisabled?: boolean;
  mask: Mask | ((value: string) => Mask);
  autoFocus?: boolean;
}

export const MaskedText = ({
  placeholder,
  label,
  field,
  form,
  textBefore,
  textAfter,
  isDisabled,
  mask,
  autoFocus

}: IMaskedTextProps) => {
  const { t } = useTranslation();
  if (field.value === undefined) {
    return <></>
  }
  const hasError = form.errors[field.name] && form.touched[field.name];
  return (
    <>
      {textBefore &&
        <FormGroup  >
          <FormText>{t(textBefore)}</FormText>
        </FormGroup>
      }
      <FormGroup>
        {label &&
          <Label htmlFor={field.name}>{t(label)}</Label>
        }
        {isDisabled && //DRUT 
          <Input type="text" disabled={isDisabled} autoComplete="off"
            value={field.value}
            name={field.name}
            id={field.name}
            placeholder={placeholder}
            className={"form-control" + (hasError ? " is-invalid" : "")}
          />
        }
        {!isDisabled &&
          <MaskedInput mask={mask} type="text"
            {...field}
            disabled={isDisabled} autoComplete="off"
            name={field.name}
            id={field.name}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={"form-control" + (hasError ? " is-invalid" : "")}
          />
        }
        {hasError &&
          <TranslatedErrors errors={form.errors[field.name]} />
        }
        {textAfter &&
          <FormText>{t(textAfter)}</FormText>
        }
      </FormGroup>
    </>
  );
};

export default MaskedText;
