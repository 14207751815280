import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Layout20Store from "./layout/reducerLayout20"

// Authentication
import LoginState from "./auth/login/reducerLogin"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"


import PreRegistration from "./auth/pre-registration/reducerRegPre"
import AgreementSignStore from  "../pages/Agreement/store-pre/reducerAgr"
import AgreementPreListStore from  "../pages/Agreement/store-pre-list/reducerPreAgrList"

import NotificationsListStore from "pages/NotificationsList/store/reducerNotificationsList"
import AgreementListStore from "pages/AgreementList/store/reducerAgrList"
import SettlementListStore from "pages/SettlementList/store/reducerSettList"
import InvoiceListStore from "pages/InvoiceList/store/reducerInvList"

import AttachmentListStore from "pages/AttachmentList/store/reducerAttList"
import AttachmentNewStore from "pages/AttachmentNew/store/reducerAttNew"
import InvoiceNewStore from "pages/InvoiceNew/store/reducerInvNew"
 
import AffiliationStore from "./affiliation/reducerAffiliation"
import AffiliationLinkStore from "pages/AffiliationLink/store/reducerAffiliationLink"
import ProfileStore from "pages/Profile/store/reducerProfile"
import ProfileRemovalStore from "pages/ProfileRemoval/store/reducerProfileRemoval"
import IbanChangeActionStore from "pages/ProfileBankAccount/store/reducerIbanChange"

import ContactFormStore from "pages/ContactForm/store/reducerContactForm"
import DalAddStore from "pages/DealAdd/store/reducerDealAdd"

import Docs2SignListStore from "pages/Docs2SignList/store/reducerDocs2SignList"


//Dashboard 
import Dashboard from "./dashboard/reducer";
import ConfigStore from "./configuration/reducerConfig";
 
const rootReducer = combineReducers({
  // public
  Layout,
  LoginState,
  Account,
  ForgetPassword,
  Dashboard,
  PreRegistration,
  AgreementSignStore,
  AgreementPreListStore,
  AgreementListStore,
  SettlementListStore,
  InvoiceListStore,
  AttachmentListStore,
  AttachmentNewStore,
  InvoiceNewStore,
  AffiliationStore,
  ConfigStore,
  ProfileStore,
  ContactFormStore,
  ProfileRemovalStore,
  AffiliationLinkStore,
  IbanChangeActionStore,
  Layout20Store,
  DalAddStore,
  NotificationsListStore,
  Docs2SignListStore
})

export default rootReducer
