import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import FileUploaderWithPreview from "components/MyComponents/custom-dropzone/FileUploaderPreview";
import CustomCheckbox from "components/MyComponents/CustomCheckbox";
import CustomDate from "components/MyComponents/CustomDate";
import CustomSelect, { SELECT_SINGLE_DEFAULT_PLACEHOLDER, translateLabels } from "components/MyComponents/CustomSelect";
import CustomText from "components/MyComponents/CustomText";
import { Field, useField } from "formik";
import { getMoneyString } from "helpers/money";
import HelperHeaderContent from "pages/Agreement/FormItems/HelperHeaderContent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import InvoiceNewFormItems from "./InvoiceNewFormItems";
import { INVOICE_NEW_ACT } from "./store/actionsInvNew";
import { AlertFrameInfo } from "components/Common/datatable/AlertFrameInfo";
import { AlertFrameError } from "components/Common/datatable/AlertFrameError";

export interface IInvoiceNewForm {
  isLoading: boolean;
}

const InvoiceNewForm = ({ isLoading }: IInvoiceNewForm) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showError = (error: string) => {
    dispatch(INVOICE_NEW_ACT.apiError(error))
  }
  const [selfDescribed] = useField("selfDescribed");
  const [files] = useField("files");

  const isSelfDescribed = selfDescribed.value;

  const invoiceTypes = useSelector((state: any) => state.ConfigStore.config.invoiceTypes);
  const parameters = useSelector((state: any) => state.ConfigStore.config.parameters);
  const invoiceTypesTranslated = translateLabels(invoiceTypes, t);

  return (
    <>
      <Row>
        <Col xs={12} className="py-3">
          <Field name="selfDescribed" label="INV_NEW.fields.selfDescribed" component={CustomCheckbox} type="checkbox" isDisabled={isLoading} />
        </Col>
      </Row>
      {!isSelfDescribed &&
        <AlertFrameInfo
          messageTitle='INV_NEW.add-invoice.selfDescribed.withCost'
          messageKey='INV_NEW.add-invoice.selfDescribed.withCost.textAfter'
          iconClass="fa-solid fa-comment-dollar"
          translationPlaceholdersOject={{
            'KOSZT_OPISU_FAKTURY': getMoneyString(parameters['KOSZT_OPISU_FAKTURY'])
          }}
          
        />
      }

      {isSelfDescribed &&
        <Row>
          <Col xs={12} md={5} >
            <Field name="invoiceType" label="INV_NEW.fields.invoiceType"
              placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
              options={invoiceTypesTranslated} className="custom-select" component={CustomSelect} />
          </Col>
          <Col xs={12} md={4}>
            <Field name="invoiceNumber" label="INV_NEW.fields.invoiceNumber" component={CustomText} isDisabled={isLoading} />
          </Col>
          <Col xs={12} md="auto">
            <Field name="invoiceDate" label="INV_NEW.fields.invoiceDate" component={CustomDate} isDisabled={isLoading} />
          </Col>
          <Col xs={12}>
            <HelperHeaderContent headerText="INV_NEW.items.header" />
          </Col>
          <Col xs={12}>
            <AlertFrameInfo messageKey="INV_NEW.items.text" thinMargin={true} />
          </Col>
          <InvoiceNewFormItems isLoading={isLoading} />
        </Row>
      }

      <HelperHeaderContent headerText="INV_NEW.files.header" textAfter="INV_NEW.files.text" />
      <FileUploaderWithPreview showError={showError} />
      <Row className="py-3">
        <Col>
          <ButtonSubmit
            iconClassPre="fa-regular fa-circle-check fa-xl"
            disabled={files.value.length === 0}
            id="button-invoice-new"
            isLoading={isLoading} label="INV_NEW.Submit" />
        </Col>
      </Row>
    </>
  )
}
export default InvoiceNewForm; 