import { LAYOUT20_ACT_TYP } from "./actionsLayout20";

export type DialogEnum = "profileData" | "profileSettement" | "profileIban" | "profileAdmin";

export interface IContactFormReducer {
    sidebarVisible: boolean,
    dialogVisibilityMap: Record<DialogEnum, boolean>
}

const INIT_STATE: IContactFormReducer = {
    sidebarVisible: false,

    dialogVisibilityMap: {
        profileData: true,
        profileIban: true,
        profileSettement: false,
        profileAdmin: false,
    }
};

const Layout20Store = (state = INIT_STATE, action: { type: any, payload: any }) => {
    switch (action.type) {
        case LAYOUT20_ACT_TYP.OPEN_SIDEBAR:
            return { ...state, sidebarVisible: true };
        case LAYOUT20_ACT_TYP.CLOSE_SIDEBAR:
            return { ...state, sidebarVisible: false };
        case LAYOUT20_ACT_TYP.TOGGLE_DIALOG:
            let dialogEnum = action.payload.dialogEnum as DialogEnum;
             let visibilityMapMod = state.dialogVisibilityMap;
             visibilityMapMod[dialogEnum] = !visibilityMapMod[dialogEnum];
            return {
                ...state,
                dialogVisibilityMap: {
                    ...state.dialogVisibilityMap,
                    visibilityMapMod,
                },
            }
        default:
            return state;
    }
}

export default Layout20Store;