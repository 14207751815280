import React from "react";
import ButtonCustom from "../ButtonCustom";

const MobileFiles = props => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target;//.files[0];
    props.handleFile(event.target);
  };
  return (
    < >
      <ButtonCustom
        id="button-add-file"
        color="default"
        outline={true}
        className='blue'
        label={props.buttonLabel}
        iconClassPre="fa-solid fa-camera  fa-xl blue"
        iconClassPre2="fa-solid fa-file-circle-plus fa-xl blue"
        onClick={handleClick} />

      <input
      capture="true" 
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept=".png, .jpg, .jpeg ,.pdf"
      />
    </>
  );
}
export default MobileFiles;