import ButtonCustom from "components/MyComponents/ButtonCustom";
import { Trans, useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap";

export interface AlertFrameGenericProps {
    messageKey: string | undefined;
    messageTitle?: string;
    color: "red" | "orange";
    iconClass?: string;
    iconFileSrc?: string;
    iconAnimation?: "fa-beat" | "fa-fade" | "fa-beat-fade" | "fa-bounce" | "fa-flip" | "fa-shake" | "fa-spin";
    iconSize?: "fa-2xs" | "fa-xs" | "fa-sm" | "fa-lg" | "fa-xl" | "fa-2xl" | "fa-1x" | "fa-2x" | "fa-3x" | "fa-4x" | "fa-5x" | "fa-6x" | "fa-7x";

    buttonId?: string;
    buttonTitleKey?: string;
    buttonColor?: string;
    buttonOnClickAction?: () => void;

    translationPlaceholdersOject?: object;
    thinMargin?: boolean;


}

export const AlertFrameGeneric = ({
    messageKey, messageTitle,
    color,
    iconFileSrc,
    iconClass, iconAnimation = "fa-beat-fade", iconSize = "fa-2x",
    buttonId, buttonTitleKey, buttonOnClickAction, buttonColor,
    translationPlaceholdersOject = {},
    thinMargin = false

}: AlertFrameGenericProps) => {
    
    
    return (
        <>
            {messageKey &&
                <section className={"warning "+(thinMargin? "warning-thin " :"")+ "warning-" + color}>
                    <Row className="items-center px-1">
                        <Col xs={12} lg={buttonTitleKey ? 9 : 12}>
                            <div className="warning-txt">
                                {iconClass && <i className={`${iconClass}  warning-${color} ${iconAnimation} ${iconSize}`} ></i>}
                                {iconFileSrc && <img src={iconFileSrc} className="to-white px-2" alt=""></img>}
                                <p>
                                    {messageTitle &&
                                        <h4>
                                            <Trans i18nKey={messageTitle}
                                                values={translationPlaceholdersOject}
                                                components={{ italic: <i />, bold: <strong />, br: <br /> }} />
                                        </h4>
                                    }
                                    <Trans i18nKey={messageKey}
                                        values={translationPlaceholdersOject}
                                        components={{ italic: <i />, bold: <strong />, br: <br /> }} />
                                </p>
                            </div>
                        </Col>
                        {(buttonId && buttonTitleKey) &&
                            <Col xs={12} lg={3} className="pt-2 pt-xl-0 text-start text-md-end">
                                <ButtonCustom id={buttonId}
                                    label={buttonTitleKey}
                                    color={buttonColor}
                                    onClick={buttonOnClickAction}
                                />
                            </Col>
                        }
                    </Row>
                </section>
            }
        </>
    )
}