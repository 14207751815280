export const toPln = (val: number | string) => 
{
    return  formatNumber(Number(val)) + ' zł'; 
}

export const toPlnDense = (val: number | string) => 
{
    return  formatNumber(Number(val)) + 'zł'; 
}


export const toCurrencyWithoutSuffix = (val: number | string) => 
{
    return   formatNumber(Number(val)); 
}


export const toPercentage = (val: number | string) => 
{
    return  (Math.round(( Number(val) + Number.EPSILON) * 100) / 100).toString()+ "%"; 
}



export const toPlnWithoutSuffix = (val: number | string) => 
{
    return formatNumber(Number(val)); 
}



export function formatNumber(number: number) {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).replace(',', ' ');
  }
