import { ENDPOINTS, get } from "helpers/api_helper";
import { showException } from "helpers/sweetalert/ShowAlert";
import { ISagaParam, ResponseGridData } from "helpers/tsx_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { NOTIFICATIONS_LIST_ACT, NOTIFICATIONS_LIST_ACT_TYP } from "./actionsNotificationsList";
import { fillPlaceholders } from "routes/placeholder_helper";
import { IDataResponse } from "helpers/IDataResponse";

function* loadData(action: ISagaParam) {
    try {
        let gridResponse: ResponseGridData<any> = yield call(get, ENDPOINTS.RESOURCE_NOTIFICATIONS, {})
        const sortedData = getSortedData(action.payload.prefferedNotificationId, gridResponse.gridData);
        yield put(NOTIFICATIONS_LIST_ACT.loadDataDone(sortedData, gridResponse.info));
    } catch (error) {
        console.log('notificationsList.loadData error', error);
        showException(error);
        yield put(NOTIFICATIONS_LIST_ACT.apiError(error));
    }
}

const getSortedData = (prefferedNotificationIdIn: string | undefined, gridData: any[]): any[] => {

    if (!prefferedNotificationIdIn || Number.isNaN(prefferedNotificationIdIn)) {
        return gridData;
    }
    const preferredNotificationId: number = Number(prefferedNotificationIdIn);
    const preferredIndex = gridData.findIndex(item => item.id === preferredNotificationId);

    // If the preferred item is found, move it to the first position
    if (preferredIndex !== -1) {
        const preferredItem = gridData[preferredIndex];
        gridData.splice(preferredIndex, 1); // Remove the item from its original position
        gridData.unshift(preferredItem); // Add it to the beginning of the array
    }
    return gridData;
}


function* markAsPresented(action: ISagaParam) {
    try {
        const url = fillPlaceholders(ENDPOINTS.RESOURCE_NOTIFICATIONS_ID_READ, { 'notificationId': action.payload.notificationId });
        let dto: IDataResponse<any> = yield call(get, url, {})
        yield put(NOTIFICATIONS_LIST_ACT.markAsPresentedDone(dto.data.notificationId, dto.data.markedAsPresented));
        yield put(NOTIFICATIONS_LIST_ACT.contextSet(dto.data.notificationContext, ''));
    } catch (error) {
        console.error('notificationsList.markAsPresented error', error);
    }
}

function* buttonClicked(action: ISagaParam) {
    try {
        const notificationDTO = action.payload.notificationDTO;

        if (notificationDTO.contentNotificationRoute === 'EXT') {
            window.open(notificationDTO.contentNotificationRouteExternalUrl, '_blank', 'noreferrer');
        } else {
            action.payload.history.push(notificationDTO.contentNotificationRoute);
        }

        //klikniecie nie oznaczone
        if (!action.payload.markedAsButtonClicked) {
            const url = fillPlaceholders(ENDPOINTS.RESOURCE_NOTIFICATIONS_ID_CLICK, { 'notificationId': notificationDTO.id });
            let dto: IDataResponse<any> = yield call(get, url, {})
            yield put(NOTIFICATIONS_LIST_ACT.buttonClickedMarked(dto.data.notificationId, dto.data.markedAsButtonClicked));
            yield put(NOTIFICATIONS_LIST_ACT.contextSet(dto.data.notificationContext, ''));
        }

    } catch (error) {
        console.error('notificationsList.markAsButtonClicked action', action);
        console.error('notificationsList.markAsButtonClicked error', error);
    }
}

export function* NotificationsListSaga() {
    yield takeEvery(NOTIFICATIONS_LIST_ACT_TYP.MARK_PRESENTED, markAsPresented);
    yield takeEvery(NOTIFICATIONS_LIST_ACT_TYP.BUTTON_CLICKED, buttonClicked);
    yield takeEvery(NOTIFICATIONS_LIST_ACT_TYP.LOAD_DATA, loadData);
}

export default NotificationsListSaga;
