import { useTranslation } from "react-i18next";
import './horizontalDivider.scss';

interface IHorizontalDivider {
  labelKey: string;
  className?: string;
}

export const HorizontalDivider = ({ labelKey, className="" }: IHorizontalDivider) => {
  const { t } = useTranslation();

  return (
    <div className={"hr-sect "+className} >{t(labelKey)}</div>
  );
};

export default HorizontalDivider;
